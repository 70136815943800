import { AlertContext, CardsSixty, Main_Loader, Main_Text_Loader, On_Click_Card, PageAlwaysVisible, PageSixty, PageTitle, Partial_Loader, Sixty_Card_Loader } from 'monica-alexandria'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import a from '../../services/api/Ads';
import store from '../../redux/store';
import { useNavigate, useParams } from 'react-router-dom';
import { alertMessage } from '../../utils/messagesAlerts';

const minioUrl = process.env.REACT_APP_MINIO_PROTOCOL +
                process.env.REACT_APP_MINIO_HOST + 
                process.env.REACT_APP_MINIO_PORT+ '/' +
                process.env.REACT_APP_MINIO_BUCKET + '/';

export default function AD_PAGES() {

    const {t} = useTranslation();
    const [ads, setAds] = useState([]);
    const navigate = useNavigate();
    const {lang, questId} = useParams();
    const {alerts, setAlerts} = useContext(AlertContext);
    const [loading, setLoading] = useState(false);
    const [adsLoading, setAdsLoading] = useState(true);
    const facebookPageId = store.getState()?.metaSource?.page_selection_info?.id;

    const get_my_ads = () => {

        a.getMyAds()
        .then(res => {
            console.log('RESPONSE GET MY ADS' , res);
            if (res?.data?.code == 'A2006') {
                setAds(res?.data?.ads);
            }
            else {
                alertMessage(alerts, setAlerts, (t('Something went wrong')), 'Error');
            }
            setAdsLoading(false);
        })
    }

    const clone_ad = (id) => {
        setLoading(true);
        a.cloneAd(id, facebookPageId)
          .then(res => {
            console.log('CLONE AD' , res);
            if (res?.data?.code == 'A2005' && questId) {
                navigate(`/${lang}/edit-ad/${questId}`);
            }
            else if (res?.data?.code == 'A2005') {
                navigate(`/${lang}/create-ad`);
            }
            else {
                alertMessage(alerts, setAlerts, (t('Something went wrong')), 'Error');
            }
            setLoading(false);
        })
    }

    useEffect(()=>{
        get_my_ads();
    }, [])

    console.log('ads', ads);

    if (loading) return <Main_Text_Loader text={t('Cloning ad and uploading files...')}/>
    return (
    <PageSixty>
        <PageAlwaysVisible>
            <PageTitle>
                <h1>{t('Select ad page')}</h1>
            </PageTitle>
            {adsLoading ? 
                 <Sixty_Card_Loader />
            :
                <CardsSixty>
                    {ads?.map((page) => (
                        <On_Click_Card
                            title={page?.title}
                            imgSmall={`${minioUrl}/${page?.userId}/${page?.visualElement}`}
                            onClick = {()=> clone_ad(page?._id)}
                        />
                    ))}
                </CardsSixty>
            }
        </PageAlwaysVisible>
    </PageSixty>
  )
}
