import { Builder_, CardsFull, PageFull, PageTitle, Slider_, Upload_Avatar } from 'monica-alexandria'
import React, { useState, useEffect } from 'react'
import styled from 'styled-components';
import Endpoints_Card from '../../library/cards/Endpoints_Card'
import l from '../../services/api/Landings';
import a from '../../services/api/Ads';
import q from '../../services/api/Quests'
import m from '../../services/api/Meta';
import e from './endpoints.json';
import { useTranslation } from 'react-i18next';

const ValueButton = styled.button`
    background-color: transparent;
    border: none;
    padding: var(--normalPads);
    display: flex;
    position: relative;
    gap: 1rem;
    cursor: pointer;
    border-radius: var(--smallRadius);

  &:not(:active):hover,
  &:focus {
    background-color: ${(p) => p.theme.high};
  }

  &:focus,
  &:active {
    background-color:  ${(p) => p.theme.high};
    outline: none;
  }

  &::before {
    content: "";
    position: absolute;
    top: 5px;
    left: -10px;
    width: 5px;
    height: 80%;
    background-color: var(--focus);
    border-radius: 5px;
    opacity: 0;
  }

  &:focus::before,
  &:active::before {
    opacity: 1;
  }

`;

export default function ENDPOINTS() {

  const {t} = useTranslation();

    const [clicked,  setClicked] = useState('');
    const [jsonData, setJsonData] = useState();
    const [jsonDataInput, setJsonDataInput] = useState();
    const [files, setFiles] = useState([]);
    const [data, setData] = useState([{
      "_id": "<mongo id>",
      "userId": "<user id>",
      "adId": "<ad id>",
      "link": "Destination url 1",
      "caption": "caption A",
      "visualElement": "<image or video link in S3>", 
      "thumbnail": "<some thumbnail, uses link from Meta CDN, in the future use link from S3>", 
      "uploadedImage": "<same structure as in Ad object>",
      "uploadedVideo": "<same structure as in Ad object>",
      "type": "Image 1"
    }, 
    {
      "_id": "<mongo id>",
      "userId": "<user id>",
      "adId": "<ad id>",
      "link": "Destination url 2",
      "caption": "caption B",
      "visualElement": "<image or video link in S3>",
      "thumbnail": "<some thumbnail, uses link from Meta CDN, in the future use link from S3>", 
      "uploadedImage": "<same structure as in Ad object>",
      "uploadedVideo": "<same structure as in Ad object>",
      "type": "Image 2"
    }, 
    {
      "_id": "<mongo id>",
      "userId": "<user id>",
      "adId": "<ad id>",
      "link": "<destination url>",
      "caption": "caption C",
      "visualElement": "<image or video link in S3>",
      "thumbnail": "<some thumbnail, uses link from Meta CDN, in the future use link from S3>", 
      "uploadedImage": "<same structure as in Ad object>",
      "uploadedVideo": "<same structure as in Ad object>",
      "type": "Image 3"
    }]);

    const [messages, setMessages] = useState({
      errors: [],
      successes: [],
  });

    const handleClickedEndpoint = (dataRequest, dataResponse) => {

      setJsonData(dataResponse);
      setJsonDataInput(dataRequest);
      setClicked(true);
    }

    // you can check your endpoint in real time here, if you wish
    const testing_an_endpoint = () => {
      m.saveCatalog('1586199365547321', 'LEatherbee', '327319108157286', '65dda9f27200c7e1633b3c95')
        .then(res => console.log('MY ENDPOINT', res))
    }

    useEffect(() => {
      if (files?.length) testing_an_endpoint();
      console.log('FILES', files)
    }, [files])
    
    

  return (
    <PageFull>
        {clicked && 
            <Slider_ onClick={()=> setClicked(false)} onClose={()=> setClicked(false)} title="Response" text={t("Done")}>
                <Builder_ title="Request">
                  {jsonDataInput ? (
                      <pre style={{fontSize: '14px', color: 'purple', fontWeight: '600'}}>{JSON.stringify(jsonDataInput, null, 2)}</pre>
                  ) : (
                      <p>Loading...</p>
                  )}
                </Builder_>
                <Builder_ title="Response">
                  {jsonData ? (
                      <pre style={{fontSize: '14px', color: 'blue', fontWeight: '600'}}>{JSON.stringify(jsonData, null, 2)}</pre>
                  ) : (
                      <p>Loading...</p>
                  )}
                </Builder_>
            </Slider_>
        }
        <PageTitle>
            <h1>Endpoints</h1>
        </PageTitle>
        <CardsFull>
        <Upload_Avatar
              accept="image/jpeg, image/png, image/jpg"
              boomRejected={(type) => console.error(type)}
              delete={("Take food away")}
              errors={messages.errors}
              getFilesFromChild={(f) => {
                if (f?.length) {
                  let newFiles = files.slice() //copy the array
                  newFiles[0] = f[0] //execute the manipulations
                  setFiles(newFiles) //set the new state
                }
              }}
              helpers={[(('Allergic to shrimps and squid'))]}
              iconDelete=""
              iconUpload=""
              img={'https://media.istockphoto.com/id/1065080212/photo/calico-maine-coon-cat-sitting-looking-up-in-bathroom-room-in-house-by-weight-scale-overweight.jpg?b=1&s=612x612&w=0&k=20&c=Zq_kUEN59Ea1gGIATO1TGVHRr_brKzo2Y3hN1h3XtA4='}
              onDelete={() => a.saveProductsExperimental(files, data).then(res => console.log('experimental: ', res))}
              onUpload="onClick event on Upload"
              successes={messages.successes}
              tips={[(("Preferable treats:")) + ' burger, pizza']}
              title={("Kastanos, protector of Endpoints page")}   
              upload={("Upload food")}
              uploaded
              maxFiles="1"
              maxSize="2000000"
            />
                    <Upload_Avatar
              accept="image/jpeg, image/png, image/jpg"
              boomRejected={(type) => console.error(type)}
              delete={("Take food away")}
              errors={messages.errors}
              getFilesFromChild={(f) => {
                if (f?.length) {
                  let newFiles = files.slice() //copy the array
                  newFiles[1] = f[0] //execute the manipulations
                  setFiles(newFiles) //set the new state
                }
              }}
              helpers={[(('Allergic to shrimps and squid'))]}
              iconDelete=""
              iconUpload=""
              img={'https://media.istockphoto.com/id/1065080212/photo/calico-maine-coon-cat-sitting-looking-up-in-bathroom-room-in-house-by-weight-scale-overweight.jpg?b=1&s=612x612&w=0&k=20&c=Zq_kUEN59Ea1gGIATO1TGVHRr_brKzo2Y3hN1h3XtA4='}
              onDelete={() => a.saveProductsExperimental(files, data).then(res => console.log('experimental: ', res))}
              onUpload="onClick event on Upload"
              successes={messages.successes}
              tips={[(("Preferable treats:")) + ' burger, pizza']}
              title={("Kastanos, protector of Endpoints page")}   
              upload={("Upload food")}
              uploaded
              maxFiles="1"
              maxSize="2000000"
            />
                    <Upload_Avatar
              accept="image/jpeg, image/png, image/jpg"
              boomRejected={(type) => console.error(type)}
              delete={("Take food away")}
              errors={messages.errors}
              getFilesFromChild={(f) => {
                if (f?.length) {
                  let newFiles = files.slice() //copy the array
                  newFiles[2] = f[0] //execute the manipulations
                  setFiles(newFiles) //set the new state
                }
              }}
              helpers={[(('Allergic to shrimps and squid'))]}
              iconDelete=""
              iconUpload=""
              img={'https://media.istockphoto.com/id/1065080212/photo/calico-maine-coon-cat-sitting-looking-up-in-bathroom-room-in-house-by-weight-scale-overweight.jpg?b=1&s=612x612&w=0&k=20&c=Zq_kUEN59Ea1gGIATO1TGVHRr_brKzo2Y3hN1h3XtA4='}
              onDelete={() => a.saveProductsExperimental(files, data).then(res => console.log('experimental: ', res))}
              onUpload="onClick event on Upload"
              successes={messages.successes}
              tips={[(("Preferable treats:")) + ' burger, pizza']}
              title={("Kastanos, protector of Endpoints page")}   
              upload={("Upload food")}
              uploaded
              maxFiles="1"
              maxSize="2000000"
            />
            {e?.endpoints?.map((endpoint, i) => 
              <Endpoints_Card 
                title={endpoint.name} 
                setClicked={(e) => setClicked(e)}
                onClick = {handleClickedEndpoint}>
                <ValueButton onClick={() => handleClickedEndpoint(endpoint['Inputs'], endpoint['Success'])}>
                    <h4>Success</h4>
                </ValueButton>
                <ValueButton onClick={() => handleClickedEndpoint(endpoint['Inputs'], endpoint['Fail Validation'])}>
                    <h4>Fail Validation</h4>
                </ValueButton>
                <ValueButton onClick={() => handleClickedEndpoint(endpoint['Inputs'], endpoint['Not Found'])}>
                    <h4>Not Found</h4>
                </ValueButton>
                <ValueButton onClick={() => handleClickedEndpoint(endpoint['Inputs'], endpoint['Server Error'])}>
                    <h4>Server Error</h4>
                </ValueButton>
            </Endpoints_Card> 
            )}
        </CardsFull>
    </PageFull>
  )
}
