import { CardsFull, Grey_Link, Main_Plain, On_Click_Card, PageFull, PageTitle, Slider_, TD, TH, TR, TRH, Table, TableDesktop, Toggle_ } from 'monica-alexandria'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import q from '../../../services/api/Quests';
import { useNavigate, useParams } from 'react-router-dom';

const clients = require('../../../config/index').config;


export default function USERS() {
  const {t} = useTranslation();
  const [selectedUser, setSelectedUser] = useState(false)
  const [selectedQuest, setSelectedQuest] = useState(false)

  const navigate = useNavigate();

  const {lang} = useParams();
  const [users, setUsers] = useState([])
   // TODO: add loading 

  const get_all_users = () => {
    q.getUsers()
      .then(res => {
        if (res?.data?.code == 'Q2009') {
          // TODO: stop loading
          console.log('users', res?.data?.data)
          setUsers(res?.data?.data);
        }
        else {
          // TODO: stop loading
          // TODO: use alertMessage to show an error
          console.log('use alertMessage to show an error')
        }
      })
  }


  useEffect(() => {
    get_all_users();
  }, [])

  console.log("USERS",users)
  
  return (
    <PageFull>
        <PageTitle > 
          <Grey_Link iconLeft='Back' noPadding onClick={()=> navigate(`/${lang}/akihiko/dailyprofit`)}/>
            <h1>{t("Questad's users")}</h1>
        </PageTitle>
        <TableDesktop>
          <Table>
            <TRH>
              <TH>{t("Internal flow")}</TH>
              <TH>{t("View user")}</TH>
              <TH>{t("Username")}</TH>
              <TH>{t("First name")}</TH>
              <TH>{t("Last name")}</TH>
              <TH>{t("View quests")}</TH>
            </TRH>
            {users?.map((user, i) => 
              <TR key={i}>
                <TD>
                  <Toggle_ active={false}/>
                </TD>
                <TD>
                  <Main_Plain noPadding iconRight="EyeOpen" text={t("View user")} onClick={()=>window.open(`${clients['erised']}/${lang}/akihiko/erised/users/${user?.id}`)}/>
                </TD>
                <TD>{user?.username}</TD>
                <TD>{user?.firstname}</TD>
                <TD>{user?.lastname}</TD>
                <TD>
                  <Main_Plain noPadding iconRight="Next" text={t("View quests")} onClick={()=>navigate(`/${lang}/akihiko/dailyprofit/user?id=${user?.userId}&username=${user?.username}`)}/>
                </TD>
              </TR>
            )}
          </Table>
        </TableDesktop>
    </PageFull>
  )
}
