import { Cta_, device, H2, P } from 'monica-alexandria'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const HomeReporting = styled.section`
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-gap: 4rem;

    .Cta{
        margin-top: 4rem;
    }

    @media ${device.md} {
        grid-template-columns: 1fr;
    }
    
`

const HomeReportingBot = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 4rem;
    margin-top: 4rem;

    img{
        width: 100%;
    }

    @media ${device.sm} {
        grid-template-columns: 1fr;
    }

`

const HomeReportingRight = styled.div`
    img{
        width: 100%;
    }

    @media ${device.sm} {
       display: none;
    }

`

export const Home_Reporting = (props) => {
    const {t} = useTranslation();
  return (
    <HomeReporting>
        <div>
            <H2>{t('Track the performance of your ads at all times.')}</H2>
            <P>{t("Questad automatically sends you a detailed report of your ad performance every Friday.")}</P>
            <P>{t("But it doesn't stop there! Through the user-friendly dashboard, you can monitor the performance of your ads at any time you want.")}</P>
            <P>{t("Extra Bonus! Doitforme marketers offer you useful tips to optimize your ads, with no obligation to implement them.")}</P>
            <P>{t("Our marketing professionals monitor and optimize ads daily. With Questad you can too! Through the detailed history of every action you take, you can compare its results and change what is needed. ")}</P>
            <Cta_ text={t("Create and track your ads")} size='large' onClick={props.onStart}/>
            <HomeReportingBot>
                <img src="https://genius1071.friktoriaservers.net/doitforme/morning/app/tarzan/report/Left.png" alt=""/>
                <img src="https://genius1071.friktoriaservers.net/doitforme/morning/app/tarzan/report/Right.png" alt=""/>
            </HomeReportingBot>
        </div>
        <HomeReportingRight>
            <img src="https://genius1071.friktoriaservers.net/doitforme/morning/app/tarzan/report/Full.png" alt=""/>
        </HomeReportingRight>
    </HomeReporting>
  )
}
