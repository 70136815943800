import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import m from '../../services/api/Meta'
import { useNavigate, useParams } from 'react-router-dom'
import { alertMessage } from '../../utils/messagesAlerts'
import { useTranslation } from 'react-i18next'
import Eshop_Configure_Pixel from '../../components/eshopConfigure/Eshop_Configure_Pixel'
import { Actions_, Alert_, AlertContext, Builder_, Dropdown_, Form, Grey_, IconSvg, Main_, Main_Loader, PageSixty, ValidatorError } from 'monica-alexandria'

const ReconnectPixel = styled(PageSixty)`
    padding-top: 4rem;
`
export const RECONNECT_PIXEL = () => {
    const {questId} = useParams();
    const {alerts, setAlerts} = useContext(AlertContext);

    const [pixels,setPixels] = useState([]);
    const {t} = useTranslation()
    const navigate = useNavigate();
    const {lang} = useParams();
    const [error, setError] = useState('');


    const [loading, setLoading] = useState(false);

    // const [selectedPixel, setSelectedPixel] = useState(
    //     props?.Meta?.pixelId && props?.Meta?.pixelName 
    //       ? { id: props.Meta.pixelId, name: props.Meta.pixelName } 
    //       : {}
    //   );



    const [selectedPixel, setSelectedPixel] = useState('');

    const get_pixels = () => {
        m.getPixels()
        .then(res => {
            console.log('RESPONSE GET PIXELS' , res);
            if (res?.data?.code == "C2001") {
                setPixels(res?.data?.data);
            }else {
                alertMessage(alerts, setAlerts, (t('Something went wrong')), 'Error');
            }
            //setAdsLoading(false);
        })
    }

    const save_pixel = () =>{
        setLoading(true);
        m.savePixel(selectedPixel?.id, selectedPixel?.name, questId)
        .then(res => {
            // check_for_ad();
            console.log('RESSS', res);
            
            if (res.data?.code === "C2001"){
                alertMessage(alerts, setAlerts, (t('Pixel reconnected successfully')), 'Success');
                navigate(`/${lang}/dashboard`)
            }
            else setError(res.data?.msg)
            console.log('RESSSSSSSSSSSSS',res);
            setLoading(false);
        })
        .catch((err) => setLoading(false))
    }
    
    

    useEffect(() => {
        get_pixels();
    }, []);

  return (
    <ReconnectPixel>
        {loading ? <Main_Loader text='Reconnecting pixel'/> : null}
        <Alert_  messages={alerts} />
        <Form>
        <h3>{t("Reconnect pixel")}</h3>
        <Builder_ title={t("Select your facebook pixel")}>
            <Dropdown_>
                <select value={selectedPixel?.id ? selectedPixel?.id : 'none'} 
                        onChange={(e) => setSelectedPixel({ id: e.target.value, name: e.target.options[e.target.selectedIndex].text })}
                  >
                    <option value={'none'} disabled>{t('Facebook pixel')}</option>
                    {pixels?.map((pixel) => (
                        <option
                            key={pixel.id}
                            value={pixel.id}
                        >
                            {pixel.name}
                        </option>
                    ))}
                </select>
            </Dropdown_>
            {error? 
            <ValidatorError>
                <IconSvg Icon ='Xicon' />
                <h6>{error} </h6>
                </ValidatorError> 
            : 
                null
            }

        </Builder_>

        <Actions_>
              <Grey_ text={t("Back")} iconLeft='Back'  onClick={()=> navigate(-1)}/>
              <Main_ text={t("Next")} iconRight = 'Next' onClick={()=> save_pixel()}/>
            </Actions_>
        {/* <Eshop_Configure_Pixel pixels={pixels} questId={questId}/> */}
        </Form>
    </ReconnectPixel>
  )
}
