import { CardsSixty, IconSvg, Main_Plain, TBody, TD, TH, THead, TR, Table, TableCard, TableDesktop, TableMobile, device } from 'monica-alexandria'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { prettifyDates, prettifyExcelDates } from '../../../../helpers/prettifiers'
import { exportExcel, transformDataForMatrix } from './utils'
import l from '../../../../services/api/Landings'
import { check_for_existing_lead_field } from '../../utils'

const DashboardLeads = styled.div`
    td,th{
        white-space: nowrap;
    }
`

const DashboardLeadsWrapper = styled.div`
    max-width: 100%;
    overflow-x: auto;
`

const DashboardLeadsNo = styled.div`
    text-align: center;

    img{
        width: 100%;
        display: block;
        max-width: 40rem;
        margin: 2rem auto;
        opacity: .5;
    }

    h1,h5{
        color: var(--greyDark);
    }
`

const DashboardLeadsActions = styled.div`
    margin-bottom: 2rem;
    display: flex;
    gap: 2rem;
    align-items: center;
    justify-content: flex-end;
`
const DashboardLeadsLoadBtn = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`
export default function Dashboard_Leads(props) {

    const {t} = useTranslation();
    const [pagination, setPagination] = useState({
        pageNo: 0,
        numOfDocuments: 10,
        leads: []
    })
    const [multipleCustomFields, setMultipleCustomFields] = useState([])

    const get_leads = (pageNo) => {
        let questId = props?.selectedQuestId ? props?.selectedQuestId : props?.selectedArchivedQuest
        l.getPaginatedLeads(questId, pageNo, pagination.numOfDocuments)
          .then(res => {
              console.log(res)
                if (pageNo > 0) {
                    append_leads(res.data.leads, pageNo)
                }
                else {
                    get_first_N_leads(res.data.leads, pageNo);
                }
          })
    }

    const append_leads = (newLeads, pageNo) => {
        setPagination({...pagination, leads: [...pagination.leads, ...newLeads], pageNo: pageNo});
        let matrixformat = transformDataForMatrix([...pagination.leads, ...newLeads], t)
        setMultipleCustomFields(matrixformat)

    }

    const get_first_N_leads = (newLeads) => {
        setPagination({...pagination, leads: newLeads, pageNo: 0});
        let matrixformat = transformDataForMatrix(newLeads, t)
        setMultipleCustomFields(matrixformat)

    }

    const get_all_leads = () => {
        let questId = props?.selectedQuestId ? props?.selectedQuestId : props?.selectedArchivedQuest
        l.getPaginatedLeads(questId, 0, false)
          .then(res => {
            exportExcel(t, res?.data?.leads, 'leads.xlsx')
          })
    }

    useEffect(() => {
        get_leads(0)
    },[props.selectedQuestId])

    console.log('asdsfsfsfsf', props?.leadsTotal, pagination?.leads?.length)
    console.log('multipleCustomFields', multipleCustomFields)

  return (
    <DashboardLeads>
        <DashboardLeadsActions>
            <Main_Plain text={t("Export to excel")} iconRight="File" noPadding onClick = {() => get_all_leads()} />
        </DashboardLeadsActions>
        {pagination?.leads?.length ?
            <DashboardLeadsWrapper>
                <TableDesktop alwaysVisible>
                    <Table>
                        <THead>
                            {check_for_existing_lead_field(pagination?.leads, "createdAt") ?
                                <TH>{t("Date")}</TH>
                            :
                                null
                            }
                            {check_for_existing_lead_field(pagination?.leads,"Name") ?
                                <TH>{t("First name")}</TH>
                            :
                                null
                            }
                            {check_for_existing_lead_field(pagination?.leads,"Surname") ?
                                <TH>{t("Last name")}</TH>
                            :
                                null
                            }
                            {check_for_existing_lead_field(pagination?.leads,"FullName") ?
                                <TH>{t("Full name")}</TH>
                            :
                                null
                            }
                            {check_for_existing_lead_field(pagination?.leads,"City") ?
                                <TH>{t("City")}</TH>
                            :
                                null
                            }
                            {check_for_existing_lead_field(pagination?.leads,"Tel") ?
                                <TH>{t("Phone number")}</TH>
                            :
                                null
                            }
                            {check_for_existing_lead_field(pagination?.leads,"Email") ?
                                <TH>{t("Email address")}</TH>
                            :
                                null
                            }
                            {check_for_existing_lead_field(pagination?.leads, "CustomField") ? 
                            multipleCustomFields.headers.map(header =>(
                                <TH key={header}>{header}</TH>
                            )): 
                                null
                            }
                        </THead>
                        {pagination?.leads?.map((lead, i) => 
                            <TR key={i} id={i}>
                                {check_for_existing_lead_field(pagination?.leads, "createdAt") ?
                                    <TD>{prettifyExcelDates(lead.createdAt || 'N/A')}</TD>
                                :
                                    null
                                }
                                {check_for_existing_lead_field(pagination?.leads,"Name") ?
                                    <TD>{lead.Name || 'N/A'}</TD>
                                :
                                    null
                                }
                                {check_for_existing_lead_field(pagination?.leads,"Surname") ?
                                    <TD>{lead.Surname || 'N/A'}</TD>
                                :
                                    null
                                }
                                {check_for_existing_lead_field(pagination?.leads,"FullName") ?
                                    <TD>{lead.FullName}</TD>
                                    :
                                    null
                                }
                                {check_for_existing_lead_field(pagination?.leads,"City") ?
                                    <TD>{lead.City}</TD>
                                    :
                                    null
                                }
                                    {check_for_existing_lead_field(pagination?.leads,"Tel") ?
                                     <TD>{lead.Tel || 'N/A'}</TD>
                                :
                                    null
                                }
                                {check_for_existing_lead_field(pagination?.leads,"Email") ?
                                    <TD>{lead.Email || 'N/A'}</TD>
                                :
                                    null
                                }
                                {multipleCustomFields.headers.map(header => (
                                    <TD key={header}>{multipleCustomFields.data[i][header] || ''}</TD>
                                ))}
                            </TR>
                        )}
                    </Table>
                    { (props.leadsTotal !== pagination?.leads?.length) && (props.leadsTotal !== 0) || (props.leadTotal <  pagination?.leads?.length) ?
                        <DashboardLeadsLoadBtn>
                            <Main_Plain text={t('Load more')} iconLeft='Loading' onClick={()=> get_leads(pagination.pageNo+1)} />
                        </DashboardLeadsLoadBtn>
                    : null}
                </TableDesktop>
            </DashboardLeadsWrapper>
        :
            <DashboardLeadsNo>
                <img src='https://genius1071.friktoriaservers.net/doitforme/morning/app/global/Empty.svg' />
                <h1>{t("No leads available")}</h1>
                <h5>{t("There are no available leads to show at the moment. Come back later to check again!")}</h5>
            </DashboardLeadsNo> 
        }
    </DashboardLeads>
  )
}
