import React, { useState } from 'react'
import styled from 'styled-components';


const Range = styled.div`
    input{
        width: 100%;
        color: var(--main);
    }
`

const RangeInput = styled.input`
  width: 100%;
  -webkit-appearance: none;
  appearance: none;
  height: 5px;
  border-radius: 5px;
  background: var(--grey);
  outline: none;
  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    background: var(--main);
    cursor: pointer;
  }
  &::-moz-range-thumb {
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    background: var(--main);
    cursor: pointer;
  }
`;

export const Range_ = ({ min, max, step, onChange, value }) => {
    const [newValue, setNewValue] = useState(value ? value : min);

    const handleChange = (event) => {
      const newValue = parseInt(event.target.value);
      setNewValue(newValue);
      onChange(newValue);
    };
    
  return (
    <Range> 
         <RangeInput
            type="range"
            min={min}
            max={max}
            step={step}
            value={value}
            onChange={handleChange}
        />
    </Range>
  )
}
