import { Grey_Link, PageTitle } from 'monica-alexandria';
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Landing_ } from './Landing_';
import i18n from '../../localization/i18next';
import { Tabs_ } from '../../library/buttons/Tabs_';
import { Desktop_ } from './Desktop_';
import { Phone_ } from './Phone_';


const TabsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const DevicesWrapper = styled.div`
    margin-top: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;

`

const DeviceMockups = styled.div``

const LandingPreview = styled.div``

const LandingMobWrapper = styled.div`
    border-radius: var(--smallRadius);
    overflow: hidden;
`

export const Landing_Preview = (props) => {


const {t} = useTranslation();
const navigate = useNavigate();
const lang = i18n.language ? i18n.language : 'el';

const [selectedDevice, setSelectedDevice] = useState('desktop');
const [windowWidth, setWindowWidth] = useState(window.innerWidth);

//if window size is less than 992 then dont show device mockups
useEffect(() => {
  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  window.addEventListener('resize', handleResize);

  return () => {
    window.removeEventListener('resize', handleResize);
  };
}, []);

  return (
    <LandingPreview>
        <PageTitle>
            <Grey_Link iconLeft='Back' onClick={()=>(props.toggleLandingPreview(false))} />
            <h1>{t('Preview')}</h1>
        </PageTitle>
        {/* //if the user is using mobile  */}
        {windowWidth < 992 ? (
            <LandingMobWrapper>
              <Landing_ viewMode={true} />
            </LandingMobWrapper>
        ) : (
             //if the user is using desktop 
            <DeviceMockups>
                <TabsWrapper>
                    <Tabs_ handleDevice={(device) => setSelectedDevice(device)}/>
                </TabsWrapper>
                <DevicesWrapper>
                    {selectedDevice === 'desktop' && <Desktop_ viewMode={true}  mobilePreview={selectedDevice==='mobile'} />}
                    {selectedDevice === 'mobile' && <Phone_ viewMode={true} mobilePreview={selectedDevice==='mobile'}/> }
                </DevicesWrapper> 
            </DeviceMockups>
            )
        }
    </LandingPreview>
  )
}
