import { Button, Card, Focus_, Focus_Plain, FormatNumbers, Grey_Link, IconSvg, LimitCharacters, Main_Plain, device } from 'monica-alexandria'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import FbLogo from '../../../../assets/dashboard/FbLogo.png'
import { handle_top_up_and_restart } from '../../utils'
import SUCCESS_PAYMENT from '../../../SUCCESS_PAYMENT.js/SUCCESS_PAYMENT'
import { useNavigate, useParams } from 'react-router-dom';
import p  from '../../../../services/api/Payments';


const ArchivedOld = styled(Card)`
    padding: 2rem;
    background: ${p => p.theme.low};
`

const ArchivedOldGrid = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 2rem;
`

const ArchivedOldBox = styled.div`
    padding: 2rem;
    border-radius: 2rem;
    background: ${p => p.theme.background};
`   

const ArchivedOldLeft = styled.div``

const ArchivedOldTop = styled.div`
    display: flex;
    align-items: flex-start;
    gap: 1rem;
    grid-column: span 2;

    img{
        width: 4rem;
        height: 4rem;
        object-fit: cover;
        border-radius: 50%;
        border: 1px solid var(--greyLight);
        background: var(--greyLight);
    }

    @media ${device.xl} {
        grid-column: span 1;
    }
`

const ArchivedOldTitle = styled.div``

const ArchivedOldLayout = styled.div`
    display: grid;
    grid-gap: 2rem;
    grid-template-columns: 1fr 2fr;
    align-items: flex-start;

    @media ${device.xl} {
        grid-template-columns: 1fr;
    }
`

const ArchivedOldRight = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;

    @media ${device.sm} {
        grid-template-columns: 1fr;
    }
`

const ArchivedOldBudget = styled.div`
    padding: 1rem;
    border: 2px solid var(--focus);
    margin: 2rem 0;
    border-radius: 1rem;
    cursor: pointer;

    h1,h5{
        color: var(--focus);
    }

    button{
        width: 100%;
        justify-content: center;
        background: transparent;
        margin-top: 2rem;

        span{
            color: var(--focus);
        }

        svg{
            fill: var(--focus);
        }
    }

    &:hover{
        background: var(--focus);

        h1,h5{
            color: var(--white);
        }

        button{

            span{
                color: var(--white);
            }

            svg{
                fill: var(--white);
            }
        } 
    }
`

const ArchivedOldActions = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 1rem;
    margin-top: 0rem;
`

const ArchivedOldLeads = styled.div`
    padding: 1rem;
    border: 2px solid ${p => p.theme.color};
    margin: 0 0 2rem 0;
    border-radius: 1rem;
    cursor: pointer;

    button{
        width: 100%;
        justify-content: center;
        background: transparent;
        margin-top: 2rem;

        span{
            color: ${p => p.theme.color};
        }

        svg{
            fill: ${p => p.theme.color};
        }
    }

    &:hover{
        background:${p => p.theme.color};

        h1,h5{
            color: ${p => p.theme.background};
        }

        button{

            span{
                color: ${p => p.theme.background};
            }

            svg{
                fill: ${p => p.theme.background};
            }
        } 
    }
`

const ArchivedOldEshop = styled.div`
    padding: 1rem;
    border: 2px solid ${p => p.theme.color};
    margin: 0 0 2rem 0;
    border-radius: 1rem;
`


const ArchivedOldValue = styled.div`

`



export default function Archived_Old(props) {

    const {t} = useTranslation();
    const {lang} = useParams();
    const navigate = useNavigate();
    const redirect = `${window.location.origin}${window.location.pathname}?name=Questad&category=dailyprofit`;
    const [ isLoading, setIsLoading ] = useState(true);

    const restart_selected_quest = async (quest) => {
        try {
            let action = await handle_top_up_and_restart(quest?._id, quest?.matchingSubId?.customer, quest?.amountPaid)
            console.log('ACTION', action)
            if(action?.payment_missing) {
                window.open(action?.redirectUrl)
                navigate(`/${lang}/success-payment?quest=${quest?._id}`)
            }  
        } 
        catch(error) {
            console.log("ERROR", error)
        }

    }

    const set_and_edit_selected_quest = (quest) => {

        props.archivedSetSelectedQuest(quest);
        props.archivedOpenBudget();
    }


    const set_and_view_selected_leads = (quest) => {
        props.archivedSetSelectedQuest(quest);
        props.archivedOpenLeads();
    }



    const go_to_edit_subscription = (customer) => {
   
  
      p.questad_customer_portal(customer , redirect)
        .then(data => {
          if (data?.data?.url) {
  
            console.log('DATAAA', data);
            
            setIsLoading(false)
            window.location.assign(data?.data?.url)
          }
          else isLoading(true);
        })
        .catch(err => {})
    }

    /* {props.archivedQuests?.map((quest , i) => (
          <Dashboard_Quest 
            key={i}
            avatar={quest?.Meta?.facebookPagePicture}
            page={quest?.Meta?.facebookPageName}
            type={quest?.questionnaires[0]?.answer}
            industry={quest?.questionnaires[1]?.answer}
            totalBudget={quest?.questionnaires[5]?.answer}
            spentBudget = {quest?.matchingKpis?.spend}
          />
        ))} */
console.log("PROPS", props)
  return (
    <ArchivedOld>
        <ArchivedOldGrid>
            {props.archivedQuests?.map((quest, i) =>
                <ArchivedOldBox key={i}>
                    <ArchivedOldLayout>
                        <ArchivedOldTop>
                            {/* <img src={quest?.Meta?.facebookPagePicture} /> */}
                            <img src={FbLogo} />
                            <ArchivedOldTitle>
                                <h5>{quest?.questionnaires[0]?.answer} | {LimitCharacters(quest?.questionnaires[1]?.answer, 15)}</h5>
                                <p>{LimitCharacters(quest?.Meta?.facebookPageName, 25)}</p>
                            </ArchivedOldTitle>
                        </ArchivedOldTop>
                        <ArchivedOldLeft>
                            {quest?.questionnaires[0]?.answer == 'services' ? 
                                <ArchivedOldLeads onClick={() => set_and_view_selected_leads(quest)}>
                                    <h5>{t("Total leads")}</h5>
                                    <h1>{quest?.matchingLeadTotal || "N/A"}</h1>
                                    <Button size="small" noPadding>
                                        <span>{t("View all")}</span>
                                        <IconSvg Icon="Next"/>
                                    </Button>
                                </ArchivedOldLeads>
                            :quest?.questionnaires[0]?.answer == 'eshop' ? 
                                <ArchivedOldEshop>
                                    <h5>{t("Total page views")}</h5>
                                    <h1>{FormatNumbers(quest?.matchingKpis?.page_views) || "N/A"}</h1>
                                </ArchivedOldEshop>
                            :
                                <ArchivedOldEshop>
                                    <h5>{t("Total post engagement")}</h5>
                                    <h1>{FormatNumbers(quest?.matchingKpis?.post_engagement) || "N/A"}</h1>
                                </ArchivedOldEshop>
                            }
                           {quest?.matchingSubId?.status === "past_due" ?
                              <ArchivedOldBudget onClick={() => restart_selected_quest(quest)}>
                                <h5>{t("Subscription plan")}</h5>
                                <h1>€ {quest?.amountPaid / 100 || "N/A"}</h1>
                                <Button size="small" noPadding>
                                    <IconSvg Icon="Payment"/>
                                    <span>{t("Last payment failed") }</span>
                                </Button>
                            </ArchivedOldBudget> :
                            <ArchivedOldBudget onClick={() => set_and_edit_selected_quest(quest)}>
                                <h5>{t("Subscription plan")}</h5>
                                <h1>€ {quest?.amountPaid / 100 || "N/A"}</h1>
                                <Button size="small" noPadding>
                                    <IconSvg Icon="Payment"/>
                                    <span>{t("Change budget and restart")}</span>
                                </Button>
                            </ArchivedOldBudget>
                            }   
                            <Focus_Plain text={t('Payment method')} iconLeft='Payment'  onClick={()=>go_to_edit_subscription(quest?.matchingSubId?.customer)} />

                        </ArchivedOldLeft>
                        <ArchivedOldRight>
                            <ArchivedOldValue>
                                <h5>{t("Total days run")}</h5>
                                <h1>{FormatNumbers(quest?.graphicalKpis[1]?.values?.length) || "N/A"}</h1>
                            </ArchivedOldValue>
                            <ArchivedOldValue>
                                <h5>{t("Total adspend")}</h5>
                                <h1>€ {FormatNumbers(quest?.matchingKpis?.spend) || "N/A"} </h1>
                            </ArchivedOldValue>
                            <ArchivedOldValue>
                                <h5>{t("Total clicks")}</h5>
                                <h1>{FormatNumbers(quest?.matchingKpis?.clicks) || "N/A"}</h1>
                            </ArchivedOldValue>
                            <ArchivedOldValue>
                                <h5>{t("Overall frequency")}</h5>
                                <h1>{FormatNumbers(quest?.matchingKpis?.frequency) || "N/A"}</h1>
                            </ArchivedOldValue>
                            <ArchivedOldValue>
                                <h5>{t("Overall impressions")}</h5>
                                <h1>{FormatNumbers(quest?.matchingKpis?.impressions) || "N/A"}</h1>
                            </ArchivedOldValue>
                            <ArchivedOldValue>
                                <h5>{t("Overall reach")}</h5>
                                <h1>{FormatNumbers(quest?.matchingKpis?.reach) || "N/A"}</h1>
                            </ArchivedOldValue>
                        </ArchivedOldRight>
                    </ArchivedOldLayout>
                    <ArchivedOldActions>
                        {/* <Main_Plain text={t("Manage Quest")} iconLeft="Edit" onClick={set_and_edit_selected_quest}/> */}
                        {/* <Focus_ iconLeft="Recycle" text={t("Restart")}/> */}
                    </ArchivedOldActions>
                </ArchivedOldBox>
            )}
        </ArchivedOldGrid>
    </ArchivedOld>
  )
}
