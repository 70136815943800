import { CardsFull, Grey_Link, On_Click_Card, PageFull, PageTitle } from 'monica-alexandria'
import React, { useTransition } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom';

export default function EDIT_QUEST() {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const {lang} = useParams();
  const {questId} = useParams();

  return (
    <PageFull>
      <PageTitle>
        <Grey_Link iconLeft="Back" onClick={()=>navigate(`/${lang}/dashboard`)} noPadding />
        <h1>{t('Edit quest')}</h1>
      </PageTitle>
      <CardsFull>
        <On_Click_Card 
          icon="Edit" 
          subtitle={t("Edit Questionnaire")} 
          onClick={()=> navigate(`/${lang}/questionnaires/beta/edit/${questId}`)}
        />
        <On_Click_Card 
          icon="Edit" 
          subtitle={t("Edit Advertisement Displays")} 
          onClick={()=> navigate(`/${lang}/edit-ad/${questId}`)}
        />
        <On_Click_Card 
          icon="Edit" 
          subtitle={t("Edit Landing")}
          onClick={()=> navigate(`/${lang}/edit-webpage/${questId}`)}
        />
        <On_Click_Card icon="Xicon" subtitle={t("Cancel subscription")} />
      </CardsFull>

    </PageFull>
  )
}
