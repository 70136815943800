import { AlertContext, Builder_, Dropdown_, IconSvg, Textarea_ } from 'monica-alexandria'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { alertMessage } from '../../utils/messagesAlerts'

const CreateAdFields = styled.div`
    
    .CreateAdFieldCaption{
        height: 8rem !important;
        transition: height .5s;
        
        &:focus{
            height: 18rem !important;
            transition: height .5s;
        }
    }
`
const AdValidatorError = styled.div`
    display: grid;
  grid-template-columns: var(--smallIcon) 1fr;
  align-items: flex-start;
  grid-gap: 1rem;
  padding: 0.5rem 1.6rem;
  h6 {
    color: var(--error) !important;
  }

  svg {
    width: var(--smallIcon);
    fill: var(--error) ;
  }
`


export const Create_Ad_Fields = ({adInfo, setAdInfo, emptyFields, setEmptyFields}) => {
    const {t} = useTranslation();
    const {alerts, setAlerts} = useContext(AlertContext);

    const dropdownOptions = [
        { value: 'SUBSCRIBE', label: (t('Subscribe')) },
        { value: 'APPLY_NOW', label: (t('Apply Now')) },
        { value: 'DOWNLOAD', label: (t('Download')) },
        { value: 'GET_OFFER', label: (t('Get offer')) },
        { value: 'GET_QUOTE', label: (t('Get quote')) },
        { value: 'LEARN_MORE', label: (t('Learn More')) },
        { value: 'SHOP_NOW', label: (t('Shop Now')) },
        { value: 'SIGN_UP', label: (t('Sign Up')) }
        // { value: 'CALL_NOW', label: (t('Call Now')) },

    ];

    const dropdownServiceOptions = [
       
        { value: 'APPLY_NOW', label: (t('Apply Now')) },
        { value: 'DOWNLOAD', label: (t('Download')) },
        { value: 'GET_QUOTE', label: (t('Get quote')) },
        { value: 'LEARN_MORE', label: (t('Learn More')) },
        { value: 'SIGN_UP', label: (t('Sign Up')) },
        { value: 'SUBSCRIBE', label: (t('Subscribe')) }

    ];

    const ctaOptions =  adInfo?.flow === 'services' ?  dropdownServiceOptions : dropdownOptions;
    console.log('ADINFOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOO', adInfo);
  return (
    <CreateAdFields>
    <Builder_ title = {t('Enter a caption for the top section of your advertisement')}>
            <Textarea_
                className="CreateAdFieldCaption"
                rows={2}
                required
                valueLength = {adInfo?.caption?.length}
                limit={5000}
                value = {adInfo.caption}
                errors = {emptyFields?.includes('Caption') ? ['Caption must not be empty'] : []}
                placeholder = {t('Caption')}
                onChange = {(e) => {setAdInfo({...adInfo, caption: e.target.value })
                    setEmptyFields(prevFields => prevFields.filter(field => field !== 'Caption'));
                    if (adInfo?.caption?.length > 5000) alertMessage(alerts, setAlerts, (t('Caption limit has been reached')),'Error'); 

                }}
                tips={[
                    (t('Write a text that will accompany your advertisement')),
                ]} 
            ></Textarea_>
        </Builder_>
        <Builder_ title = {t('Call to action button')}>
                <Dropdown_>
                    <select value={adInfo?.cta ? adInfo.cta: ''} onChange={(e) => {
                        setAdInfo({ ...adInfo, cta: e.target.value })
                        setEmptyFields(prevFields => prevFields.filter(field => field !== 'Cta'));
                    }}>
                        <option value={''} disabled>{t('Select call to action')}</option>
                        {ctaOptions?.map((option) => (
                            <option
                                key={option.value}
                                value={option.value}
                            >
                                {option.label}
                            </option>
                        ))}
                    </select>
                </Dropdown_>
                {emptyFields?.includes('Cta') ? (
                    <AdValidatorError>
                        <IconSvg Icon="Error" />
                        <h6>{t('Call to action must be selected')}</h6>
                    </AdValidatorError>
                ) : null}
            </Builder_>
    </CreateAdFields>
  )
}
