import { Cta_, device, H2, H3, H5, P } from 'monica-alexandria'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled, { keyframes } from 'styled-components'

// Create keyframes for sliding effect
const slide = (currentIndex, totalSlides) => keyframes`
  from {
    transform: translateX(-${((currentIndex - 1 + totalSlides) % totalSlides) * (100 / totalSlides)}%);
  }
  to {
    transform: translateX(-${(currentIndex * 100) / totalSlides}%);
  }
`;

const HomeSuccess = styled.section`
    display: grid;
    grid-template-columns: 1fr 3fr;
    grid-gap: 4rem;

    @media ${device.lg} {
      grid-template-columns: 1fr;
      padding-left: 0;
      padding-right: 0;
    }
`;

const HomeSuccessLeft = styled.div`
  width: 100%;
  h5{
    margin-bottom: 4rem;
  }

  @media ${device.lg} {
       padding: 0 4rem;
    }
  @media ${device.sm} {
    padding: 0 2rem;
  }

  @media ${device.xs} {
    a{
      width: 100%;
      span{
        width: 100%;
        text-align: center;
      }
    }
  }
`;

const HomeSuccessGrid = styled.div`
   overflow: hidden;
   border-radius: 2rem;
   width: 100%;
`;

const HomeSuccessCarousel = styled.div`
  display: flex;
  width: ${({ totalSlides }) => totalSlides * 100}%;
  animation: ${({ currentIndex, totalSlides }) => slide(currentIndex, totalSlides)} 0.8s ease-in-out forwards;
`;


const HomeSuccessControls = styled.div`
    margin-top: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;

    div {
      height: 1rem;
      width: 2rem;
      background: var(--greyDark);
      border-radius: 1rem;
      transition: all 0.4s ease-in-out;
      cursor: pointer;
    }

    div:hover {
      background: var(--grey);
    }

    .Active {
      height: 1rem;
      width: 4rem;
      background: var(--greyDark);
      border-radius: 1rem;
    }
`;

const ControlDot = styled.div`
  height: 1rem;
  width: ${(props) => (props.active ? '4rem' : '2rem')};
  background: ${(props) => (props.active ? 'var(--greyDark)' : 'var(--greyDark)')};
  border-radius: 1rem;
  transition: all 0.4s ease-in-out;
  cursor: pointer;

  &:hover {
    background: var(--grey);
  }
`;

const HomeSuccessCard = styled.div`
    position: relative;
    width: 100%;
    padding: 5rem;
    padding-bottom: 0;



    @media ${device.lg} {
      padding: 0rem 4rem 0rem 4rem;
    }

    @media ${device.lg} {
      padding: 0rem 2rem 0rem 2rem;
    }
    
`;

const HomeSuccessCardLeft = styled.div`
    display: flex;
    gap: 2rem;
    align-items: flex-start;

    @media ${device.sm} {
      flex-direction: column;
      align-items: center;
    }
`;

const HomeSuccessImg = styled.div`
    background: linear-gradient(to bottom right, var(--mainDark), var(--focus));
    height: 20rem;
    width: 20rem;
    border-radius: 2rem;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    @media ${device.md} {
      height: 15rem;
      width: 15rem;
    }
`;

const HomeSuccessInfo = styled.ul`
  h5 {
    margin-bottom: 1rem;
  }

  li {
    display: flex;
    align-items: center;
    gap: 1rem;
    img {
      width: 1.5rem;
    }
  }

  @media ${device.sm} {
      h5{
        color: var(--white);
      }
      h6{
        color: var(--white);
        margin-bottom: 1rem;
      }
      li{
      img{
        background: var(--white);
        border-radius: 5px;
        height: 2.5rem;
        width: 2.5rem;
        padding: .5rem;
      }
    }
    }

   
`;

const HomeSuccessCardRight = styled.div`
    background: var(--dark);
    padding: 4rem;
    border-radius: 2rem;
    box-shadow: ${p=>p.theme.out};
    margin-left: 5rem;
    margin-top: -8rem;

    img {
      width: 5rem;
    }

    .topImg {
      margin-bottom: 2rem;
    }

    .bottomImg {
      text-align: right;
      margin-top: 2rem;
    }

    h3 {
      margin-bottom: 1rem;
      color: var(--white);
    }

    p {
      color: var(--white);
    }

    @media ${device.sm} {
      padding-top: 20rem;
      margin-top: -20rem;
      margin-left: 0;
    }
`;

export const Home_Success = (props) => {
  const { t } = useTranslation();
  const [currentIndex, setCurrentIndex] = useState(0);
  const totalSlides = 3; // Adjust this based on the number of cards
  const intervalTime = 5000;

  // Autoplay logic
  useEffect(() => {
    const autoplay = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % totalSlides);
    }, intervalTime);

    return () => clearInterval(autoplay);
  }, [totalSlides]);


  return (
    <HomeSuccess>
      <HomeSuccessLeft>
        <H2>{t("Real success stories")}</H2>
        <H5>{t("Join business owners like you that trusted Questad with their ads and saw results immediatly.")}</H5>
        <Cta_ text={t("Start your story")} size='large' onClick={props.onStart}/>
      </HomeSuccessLeft>
      <HomeSuccessGrid>

        <HomeSuccessCarousel currentIndex={currentIndex} totalSlides={totalSlides}>
            <HomeSuccessCard>
                <HomeSuccessCardLeft>
                  <HomeSuccessImg>
                    <img src="https://genius1071.friktoriaservers.net/doitforme/morning/app/tarzan/success/ZoiBW.png" alt="" />
                  </HomeSuccessImg>

                  <HomeSuccessInfo>
                      <H5>{t("Zoe Papandreou")}</H5>
                      <li class="HomeSuccessItem">
                        <img src="https://genius1071.friktoriaservers.net/doitforme/morning/app/tarzan/icons/Label.svg" alt=""/>
                        <h6>La tienda de la vita</h6>
                      </li>
                      <li class="HomeSuccessItem">
                        <img src="https://genius1071.friktoriaservers.net/doitforme/morning/app/tarzan/icons/Industry.svg" alt=""/>
                        <h6>E-commerce fashion</h6>
                      </li>
                      <li class="HomeSuccessItem">
                        <img src="https://genius1071.friktoriaservers.net/doitforme/morning/app/tarzan/icons/Location.svg" alt=""/>
                      <h6>Larissa, Greece</h6>
                      </li>
                  </HomeSuccessInfo>
                </HomeSuccessCardLeft>
                <HomeSuccessCardRight>
                    <img className='topImg' src="https://genius1071.friktoriaservers.net/doitforme/morning/app/tarzan/icons/QuotesOpen.svg" alt=""/>  
                    <H3>{t("MY WORK CYCLE SKYROCKETED")}</H3>
                    <P>{t("I started running ads for my eshop through Questad. I saw a difference in results from the very first month. My work cycle skyrocketed and because of the results reports the app sends every week I was able to accurately measure my refund on advertising expense.")}</P>
                    <img className='bottomImg' src="https://genius1071.friktoriaservers.net/doitforme/morning/app/tarzan/icons/QuotesClose.svg" alt=""/>

                </HomeSuccessCardRight>
            </HomeSuccessCard>

            <HomeSuccessCard>
                <HomeSuccessCardLeft>
                  <HomeSuccessImg>
                    <img src="https://genius1071.friktoriaservers.net/doitforme/morning/app/tarzan/success/SophieBW.png" alt="" />
                  </HomeSuccessImg>

                  <HomeSuccessInfo>
                      <H5>{t("Sophia Diamantea")}</H5>
                      <li class="HomeSuccessItem">
                        <img src="https://genius1071.friktoriaservers.net/doitforme/morning/app/tarzan/icons/Label.svg" alt=""/>
                        <h6>Sophie's Lemonade</h6>
                      </li>
                      <li class="HomeSuccessItem">
                        <img src="https://genius1071.friktoriaservers.net/doitforme/morning/app/tarzan/icons/Industry.svg" alt=""/>
                        <h6>Food and beverage</h6>
                      </li>
                      <li class="HomeSuccessItem">
                        <img src="https://genius1071.friktoriaservers.net/doitforme/morning/app/tarzan/icons/Location.svg" alt=""/>
                      <h6>Athens, Greece</h6>
                      </li>
                  </HomeSuccessInfo>
                </HomeSuccessCardLeft>
                <HomeSuccessCardRight>
                    <img className='topImg' src="https://genius1071.friktoriaservers.net/doitforme/morning/app/tarzan/icons/QuotesOpen.svg" alt=""/>  
                    <H3>{t("VERY EASY TO USE, IMPRESSIVELY EFFICIENT")}</H3>
                    <P>{t("Questad has solved all my problems in finding new clients. It saved me valuable time and I managed to have clients in areas that I would not have been able to reach easily. It also helped me in getting a lot more people to know my work than if I had done an advertisement on social media by myself. Very easy to use, impressively efficient and excellent technical support from the questad team. Extra bonus tips and guidance for more effective ads!")}</P>
                    <img className='bottomImg' src="https://genius1071.friktoriaservers.net/doitforme/morning/app/tarzan/icons/QuotesClose.svg" alt=""/>

                </HomeSuccessCardRight>
            </HomeSuccessCard>
            <HomeSuccessCard>
                <HomeSuccessCardLeft>
                  <HomeSuccessImg>
                    <img src="https://genius1071.friktoriaservers.net/doitforme/morning/app/tarzan/success/KaterinaBW.png" alt="" />
                  </HomeSuccessImg>

                  <HomeSuccessInfo>
                      <H5>{t("Katerina Dysseaki")}</H5>
                      <li class="HomeSuccessItem">
                        <img src="https://genius1071.friktoriaservers.net/doitforme/morning/app/tarzan/icons/Label.svg" alt=""/>
                        <h6>Gata Negra Spanish Online</h6>
                      </li>
                      <li class="HomeSuccessItem">
                        <img src="https://genius1071.friktoriaservers.net/doitforme/morning/app/tarzan/icons/Industry.svg" alt=""/>
                        <h6>Education</h6>
                      </li>
                      <li class="HomeSuccessItem">
                        <img src="https://genius1071.friktoriaservers.net/doitforme/morning/app/tarzan/icons/Location.svg" alt=""/>
                      <h6>Athens, Greece</h6>
                      </li>
                  </HomeSuccessInfo>
                </HomeSuccessCardLeft>
                <HomeSuccessCardRight>
                    <img className='topImg' src="https://genius1071.friktoriaservers.net/doitforme/morning/app/tarzan/icons/QuotesOpen.svg" alt=""/>  
                    <H3>{t("IT IS AN INVESTMENT FOR THE DEVELOPMENT OF ANY BUSINESS")}</H3>
                    <P>{t("I have no words for how much Questad has helped me in my Facebook campaign as a Spanish teacher. The first campaign I did for a month for 50€ brought me 27 contacts with prospective students. I had done the exact same campaign (verbal, video, targeting and 60€/30 days) right before working with Doitforme by myself, through the Facebook platform, resulting in 4 spam messages, about 30 likes from random names and no(!) contact with a prospective student. Now 3 days ago I did a second campaign with 21 contacts so far!!! -this time on my own, without the help of Luigi, who was very helpful the first time, step by step, via video call he guided me to set it up. I highly recommend working with Doitforme! It is an investment for the growth of any business, especially for an individual service provider like mine.")}</P>
                    <img className='bottomImg' src="https://genius1071.friktoriaservers.net/doitforme/morning/app/tarzan/icons/QuotesClose.svg" alt=""/>

                </HomeSuccessCardRight>
            </HomeSuccessCard>
        
  
        </HomeSuccessCarousel>
        <HomeSuccessControls>
          {[...Array(totalSlides)].map((_, index) => (
            <ControlDot
              key={index}
              className={index === currentIndex ? 'Active' : ''}
              onClick={() => setCurrentIndex(index)}
            />
          ))}
        </HomeSuccessControls>
      </HomeSuccessGrid>
    </HomeSuccess>
  )
}
