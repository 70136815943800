import { IconSvg, device } from 'monica-alexandria'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import store from '../../../redux/store'
import ReactPlayer from 'react-player'
import { DailyprofitSvg } from '../../../svg/DailyprofitSvg'
import { findLabelByValue } from '../../../utils/findCtaLabel'
import NoImg from '../../../assets/ad/NoImgAd.jpg'
import { useParams } from 'react-router-dom'


const FacebookMockupWrapper = styled.div`
    aspect-ratio: 9/16;
    max-width: 27.4rem; //normal
    width: 100vw; //temp
 //   height: 56.7rem;
   //height: 100vh;
    box-shadow: ${p => p.theme.out};
    border-radius: var(--smallRadius);
    position: relative;
    overflow: hidden;
    background: var(--vanta);


`

const AdMockupCtaTitle = styled.div`
    background: var(--white); 
    border-radius: .5rem;
    padding: 0.7rem 0rem;
    text-align: center;

    h6{
        color: var(--black);
    }
  
`

const AdMockupAccountInfo = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;

    h6{
        color: var(--white);
        font-size: 1.4rem;
        font-family: serif;
    }
    img{
        border: 1px solid var(--grey);
        border-radius: 50%;
        width: 3rem;
        height: 3rem;
    }
`

const AdMockupImage = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    
    img{
        height : 100% ;
        width: 100%;
        object-fit: cover;
    }
`

const AdMockupLabel = styled.div`
    display: flex;
    align-items: center;
    margin: 1rem 0 .5rem 0;
    gap: 0.5rem;
    background-color: #0E0F10;
    padding: 0.5rem 1rem;
    border-radius: 2rem;
    width: fit-content;


    h6{
        color: var(--white);
        font-size: 1.2rem;
    }
   
`

const AdMockupBottom = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    align-content: center;
    justify-content: space-between;
    padding: 5rem 1.5rem 0.5rem 1.5rem;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    gap: 3rem;
`

const AdMockupBottomLeft = styled.div`
    width: 100%;
`

const AdMockupCaption = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 0;

    p{
        font-family: serif;
        color: #fff;
        font-size: 1.4rem;
    }
`
const minioUrl = process.env.REACT_APP_MINIO_PROTOCOL +
                process.env.REACT_APP_MINIO_HOST + 
                process.env.REACT_APP_MINIO_PORT+ '/' +
                process.env.REACT_APP_MINIO_BUCKET + '/';

export const Facebook_Reels_Mobile = (props) => {

    const {t} = useTranslation();
    const facebook_page = store.getState().metaSource?.page_selection_info;
    const userId = store.getState()?.user?.user?.sub;
    const {questId} = useParams();

  return (
    <FacebookMockupWrapper>
        <AdMockupImage>
            {props?.adInfo?.isVideo ? (
                <ReactPlayer
                    url={props.adInfo.file?.preview ? props.adInfo.file?.preview : (props?.adInfo.visualElement ? minioUrl+userId+'/'+props?.adInfo?.visualElement : '')}
                    light={props?.adInfo?.thumbnail !== "default.jpg"  ? <img src={props?.adInfo?.thumbnail} alt='Thumbnail' /> : false}
                    width="100%"
                    height="100%"  //might need change
                    controls={true} 
                    playing={false}
                />
            ):(
                <img src={props.adInfo.file?.preview ? props.adInfo.file?.preview : (props?.adInfo.visualElement ? minioUrl+userId+'/'+props?.adInfo?.visualElement : NoImg)} />
            ) }
        </AdMockupImage>
        <AdMockupBottom>
            <AdMockupBottomLeft>
                <AdMockupAccountInfo>
                    <img src={props.pageLogo} />
                    <div>
                        <h6>{questId ? props?.adInfo?.facebookPageName :facebook_page?.name}</h6>
                    </div>
                    </AdMockupAccountInfo>
                    {/* <AdMockupCaption>
                        
                        <p>{props.adInfo?.caption}</p>
                        <p>...more</p>
                    </AdMockupCaption> */}
                    <AdMockupCaption>
                        <p>{props.adInfo?.caption && props.adInfo.caption.length > 15 ? props.adInfo.caption.substring(0, 15) + '...' : props.adInfo.caption}</p>
                        <p>{props.adInfo?.caption && props.adInfo.caption.length > 15 ? '...more' : null}</p>
                    </AdMockupCaption>
                    {props.adInfo?.cta ?
                        <AdMockupCtaTitle>
                            <h6>{findLabelByValue(props.adInfo?.cta,t)}</h6>
                        </AdMockupCtaTitle>
                    : null}
                    <AdMockupLabel>
                        <h6>Sponsored</h6>
                    </AdMockupLabel>
            </AdMockupBottomLeft>
        </AdMockupBottom>
    </FacebookMockupWrapper>
  )
}
