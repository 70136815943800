import { device, H2, H3, Main_ } from 'monica-alexandria'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const HomeForm = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    background: var(--dark);

    h2{
        text-align: center;
        color: var(--white);
        max-width: 70rem;
    }

    h3{
        text-align: center;
        color: var(--white);
        max-width: 70rem;
        margin-top: 4rem;
    }
`

const HomeFormImg = styled.div`
    height: 50rem;

    img{
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    @media ${device.md}{
        height: 25rem; 
    }
`

export const Home_Form = (props) => {
    const {t} = useTranslation();
  return (
    <HomeForm>
        <H2>{t("You've made it this far, and you're still not convinced?")}</H2>
        <Main_ size='large' text={t("Book an appointment")} onClick={props.onBook} />
        <H3>{t("Our team will demonstrate a live demo of our product and answer any questions you may have.")}</H3>
        {/* <HomeFormImg>
            <img src="https://genius1071.friktoriaservers.net/doitforme/morning/app/tarzan/device/MobileFeed.svg" alt=""/>
        </HomeFormImg> */}
    </HomeForm>
  )
}
