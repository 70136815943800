import { Actions_, Alert, AlertContext, Alert_, Builder_, Dropdown_, Grey_, Grey_Link, Input_, Main_, Main_Plain, Page, PageAlwaysVisible, Section_Title, PageDesktopOnly, PageForty, PageTitle, Upload_Media, device, Main_Loader, Confirmation_, Form, Textarea_, PageDesktopPadding, Toggle_Stat, ValidatorError, IconSvg, Main_Text_Loader, Partial_Loader } from 'monica-alexandria'
import React, { useContext, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { alertMessage } from '../../utils/messagesAlerts';
import a from '../../services/api/Ads'
import m from '../../services/api/Meta'
import q from '../../services/api/Quests'
import styled from 'styled-components';
import { load_my_ad, prepare_ad_for_saving_in_DB } from './utils';
import { useNavigate, useParams } from 'react-router-dom';
import { Preview_Ad_Mockup } from '../../components/ad/Preview_Ad_Mockup';
import store from '../../redux/store';
import { Ad_Templates } from '../../components/ad/Ad_Templates';
import { Helmet } from 'react-helmet';
import { useDebounce } from 'use-debounce';
import Preview_Catalog_Mockup from '../../components/ad/Preview_Catalog_Mockup';
import { Create_Ad_Fields } from '../../components/ad/Create_Ad_Fields';
import { Create_Ad_Service_Only_Fields } from '../../components/ad/Create_Ad_Service_Only_Fields';
import { ModifyAdToggle } from '../../styles/components/ModifyAd';


const EditAd = styled.div`
    .unclickable{
        opacity: 0.7;
        pointer-events: none;
    }
`
const AdMockupWrapper = styled.div`
    padding-top: 6rem;
    display: flex;
    align-items: center;

    @media ${device.md} {
       padding-top: 0rem;
    }
`

const AdError = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  margin-bottom: 1rem;
  align-items: center;
  grid-gap: .5rem;
  border-radius: .5rem;
  padding: var(--normalPads);
  border: 2px solid var(--error);
  a {
    text-decoration: underline;
    font-weight: 900;
    color: ${(p)=> p.theme.color}
  }

  h6{
    color: var(--error);
  }

  svg {
    fill: var(--error);
    width: 2rem;
  }
`
const EditAdEshopType = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
`

const EditAdEshopTypeChoice = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    border-top-left-radius: 2rem;
    border-top-right-radius: 2rem;
    border-bottom-left-radius: ${p => p.activeType ? '0': '2rem'};
    border-bottom-right-radius: ${p => p.activeType ? '0': '2rem'};
    background: ${p => p.theme.low};
    cursor: pointer;
    margin: ${p => p.activeType ? '0rem 0rem' : '.5rem 1.5rem'};
    padding: ${p => p.activeType ? '1rem 2rem': '0rem 0rem'};

    span{
        transition: color .5s;
        font-family: 'fontMedium', sans-serif;
        font-size: 1.8rem;
        color: ${p => p.activeType ? 'var(--main)' : 'var(--greyDark)'};
    }

    svg{
        width: 2rem;
        fill: ${p => p.activeType ? 'var(--main)' : 'var(--greyDark)'};
    }

    &:hover{

        span{
            transition: color .5s;
            /* color: ${p => p.theme.color}; */
            color: var(--mainLight);
        }        
        svg{
            fill: var(--mainLight)
        }
    }
`

const AdForm = styled(Form)`
    border-top-left-radius: ${p => p.activeType === 'productCatalog' ? '0' : p.activeType === 'default' ? '3rem' : '3rem'};
    border-top-right-radius: ${p => p.activeType === 'productCatalog' ? '3rem' : p.activeType === 'default' ? '0' : '3rem' }; 
`

const CatalogMockupWrapper = styled.div``

export default function EDIT_AD() {

    const {t} = useTranslation();
    const {alerts, setAlerts} = useContext(AlertContext);
    const [confirmation, setConfirmation] = useState(false);

    const [showTemplates, setShowTemplates] = useState({id:'', slider:false});

    const {lang} = useParams();
    const {questId} = useParams();
    const navigate = useNavigate();

    const [productSets, setProductSets] = useState([]);
    const [products, setProducts] = useState([])

    const [emptyFields, setEmptyFields] = useState([]);
    const [mediaErrors, setMediaErrors] = useState([]);

    const [ads, setAds] = useState([]);
    const [adsLoading, setAdsLoading] = useState(true);

    const [loadingProductSets, setLoadingProductSets] = useState(false)

    const [error, setError] = useState('');


    const [productCatalogs, setProductCatalogs] = useState([]);

    //const [adsLoading, setAdsLoading] = useState(true);


    const [adInfo, setAdInfo] = useState({caption:"", title: "",cta:"", media:""})
    const [activeFields, setActiveFields] = useState({caption:false, title: false,cta:false, media:false})
    const [activeFieldsReset] = useDebounce(adInfo, 1000);
    const [loading, setLoading] = useState(false)



    // Searches for previous quests in order to reuse an ad, if possible
    // const check_for_previous_quests = () =>{
    //     q.getMyQuests()
    //     .then(res => {
    //         if (res?.data?.code == 'Q5006') {

    //             let quests = res?.data?.data;

    //             if (quests?.length > 1 && store?.getState()?.reuseAd !== 'DECIDED') {
    //             setConfirmation(true);
    //             }
    //         }
    //     })
    // }


    const get_my_ad = async() => {
        try {
            await a.getMyAd(questId)
            .then(res => {
                if (res?.data?.code == "A2002") {
                    setAdInfo(load_my_ad(res?.data?.ad));
                    
                    if (res?.data?.ad?.mode==='productCatalog') {
                        get_product_catalogs();
                        get_product_sets(res?.data?.ad?.catalogId, false)

                    }
                }
            })
        } catch (err) {
            console.log(err);
        }
    }


    // Saving the decision in the cache because we do not want to spam the user with the popup
    const choice_no = () => {
        store.dispatch({type:'REUSE_AD', payload: 'DECIDED'});
        setConfirmation(false);
    }

    // Saving the decision in the cache because we do not want to spam the user with the popup
    const choice_yes = () => {
        store.dispatch({type:'REUSE_AD', payload: 'DECIDED'});
        setConfirmation(false);
        setShowTemplates({...showTemplates, slider:true})
    }

   
    const get_my_ads = async() => {
        try {
            await a.getMyAds()
                a.getMyAds()
                .then(res => {
                    console.log('RESPONSE GET MY ADS' , res);
                    if (res?.data?.code == 'A2006') {
                        console.log('RESADS',res?.data?.ads );
                        handleFilter(adInfo.flow,adInfo?.mode, res?.data?.ads )
                        //setAds(res?.data?.ads);

                    }
                    else {
                        alertMessage(alerts, setAlerts, (t('Something went wrong')), 'Error');
                    }
                    setAdsLoading(false);
                })
        } catch (err) {
            console.log(err);
        }
    }

    const handleFilter = (flow, mode, ads) => {
        const filteredData = ads.filter((item) => {

        if (flow === 'eshop' && mode) {
            return item.flow === flow && item.mode === mode;
        } else if (flow) {
            return item.flow === flow;
        } else {
            return item; // No filter applied, return everything
        }
        })
        
        // if (filteredData?.length > 1 && store?.getState()?.reuseAd !== 'DECIDED') {
        //     setConfirmation(true);
        // }

         setAds(filteredData);
    };

    const get_product_catalogs = async() => {
        try {
            await m.getProductCatalogs()
            .then(res => {
                setProductCatalogs(res?.data?.productCatalogs)
            // get_product_sets()
                
            })
        } catch (err) {
            console.log(err);
        }
    }


    const get_products_from_product_set = async(productSetId, productSetName) => {
            try {
                await q.getProductsFromProductSet(adInfo?.questId, productSetId)
                .then(res => {
                    console.log('RESPRODSET', res?.data);
                    
                    if (!["Q2010", "Q2011"]?.includes(res?.data?.code)) {
                        setProducts([]);
                        console.log('res?.data?.msg', res?.data?.msg);
                        
                        alertMessage(alerts, setAlerts, res?.data?.msg, 'Error')
                    }
                    else {
                        setProducts(res?.data?.products);
                        edit_product_set(productSetId, productSetName,res?.data?.products[0]?.image_url  )
                        //productImg to save as ad preview
                        // setAdInfo(prevAdInfo => ({
                        // ...prevAdInfo,
                        // productSetImg: res?.data?.products[0]?.image_url
                        // })); 
                    }
                })
            } catch (error) {
                console.error('Error fetching product sets:', error);
            }
        }

    const get_product_sets = async(catalogId, changed) => {
        setLoadingProductSets(true)
        try {
            await m.getProductSets(catalogId)
            .then(res => {
            
                setProductSets(res?.data?.productSets)
                setLoadingProductSets(false)
                
                //changed from dropdown
                if(changed) {
                    setAdInfo(prev => ({
                        ...prev,
                        productSetId: res?.data?.productSets[0]?.id || '',
                        productSetName: res?.data?.productSets[0]?.name || '',
                    }));

                }
        
            })
        } catch (err) {
            console.log(err);
        }
    }

    

    const validateFields = () => {
        const emptyFields = [];
     
        if (!adInfo.caption) {
            emptyFields.push('Caption');
        }
        if ((!adInfo.title || adInfo?.title === "") && adInfo?.mode !== 'productCatalog') {
            emptyFields.push('Headline');
        }
        if (adInfo.cta === 'none' || !adInfo.cta) { // Check if cta is 'none' or empty
            emptyFields.push('Cta');
        }   
        if (!adInfo.media && !adInfo?.visualElement &&  adInfo?.mode !== 'productCatalog') {
            emptyFields.push('Media');
            setMediaErrors([])
        }
        if (adInfo?.flow == 'eshop' && adInfo?.mode === 'productCatalog' && !adInfo?.catalogId) {
            emptyFields.push('Catalog');
        }
        if ( adInfo?.flow == 'eshop' && adInfo?.mode === 'productCatalog' && productSets?.length  && !adInfo?.productSetId)  {
            emptyFields.push('ProductSet');
        }

       setEmptyFields(emptyFields)
        return emptyFields;
    };


    const edit_ad = (isDraft) => {
        let adFinal = prepare_ad_for_saving_in_DB(adInfo, isDraft);
            
        setLoading(true);
        console.log("adInfo?.file", adInfo?.file)
        a.editAd(adFinal, adInfo?.file, isDraft,  adInfo?.mode)
         .then(res => {
            console.log("res", res?.data)
            if (res?.data?.code !== 'A2001') {
                setError(res?.data);
              }
              else {
                alertMessage(alerts, setAlerts, (t('Campaigns edited succesfully!')),'Success');
                navigate(`/${lang}/dashboard`)
              }
              setLoading(false);            
        })
    }


    const editAdCreative = () => {

        setLoading(true);
        m.editAdCreative(questId, adInfo?.mode)
          .then(res => {

            console.log('RES EDIT AD CREATIVE', res?.data);
            
            if (res?.data?.code !== 'C2007' && res?.data?.code !== '2002') {
              setError(res?.data);
            }
            else {
              alertMessage(alerts, setAlerts, (t('Campaigns edited succesfully!')),'Success');
              navigate(`/${lang}/dashboard`)
            }
            setLoading(false);
          }) 
      }

    const save_product_catalog = async(catalogId) =>{
        setAdInfo((prev) => ({
            ...prev,
            catalogId: catalogId,
          }));
        const selectedCatalog = productCatalogs.find(catalog => catalogId === catalog?.catalogId);
        console.log('catalog--',selectedCatalog);
      
      
        try {
            await m.saveCatalog(selectedCatalog?.catalogId, selectedCatalog?.catalogName, selectedCatalog?.catalogBusinessId, adInfo?.questId);
        } catch (err) {
            console.log(err);
        }

        get_product_sets(catalogId, true)
        
      }

      const edit_product_set = async(productSetId, productSetName, productSetImg) =>{
        const newMetaField = {
            'Meta.productSetId': productSetId,
            'Meta.productSetName': productSetName,
            'Meta.productSetImg': productSetImg,

            questId: adInfo?.questId
        };
      
        
        
        setAdInfo(prev => ({
            ...prev,
            productSetId: productSetId,
            productSetName: productSetName,
        }));

        try {
            await q.editQuestMode(newMetaField);
        } catch (err) {
            console.log(err);
        }
      }
      
      
      
      const edit_quest_mode = async (newMode) => {
        const newMetaField = {
            'Meta.mode': newMode,
            questId: adInfo?.questId
        };
    
        try {
            await q.editQuestMode(newMetaField);
            setAdInfo({ ...adInfo, mode: newMode });
    
            if (newMode === 'productCatalog') {
                if (adInfo?.catalogId && adInfo?.productSetId) {
                    await get_product_catalogs();
                    await get_product_sets(adInfo?.catalogId, false);
                    await get_products_from_product_set(adInfo?.productSetId, adInfo?.productSetName);
                } else {
                    await get_product_catalogs();
                }
            }
        } catch (error) {
            console.error('Error updating quest mode:', error);
        }
    };

    useEffect(() => {
        get_my_ad();
       // check_for_previous_quests();
    }, [])

    useEffect(()=>{
        get_my_ads();
    }, [adInfo?.flow, adInfo?.mode])

    useEffect(() => {
        if (adInfo?.productSetId) get_products_from_product_set(adInfo?.productSetId, adInfo?.productSetName)
     }, [adInfo?.productSetId])

  
    useEffect(() => {
        setActiveFields({caption:false, title: false,cta:false, media:false})
    },[activeFieldsReset])


   
    console.log('adinfo', adInfo);
    
  if (loading) return <Main_Text_Loader text={t('Uploading media and creating your ad. This may take a moment...')}/>

  return (
    <EditAd>

        <Helmet>
            <title>Questad | Edit advertisement</title>
        </Helmet>
        {showTemplates?.slider &&  
            <Ad_Templates adInfo={adInfo} ads={ads} setAds={setAds} adsLoading={adsLoading} setShowTemplates={setShowTemplates} questId={questId}/>
        }
        <PageForty>
        {/* {confirmation  ?
         <Confirmation_
                message={t("Would you like to reuse an already existing ad?")}
                no={t("No")}
                onNo={() => choice_no()}
                onYes={()=> choice_yes()}
                yes={t("Yes")}
            /> : <></>} */}
        <Alert_
            messages={alerts}
            duration = {2000}
        />
        <PageAlwaysVisible>
           <PageTitle cta>
                <h1>{t('Edit ad')}</h1>
                {/* {store?.getState()?.reuseAd == 'DECIDED' && ads?.length ? <Main_Plain text={t('Reuse Ad')} iconLeft='Recycle' onClick={()=> setShowTemplates({...showTemplates, slider:true})} /> : <></>} */}
                {/* {ads?.length ? <Main_Plain text={t('Reuse Ad')} iconLeft='Recycle' onClick={()=> setShowTemplates({...showTemplates, slider:true})} /> : <></>} */}
            </PageTitle>
            {error ? 
                <AdError>
                    <IconSvg Icon ='Tip' />
                    <h6>{error?.msg} {error?.link ? <a href={error?.link} target="_blank">{t("Click here to accept the terms")}</a>: <></>}</h6>
                </AdError> 
            :   null }  

            {adInfo?.flow == 'eshop' ? 
                    <ModifyAdToggle>
                        <EditAdEshopType>
                            <EditAdEshopTypeChoice activeType={adInfo?.mode == 'productCatalog'} onClick={() => edit_quest_mode('productCatalog')}>
                                <IconSvg Icon='Products'/>
                                <span>{t('Product catalog')} </span>
                            </EditAdEshopTypeChoice>
                            <EditAdEshopTypeChoice activeType={adInfo?.mode == 'default'} onClick={() => edit_quest_mode('default')}>
                                <IconSvg Icon='UploadImage'/>
                                <span> {t('Media')}</span>
                            </EditAdEshopTypeChoice>
                        </EditAdEshopType>
                    </ModifyAdToggle>
            : 
                null
            }
            <AdForm activeType={adInfo?.flow == 'eshop' ? adInfo?.mode : 'none'}>
                <Create_Ad_Fields
                    adInfo={adInfo} 
                    setAdInfo={setAdInfo} 
                    emptyFields={emptyFields} 
                    setEmptyFields={setEmptyFields}
                />

                {adInfo?.flow == 'eshop' && adInfo?.mode == 'productCatalog' ?  null :
                    <Create_Ad_Service_Only_Fields
                        adInfo={adInfo} 
                        setAdInfo={setAdInfo} 
                        emptyFields={emptyFields} 
                        setEmptyFields={setEmptyFields}
                        mediaErrors={mediaErrors}
                        setMediaErrors={setMediaErrors}
                    />
                }
                 {adInfo?.flow == 'eshop' && adInfo.mode == 'productCatalog'  ? 
                    <div>
                            <Builder_ title={t('Select a product catalog')}>
                            <Dropdown_>
                                <select 
                                    value ={adInfo?.catalogId || ''} 
                                    onChange={(e) => save_product_catalog(e.target.value)}
                                >
                                    <option value={''} disabled>{t('Product catalog')}</option>
                                    {productCatalogs?.map((catalog) => (
                                        <option
                                            key={catalog?.catalogId}
                                            value={catalog?.catalogId}
                                        >
                                            {catalog?.catalogName}
                                        </option>
                                    ))}
                                </select>
                            </Dropdown_>

                            {emptyFields?.includes('Catalog') ? (
                                    <ValidatorError>
                                        <IconSvg Icon="Error" />
                                        <h6>{t('Product catalog must be selected')}</h6>
                                    </ValidatorError>
                           ) : null} 
                        </Builder_>


                        <Builder_ title={t('Select a product set')}>
                            {loadingProductSets ? <Partial_Loader /> : 
                                <Dropdown_>
                                    <select 
                                        value ={adInfo?.productSetId || ''} 
                                        onChange={(e) => edit_product_set(e.target.value, e.target.selectedOptions[0].text)}
                                    >
                                        <option value={''} disabled>{t('Product set')}</option>
                                        {productSets?.map((product) => (
                                            <option
                                                key={product.id}
                                                value={product.id}
                                            >
                                                {product.name}
                                            </option>
                                        ))}
                                    </select>
                                </Dropdown_>
                            }
                            {emptyFields?.includes('ProductSet') ? (
                                <ValidatorError>
                                    <IconSvg Icon="Error" />
                                    <h6>{t('Product set must be selected')}</h6>
                                </ValidatorError>
                             ) : null} 
                            </Builder_>
                    </div>
                : null
                }
                {/* {adInfo.mode == 'productCatalog' && productSets?.length ? 
                    <Builder_ title={t('Select a product set')}>
                        <Dropdown_>
                            <select 
                                defaultValue ={adInfo?.productSetId || ''} 
                                onChange={(e) => edit_product_set(e.target.value, e.target.selectedOptions[0].text)}
                            >
                                <option value={''} disabled>{t('Select product catalog')}</option>
                                {productSets?.map((product) => (
                                    <option
                                        key={product.id}
                                        value={product.id}
                                    >
                                        {product.name}
                                    </option>
                                ))}
                            </select>
                        </Dropdown_>
                    </Builder_>
                : null
                } */}
           </AdForm>
           <Actions_>
                <Grey_ iconLeft="Back" text={t('Back')} onClick={()=>navigate(`/${lang}/dashboard`)} noPadding />
                <Main_ 
                    text={t('Done')} 
                    iconRight='Save'   
                    className = {adInfo?.flow == 'eshop' && adInfo.mode == 'productCatalog' && !products?.length ? 'unclickable' :''}
                    onClick={() => {
                        if (validateFields()?.length ) {
                            alertMessage(alerts, setAlerts, (t('Please check fields')),'Error')
                            return; }
                        else edit_ad(false)
                        }}  
                 />
            </Actions_>
        </PageAlwaysVisible>
        <PageDesktopOnly>
             {/* <PageDesktopPadding> */}
                { adInfo?.flow == 'eshop' && adInfo?.mode == 'productCatalog'  ?
                    <CatalogMockupWrapper>
                        <Preview_Catalog_Mockup products={products} edit_product_set={edit_product_set} adInfo={adInfo} setAdInfo={setAdInfo}/>
                    </CatalogMockupWrapper>
                    :
                    <AdMockupWrapper>
                        <Preview_Ad_Mockup adInfo={adInfo} />
                    </AdMockupWrapper>
                }
            {/* </PageDesktopPadding> */}
        </PageDesktopOnly>
    </PageForty>
    </EditAd>
  )
}
