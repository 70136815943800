import { Alert_, AlertContext, CardsFull, CardsSixty, Filters_, Google_Search, Grey_Link, Main_, Main_Loader, Main_Plain, PageAlwaysVisible, PageFull, PageSixty, PageSubtitle, PageTitle, Partial_Loader, Partial_Text_Loader, Section_Search, Section_Title, Sixty_Card_Loader, Sixty_Stat_Loader, Toggle_Stat } from 'monica-alexandria'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import m from "../../services/api/Meta";
import { useNavigate, useParams } from 'react-router-dom';
import { alertMessage } from '../../utils/messagesAlerts';

const AdAccountSearch = styled.div`
  width: 100%;
`


export default function AD_ACCOUNTS() {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const {lang} = useParams();
  const [businesses, setBusinesses] = useState([]);
  const [selectedBusiness, setSelectedBusiness] = useState(false);

  const {alerts, setAlerts} = useContext(AlertContext);
  const initialAccountState = {
    cursor: '',
    accounts: []
  }
  const [searchResults, setSearchResults] = useState([])
  const [ownedAdAccounts, setOwnedAdAccounts] = useState(initialAccountState);
  const [clientAdAccounts, setClientAdAccounts] = useState(initialAccountState);
  const [selectedAdAccount, setSelectedAdAccount] = useState(false);
  const [loading, setLoading] = useState(false);


  const get_connected_businesses= () => {

    m.getConnectedBusinesses()
    .then(res => {

      setBusinesses(res.data.data)
      handleSelectedBusiness(res.data.data[0]?.id)
       console.log('adsfgd',res.data.data[0]?.id)
     })

  }

  const get_owned_ad_accounts= (businessId) => {
    setLoading(true)

    m.getOwnedAdAccounts(businessId, ownedAdAccounts?.cursor)
    .then(res => {
      let oldPages = [];
      if(businessId === selectedBusiness)
       oldPages = JSON.parse(JSON.stringify(ownedAdAccounts?.accounts));
      else oldPages = []

    const pages = res?.data?.data || [];
    const oldAndNewAccounts = oldPages.concat(pages);

    const cursor = res?.data?.next;
    if (!cursor) setLoading(false);
    console.log('oldAndNewAccounts',oldAndNewAccounts);
    setOwnedAdAccounts(prev => ({
      ...prev,
      accounts: oldAndNewAccounts,
      cursor: cursor
  }));
    if (!cursor) get_client_ad_accounts(businessId);
    
  })

  }

  const get_client_ad_accounts= (businessId) => {
    setLoading(true)
    console.log('selectedBusiness', selectedBusiness);

    m.getClientAdAccounts(businessId, clientAdAccounts?.cursor)
    .then(res => {
      console.log('getClientAdAccounts', res)
      console.error(res)
    
      let oldPages = [];
      if(businessId === selectedBusiness)
        oldPages = JSON.parse(JSON.stringify(clientAdAccounts?.accounts));
      else oldPages = []

      const pages = res?.data?.data || [];
      const oldAndNewAccounts = oldPages.concat(pages);

      const cursor = res?.data?.next;
      if (!cursor) setLoading(false);

   
      console.log('oldAndNewPages',oldAndNewAccounts);
      setClientAdAccounts({...clientAdAccounts, accounts: oldAndNewAccounts, cursor: cursor});
     
     })

  }

  const handleNext= () =>{
    if (!selectedBusiness || !selectedAdAccount){
      alertMessage(alerts, setAlerts, (t('Please select business and ad account')), 'Error');
    }
    else navigate(`/${lang}/questionnaire/beta?business_id=${selectedBusiness}&ad_account_id=${selectedAdAccount}`)
  }

  const handleSelectedBusiness = (businessId) => {

    console.log('businessId', businessId);
    if (businessId) {
      get_owned_ad_accounts(businessId)
    } 
    
    
    setSelectedBusiness(businessId)
    
    
  }

  useEffect(() => {
    get_connected_businesses();
  }, []);

  
  useEffect(()=>{
    if (clientAdAccounts?.cursor) get_client_ad_accounts(selectedBusiness);
}, [clientAdAccounts?.cursor])

useEffect(()=>{
  if (ownedAdAccounts?.cursor) get_owned_ad_accounts(selectedBusiness);
}, [ownedAdAccounts?.cursor])

const combinedAccounts = [...ownedAdAccounts?.accounts, ...clientAdAccounts?.accounts];


console.log('ownedAdAccounts', ownedAdAccounts, clientAdAccounts);
console.log('selectedBusines', selectedBusiness);


  return (
    <PageFull>
      <Alert_ />
      <PageTitle >
        <h1>{t("Ad Account Integration")}</h1>
      </PageTitle>
      <Filters_>
        <Grey_Link iconLeft='Back' noPadding onClick={()=> navigate(`/${lang}/choose-facebook-page`)}/>
        <AdAccountSearch>
          <Google_Search
            file={combinedAccounts}
            fileKeys={['name','greeklish', 'greeklish_reversed']}
            defaultValue={''}
            sanitize={true}
            placeholder={t('Search Ad Account')}
            applyReverseFileProperties={[
                { 
                  originalKey: 'name',
                  reversedKey: 'name_reversed',
  
                  originalKey: 'greeklish',
                  reversedKey: 'greeklish_reversed',
                },
            ]}
            setResults={(data) => setSearchResults(data)}
            minMatchCharacters={1}
            maxMatchCharacters={50}
            disabled={false}
            isCaseSensitive={false}
            maxAcceptedScore={0.6}
          /> 
        </AdAccountSearch>
        <Main_ noPadding text={t("Next")} iconRight='Next' onClick={()=>handleNext()} />
      </Filters_>

      <Section_Title text={t('Select Facebook Business Manager')} />

        <CardsFull>


        {businesses?.map((business, i) => {
          const isActive = selectedBusiness === business?.id; 
          return (
            <Toggle_Stat
              key={i}
              id={i}
              text={business.name}
              onClick={() => handleSelectedBusiness(isActive ? false : business?.id)} // Toggle by id
              active={isActive} // Set active state
            />
          );
        })}  
        </CardsFull> 
        {selectedBusiness ? 
        <div>
          <Section_Title text={t('Select Facebook Ad Account')} />

      {searchResults?.input? 
         <CardsFull>
         {searchResults?.data?.map((account, i) => {
            const isActive = selectedAdAccount === account?.id; 
            return (
              <Toggle_Stat
                key={i}
                id={i}
                text={account.name}
                onClick={() => setSelectedAdAccount(isActive ? false : account?.id)} // Toggle by id
                active={isActive}
              />
            );
          })}
          </CardsFull>
      :
        <CardsSixty>
          {combinedAccounts?.map((account, i) => {
            const isActive = selectedAdAccount === account?.id; 
            return (
              <Toggle_Stat
                key={i}
                id={i}
                text={account.name}
                onClick={() => setSelectedAdAccount(isActive ? false : account?.id)} // Toggle by id
                active={isActive}
              />
            );
          })}

        </CardsSixty>
      }

        {loading ? <Sixty_Stat_Loader/> : null}

      </div>
      : null}


    </PageFull>
  )
}
