import { Builder_, Color_Picker, Slider_, Upload_Logo } from 'monica-alexandria'
import React, { useContext, useState } from 'react'
import styled from 'styled-components'
import { LandingContext } from '../../context/LandingContext'
import { useTranslation } from 'react-i18next'

const LandingColors = styled.div`
`
const ColorPickerWrapper = styled.div`
  padding-bottom: 2rem;
`
export const Landing_Colors_Slider = (props) => {

    const {landing, setLanding} = useContext(LandingContext) || {};
    const {t} = useTranslation();

    const upload_logo = async (files) => {
      if (files?.length && files[0]?.preview) {    
              setLanding({
                ...landing,
                logo:`${files[0]?.preview}`,
                fileLogo: files[0]
              });    
          }  
    };

    const delete_pic = async (files) => {
      if (files?.length ) {
              setLanding((prevLanding) => ({
                ...prevLanding,
                img:''
              }));    
          }  
    };

    const handleSaveColorsToContext = ({ colorType, newColor }) => {
      setLanding((prevLanding) => ({
        ...prevLanding,
        colors: {
          ...prevLanding.colors,
          [colorType]: newColor,
        },
      }));
    };

    console.log('landing COLORS SLIDER', landing);
  return (
    <LandingColors>
        <Slider_
            text={t("Done")}
            grey={t("Cancel")}
            onGrey={() => props.onClose()}
            onClose={() => props.onClose()}
            title={t("Brand kit")}
            onClick={() => props.onClose()}
          >
            {/* <Builder_ title={t("Upload you logo")}>
              <Upload_Logo 
                upload={t("Upload")}
                getFilesFromChild={(files) => upload_logo(files)}
                img={landing?.logo}
                onDelete={() => delete_pic()}
                delete={t("Delete")}
                uploaded={true}
                boomRejected={props.boomRejected}
                helpers={[(t('Acceptable files png, jpg, jpeg, less than 2mb'))]}
                maxFiles="1"
                maxSize="2000000"
              />
            </Builder_> */}
            <Builder_ title={t('Select your colors')}>
              <ColorPickerWrapper>
                <Color_Picker
                    text={t('Background')}
                    passColor = {handleSaveColorsToContext}
                    colorType = 'background'
                    selectedColor={landing?.colors?.background}
                />
              </ColorPickerWrapper>
              <Color_Picker
                  text='Text'
                  passColor = {handleSaveColorsToContext}
                  colorType = 'text'
                  selectedColor={landing?.colors?.text}
              />
            </Builder_>
        </Slider_>
    </LandingColors>
  )
}
