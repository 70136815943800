import { t } from 'i18next';
import { Main_Plain, device, IconSvg } from 'monica-alexandria';
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import styled, { keyframes } from 'styled-components'
import m  from '../../../services/api/Meta';
import { prettifyDates } from '../../../helpers/prettifiers';
import { useTranslation } from 'react-i18next'
import Dashboard_Graph from './graph/Graph_Chart';
import { getAdImageUrl } from '../../../utils/getAdImageUrl';

const minioUrl = process.env.REACT_APP_MINIO_PROTOCOL +
                process.env.REACT_APP_MINIO_HOST + 
                process.env.REACT_APP_MINIO_PORT+ '/' +
                process.env.REACT_APP_MINIO_BUCKET + '/';

const QuestCard = styled.div`
    padding-bottom: 2rem;

    &:hover .DashboardIcon{
        svg{
            transform: scale(1.1) translateX(1rem);
        }
    }
`

const activeQuestAnimation = keyframes`
    0% {
        transform: translateX(-100%);
        opacity:1;
    }

    10%{
        transform: translateX(-45%);
    }

    50% {
        transform: translateX(0%);
        opacity: .2;
    }

    90%{
        transform: translateX(70%);
    }

    100% {
        transform: translateX(100%);
        opacity:1;
    }
`;


const DashboardGrid = styled.div`
    display: grid;
    grid-template-columns: 15rem 1fr 2fr 1fr auto;
    grid-gap: 1rem;
    padding: var(--normalPads);
    border-radius: var(--smallRadius); 
    background: transparent;
    position: relative ;

    @media ${device.xl} {
        grid-template-columns: 15rem 1fr;
    }

    @media ${device.md} {
        grid-template-columns: 15rem 1fr 2fr 1fr auto;
    }

    @media ${device.sm} {
        grid-template-columns: 1fr;
    }
`

const DashboardTitle = styled.div`
    padding: 1rem 2rem 0 2rem;
`

const DashboardGridAnimation = styled.div`
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
    animation: ${activeQuestAnimation} infinite 5s linear;
    background: linear-gradient(to right, transparent, transparent, var(--main), transparent, transparent);

`

const DashboardGridWrapper = styled.div`
    border-radius: var(--smallRadius); 
    position: relative;
    background: ${p => p.theme.mid};
    overflow: hidden;
    cursor: pointer;
`
const DashboardGridInner = styled.div`
    display: grid;
    grid-template-rows: 1fr 2fr;
    grid-gap: 1rem;
`

const DashboardBox = styled.div`
    padding: var(--largePads);
    border-radius: var(--smallRadius);
    background: ${p => p.theme.low};
`
 
const DashboardBoxAd = styled(DashboardBox)`
    padding: 0;
`

const DashboardBoxPrice = styled(DashboardBox)`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    grid-column: ${p => p.productCatalog ? 'span 2' : 'span 1'};

    h5{
        text-align: center;
        transform: translateY(-1rem);
        color: var(--greyDark);
    }

    h2{
        color: var(--main);
        font-size: 8rem;
        font-family: "fontBold", sans-serif;
    }

    .Decimals{
        font-size: 2rem;
    }
    
`

const DashboardBoxInfo = styled(DashboardBox)`
    //background: transparent;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    h5{
        padding-bottom: 1rem;
    } 
    p{
        padding-bottom: 1rem;
    }   
`

const DashboardBoxKpi = styled(DashboardBox)`
   h2{
    color: var(--main);
   }
`

const DashboardBoxLeads = styled(DashboardBoxKpi)`
    @media ${device.xl} {
        grid-column: span 2;
    }

    @media ${device.md} {
        grid-column: span 1;
    }

    @media ${device.sm} {
        grid-column: span 1;
    }
`

const DashboardBoxContent = styled.div`
height: 100%;
        width: 100%;
    img {
        object-fit: cover;
        height: 100%;
        width: 100%;
        border-radius: var(--smallRadius);
    }
    h5{
        text-align: center;
    }

    `
    
const DashboardActions = styled.div`
    display: flex;
    justify-content: right;
    padding: var(--normalPads);
`

const DashboardBoxMetrics = styled.div`
      display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;
    //padding: var(--normalPads);
    border-radius: var(--smallRadius); 
    background: transparent;
    position: relative ;

    @media ${device.xl} {
        grid-column: span 2;
    }

    @media ${device.md} {
        grid-column: span 1;
    }

    @media ${device.sm} {
        grid-column: span 1;
    }
`

const DashboardMetricsWrapper = styled.div``

const DashboardProblem = styled.div`
    padding: var(--normalPads);
    display: flex;
    align-items: baseline;
    gap: 0.5rem;
    background-color: ${p => p.theme.background};
    border: 2px dotted var(--greyDark);

    svg{
        width: 1.3rem;
        fill: var(--error);
    }
    h6{
        color: var(--error);
    }

    @media ${device.md} {
        align-items: baseline;
        svg{
            width: 2rem;
        }
    }
`

const DashboardReview = styled(DashboardProblem)`
    svg{
        width: 1.3rem;
        fill: var(--warning);
    }
    h6{
        color: var(--warning);
    }
`

const DashboardIcon = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    svg{
        width: 3rem;
        fill: var(--greyDark);
        transition: all .5s;
    }

    @media ${device.xl} {
        display: none;
    }

    @media ${device.md} {
        display: flex;
    }

    @media ${device.sm} {
        display: none;
    }
`

const DashbboardFullGridItem = styled.div`
    padding: var(--normalPads);
`

const DashboardMetrics = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin: 2rem;
    grid-gap: 2rem;

    @media ${device.xl} {
        grid-template-columns: 1fr;
    }

    @media ${device.md} {
        grid-template-columns: 1fr 1fr;
    }

    @media ${device.sm} {
        grid-template-columns: 1fr;
    }
`

export const Quest_Card = (props) => {

     const {lang} = useParams();
     const navigate = useNavigate();
    


      
      const getQuestImageUrl = () => {
        if (props.quest?.adInfo?.thumbnail && props.quest?.adInfo?.thumbnail !== 'default.jpg') {
          return props.quest?.adInfo?.thumbnail;
        }

        if (props.quest?.Meta?.productSetImg) {
            return props.quest?.Meta?.productSetImg;
        }
      
        if (props.quest?.adInfo?.visualElement) {
          const minioUrl = props.minioUrl;
          return `${minioUrl}${props.quest?.userId}/${props.quest?.adInfo?.visualElement}`;
        }
      
        return false;
      };
      

     const {t} = useTranslation();

     console.log('KPIS', props.graphicalKpis);
     console.log('QUESTTTTTTTTT', props.quest , getQuestImageUrl() === false);
    
  return (
    <QuestCard onClick={props.onClick}>
        <DashboardGridWrapper>
        {props.problem ?
            <DashboardProblem>
                <IconSvg Icon='Warning' />
                <h6>{t('This quest is currently experiencing an issue. Please reach out to our support team for assistance.')}</h6>
            </DashboardProblem>
        : 
            null
        }
        {props.error ?
            <DashboardProblem>
                <IconSvg Icon='Warning' />
                <h6>{t('Your ad is dissapproved. Our experienced team is solving the problem. As soon as possible we will notify you through email for the actions that must be taken')}</h6>
            </DashboardProblem>
        : 
            null
        }
        {props.review ?
            <DashboardReview >
                <IconSvg Icon='Tip' />
                <h6>{t('Your ad is currently under review by Meta. It usually takes 5-15 minutes for the review process to complete.')}</h6>
            </DashboardReview>
        : 
            null
        }
        <DashboardTitle>
            <h5>{props.quest?.adInfo?.facebookPageName}</h5>
            <h6>{props?.quest?.questionnaires[1]?.answer} | {props?.quest?.questionnaires[0]?.answer}</h6>
        </DashboardTitle>
        {props.activeQuest ? 
            <DashboardGridAnimation/>
        : 
            null
        }
        <DashboardGrid>
            {!getQuestImageUrl() ?   null
            :
                <DashboardBoxAd>
                    <DashboardBoxContent>
                        <img src={getAdImageUrl(props.quest)} alt={props.quest?.adInfo?.title || 'Quest Image'} />
                    </DashboardBoxContent>
                </DashboardBoxAd>
            } 
            <DashboardBoxPrice productCatalog={getAdImageUrl(props.quest) === false}>
                <h2>€{props?.quest?.matchingKpis?.spend?.toFixed(2)}<span className='Decimals'></span></h2>
                <h5>{t('monthly charged')}</h5>
            </DashboardBoxPrice>
            {/* <DashboardBoxMetrics>
                <DashboardBoxKpi>
                    <h4>{t('Clicks')}</h4>
                    <h2>{props?.quest?.matchingKpis?.clicks || 0}</h2>
                </DashboardBoxKpi>
                <DashboardBoxKpi>
                    <h4>{t('Impressions')}</h4>
                    <h2>{props?.quest?.matchingKpis?.impressions || 0}</h2>
                </DashboardBoxKpi>
                <DashboardBoxKpi>
                    <h4>{t('Reach')}</h4>
                    <h2>{props?.quest?.matchingKpis?.reach || 0}</h2>
                </DashboardBoxKpi> 
                <DashboardBoxKpi>
                    <h4>{t('Frequency')}</h4>
                    <h2>{props?.quest?.matchingKpis?.frequency || 0}</h2>
                </DashboardBoxKpi> 
            </DashboardBoxMetrics>
        {props?.quest?.questionnaires[0]?.answer !== "eshop" ?
            <DashboardBoxLeads>
                <h4>{t('Leads')}</h4>
                <h2>{props.quest?.matchingLead?.length}</h2>
            </DashboardBoxLeads>
        :<></>}
        <DashboardIcon className='DashboardIcon'>
            <IconSvg Icon="Next" />
        </DashboardIcon>
    </DashboardGrid>

    {props?.quest?.draft ? 
        <DashboardActions>
            <Main_Plain iconLeft='Edit' text={t('Edit draft')} onClick={()=> navigate(`/${lang}/questionnaire/beta`)}/>
        </DashboardActions>
    : null}
            </DashboardBoxMetrics> */}
            {(props?.quest?.Meta?.pixelId == ''
            ||  !props?.quest?.Meta?.pixelId || props?.quest?.Meta?.pixelId == '2477760679178459') ? 
            <DashboardBoxLeads>
                <h4>{t('Leads')}</h4>
                <h2>{props.quest?.matchingLeadTotal || 'N/A'}</h2>
            </DashboardBoxLeads>
            : 
            <DashboardBoxLeads>
                <h4>{t('Page views')}</h4>
                <h2>{props?.matchingKpis?.page_views || 'N/A'}</h2>
            </DashboardBoxLeads>
        }
            
            <DashboardIcon className='DashboardIcon'>
                <IconSvg Icon="Next" />
            </DashboardIcon>
        </DashboardGrid>
        {/* <DashboardMetrics>
            {props?.graphicalKpis?.filter(kpi => !(kpi.key === 'date_start' || kpi.key === 'page_views' || kpi.key === 'date_stop' || kpi.key === 'spend')).map((metric, i) => 
                <Dashboard_Graph
                    total={metric.key === 'frequency' ? props.matchingKpis[metric.key].toFixed(2) : props.matchingKpis[metric.key] }
                    title={metric?.key}
                    key={i}
                    data={metric?.values}
                    xDataKey="date"
                    lineDataKey="value"
                />
            )}
        </DashboardMetrics> */}
        {props?.quest?.draft ? 
            <DashboardActions>
                <Main_Plain iconLeft='Edit' text={t('Edit draft')} onClick={()=> navigate(`/${lang}/questionnaire/beta`)}/>
            </DashboardActions>
        : 
            null
        }
    </DashboardGridWrapper>

    </QuestCard>
  )
}