import { AlertContext, CardsSixty, Grey_Link, On_Click_Card, PageAlwaysVisible, PageSixty, PageSubtitle, PageTitle } from 'monica-alexandria'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom';
import store from '../../redux/store';
import q from "../../services/api/Quests";
import { alertMessage } from '../../utils/messagesAlerts';


export default function NEW_QUEST() {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const { lang,questId } = useParams();
    const { alerts, setAlerts } = useContext(AlertContext);

    const continue_draft = () => {
        store.dispatch({ type: "REUSE_LANDING", payload: "NOT_DECIDED" });
        store.dispatch({ type: "REUSE_AD", payload: "NOT_DECIDED" });
        store.dispatch({ type: "ENTER_NEW_FLOW", payload: "START_NEW_QUEST" });
        navigate(`/${lang}/facebook-login`);
      };


    const delete_draft_and_create_quest = () => {
        q.deleteDraftQuest(questId).then((res) => {
          if (res?.data?.code == "Q2006") {

            // notifes frontend that the flow we are about to enter is "START_NEW_QUEST"
            store.dispatch({ type: "REUSE_LANDING", payload: "NOT_DECIDED" });
            store.dispatch({ type: "REUSE_AD", payload: "NOT_DECIDED" });
            store.dispatch({ type: "ENTER_NEW_FLOW", payload: "START_NEW_QUEST" });
            window.location.assign(`/${lang}/questionnaire/alpha`, {
              state: { flow: "START_NEW_QUEST" },
            });
          } else {
            alertMessage(alerts, setAlerts, (t("Something went wrong.")), "Error");
          }
        });
      };
    
    
  return (
    <PageSixty>
        <PageAlwaysVisible>
        <PageTitle>
            <Grey_Link iconLeft='Back' noPadding onClick={()=>navigate(`/${lang}/dashboard`)}/>
            <h1>{t("New quest")}</h1>
        </PageTitle>
        <PageSubtitle>            
            <p>{t("You have an unfinished quest. Starting a new quest will delete your current draft. What would you like to do?")} </p>
        </PageSubtitle>
        <CardsSixty>
            <On_Click_Card
                icon="Template"
                subtitle={t("Continue draft")}
                onClick={() => continue_draft()}
            />
            <On_Click_Card
                icon="AddPlan"
                subtitle={t("Start new quest")}
                onClick={() => delete_draft_and_create_quest()}
            />
           
        </CardsSixty>
        </PageAlwaysVisible>
    </PageSixty>
  )
}
