import { Builder_, CardsFull, Grey_Link, Input_, Main_, Main_Plain, On_Click_Card, PageFull, PageTitle, Slider_, TD, TH, TR, TRH, Table, TableDesktop, Textarea_ } from 'monica-alexandria'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import q from '../../../services/api/Quests';
import m from '../../../services/api/Meta';

import queryString from 'query-string'
import styled from 'styled-components';

const UserQuestsStatus = styled.div`
  height: 1rem;
  width: 1rem;
  border-radius: 50%;
  background: ${p => p.status === 'active' ? 'var(--success)' : 'var(--error)'};
`

const RecommendationsBtn = styled.div`
  display: flex;
  justify-content: right;
  padding-top: 1rem;

`

const RecommendationsList = styled.ul`
  li{
    padding: var(--largePads);
    border-radius: var(--smallRadius);
    background: ${p => p.theme.mid};
    margin-bottom: 1rem;

    p{
      text-align: right;
      padding-top: 1rem;
    }
  }
  
   /* display: grid;
   grid-template-columns: auto 1fr;
   grid-gap : 1rem; */
`


export default function USER_QUESTS() {
    const [selectedQuest, setSelectedQuest] = useState(false);
    const [ids, setIds] = useState({pixelId:'', catalogId: ''});
    const navigate = useNavigate();
    const {lang} = useParams();
    const {t} = useTranslation();
   // const {id} = useParams(); 
    const [quests, setQuests] = useState([])

    const [recommendationsList, setRecommendationsList] = useState([])
    const [recommendation, setRecommendation] = useState('')

    const {id, username} = queryString.parse(window.location.search)

    const get_user_quests = () =>{
      q.getQuests(id)
      .then(res => {
          if (res?.data?.code == 'Q2008') {
              setQuests(res?.data?.data)
          }
      })
    }

    const admin_edit_quest = (pixelId, catalogId) =>{
        q.adminEditQuest({
          'Meta.pixelId': pixelId.trim(),
          'Meta.catalogId': catalogId.trim(),
          '_id': selectedQuest?._id
        })
        .then(res => {
            if (res?.data?.code == 'Q2010') {
              m.sharePixelAdmin(pixelId.trim())
                .then(res => console.log('PIXEL ADMIN', res))
            }
            else {
              // alertMessage
            }
        })
      }



    const createNotification = (notification) =>{
      let userId = selectedQuest?.userId;
      let questId = selectedQuest?._id;

      let details={ action:notification}

      q.createNotification({userId, questId, details})
      .then(res => {
            console.log('RES CREATE NOTIF', res);
            get_notifications();
          }
        
     ) }
    

    const get_notifications = () =>{
      q.getAdminNotifications(selectedQuest?._id)
      .then(res => { 
        const filteredNotifications = res?.data?.notifications?.filter(notification => notification.source === 'Admin');
        console.log('res?.data?.notifications',res?.data?.notifications);
        console.log('filteredNotifications',filteredNotifications);
        setRecommendationsList(filteredNotifications);
      })
      .catch(err => console.log(err))
    }


    const delete_notification = (notificationId, questId) =>{
      q.deleteNotification(notificationId, questId)
      .then(res => { 
        get_notifications();
        console.log('resAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAa',res);

      })
      .catch(err => console.log(err))
    }

  
  useEffect(() =>{
    get_user_quests()
  },[])

  useEffect(() => {
    // Set initial values when selectedQuest changes
    if (selectedQuest) {
        setIds({
            pixelId: selectedQuest.Meta?.pixelId || '',
            catalogId: selectedQuest.Meta?.catalogId || ''
        });
    }
}, [selectedQuest]);


  useEffect(()=>{
    get_notifications();
  },[selectedQuest?._id])


  console.log('recommentdatopsm', recommendationsList);
  console.log('selectedQuest', selectedQuest)
  return (
    <PageFull>
        {selectedQuest ? 
            <Slider_
                title={t("Edit quest")}
                text = {t("Done")}
                onClose = {() => setSelectedQuest(false)}
                onClick = {() => {
                  setSelectedQuest(false)
                  //admin_edit_quest(ids?.pixelId, ids?.catalogId)
                  get_user_quests()
                }}
            >
{/*             
            <Builder_ title='Pixel Id'>
                <Input_ defautValue={selectedQuest.Meta?.pixelId} value={ids?.pixelId} onChange ={(e)=> setIds({...ids, pixelId: e.target.value})} />
            </Builder_>
            <Builder_ title='Catalog Id'>
                <Input_ defautValue={selectedQuest.Meta?.catalogId} value={ids?.catalogId} onChange ={(e)=> setIds({...ids, catalogId: e.target.value})} />
            </Builder_> */}

            <Builder_ title={t('Quest recommendations')}>
              <Textarea_ value={recommendation} onChange={(e)=>setRecommendation(e.target.value)} rows={3} />
              <RecommendationsBtn> 
                <Main_ text='Add' iconLeft='Add' size='small' 
                onClick={()=> {
                  createNotification(recommendation)
                  // setRecommendationsList((prevRecommendations) => [...prevRecommendations, recommendation])
                  setRecommendation('') 
                 } }/>
                </RecommendationsBtn>
    
            </Builder_>
            <Builder_>
                <RecommendationsList>
                  {recommendationsList.map((recommendation,i) => (
                    <li>
                      <RecommendationsBtn> 
                        <Main_Plain iconLeft='Xicon' size='small' noPadding onClick={()=>delete_notification(recommendation?._id,recommendation?.questId)}/>
                      </RecommendationsBtn>
                      <h5>{recommendation?.details?.action}</h5>

                      <p>{new Date(recommendation?.updatedAt).toLocaleDateString()}</p>
                    </li>
                  ))}
                </RecommendationsList>
            </Builder_>
          </Slider_> 
        : 
          null
        }
          <PageTitle > 
            <Grey_Link iconLeft='Back' noPadding onClick={()=> navigate(`/${lang}/akihiko/dailyprofit/users`)}/>
                <h1>{username}</h1>
            </PageTitle>
            <TableDesktop>
              <Table>
                <TRH>
                  <TH></TH>
                  <TH>{t("Quest status")}</TH>
                  <TH>{t("Facebook page")}</TH>
                  <TH>{t("Adspend")}</TH>
                  <TH>{t("Quest type")}</TH>
                  <TH>{t("Quest field")}</TH>
                </TRH>
                {quests?.map((quest,i) =>
                  <TR>
                    <TD>
                      <Main_Plain noPadding iconLeft='Edit' onClick={()=> setSelectedQuest(quest)} />
                    </TD>
                    <TD>
                      {quest?.active === true ? 
                        <UserQuestsStatus status='active'></UserQuestsStatus>
                      :
                        <UserQuestsStatus status='innactive'></UserQuestsStatus>
                      }
                    </TD>
                    <TD>{quest?.Meta?.facebookPageName}</TD>
                    <TD>€ {quest?.questionnaires[5]?.answer}</TD>
                    <TD>{quest?.questionnaires[0]?.answer}</TD>
                    <TD>{quest?.questionnaires[1]?.answer}</TD>
                  </TR>
                )}
              </Table>
             </TableDesktop>
    </PageFull>
  )
}
