import styled from "styled-components";

export const QuestionnaireSubtitle = styled.div`
    display: flex;
    align-items: center;
    gap: .5rem;

    img{
        width: 3rem;
        border-radius: .5rem;
    }
`