import { AlertContext, Alert_, Builder_, Dropdown_, Steps_Actions } from 'monica-alexandria'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components'
import { StepContext } from '../../../context/StepContext';
import { push_new_answer } from '../utils';
import serviceJobs from '../../../data/serviceJobs.json';
import i18n from '../../../localization/i18next'
import store from '../../../redux/store';
import { Elastic_Search } from '../../../components/Elastic_Search';


const lang = i18n.language ? i18n.language : 'el';

const BusinessField = styled.div``
export default function Business_Field() {

    const user = store.getState()?.user?.user;
    const isKnight = user?.roles?.includes('Knight');

    const { step, setStep } = useContext(StepContext);
    const { t } = useTranslation();
    const { alerts, setAlerts } = useContext(AlertContext);
    const navigate = useNavigate();

    const [stepInfo, setStepInfo] = useState(step?.answers[step?.step] ? step?.answers[step?.step] : {
        question: (t("What's your industry field?")),
        type: 'select',
        answer: ''
      })

      const alertMessage = (message, status) => {
        setAlerts([
            ...alerts,
            {
                message: {
                    text: message,
                    status: status,
                },
            },
        ]);
    };
    
    const submit_data_and_exit_questionnaire = () => {

        if (!stepInfo.answer)  alertMessage(t('Please select your industry field.'), 'Error')
        else  {
            setStep({step: step?.step + 1, answers: push_new_answer(step, stepInfo)});

            // saving answers to the cache
            store.dispatch({type:'SAVE_QUESTIONNAIRE_A', payload: step?.answers?.length ? push_new_answer(step, stepInfo) : false});
    
            navigate(`/${lang}/facebook-login?field=${step?.answers[0]?.answer?.toLowerCase()}`);
        }
    }
    
     //for fb review 
      const defaultSelected = {category: ''}
  return (
    <BusinessField>
        <h3>{t("What's your field of work?")}</h3>

        <Builder_ title={t("Fill in your field")}>
            <Elastic_Search
            //  debounce={500}
              defaultSelected={stepInfo.answer == '' ? defaultSelected : ''} //for fb review
              displayProperty="category"
              file={serviceJobs}
              maximumSuggestions={7}
              placeholder={t("Field")}
              results={(data)=> setStepInfo({ ...stepInfo, type: 'select', answer: data?.category })}
              returnType="object"
             // sanitize="greeklish"
              searchKeys={[
                'category'
              ]}
              selectionType="one"
              blockDuplicateProperty="category"
              freeInput
            />

          </Builder_>
        <Builder_>
            <Steps_Actions
                icon_Grey="Back"
                icon_Main="Next"
                onClick_Grey={() => setStep({...step, step: step?.step - 1})}
                onClick_Main={()=>submit_data_and_exit_questionnaire()}
                text_Grey={t("Back")}
                text_Main={t("Next")}
            />
        </Builder_>
    </BusinessField>
  )
}
