import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import Fuse from "fuse.js";

// import { FormGroup, FormList } from "../../styles/Form";
// import { IconSvg } from "../../svg/IconSvg";
// import { Input_ } from "./Input_";
// import { Red_ } from "../button";
import { FormGroup, FormList, IconSvg, Input_, Red_ } from "monica-alexandria";
var greekUtils = require("greek-utils");

const ElasticSearch = styled(FormGroup)`

.ElasticSuggestions{
  display: block !important;
}
`;

const ElasticSuggestions = styled.ul`
  margin-top: 1rem;
  background: ${(props) => props.theme.background};
  box-shadow: ${(props) => props.theme.out};
  border-radius: var(--smallRadius);
  overflow: hidden;
  transition: all 0.5s ease;

  li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    cursor: pointer;
    padding: var(--normalPads);
    transition: background 0.5s;

    &:hover {
      background: ${(props) => props.theme.low};
    }
  }

  .ElasticSelected {
    background: ${(props) => props.theme.low};
  }
`;

const ElasticList = styled(FormList)`
  h5 {
    padding: var(--smallPads);
  }
`;

// Used to wait before execution
function debounce(func, wait) {
  let timeout;
  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      func(...args);
    }, wait);
  };
}

function fuzzySearch(query, collection, fuseOptions) {
  try {
    const fuse = new Fuse(collection, fuseOptions);

    const results = fuse.search(query);

    results.sort((a, b) => b.score + a.score);

    const formattedResults = results.map((item) => {
      return item.item;
    });

    return formattedResults;
  } catch (error) {
    return [];
  }
}

function handleSanitizeProp(sanitize, value) {
  let newFuseSearchQueryInput = "";
  switch (sanitize) {
    case "greek": {
      newFuseSearchQueryInput = greekUtils.toGreek(value);
      break;
    }
    case "greeklish": {
      newFuseSearchQueryInput = greekUtils.toGreeklish(value);
      break;
    }
    default:
      newFuseSearchQueryInput = value;
  }
  return newFuseSearchQueryInput;
}

function validateProps(props) {
  /* searchKeys */
  if (!props.searchKeys) {
    throw new Error(
      '[Elastic_Search] Error: Missing required prop "searchKeys". Please provide an array of strings.'
    );
  }

  if (!Array.isArray(props.searchKeys)) {
    throw new Error(
      '[Elastic_Search] Error: The "searchKeys" prop must be an array of strings.'
    );
  }

  props.searchKeys?.map((key) => {
    if (typeof key !== "string") {
      throw new Error(
        '[Elastic_Search] Error: All items in the "searchKeys" prop must be of type string.'
      );
    }
  });

  /* debounce (optional) */
  if (props.debounce !== undefined && props.debounce !== false) {
    if (
      typeof props.debounce !== "number" ||
      !Number.isInteger(props.debounce)
    ) {
      throw new Error(
        '[Elastic_Search] Error: If provided, "debounce" must be an integer.'
      );
    }
  }

  /* maximumSuggestions (optional) */
  if (
    props.maximumSuggestions !== undefined &&
    props.maximumSuggestions !== false
  ) {
    if (
      typeof props.maximumSuggestions !== "number" ||
      !Number.isInteger(props.maximumSuggestions)
    ) {
      throw new Error(
        '[Elastic_Search] Error: If provided, "maximumSuggestions" must be an integer.'
      );
    }
  }

  /* sanitize (optional) */
  if (props.sanitize !== undefined && props.sanitize !== false) {
    if (
      typeof props.sanitize !== "string" ||
      (props.sanitize !== "greek" && props.sanitize !== "greeklish")
    ) {
      throw new Error(
        '[Elastic_Search] Error: If provided, "sanitize" must be a string with a value of "greek" or "greeklish".'
      );
    }
  }

  /* placeholder (optional) */
  if (props.placeholder !== undefined && props.placeholder !== false) {
    if (typeof props.placeholder !== "string") {
      throw new Error(
        '[Elastic_Search] Error: If provided, "placeholder" must be a string.'
      );
    }
  }

  /* file */
  if (!props.file) {
    throw new Error(
      '[Elastic_Search] Error: Missing required prop "file". Please provide an array of objects.'
    );
  }

  if (!Array.isArray(props.file)) {
    throw new Error(
      '[Elastic_Search] Error: The "file" prop must be an array.'
    );
  }

  props.file.map((item) => {
    if (typeof item !== "object") {
      throw new Error(
        '[Elastic_Search] Error: All items in the "file" prop must be objects.'
      );
    }
  });

  /* returnType */
  if (!props.returnType) {
    throw new Error(
      '[Elastic_Search] Error: Missing required prop "returnType". Please provide a string with a value of "string" or "object".'
    );
  }

  if (
    typeof props.returnType !== "string" ||
    (props.returnType !== "string" && props.returnType !== "object")
  ) {
    throw new Error(
      '[Elastic_Search] Error: "returnType" must be a string with a value of "string" or "object".'
    );
  }

  /* returnProperty (required if returnType === "string") */
  if (props.returnType === "string") {
    if (!props.returnProperty) {
      throw new Error(
        '[Elastic_Search] Error: When "returnType" is "string," "returnProperty" is required. Please provide a string.'
      );
    }

    if (typeof props.returnProperty !== "string") {
      throw new Error(
        '[Elastic_Search] Error: "returnProperty" must be a string when "returnType" is "string".'
      );
    }
  }

  /* selectionType */
  if (!props.selectionType) {
    throw new Error(
      '[Elastic_Search] Error: Missing required prop "selectionType". Please provide a string with a value of "many" or "one".'
    );
  }

  if (
    typeof props.selectionType !== "string" ||
    (props.selectionType !== "many" && props.selectionType !== "one")
  ) {
    throw new Error(
      '[Elastic_Search] Error: "selectionType" must be a string with a value of "many" or "one".'
    );
  }

  /* displayProperty */
  if (!props.displayProperty) {
    throw new Error(
      '[Elastic_Search] Error: Missing required prop "displayProperty". Please provide a string.'
    );
  }

  if (typeof props.displayProperty !== "string") {
    throw new Error(
      '[Elastic_Search] Error: "displayProperty" must be a string.'
    );
  }

  /* defaultSelected */
  if (props.defaultSelected) {
    if (props.selectionType === "one") {
      if (
        typeof props.defaultSelected !== "object" ||
        Array.isArray(props.defaultSelected)
      ) {
        throw new Error(
          '[Elastic_Search] Error: If "selectionType" is "one", "defaultSelected" must be an object.'
        );
      }
    } else if (props.selectionType === "many") {
      if (
        !Array.isArray(props.defaultSelected) ||
        !props.defaultSelected.every(
          (item) => typeof item === "object" && !Array.isArray(item)
        )
      ) {
        throw new Error(
          '[Elastic_Search] Error: If "selectionType" is "many", "defaultSelected" must be an array of objects.'
        );
      }
    }
  }

  /* selectionSectionTitle */
  if (
    props.selectionSectionTitle !== undefined &&
    props.selectionSectionTitle !== false &&
    typeof props.selectionSectionTitle !== "string"
  ) {
    throw new Error(
      '[Elastic_Search] Error: "selectionSectionTitle" must be a string.'
    );
  }

  /* selectionItemIcon */
  if (
    props.selectionItemIcon !== undefined &&
    props.selectionItemIcon !== false &&
    typeof props.selectionItemIcon !== "string"
  ) {
    throw new Error(
      '[Elastic_Search] Error: "selectionItemIcon" must be a string.'
    );
  }

  /* results */
  if (
    props.results !== undefined &&
    props.results !== false &&
    typeof props.results !== "function"
  ) {
    throw new Error(
      '[Elastic_Search] Error: "results" must be a callback function.'
    );
  }

  /* addSelectionFirst */
  if (props.addSelectionFirst !== undefined) {
    if (typeof props.addSelectionFirst !== "boolean") {
      throw new Error(
        '[Elastic_Search] Error: "addSelectionFirst" must be a boolean if provided.'
      );
    }
  }

  /* blockDuplicateProperty */
  if (
    props.blockDuplicateProperty !== undefined &&
    props.blockDuplicateProperty !== false &&
    typeof props.blockDuplicateProperty !== "string"
  ) {
    throw new Error(
      '[Elastic_Search] Error: "blockDuplicateProperty" must be a string.'
    );
  }
}

function throwWarnings(props) {
  /* List of valid props */
  const validProps = [
    "searchKeys", // The keys that the fuse search will run on the collection
    "debounce", // Use debounce on search
    "sanitize", // Convert the ElasticInput to either greeklish or greek
    "placeholder", // Input_ placeholder
    "maximumSuggestions", // Maximum number of the displayed suggestions
    "file", // The collection that fuse search runs on
    "selectionType", // Select many or select one
    "returnType", // Return array of strings or array of objects
    "returnProperty", // If returnType === 'string', select the property of the file objects to return as string
    "displayProperty", // The property of the suggestion that will be displayed to the user
    "defaultSelected", // Item(s) to be displayed as pre-selected. UI depends on selectionType
    "selectionSectionTitle", // The title above the list of selected items
    "selectionItemIcon", // The icon of each selected item
    "results", // The selected data that are returned to the parent via a callback function
    "addSelectionFirst", // Boolean, if true the selected item is going to be first on the list
    "blockDuplicateProperty", // String to block duplicated based on a property to be added to the selected list
  ];

  /* Check if there are any extra props */
  const extraProps = Object.keys(props).filter(
    (prop) => !validProps.includes(prop)
  );

  if (extraProps.length > 0) {
    console.warn(
      `[Elastic_Search] Warning: The following extra props were provided and will be ignored: ${extraProps.join(
        ", "
      )}`
    );
  }

  /* returnProperty */
  if (props.returnType !== "string" && props.returnProperty) {
    console.warn(
      `[Elastic_Search] Warning: "returnProperty" will be ignored because "returnType" is not "string".`
    );
  }

  /* addSelectionFirst */
  if (props.selectionType === "one" && props.addSelectionFirst) {
    console.warn(
      `[Elastic_Search] Warning: "addSelectionFirst" will be ignored because "selectionType" is set to "one".`
    );
  }

  /* blockDuplicateProperty */
  if (props.selectionType === "one" && props.blockDuplicateProperty) {
    console.warn(
      `[Elastic_Search] Warning: "blockDuplicateProperty" will be ignored because "selectionType" is set to "one".`
    );
  }

  /* selectionSectionTitle */
  if (props.selectionType === "one" && props.selectionSectionTitle) {
    console.warn(
      `[Elastic_Search] Warning: "selectionSectionTitle" will be ignored because "selectionType" is set to "one".`
    );
  }

  /* selectionItemIcon */
  if (props.selectionType === "one" && props.selectionItemIcon) {
    console.warn(
      `[Elastic_Search] Warning: "selectionItemIcon" will be ignored because "selectionType" is set to "one".`
    );
  }
}

export const Elastic_Search = (props) => {
  /* Required props validation. These warnings are ment to help devs */
  validateProps(props); // Validate that the given props are correct and the required are not missing
  throwWarnings(props); // Used to print a warning when a prop that is nowhere to be used i given from the parent

  const [showResults, toggleShowResults] = useState(false);
  const [displayedResults, setDisplayedResults] = useState([]);

  /* Local data */
  const [data, setData] = useState({
    displayedValue:
      props.selectionType === "one" &&
      props?.defaultSelected &&
      props.defaultSelected[props.displayProperty]
        ? props.defaultSelected[props.displayProperty]
        : "", // Whats is going to be displayed to the user. Takes the value of props.displayedProperty property from file object(s)
    fuseQueryValue: "", // sanitized displayValue. Used for fuse
    searchResults: [], // fuse search results
    defaultMaximumSuggestions: 6, // maximum displayed number of results
    selected: props?.defaultSelected
      ? props?.defaultSelected
      : props.selectionType === "one"
      ? {}
      : [], // The selected obj of the selected array of objects
    skipDebounce: false, // Used to skip debounce when needed. This is always going to be set false when we setData on applyFuzzySearch
  });

  /* Use to highlight suggestion */
  const [highlighted, highlight] = useState(0);

  let fuseOptions = {
    isCaseSensitive: false,
    includeScore: false,
    shouldSort: true,
    threshold: 0.5,
    findAllMatches: true,
    address: 0,
    minMatchCharLength: 1,
    ignoreLocation: false,
    keys: props.searchKeys,
  };

  /* Apply search | Set the results at data.searchResults and re-disables skipDebounce */
  const applyFuzzySearch = () => {
    let results = fuzzySearch(data.fuseQueryValue, props.file, fuseOptions);

    /* blockDuplicate logic. In the selectionType === "many" mode we run a check to hide duplicates if the prop blockDuplicateProperty is given */
    if (props.blockDuplicateProperty && props.selectionType === "many") {
      results = results.filter(
        (obj) =>
          !data.selected.some(
            (o) =>
              o[props.blockDuplicateProperty] ===
              obj[props.blockDuplicateProperty]
          )
      );
    }

    results = results.slice(
      0,
      props.maximumSuggestions
        ? props.maximumSuggestions
        : data.defaultMaximumSuggestions
    );

    setData((prevState) => ({
      ...prevState,
      searchResults: results,
      skipDebounce: false,
    }));
  };

  /* When called, applyFuzzySearch will run with debounce */
  const triggerFuzzySearch = debounce(applyFuzzySearch, props.debounce);

  /* Called when the user is typing or deleting from the input */
  const onChange = (e) => {
    const newDisplayedValue = e.target.value;
    let newFuseQueryValue = handleSanitizeProp(props.sanitize, e.target.value);
    console.log('VALUEEE', newDisplayedValue);
    /* for the selectionType === "one" mode we need to run additional logic 
        We clear the selection if the user tries to type custom inputs */
    if (
     !props.freeInput &&
      props.selectionType === "one" &&
      data.selected[props.displayProperty] &&
      !props.file.some(
        (obj) => obj[props.displayProperty] === newDisplayedValue
      )
    ) {
  
      const keepQuery =
        newDisplayedValue.length -
          data.selected[props.displayProperty].length ===
        1
          ? true
          : false;
      const newQuery = keepQuery
        ? newDisplayedValue[newDisplayedValue.length - 1]
        : "";
      const newSanitizedQuery = keepQuery
        ? handleSanitizeProp(props.sanitize, newQuery)
        : "";
      setData((prevState) => ({
        ...prevState,
        displayedValue: newQuery,
        fuseQueryValue: newSanitizedQuery,
        selected: {[props.displayProperty]: newDisplayedValue},
      }));
      return;
    } else if (props.freeInput &&  props.selectionType === "one"  ) {
      setData((prevState) => ({
        ...prevState,
        displayedValue: newDisplayedValue,
        fuseQueryValue: newFuseQueryValue,
        selected: {[props.displayProperty]: newDisplayedValue},
      }));
    }  else {

      setData((prevState) => ({
        ...prevState,
        displayedValue: newDisplayedValue,
        fuseQueryValue: newFuseQueryValue,
      }));
    }


  };

  /* Called when we click a suggestion, replace item if selectType === "one" or add item to the array */
  const handleSelection = (suggestion) => {

    console.log('suggestion ON CLICK', suggestion);
    toggleShowResults(false)
    /* In selectionType === "many" mode, items are added last on the list unless the addSelectionFirst property is given */
    const newSelected =
      props.selectionType === "one"
        ? suggestion
        : props.addSelectionFirst
        ? [suggestion, ...data.selected]
        : [...data.selected, suggestion];

    console.log('newSelected ON CLICK', newSelected);

    setData((prevState) => ({
      ...prevState,
      selected: newSelected,
      displayedValue:
        props.selectionType === "many"
          ? ""
          : newSelected[props.displayProperty],
      fuseQueryValue: "",
      skipDebounce: true, // skips debounce to hide suggestions immediately
    }));
    console.log('DATAA ON CLICK', data);
    setDisplayedResults([])

  //  highlight(0);
  };

  /* Called when we click delete on an item */
  const handleDeletion = (i) => {
    if (props.selectionType === "many") {
      const newSelected = [];
      for (const [index, item] of data.selected.entries()) {
        if (index !== i) newSelected.push(item);
      }
      setData((prevState) => ({
        ...prevState,
        selected: newSelected,
      }));
    } else {
      setData((prevState) => ({
        ...prevState,
        selected: {},
      }));
    }
  };

  /* helper to allow user navigate the searchResult suggestions with arrows and enter */
  const detectEnter = (e) => {
    if (e.key === "ArrowUp") {
      e.preventDefault();
      if (highlighted === 0) highlight(displayedResults.length - 1);
      else highlight(highlighted - 1);
    }
    if (e.key === "ArrowDown") {
      e.preventDefault();
      if (highlighted === displayedResults.length - 1) highlight(0);
      else highlight(highlighted + 1);
    }
    if (e.key === "Enter") {
      const highlightedSuggestion = displayedResults[highlighted];
      handleSelection(highlightedSuggestion);
    }
  };
  
  
  /* Get triggered after the onChange | calls the applyFuzzySearch either directly or with debounce
    Warning: Note that any changes to data.fuseQueryValue will trigger the following code */
  useEffect(() => {
    if (props.debounce && !data.skipDebounce) {
      triggerFuzzySearch();
    } else {
      applyFuzzySearch();
    }
  }, [data.fuseQueryValue]);

  /* return results to the parent every time the selected item(s) changes */
  useEffect(() => {
    props.results(
      props.returnType === "object"
        ? data.selected
        : props.selectionType === "one"
        ? data.selected[props.returnProperty]
          ? data.selected[props.returnProperty]
          : ""
        : data.selected?.map((s) => {
            return s[props.returnProperty];
          })
    );
  }, [data.selected]);


  const dropdownPopupRef = useRef(null);

  // Function to close the OptionsPopup when clicking outside
  const closeOptionsPopup = (event) => {
    if (dropdownPopupRef.current && !dropdownPopupRef.current.contains(event.target)) {
      toggleShowResults(false);
    }
  };

  useEffect(() => {
    // Add event listener when component mounts
    document.addEventListener('mousedown', closeOptionsPopup);
    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener('mousedown', closeOptionsPopup);
    };
  }, []);

  useEffect(()=>{
    if (data.searchResults.length) toggleShowResults(true);
  },[data.searchResults])

  /* Component Dev Logs. Should be commented for any production usage */
  // /* Component Dev Logs. Should be commented for any production usage */
  // console.group("ElasticSearch")
  //     console.log('data:',data);
  //     console.log('props:',props);
  //     console.log('displayedValue:',data.displayedValue);
  //     console.log('fuseQueryValue:',data.fuseQueryValue);
  //     console.log('searchResults:',data.searchResults);
  // console.groupEnd()


//  if data.displayedValue && !selected set selected(data displayed value)
  useEffect(() => {
 
    if (!data.selected[props.displayProperty] && props.selectionType === "one" &&  data?.displayedValue?.length ) {
      setData((prevState) => ({
        ...prevState,
        selected: {[props.displayProperty]: data.displayedValue}
      }))
    }

  },[])

  useEffect(()=>{
    if (props.freeInput && data?.displayedValue?.length) {
      const freeInput = {[props.displayProperty]: data.displayedValue};

      // Update the search results array with the created object
      const updatedSearchResults = [freeInput, ...data.searchResults];
      setDisplayedResults(updatedSearchResults)
    } else {
      setDisplayedResults(data?.searchResults)
    }
  },[data?.searchResults])

  console.log('displayed Results' , displayedResults);
  console.log('σshow Results' , showResults);
console.log('data.displayedValue00000000', data.displayedValue);

  return (
    <ElasticSearch className="ElasticSearch" ref={dropdownPopupRef}>
      <Input_
        id="elasticSearch"
        placeholder={props.placeholder}
        autoComplete="off"
        type="text"
        value={data.displayedValue}
        onChange={onChange}
        onKeyDown={(e) => detectEnter(e)}
        freeInput={props.freeInput}
      />
      {showResults &&
        <ElasticSuggestions className="ElasticSuggestions">
          {displayedResults?.map((suggestion, i) => (
              <li
                  className={
                    i === (highlighted % displayedResults?.length) 
                      ? "ElasticSelected"
                      : ""
                  }
                  key={i}
                  onClick={() => handleSelection(suggestion)}
                >
              <p>{suggestion[props.displayProperty]}</p>
            </li>
          ))}
        </ElasticSuggestions>
      }
      {props.selectionType === "many" && (
        <ElasticList>
          <h5>{props.selectionSectionTitle}</h5>
          {data.selected?.map((item, i) => (
            <li key={i}>
              {props.selectionItemIcon && (
                <IconSvg Icon={props.selectionItemIcon} />
              )}
              <h6>{item[props.displayProperty]}</h6>
              <Red_
                size="small"
                iconLeft="Delete"
                onClick={() => handleDeletion(i)}
              />
            </li>
          ))}
        </ElasticList>
      )}
    </ElasticSearch>
  );
};
