import axios from 'axios';
import store from '../../redux/store';

const protocol = process.env.REACT_APP_DAILYPROFIT_BACKEND_PROTOCOL;
const url = process.env.REACT_APP_DAILYPROFIT_BACKEND_HOST;
const port = process.env.REACT_APP_DAILYPROFIT_BACKEND_PORT;


const checkForAd = () => {

    return new Promise((resolve, reject) => {

        axios.get(`${protocol}${url}${port}/ads/checkForAd`)
        .then(res => {
            resolve(res);
        })
        .catch(() => {
            console.error('CHECK FOR Ad')
            resolve(false);
        })
    })
}

const editAd = (dataa, file, isDraft, mode) => {

    let form = new window.FormData();

    let data = dataa;

    if (file) form.append('file', file);
    if (mode) form.append('mode', mode);
    
    form.append('data', JSON.stringify(data));

    return new Promise((resolve, reject) => {

        axios.post(`${protocol}${url}${port}/ads/editAd`, form, {})
        .then(res => {
            resolve(res);
        })
        .catch(() => {
            console.error('EDIT AD')
            resolve(false);
        })
    })
}

const getMyAd = (questId) => {

    return new Promise((resolve, reject) => {

        axios.get(`${protocol}${url}${port}/ads/getMyAd?questId=${questId}`)
        .then(res => {
            console.log('MYA ADDDDDDD', res)
            resolve(res);
        })
        .catch(() => {
            console.error('GET MY AD')
            resolve(false);
        })
    })
}

const getMyAds = (flow,mode) => {

    return new Promise((resolve, reject) => {

        axios.get(`${protocol}${url}${port}/ads/getMyAds?flow=${flow}&mode=${mode}`)
        .then(res => {
            resolve(res);
        })
        .catch(() => {
            console.error('GET MY ADS')
            resolve(false);
        })
    })
}

const getAdById = (id) => {

    return new Promise((resolve, reject) => {

        axios.get(`${protocol}${url}${port}/ads/getAdById?AdId=${id}`)
        .then(res => {
            resolve(res);
        })
        .catch(() => {
            console.error('GET AD')
            resolve(false);
        })
    })
}

const cloneAd = (id, facebookPageId) => {

    return new Promise((resolve, reject) => {

        axios.post(`${protocol}${url}${port}/ads/cloneAd?adId=${id}&facebookPageId=${facebookPageId}`, {}, {})
        .then(res => {
            resolve(res);
        })
        .catch(() => {
            console.error('CLONE AD')
            resolve(false);
        })
    })
}

const saveFacebookPage = (facebookData, questId) => {

    let userFBToken = store.getState()?.metaSource?.facebook_user?.accessToken || false;
    facebookData.userFBToken = userFBToken;

    return new Promise((resolve, reject) => {

        axios.post(`${protocol}${url}${port}/ads/saveFacebookPage?questId=${questId}`, facebookData, {})
        .then(res => {
            resolve(res);
        })
        .catch(() => {
            console.error('SAVE FACEBOOK PAGE')
            resolve(false);
        })
    })
}


const saveProductsExperimental = (files, products) => {

    let form = new window.FormData();

    console.log(files[0])

    files.forEach((file, i) => {
        form.append(`file-${i}`, file);
    });
    
    form.append('data', JSON.stringify(products))


    return new Promise((resolve, reject) => {

        axios.post(`${protocol}${url}${port}/ads/saveProductsExperimental`, form, { })
        .then(res => {
            resolve(res);
        })
        .catch(() => {
            console.error('UPLOAD FILE')
            resolve(false);
        })
    })
}

const AdEndpoints = {
    checkForAd,
    editAd,
    getMyAd,
    getMyAds,
    getAdById,
    cloneAd,
    saveFacebookPage,
    saveProductsExperimental
}



export default AdEndpoints;