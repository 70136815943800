import { AlertContext, IconSvg, device } from 'monica-alexandria'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import store from '../../../redux/store'
import ReactPlayer from 'react-player'
import { DailyprofitSvg } from '../../../svg/DailyprofitSvg'
import { findLabelByValue } from '../../../utils/findCtaLabel'
import NoImg from '../../../assets/ad/NoImgAd.jpg'
import { alertMessage } from '../../../utils/messagesAlerts'
import q from '../../../services/api/Quests';


const AdMockupActions = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;
    padding-bottom: .5rem;

    svg{
        stroke: var(--white);
        stroke-width: 1;
        fill: none;
        /* width: 2.6rem;
        height: 2.6rem; */
    }

    .fbLike{
        width: 2.2rem;
        fill: #fff;
        padding-bottom: 1rem;
        
    }

    .fbComment {
        width: 2.2rem;
        stroke: #fff;
        stroke-width: 1.2;
        fill: none;
    }   

    .fbShare{
        width: 3rem;
        stroke: #fff;
        stroke-width: 1.2;
        fill: none;
    }

    svg:last-of-type{
        width: 1.5rem;

        fill: var(--white);
        stroke: none;
    }
`


const FacebookMockupWrapper = styled.div`
    /* aspect-ratio: 9/16; */
    max-width: 30rem; //normal
    width: 100vw; //temp
      height: 56.7rem;
   //height: 100vh;
    box-shadow: ${p => p.theme.out};
    border-radius: var(--smallRadius);
    position: relative;
    overflow: hidden;
    background: var(--vanta);


`

const AdMockupCtaTitle = styled.div`
    background: var(--white); 
    border-radius: .5rem;
    padding: 0.7rem 0rem;
    text-align: center;

    h6{
        color: var(--black);
    }
  
`

const AdMockupAccountInfo = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;

    h6{
        color: var(--white);
        font-size: 1.4rem;
        font-family: serif;
    }
    img{
        border: 1px solid var(--grey);
        border-radius: 50%;
        width: 3rem;
        height: 3rem;
    }
`

const AdMockupImage = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    
    img{
        height : 100% ;
        width: 100%;
        object-fit: cover;
    }
`

const AdMockupLabel = styled.div`
    display: flex;
    align-items: center;
    margin: 1rem 0 .5rem 0;
    gap: 0.5rem;
    background-color: #0E0F10;
    padding: 0.5rem 1rem;
    border-radius: 2rem;
    width: fit-content;


    h6{
        color: var(--white);
        font-size: 1.2rem;
    }
   
`

const AdMockupBottom = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    align-content: center;
    justify-content: space-between;
    padding: 5rem 1.5rem 0.5rem 1.5rem;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    gap: 3rem;
`

const AdMockupBottomLeft = styled.div`
    width: 100%;
`

const AdMockupCaption = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 0;

    p{
        font-family: serif;
        color: #fff;
        font-size: 1.4rem;
    }
`


const AdMockupproductImgs = styled.div`
    display: flex;
    align-items: center;
    overflow-x: scroll ;
    gap: 1rem;
    padding-top: 1rem;

    img{
        width: 8rem;
        border-radius: var(--smallRadius);
    }

`

const AdMockupCarousel = styled.div`
    background: var(--vanta);

    img{
        position: absolute;
        width: 100%;
        height: auto;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -75%);
        opacity: 0;
    }

    @keyframes fadeInUp {
        0%{
            opacity: 0;
        }

        50%{
            opacity: 1;
        }

        100%{
            opacity: 1;
        }
    }

    .fade-in-up {
        animation: fadeInUp 3s linear;
    }

`

const minioUrl = process.env.REACT_APP_MINIO_PROTOCOL +
                process.env.REACT_APP_MINIO_HOST + 
                process.env.REACT_APP_MINIO_PORT+ '/' +
                process.env.REACT_APP_MINIO_BUCKET + '/';

export const Facebook_Product_Reels = (props) => {

    const {t} = useTranslation();
    const facebook_page = store.getState().metaSource?.page_selection_info;
    const userId = store.getState()?.user?.user?.sub;

    const {alerts, setAlerts} = useContext(AlertContext);

    const [products, setProducts] = useState([])


    const [currentIndex, setCurrentIndex] = useState(0);
    const [fade, setFade] = useState(false);




    // const get_products_from_product_set = () => {
    //     // TODO: check for user mode
    //     if (props?.adInfo?.questId && props?.adInfo?.mode == 'productCatalog') {
    //       console.log('this is the adinfo', props.adInfo)
    //       q.getProductsFromProductSet(props?.adInfo?.questId, props?.adInfo?.productSetId)
    //         .then(res => {

    //             console.log('res?.data?.products', res?.data?.products);
    //           if (!["Q2010", "Q2011"]?.includes(res?.data?.code)) {
    //             alertMessage(alerts, setAlerts, res?.data?.msg, 'Error')
    //           }
    //           else {
    //             setProducts(res?.data?.products);
    //           }
    //         })
    //       }
    //   }
  
    //   useEffect(() => {
    //     get_products_from_product_set();
    //   }, [props?.adInfo?.questId, props?.adInfo?.productSetId])



      useEffect(() => {
        const interval = setInterval(() => {
            setFade(false); // Reset fade effect
            setCurrentIndex(prevIndex => (prevIndex + 1) % props.products.length);
            setTimeout(() => setFade(true), 20); // Reapply fade effect
        }, 3000);

        // Cleanup the interval on component unmount
        return () => clearInterval(interval);
    }, [props.products.length]);

    const getFirstImageUrl = (imageUrl) => {
        return imageUrl?.split(',')[0].trim();
    };


  return (
    <FacebookMockupWrapper>

        <AdMockupCarousel>
        {props.products.length > 0 && (
            <img 
                src={getFirstImageUrl(props.products[currentIndex].image_url)}
                alt="Product"
                className={fade ? 'fade-in-up' : ''}
            />
        )}
        </AdMockupCarousel>
        <AdMockupBottom>
            <AdMockupBottomLeft>
                <AdMockupAccountInfo>
                    <img src={props.pageLogo} />
                    <div>
                        <h6>{facebook_page?.name}</h6>
                    </div>
                    </AdMockupAccountInfo>
                    {/* <AdMockupCaption>
                        
                        <p>{props.adInfo?.caption}</p>
                        <p>...more</p>
                    </AdMockupCaption> */}
                    <AdMockupCaption>
                        <p>{props.adInfo?.caption && props.adInfo?.caption?.length > 15 ? props.adInfo?.caption?.substring(0, 15) + '...' : props.adInfo?.caption}</p>
                        <p>{props.adInfo?.caption && props.adInfo?.caption?.length > 15 ? '...more' : null}</p>
                    </AdMockupCaption>
                     <AdMockupproductImgs>
                        {props.products?.length > 0 && (
                            props.products?.map((product, i) =>
                            <img 
                                src={getFirstImageUrl(product?.image_url)}
                                alt="Product"
                            />

                            )
                        )}
                      
                    </AdMockupproductImgs>

                    <AdMockupLabel>
                   
                        <h6>Sponsored</h6>
                    </AdMockupLabel>
            </AdMockupBottomLeft>
            <AdMockupActions>
                <DailyprofitSvg Icon='FbLike' class_Icon='fbLike' />
                <DailyprofitSvg Icon='FbComment' class_Icon='fbComment' />
                <DailyprofitSvg Icon='FbShare' class_Icon='fbShare' />
                <DailyprofitSvg Icon='InstaOptions' class_Icon='instaiconfill' />
            </AdMockupActions>
        </AdMockupBottom>
    </FacebookMockupWrapper>
  )
}
