import { Card, IconSvg, LimitCharacters, Partial_Loader } from "monica-alexandria";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { prettifyDates } from "../../../../helpers/prettifiers";

const DashboardNotifications = styled.div``;

const DashboardNotificationsTitle = styled.h5``;

const DashboardNotificationsBox = styled(Card)`
  background: ${(p) => p.theme.low};
  padding: 1rem;
  margin-top: 1rem;
  cursor: pointer;
  border: 1px solid ${p => 
    p.source === 'Admin' ? 'var(--focus)'
    : 'transparent'
  };

  h5 {
    font-size: 1.4rem;
    opacity: ${(p) => (p.read ? ".5" : "1")};
  }

  p {
    font-size: 1.6rem;
    opacity: ${(p) => (p.read ? ".5" : "1")};
  }

  h6 {
    font-size: 1rem;
    color: var(--greyDark) !important;
    margin-top: 1rem;
    text-align: right;
  }

  &:hover{
    opacity: .5;
  }
`;

const DashboardNotificationsTo = styled.div`
  display: flex;
  align-items: center;
  margin: 1rem 0.5rem;

  svg {
    fill: ${(p) => (p.read ? "var(--greyDark)" : p.theme.color)};
    width: 1rem;
  }
`;

const DashboardNotificationsList = styled.div`
  max-height: calc(100vh - 18rem);
  overflow: auto;

  /* Hide scrollbar */
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  & {
    scrollbar-width: none; /* For Firefox */
  }

  /* Show scrollbar on hover */
  &:hover {
    overflow: auto;
    scrollbar-width: thin; /* For Firefox */
    scrollbar-color: rgba(0, 0, 0, 0.5) rgba(0, 0, 0, 0.1); /* For Firefox */
  }

  &:hover::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  &:hover::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 4px;
  }

  &:hover::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.1);
  }
`

export default function Dashboard_Notifications(props) {
  const { t } = useTranslation();

  const view_notification = (quest, notification) => {
    if (notification?.source === 'History') {
      props.notificationsSetViewHistory();
    }
    else if (notification?.source === 'Admin') {
      props.notificationsSetViewReccomendations();
    }
    props.notificationsSetSelectedQuest(quest);
    props.notificationsMarkRead(notification?.questId);
  }

  return (
    <DashboardNotifications>
        <DashboardNotificationsTitle>
          {t("Notifications")}
        </DashboardNotificationsTitle>
          {props.quests?.length ?
            <DashboardNotificationsList>
              {/* {props.notifications?.filter((notification) =>  notification.read === false)?.map((notification, i) => ( */}
              {props.notifications?.map((notification, i) => (
                <DashboardNotificationsBox source={notification?.source} read={notification?.read} status={notification.outcome} onClick={() => view_notification(props.quests?.find(quest => quest?._id === notification?.questId), notification)}>
                  <p>{props.quests?.find(quest => quest?._id === notification?.questId)?.Meta?.facebookPageName}</p>
                  <h5>{LimitCharacters(notification?.details?.action, 60)}</h5>
                  <h6>{prettifyDates(notification.createdAt)}</h6>
                </DashboardNotificationsBox>
              ))}
            </DashboardNotificationsList>
          :
            <Partial_Loader />
          }
    </DashboardNotifications>
  );
}
