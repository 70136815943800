/* Initial state of store */


export const initialState = {
  selectedLanguage: 'el',
  country:'GR',
  theme:'Light',
  user: {},
  metaSource: {
    facebook_user: {
      userID: "",
      username: "",
      accessToken: ""
    },
    page_selection_info: {
      id: '',
      picture: '',
      name: '',
    },
  },
  selectedQuest: {},
}

export function rootReducer(state = initialState, action) {
  const payload = action.payload;

  switch (action.type) {
    case 'LANGUAGE_UPDATE': {
      return { ...state, selectedLanguage: payload };
    }
    case 'COUNTRY_UPDATE': {
      return { ...state, country: payload };
    }
    case 'THEME_UPDATE': {
      return { ...state, theme: payload };
    }
    case 'KEYCLOAK': {
      return { ...state, user: payload };
    }
    case 'SAVE_TRANSLATIONS': {
      return { ...state, translations: payload };
    }
    case 'SAVE_QUESTIONNAIRE_A': {
      return { ...state, answers: payload };
    }
    case 'SAVE_FACEBOOK_USER': {
      return { ...state, metaSource: {...state.metaSource, facebook_user: payload }};
    }
    case 'SAVE_PAGE_SELECTION': {
      return { ...state, metaSource: {...state.metaSource, page_selection_info: payload }};
    }
    case 'SAVE_INSTAGRAM_ACCOUNT': {
      return { ...state, metaSource: {...state.metaSource, instagramAccount: payload?.id, instagramAccountUsername: payload?.username}};
    }
    case 'SAVE_ACCOUNT_SELECTION': {
      return { ...state, metaSource: {...state.metaSource, account_selection_info: payload }};
    }
    case 'ENTER_NEW_FLOW': {
      return { ...state, flow: payload };
    }
    case 'ADMIN_MODE': {
      return { ...state, adminMode: payload };
    }
    case 'REUSE_LANDING': { 
      return { ...state, reuseLanding: payload };
    }
    case 'REUSE_AD': {
      return { ...state, reuseAd: payload };
    }
    case 'SELECTED_QUEST': {
      return { ...state, selectedQuest: payload };
    }
    default: return state;
  }
}

export default rootReducer;
