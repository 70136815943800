import { PageFull } from "monica-alexandria";
import React, { useContext, useEffect, useState } from "react";
import q from "../../services/api/Quests";
import { useNavigate, useParams } from "react-router-dom";
import { AuthContext } from "../../services/authentication/Guard";

export default function REDIRECT_AFTER_LOGIN() {
  const { lang } = useParams();
  const navigate = useNavigate();
  const auth = useContext(AuthContext);

  const hasQuestAdSubscription = () => {
    q.hasQuestAdSubscription()
      .then((res) => {
        //no subscription
        if (res?.data.code === "Q4000")
          navigate(`/${lang}/questionnaire/alpha`);
        //has subscription
        else if (res?.data.code === "Q2000") navigate(`/${lang}/dashboard`);
        else navigate(`/${lang}/questionnaire/alpha`);
      })
      .catch((err) => console.error("ERR", err));
  };

  useEffect(() => {
    if (auth?.authenticated) hasQuestAdSubscription();
    else navigate(`/${lang}/questionnaire/alpha`);
  }, []);

  return (
    // TODO: Make a proper page
    <PageFull></PageFull>
  );
}
