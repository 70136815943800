import React, { useState } from 'react'
import styled from 'styled-components'
import store from '../../../redux/store'
import ReactPlayer from 'react-player'
import { IconSvg } from 'monica-alexandria'
import { DailyprofitSvg } from '../../../svg/DailyprofitSvg'
import { findLabelByValue } from '../../../utils/findCtaLabel'
import { useTranslation } from 'react-i18next'
import NoImg from '../../../assets/ad/NoImgAd.jpg'
import { useParams } from 'react-router-dom'

const MessengerMobileInbox = styled.div`
    max-width: 37.5rem;
    width: 100vw;
    height: 76rem;
    background-color: #fff;
    h1,h2,h3,h4,h5,h6,p{
        color: var(--black);
    }
`
const MessengerViewOptions = styled.div`
    padding: 1.3rem 0;
    display: flex;
    align-items: center;
    justify-content: center;

`
const MessengerViewOption = styled.div`
    padding: 0.5rem 1rem;
    border: 1px solid var(--grey);
    background-color: ${(p) => (p.active ? '#1877F2' : 'var(--greyLight)')};
    cursor: pointer;

    h6{
        color:  ${(p) => (p.active ? '#fff' : 'var(--black)')};
    }
`

const MessengerAdView = styled.div`
    border-top: 1px solid var(--grey);
    border-bottom: 1px solid var(--grey);

    padding: 0.8rem 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
`

const MessengerAdViewInner = styled.div`
      display: flex;
    align-items: center;
    gap: 1rem;

`

const MessengerAdViewFbImg = styled.div`
     img{
        border: 1px solid var(--grey);
        border-radius: 50%;
        width: 5.5rem;
        height: 5.5rem;
    }
`


const MessengerAdViewFbText = styled.div`
    
     img{
        border: 1px solid var(--grey);
        border-radius: 50%;
        width: 5.5rem;
        height: 5.5rem;
    }

    p{
        font-family: serif;
        font-size: 1.4rem;
    }
    .bluetext {
        color: #1877F2;
    }
    .greytext{
        color: var(--greyDark);
    }
`
const MessengerAdViewFbName = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;

    h4{
        color: var(--black);
        font-family: serif;
    }
`

const MessengerAdViewLabel = styled.div`
    background: #C2C6CC;
    padding: 0.3rem 0.5rem;
    border-radius: 0.5rem;
    h6{
        color: #fff;
        font-size: 1rem;
    }
`

const MessengerAdViewImg = styled.div`
    img{
        height: 6rem;
        width: 6rem;
        border-radius: 1rem;
    }
`
const MessengerExpandedView = styled.div`
    border-top: 1px solid var(--grey);
    padding: 2rem 1rem;
`

const ExpandedViewTop = styled.div`

    display: flex;
    align-items: center;
    justify-content: space-between;

    svg{
        width: 2rem;
    }

`
const ExpandedViewFb = styled.div`
    padding: 2rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

const ExpandedViewFbName = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;
`
const ExpandedViewAdWrapper = styled.div`
    h5{
        white-space: pre-wrap;
        word-break: break-word;
    }
`
const ExpandedViewAd = styled.div`
    margin-top: 1rem;
    background-color: var(--greyLight);
    border-radius: 2rem;
    overflow: hidden;
`

const ExpandedViewAdInfo = styled.div`
    padding-left: 0.5rem ;
    padding-bottom: 1rem ;
    h4{
        font-size: 1.7rem;
        padding-bottom: 0.5rem;
    }
    p{
        font-size: 1.4rem;
    }
`

const ExpandedViewAdImg = styled.div`
    img{
        width: 100%;
        max-height: 35rem;
        height: 100%;
        object-fit: cover;
    }
`

const ExpandedViewAdBottom = styled.div`
  padding: 1rem 1rem;
`

const ExpandedViewAdCta = styled.div`
    background-color: #0A7CFF;
    width: 100%;
    border-radius: 1.2rem;
    text-align: center;
    padding: 0.7rem 0;
    h5{
        color: #fff;
    }
`

const MessengerExpandediewFbImg = styled.div`
    //margin: 0 auto;
     img{
        border: 1px solid var(--grey);
        border-radius: 50%;
        width: 5.5rem;
        height: 5.5rem;
    }
`

const dailyprofitUrl = process.env.REACT_APP_DAILYPROFIT_FRONTEND_PROTOCOL +
                        process.env.REACT_APP_DAILYPROFIT_FRONTEND_HOST + 
                        process.env.REACT_APP_DAILYPROFIT_FRONTEND_PORT;

const minioUrl = process.env.REACT_APP_MINIO_PROTOCOL +
                process.env.REACT_APP_MINIO_HOST + 
                process.env.REACT_APP_MINIO_PORT+ '/' +
                process.env.REACT_APP_MINIO_BUCKET + '/';

const ogTitle = process.env.REACT_APP_OG_TITLE;

export const Messenger_Mobile_Inbox = (props) => {
    const facebook_page = store.getState().metaSource?.page_selection_info;
    const userId = store.getState()?.user?.user?.sub;

    const {t} = useTranslation();
    const [selectedView, setSelectedView] = useState('ad');

    const {questId} = useParams();

  return (
    <MessengerMobileInbox>
        <MessengerViewOptions>
            <MessengerViewOption onClick={()=>setSelectedView('ad')}  active={selectedView==='ad'}>
                <h6>Ad view</h6>
            </MessengerViewOption>
            <MessengerViewOption onClick={()=>setSelectedView('extended')} active={selectedView==='extended'}>
                <h6>Expanded view</h6>
            </MessengerViewOption>
        </MessengerViewOptions>


        {selectedView === 'ad' ?
            <MessengerAdView>
                <MessengerAdViewInner>
                    <MessengerAdViewFbImg>
                        <img src={props.pageLogo} />
                    </MessengerAdViewFbImg>
                    <MessengerAdViewFbText>
                        <MessengerAdViewFbName>
                        <h4>{questId ? props?.adInfo?.facebookPageName :facebook_page?.name}</h4>
                        <MessengerAdViewLabel>
                                <h6>Ad</h6>
                            </MessengerAdViewLabel>
                        </MessengerAdViewFbName>
                        <p className='greytext'>{props.adInfo?.title && props.adInfo.title?.length > 10 ? props.adInfo.title?.substring(0, 10) + '...' : props.adInfo.title}</p>
                        
                        <p className='bluetext'>View more</p>
                    </MessengerAdViewFbText>
                </MessengerAdViewInner>
                <MessengerAdViewImg>
                {props?.adInfo?.isVideo ? (
                    <ReactPlayer
                        url={props.adInfo.file?.preview ? props.adInfo.file?.preview : (props?.adInfo.visualElement ? minioUrl+userId+'/'+props?.adInfo?.visualElement : '')}
                        light={props?.adInfo?.thumbnail !== "default.jpg"  ? <img src={props?.adInfo?.thumbnail} alt='Thumbnail' /> : false}
                        width="100%"
                        height="100%"  //might need change
                        controls={true} 
                        playing={false}
                        //config={facebookConfig}
                    />
                ):(
                    <img src={props.adInfo.file?.preview ? props.adInfo.file?.preview : (props?.adInfo.visualElement ? minioUrl+userId+'/'+props?.adInfo?.visualElement : NoImg)} />
                ) }
                </MessengerAdViewImg>
            </MessengerAdView>
         : 
            <MessengerExpandedView>
                <ExpandedViewTop>
                    <IconSvg Icon='Back' />
                    <MessengerExpandediewFbImg>
                        <img src={questId ? props?.adInfo?.facebookPagePicture :facebook_page?.picture} />
                    </MessengerExpandediewFbImg>
                    <DailyprofitSvg Icon= 'InstaOptions' />
                </ExpandedViewTop>
                <ExpandedViewFb>
                    <ExpandedViewFbName>
                        <h4>{questId ? props?.adInfo?.facebookPageName :facebook_page?.name}</h4>
                        <MessengerAdViewLabel>
                            <h6>Ad</h6>
                        </MessengerAdViewLabel>
                    </ExpandedViewFbName>
                    <p>? people like {questId ? props?.adInfo?.facebookPageName :facebook_page?.name}</p>
                </ExpandedViewFb>
                <ExpandedViewAdWrapper>
                <h5>{props.adInfo?.caption && props.adInfo.caption?.length > 500 ? props.adInfo.caption?.substring(0, 500) + '...' : props.adInfo.caption}</h5>
                    <ExpandedViewAd>
                        <ExpandedViewAdImg>
                        {props?.adInfo?.isVideo ? (
                            <ReactPlayer
                                url={props.adInfo.file?.preview ? props.adInfo.file?.preview : (props?.adInfo.visualElement ? minioUrl+userId+'/'+props?.adInfo?.visualElement : '')}
                                light={props?.adInfo?.thumbnail !== "default.jpg"  ? <img src={props?.adInfo?.thumbnail} alt='Thumbnail' /> : false}
                                width="100%"
                                height="100%"  //might need change
                                controls={true} 
                                playing={false}
                                //config={facebookConfig}
                            />
                        ):(
                            <img src={props.adInfo.file?.preview ? props.adInfo.file?.preview : (props?.adInfo.visualElement ? minioUrl+userId+'/'+props?.adInfo?.visualElement : NoImg)} />
                        ) }
                        </ExpandedViewAdImg>

                        <ExpandedViewAdBottom>
                            <ExpandedViewAdInfo>
                                <h4>{props.adInfo?.title && props.adInfo.title?.length > 10 ? props.adInfo.title?.substring(0, 10) + '...' : props.adInfo.title}</h4>
                                <p>{ogTitle}</p>
                                <p>{dailyprofitUrl}</p>
                            </ExpandedViewAdInfo>
                            <ExpandedViewAdCta>
                                <h5>{findLabelByValue(props.adInfo?.cta, t)}</h5>  
                            </ExpandedViewAdCta>
                        </ExpandedViewAdBottom>
                    </ExpandedViewAd>
                </ExpandedViewAdWrapper>
            </MessengerExpandedView>
         }

    </MessengerMobileInbox>
  )
}
