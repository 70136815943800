

// If this answer was already submitted, don't push it but edit it.
export const push_new_answer = (step, answer) => {

    let newAnswers = JSON.parse(JSON.stringify(step));
    newAnswers = newAnswers.answers;

    if (step?.answers?.length == step.step) newAnswers.push(answer);
    else newAnswers[step?.step] = answer;

    return newAnswers;
  }

  export const renderStepDescription = (step,t) => {
    switch (step) {
      case 0:
        return (
          <p>{t('Identify the geographical areas where you aim to find your clients. This helps in targeting your marketing efforts more effectively.')}</p>
        );
      case 1:
        return (
          <p>{t('Specify the interests or hobbies that your ideal clients are likely to have. Understanding their interests aids in creating more engaging and relevant marketing content.')}</p>
        );
      case 2:
        return (
          <p>{t('Defining the age range and gender of your prospective clients allows for more precise demographic targeting, ensuring that your marketing efforts reach the most appropriate audience.')}</p>
        );
      case 3:
        return (
          <p>
            {t('Setting a clear monthly marketing budget helps optimize ad campaigns for the best results')}
            <br />
             {t('We help you determine the optimal budget by asking;')}
            <br />
            {t('How much money can i spend daily? Multiply that by 30.')}
            <br />
            {t("Sometimes, just 'a beer/day' can significantly impact your business!")}
          </p>
        
        );
    }
  };