import { Button } from 'monica-alexandria'
import React from 'react'
import styled from 'styled-components'

const InterestTabs = styled.div``

const InterestTabsList = styled.ul`
    display: flex;
    flex-wrap: nowrap;
    gap: 1rem;
`

const InterestTabsItem = styled.li`
    border-bottom: 2px solid ${p => p.selected ? 'var(--main)' : 'transparent'};

    button{

        span{

            color: ${p => p.selected ? 'var(--main)' : p.theme.color};
        }
    }

    &:hover{
        border-bottom: 2px solid ${p => p.selected ? 'var(--mainLight)' : 'transparent'};
    }
`

const InterestTabsButton = styled(Button)`
    background: transparent;
`

export default function Interest_Tabs(props) {

    // console.log();
  return (
    <InterestTabs>
        <InterestTabsList>
            {props.categories?.map((category, i) =>
                <InterestTabsItem selected={props.categorySelected === category}>
                    <InterestTabsButton size="small" key={i} onClick={() => props.onCategory(category)}>
                        <span>{category}</span>
                    </InterestTabsButton>
                </InterestTabsItem>
            )}
        </InterestTabsList>
    </InterestTabs>
  )
}
