import React, { useState } from 'react'
import styled from 'styled-components'
import { getAdImageUrl } from '../../../utils/getAdImageUrl'
import { useTranslation } from 'react-i18next'
import { Main_Plain } from 'monica-alexandria'

import q from '../../../services/api/Quests'
import { DailyprofitSvg } from '../../../svg/DailyprofitSvg'


const DashboardNotificationBox = styled.li`
    padding: var(--largePads);
    border-radius: var(--smallRadius);
    background: ${p => p.theme.low};
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  

   /* display: grid;
   grid-template-columns: 1fr auto;
   grid-gap : 1rem; */
  
`

const DashboardNotificationHidden = styled(DashboardNotificationBox)`

`

const DashboardNotificationBoxWrapper = styled.div`
   
   

   display: grid;
   grid-template-columns: auto 1fr;
   grid-gap : 1rem;
`


const DashboardNotificationBoxNew = styled.h6`
  padding: var(--smallPads);
  border-radius: var(--normalRadius);
  background: ${p => p.theme.mid};
  margin-right: 1rem;
`

const DashboardNotificationBoxStamp = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: .5rem;
`

const DashboardNotificationInner = styled.div`
    h5{
        padding-bottom: 0.5rem;
    }
    span{
        font-weight: 200;
        font-family: "fontLight", sans-serif;

    }
    img{
        width: 7rem;
        border-radius: 50%;
    }
`


export const History_Notification_Box = (props) => {
  const {t} = useTranslation();
  const [read, toggleRead] = useState(false)

  // const mark_as_read = (id) => {
  //   let ids = [];
  //   ids.push(id);
  //   toggleRead(id)
  //   q.markAsRead(ids).then(res => console.log('asdfdgf', res))
  // }

  if (props?.notification?.visible === false) 
  return <DashboardNotificationHidden>
    <div></div>
    {/* <h5>Your quest suggestion has been hidden and will not be viewed again.</h5> */}
    <Main_Plain iconLeft='EyeOpen' text='Show hidden' size='medium' noPadding  onClick={props.onClick}/>
  </DashboardNotificationHidden>;

  return (
    <DashboardNotificationBox>
      <DashboardNotificationBoxWrapper>
          {/* <DailyprofitSvg Icon='Undo' /> */}
          <DashboardNotificationInner>
            <img src= {props.notification?.source==='Admin' ? 'https://genius1071.friktoriaservers.net/doitforme/logo/logo_light.svg': getAdImageUrl(props.quest)} alt={props.quest?.adInfo?.title || 'Quest Image'} /> 
          </DashboardNotificationInner>
          <DashboardNotificationInner>
          {props.notification?.source==='Admin' ?
            <h5>{props.notification?.details?.action}</h5>
            : <h5>{props.notification?.details?.action} <span>{t('from')}</span> {props.notification?.details?.from} <span>{t('to')}</span> {props.notification?.details?.to} </h5> }
                <p>{props?.quest?.questionnaires[1]?.answer} | {props?.quest?.questionnaires[0]?.answer}</p>
                <p>{new Date(props?.quest?.updatedAt).toLocaleDateString()}</p>
          </DashboardNotificationInner>
        </DashboardNotificationBoxWrapper>
        {props.notification?.source==='Admin' ? 
          <Main_Plain iconLeft='EyeClose' text='Hide' noPadding onClick={props.onClick}/> : null}
    </DashboardNotificationBox>
  
  )
}
