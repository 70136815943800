import { AlertContext, CardsSixty, Main_Loader, Main_Text_Loader, On_Click_Card, PageAlwaysVisible, PageSixty, PageTitle, Partial_Loader, Sixty_Card_Loader } from 'monica-alexandria'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import l from '../../services/api/Landings';
import store from '../../redux/store';
import { useNavigate, useParams } from 'react-router-dom';
import { alertMessage } from '../../utils/messagesAlerts';

const minioUrl = process.env.REACT_APP_MINIO_PROTOCOL +
                process.env.REACT_APP_MINIO_HOST + 
                process.env.REACT_APP_MINIO_PORT+ '/' +
                process.env.REACT_APP_MINIO_BUCKET + '/';

export default function LANDING_PAGES() {

    const {t} = useTranslation();
    const [landings, setLandings] = useState([]);
    const navigate = useNavigate();
    const {lang, questId} = useParams();
    const {alerts, setAlerts} = useContext(AlertContext);
    const [loading, setLoading] = useState(false);
    const [landingsLoading, setLandingLoading] = useState(true);
    const facebookPageId = store.getState()?.metaSource?.page_selection_info?.id;

    const get_my_landings = () => {

        l.getMyLandings()
        .then(res => {
            console.log('RESPONSE GET MY LANDINGS' , res);
            if (res?.data?.code == 'L2007') {

                setLandings(res?.data?.landings);
            }
            else {
                alertMessage(alerts, setAlerts, (t('Something went wrong')), 'Success');
            }
            setLandingLoading(false);
        })
    }

    const clone_landing = (id) => {
        setLoading(true);
        l.cloneLanding(id, facebookPageId)
          .then(res => {
            console.log('CLONE LANDING' , res);
            if (res?.data?.code == 'L2008' && questId) {
                navigate(`/${lang}/edit-webpage/${questId}`);
            }
            else if (res?.data?.code == 'L2008') {
                navigate(`/${lang}/webpage-setup`);
            }
            else {
                alertMessage(alerts, setAlerts, (t('Something went wrong')), 'Success');
            }
            setLoading(false);
        })
    }

    useEffect(()=>{
        get_my_landings();
    }, [])

    console.log('LANDINGS', landings);

    if (loading) return <Main_Text_Loader text={t('Cloning landing and uploading files...')}/>
    return (
    <PageSixty>
        <PageAlwaysVisible>
            <PageTitle>
                <h1>{t('Select landing page')}</h1>
            </PageTitle>
            {landingsLoading ? 
                 <Sixty_Card_Loader />
            :
                <CardsSixty>
                    {landings?.map((page) => (
                        <On_Click_Card
                            title={page?.title}
                            imgSmall={`${minioUrl}/${page?.userId}/${page?.image}`}
                            onClick = {()=> clone_landing(page?._id)}
                        />
                    ))}
                </CardsSixty>
            }
        </PageAlwaysVisible>
    </PageSixty>
  )
}
