import q from '../../services/api/Quests';
import i18n from '../../localization/i18next';

const hasQuestAdSubscription = (navigate, auth) => {
    if (auth?.authenticated) {
        q.hasQuestAdSubscription()
        .then((res) => {
            //no subscription
            if (res?.data.code === "Q4000")
            navigate(`/${i18n.language}/questionnaire/alpha`);
            //has subscription
            else if (res?.data.code === "Q2000") navigate(`/${i18n.language}/dashboard`);
            else navigate(`/${i18n.language}/questionnaire/alpha`);
        })
        .catch((err) => console.error("ERR", err));
    }
    else {
        navigate(`/${i18n.language}/questionnaire/alpha`);
    }
}

export {
    hasQuestAdSubscription
}