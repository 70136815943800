import { Actions_, CardsFull, CardsTools, On_Click_Card, PageFull, PageTitle, PageTools, Toggle_, Toggle_Stat } from 'monica-alexandria'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Toolbar_ from '../../partials/navigation/Toolbar_'
import store from '../../../redux/store';
import styled from 'styled-components';

const PanelToggle = styled.div`
  display: flex;
  justify-content: center;
 
`
const PanelToggleCard = styled.div`
.ToggleStat {
  border: none;
  box-shadow: none;
  padding: 0;
}
.Children{
    margin-top: 0rem !important;
  }
`
export default function PANEL() {
  const navigate = useNavigate();
  const handlenav = (toPath) => {
    navigate(toPath);
  }

  const {t} = useTranslation();


  // useEffect(() =>{
  //   if (adminMode) store.dispatch({type:'ADMIN_MODE', payload: "INTERNAL_MODE"});
  //   else store.dispatch({type:'ADMIN_MODE', payload: "DEFAULT_MODE"});
  // },[adminMode])

  return (
    <PageFull>
        <PageTitle > 
            <h1>{t("Quastad's admin panel")}</h1>
        </PageTitle>
        <CardsFull>
          <On_Click_Card
            onClick = {()=>handlenav('users')}
            icon = 'User'
            title = {t('Users')}
          />
          <On_Click_Card
            onClick = {()=>handlenav('translations')}
            icon = 'Flag'
            title = {t('Translations')}
          />
          <On_Click_Card 
              onClick={() => window.open("https://insights.hotjar.com/login?next=%2Fsites%2F3900172%2Fheatmap")}
              imgBehave="Cover"
              img = "https://genius1071.friktoriaservers.net/doitforme/morning/app/global/Hotjar.png"
              title = "webdevelopment"
          />
        </CardsFull>
    </PageFull>
  )
}
