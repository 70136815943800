import React, { useEffect, useState } from 'react'
import { Circle, MapContainer, Marker, Polygon, Polyline, Popup, Rectangle, TileLayer, useMap } from 'react-leaflet'
import "leaflet/dist/leaflet.css";
import doitCircle from '../../assets/home/doitCircle.svg'
import icon from "leaflet/dist/images/marker-icon.png";
import iconRetinaUrl from "leaflet/dist/images/marker-icon-2x.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";
import L, { Bounds } from "leaflet";
import styled from 'styled-components';

let DefaultIcon = L.icon({
  iconUrl: icon,
  iconRetinaUrl: iconRetinaUrl,
  shadowUrl: iconShadow,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  tooltipAnchor: [16, -28],
  shadowSize: [41, 41],
});



L.Marker.prototype.options.icon = DefaultIcon;

const ClientLocationMap = styled.div`
  .map-container {
  height: 100vh; 
}

@media (max-width: 992px) {
  .map-container {
    height: 30vh; 
  }
}
`

export const Client_Location_Map = (props) => {

  const initialPosition = [39.16227, 23.49089];
  const initialZoom = 10;
  const [center, setCenter] = useState([39.16227, 23.49089])

  const [mapKey, setMapKey] = useState(0);
  

  useEffect(() => {
    if (props.locations && props.locations?.length > 0) {

      const lastLocation = props.locations[props.locations.length - 1];
      
      if (lastLocation.latitude && lastLocation.longitude) {
        props.setMapCenter([lastLocation.latitude, lastLocation.longitude]);
      }
      else {
        props.setMapCenter([39.16227, 23.49089]);
      }
    }
}, [props.locations?.length]);

  console.log('center', center);
  console.log('props.locations', props.locations);

  useEffect(() => {
    // When the center changes, change the map key to force re-render
    setMapKey(mapKey + 1);
  }, [props.mapCenter]);


  return (
  <ClientLocationMap>
    <MapContainer className="map-container" key={mapKey} style={{  width: '100wh', zIndex: '1' }} center={props.mapCenter} zoom={initialZoom} scrollWheelZoom={true}>
    <TileLayer
      attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
    />
    {props?.locations?.map((location, index) => (
      <div>
        {location.latitude && location.longitude && (
        <Marker key={index} position={[location.latitude, location.longitude]}>
          <Popup>
            {location.name}, {location.region}, {location.country_name}
          </Popup>
        </Marker>
        )}
         {location.radius && (
          <Circle style={{ backgroundColor: '#5bb6b0' }} color="#5bb6b0"  weight={1} center={[location.latitude, location.longitude]} radius={location.radius * 1000 /* converting miles to meters */} />
        )}  
        </div>
      ))}
  </MapContainer>
  </ClientLocationMap>
  )
}
