import React from 'react'
import styled from 'styled-components'
import Graph_Chart from './Graph_Chart'
import Graph_Box from './Graph_Box'
import { Dropdown } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { Dropdown_, device } from 'monica-alexandria'

const DashboardGraph = styled.div`
    display: grid;
    grid-template-columns: 1fr 3fr;
    margin-top: 2rem;
    grid-gap: 2rem;

    @media ${device.lg} {
        grid-template-columns: 1fr;
    }

    @media ${device.md} {
        grid-template-columns: 1fr 3fr;
    }

    @media ${device.sm} {
        grid-template-columns: 1fr;
    }
`

const DashboardGraphList = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;

    @media ${device.lg} {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }

    @media ${device.md} {
        display: flex;
        flex-direction: column;
    }

    @media ${device.sm} {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
`

const DashboardGraphChart = styled.div`
    padding: 2rem;
    background: ${p => p.theme.low};
    border-radius: var(--smallRadius);
`

const DashboardGraphChartActions = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-bottom: 2rem;
`

export default function Dashboard_Graph(props) {

    const {t} = useTranslation()

  return (
    <DashboardGraph>
        <DashboardGraphList>
            {props?.kpis?.map((kpi, i) => 
                <Graph_Box 
                    id={kpi?.key}
                    key={kpi?.key}
                    kpiTitle={kpi?.key}
                    kpiValue={kpi?.value}
                    onClick={() => props.onKpi(kpi?.key)}
                    selected = {kpi?.key === props.kpiSelected}
                />
            )}
        </DashboardGraphList>
        <DashboardGraphChart>
            <DashboardGraphChartActions>
                {/* TODO:When the all time / current interval feature becomes available */}
                {/* <Dropdown_>
                    <select>
                        <option>{t("Current interval")}</option>
                        <option>{t("All time")}</option>
                    </select>
                </Dropdown_> */}
            </DashboardGraphChartActions>
            <Graph_Chart 
                total={props.graphTotal}
                title={props.graphTitle}
                data={props.graphData}
                xDataKey="date"
                lineDataKey="value"
            />
        </DashboardGraphChart>
    </DashboardGraph>
  )
}
