import store from "../../redux/store";

const load_my_ad = (adDoc) => {

    console.log('--------------------------------------------------------------------------', adDoc)

    let doc = {};
    const isVideo = /\.(mp4|mpeg|webm)$/i.test(adDoc?.visualElement)
    doc.caption = adDoc?.caption;
    doc.title = adDoc?.title;
    doc.cta = adDoc?.cta;
    doc.visualElement = adDoc?.visualElement;
    doc.draft = adDoc?.draft;
    doc.questId = adDoc?.questId;
    doc.mode = adDoc?.mode;
    doc.productSetId = adDoc?.productSetId;
    doc.pixelId = adDoc?.pixelId;
    doc.catalogId = adDoc?.catalogId;
    doc.userId = adDoc?.userId;
    doc.thumbnail = adDoc?.thumbnail;
    doc.isVideo = isVideo;
    doc.flow = adDoc?.flow;
    doc.facebookPageId = adDoc?.facebookPageId;
    doc.facebookPageName = adDoc?.facebookPageName;
    doc.facebookPagePicture = adDoc?.facebookPagePicture;

    if (adDoc?.visualElement) doc.visualElement = adDoc?.visualElement;

    return doc;
}

const prepare_ad_for_saving_in_DB = (ad, isDraft) => {

    let adFinal = {};

    adFinal.caption = ad?.caption;
    adFinal.title = ad?.title;
    adFinal.cta = ad?.cta;
    adFinal.draft = isDraft;
    adFinal.questId = ad?.questId;

    if (ad?.visualElement) adFinal.visualElement = ad?.visualElement;

    return adFinal;
}

export {
    load_my_ad,
    prepare_ad_for_saving_in_DB
}
