import React from 'react'
import styled, { keyframes } from 'styled-components'

const LoadingAnimation = keyframes`
  0%{
    opacity: 1;
  }

  30%{
    opacity: .6;
  }

  100%{
    opacity: 1;
  }
`

const DashboardLoading = styled.div`
  min-height: calc(100vh - 20rem);
  background: ${p => p.theme.background};
  z-index: 2;
  border-radius: var(--normalRadius);
  box-shadow: ${p => p.theme.out};
  padding: 2rem 4rem;
  overflow: auto;
`

const DashboardLoadingWrapper = styled.div`
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: 1fr 3fr;
  grid-template-rows: 10rem 25rem 1fr;
  height: calc(100vh - 25rem);
`

const DashboardLoadingBox = styled.div`
  background: ${p => p.theme.low};
  border-radius: var(--smallRadius);
  animation: ${LoadingAnimation} infinite 1s;
`

const DashboardLoadingTop = styled(DashboardLoadingBox)`
  grid-column-start: 1;
  grid-column-end: 3;
`

export default function Dashboard_Loading(props) {
  return (
    <DashboardLoading>
      <DashboardLoadingWrapper>
        <DashboardLoadingTop></DashboardLoadingTop>
        <DashboardLoadingTop></DashboardLoadingTop>
        <DashboardLoadingBox></DashboardLoadingBox>
        <DashboardLoadingBox></DashboardLoadingBox>
      </DashboardLoadingWrapper>
    </DashboardLoading>
  )
}
