import { AlertContext, Alert_, Edit_, Focus_, Focus_Plain, Grey_, Grey_Link, Main_, Main_Loader, Main_Plain, PageFull, PageTitle, device, Confirmation_, Slider_, Main_Text_Loader, Filters_ } from 'monica-alexandria'
import React, { useContext, useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import i18n from '../../localization/i18next'
import { Landing_ } from '../../components/landing/Landing_'
import { LandingContext } from '../../context/LandingContext'
import l from '../../services/api/Landings'
import q from '../../services/api/Quests'
import a from '../../services/api/Ads'
import { campaign_of_type_instant_form_exists, load_my_landing, prepare_landing_for_saving_in_DB } from './utils'
import { alertMessage } from '../../utils/messagesAlerts'
import { Landing_Preview } from '../../components/landing/Landing_Preview'
import store from '../../redux/store'
import Landing_Templates from '../../components/landing/Landing_Templates'
import { FixedTitle, LandingActions, LandingActionsDropdown, LandingBtnWrapper, LandingWrapper } from '../../styles/Layout'
import { Dropdown_Button } from '../../library/buttons/Dropdown_Button'
import Landing_New from '../../components/landing/Landing_New'
import { Landing_Form_Preview } from '../../components/landing/Landing_Form_Preview'


// const FixedTitle = styled(PageTitle)`
//   position: sticky;
//   top: 6.6rem;
//   z-index: 15;
//   background: ${p => p.theme.mid};
//   padding-right: 2rem;
//   border-radius: 2rem;
// `

const PageTitleLeft = styled.div`
  display: flex;
  align-items: center;
`


const LandingMobNav = styled.div`
    .mobNav {
      display: none;

        @media ${device.md} {
          display: block;
        }
    }

    .desktopNav{
      @media ${device.md} {
        display: none;
      }
    }
  `

const EditLanding = styled.div``

const LandingBuilderWrapper = styled.div``

let minioUrl = process.env.REACT_APP_MINIO_PROTOCOL +
process.env.REACT_APP_MINIO_HOST + 
process.env.REACT_APP_MINIO_PORT+ '/' +
process.env.REACT_APP_MINIO_BUCKET + '/';

export default function EDIT_LANDING() {

  const {t} = useTranslation();
  const navigate = useNavigate();
  const lang = i18n.language ? i18n.language : 'el';
  const {questId} = useParams();

  const {landing, setLanding} = useContext(LandingContext)
  const {alerts, setAlerts} = useContext(AlertContext)
  // const {alerts, setAlerts} = useContext(AlertContext)
  const [loading, setLoading] = useState(false);

  const [adImg, setAdImg] = useState('')

  const [landingPreview, toggleLandingPreview] = useState(false);
  const [confirmation, setConfirmation] = useState(false);

  const [landings, setLandings] = useState([]);
  const [landingsLoading, setLandingsLoading] = useState(true);

  const [showOptions, toggleShowOptions] = useState(false);
  const [showTemplates, setShowTemplates] = useState({id:'', slider:''});
  const dropdownPopupRef = useRef(null);


    // Function to close the OptionsPopup when clicking outside
    const closeOptionsPopup = (event) => {
      if (dropdownPopupRef.current && !dropdownPopupRef.current.contains(event.target)) {
        toggleShowOptions(false);
      }
    };
  
    useEffect(() => {
      // Add event listener when component mounts
      document.addEventListener('mousedown', closeOptionsPopup);
      // Clean up the event listener when the component unmounts
      return () => {
        document.removeEventListener('mousedown', closeOptionsPopup);
      };
    }, []);

  // Searches for previous quests in order to reuse a landing, if possible
  // const check_for_previous_quests = () =>{
  //   q.getMyQuests()
  //   .then(res => {
  //       if (res?.data?.code == 'Q5006') {

  //           let quests = res?.data?.data;

  //           if (quests?.length > 1 && store?.getState()?.reuseLanding !== 'DECIDED') {
  //             setConfirmation(true);
  //           }
  //       }
  //   })
  // }

    const instantFormExists = campaign_of_type_instant_form_exists(landing?.campaigns);
   //const instantFormExists = false

   

  const get_my_landings = () => {

    l.getMyLandings()
    .then(res => {
        console.log('RESPONSE GET MY LANDINGS' , res);
        if (res?.data?.code == 'L2007') {
            setLandings(res?.data?.landings);
            if (res?.data?.landings?.length > 0 && store?.getState()?.reuseLanding !== 'DECIDED') {
              setConfirmation(true);
            }
        }
        else {
          alertMessage(alerts, setAlerts, res?.data?.msg, 'Success');
        }
        setLandingsLoading(false);
    })
}
  // Saving the decision in the cache because we do not want to spam the user with the popup
  const choice_no = () => {
    store.dispatch({type:'REUSE_LANDING', payload: 'DECIDED'});
    setConfirmation(false);
  }

  // Saving the decision in the cache because we do not want to spam the user with the popup
  const choice_yes = () => {
    store.dispatch({type:'REUSE_LANDING', payload: 'DECIDED'});
    setConfirmation(false);
    //navigate(`/${lang}/choose-landing-page/${questId}`);
    setShowTemplates({...showTemplates, slider:true})
  }

  const get_my_landing = () => {
    l.getMyLanding(questId)
      .then(res => {
        console.log('&*(*&*(', res?.data?.landing)
        if (res?.data?.code == 'L2002') setLanding(load_my_landing(res?.data?.landing, res?.data?.code));
      })
  }

  const validateFields = () => {
    const emptyFields = [];
    console.log('landing?.img || !landing?.image', landing?.img || landing?.image, instantFormExists, landing?.img ,landing?.image);

    if (!landing?.header?.title) {
        emptyFields.push('Title');
    }
    if (!landing?.header?.subtitle) {
        emptyFields.push('Subtitle');
    }
    if (!instantFormExists && (!landing?.img && !landing?.image)) {
      emptyFields.push('Image');
    }
    if (instantFormExists && (!landing?.customFormField || landing.customFormField?.length === 0)) {
      emptyFields.push('Custom fields');
    } else {
      landing.customFormField.forEach((field, index) => {
          if (!field.label) {
              emptyFields.push(`Custom field #${index + 1} title`);
          }
          if (field.type === 'multiple') {
              field.choices.forEach((choice, choiceIndex) => {
                  if (!choice) {
                      emptyFields.push(`Custom field #${index + 1} choice #${choiceIndex + 1}`);
                  }
              });
          }
      });}
    return emptyFields;
};


  const edit_landing_page = (isDraft) => {
    setLoading(true)
    const validationResult = validateFields();
    if (validationResult.length > 0) {
      const emptyFieldsMessage = `Please fill in the following fields: ${validationResult.join(', ')}`;
      alertMessage(alerts, setAlerts, emptyFieldsMessage, 'Error');
      setLoading(false);  // Ensure loading state is reset
      return;  // Skip calling editLanding if validation fails
    }
  
    let landingFinal = prepare_landing_for_saving_in_DB(landing, isDraft);


    l.editLanding(landingFinal, landing.file)
    .then(res => {
      if (isDraft && res?.data?.code == 'L2001') alertMessage(alerts, setAlerts, (t('Draft has been saved')), 'Success');
      else if (!isDraft && res?.data?.code == 'L2001') navigate(`/${lang}/dashboard`)
      else if (res?.data?.code.startsWith('L4001')) {
        const emptyFieldsMessage = (t('Please check fields!'));
        alertMessage(alerts, setAlerts, emptyFieldsMessage, 'Error');
      }
      else alertMessage(alerts, setAlerts, res?.data?.msg, 'Error');

      setLoading(false)
    })
  }


  const get_my_ad = ()=> {

    a.getMyAd(questId)
    .then(res => {
        if (res?.data?.code == "A2002") {
          // const image = res?.data?.ad?.thumbnail && res.data?.ad?.thumbnail !== 'default.jpg' ?  res.data?.ad?.thumbnail  : `${minioUrl}${res.data?.ad?.userId}/${res.data?.ad?.visualElement}`
          const image = res?.data?.ad?.thumbnail && res.data?.ad?.thumbnail !== 'default.jpg' ?  res.data?.ad?.thumbnail  : `${minioUrl}${res.data?.ad?.userId}/${res.data?.ad?.visualElement}` ;
          setAdImg(image)
           console.log('res?.data?.ad', res?.data?.ad);
        }
    })
  }

  console.log('instantFormExists', instantFormExists);
  useEffect(() => {
    get_my_landing();
    // check_for_previous_quests();
  }, [showTemplates.id])
  

  useEffect(() => {
    get_my_landings();
    get_my_ad()
  }, [])


  if (!landing.code) return <Main_Loader />
  if (loading) return <Main_Text_Loader text='Updating landing...' />
  return (
   
    <EditLanding>

{/* <LandingBtnWrapper>
              {landings?.length > 0  && !instantFormExists? <Main_Plain text={t('Reuse landing')} iconLeft='Recycle' onClick={()=>  setShowTemplates({...showTemplates, slider:true})} /> : null}
            </LandingBtnWrapper> */}
      {/* {confirmation ? <Confirmation_
        message={t("Would you like to reuse an already existing landing?")}
        no={t("No")}
        onNo={() => choice_no()}
        onYes={()=> choice_yes()}
        yes={t("Yes")}
      /> : <></>} */}
      <Alert_ 
        messages={alerts} 
        duration = {3000}
      />
      {showTemplates.slider && 
        <Landing_Templates  landingsLoading={landingsLoading} landings={landings}  questId={questId} setShowTemplates={setShowTemplates}/>
      }
        {landingPreview ?
            instantFormExists? 
              <Landing_Form_Preview 
                adImg={adImg} 
                onSave={()=> edit_landing_page(false)} 
                landingNew disableForm viewMode={true} 
                toggleLandingPreview={toggleLandingPreview}
              /> 
              : <Landing_Preview  
                  onSave={()=> edit_landing_page(false)} 
                  questId={questId} 
                  toggleLandingPreview={toggleLandingPreview}
                />
          
        :
          <LandingBuilderWrapper>
            
  
         
            {/* <LandingWrapper> */}
              {instantFormExists ?
                  <Landing_New 
                    adImg={adImg} 
                    disableForm
                    onNext={()=> toggleLandingPreview(true)} 
                    onSave={()=> edit_landing_page(false)}
                    onBack={()=> navigate(`/${lang}/dashboard`)}
                  />
                :
                  <Landing_ 
                    landingPreview={landingPreview}
                    disableForm
                    onSaveDraft={()=> edit_landing_page(true)}  
                    onPreview={()=> toggleLandingPreview(true)} 
                    onPublish={()=> edit_landing_page(false)}
                   
                  />
              }
            {/* </LandingWrapper> */}
          </LandingBuilderWrapper>
        }
    </EditLanding>
 
  )
}
