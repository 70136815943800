import { device, Footer_, Main_Loader, Page } from 'monica-alexandria'
import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import { Home_Header } from './components/Home_Header'
import { Home_Run_Ads } from './components/Home_Run_Ads'
import { Home_Business } from './components/Home_Business'
// import { Home_Pricing } from './components/Home_Pricing'
import { Home_Ads } from './components/Home_Ads'
import { Home_Devices } from './components/Home_Devices'
import { Home_Team } from './components/Home_Team'
import { Home_Euros } from './components/Home_Euros'
import { Home_Dashboard } from './components/Home_Dashboard'
import { Home_Reporting } from './components/Home_Reporting'
import { Home_Form } from './components/Home_Form'
import { useTranslation } from 'react-i18next'
import { Home_Success } from './components/Home_Success'
import { useNavigate, useParams } from 'react-router-dom'
import { AuthContext } from '../../services/authentication/Guard'
import { hasQuestAdSubscription } from './utils'
import { Home_Qa } from './components/Home_Qa'
import q from '../../services/api/Quests';
import i18n from '../../localization/i18next'
import Home_Pricing from './components/Home_Pricing'

const clients = require('../../config/index').config



const Home = styled.div`

    section {
      padding: 8rem 4rem;
    }

    h2{
      margin-bottom: 4rem;
    }

    @media ${device.md} {
        section{
            padding: 4rem 2rem;
        }

        h2{  
          font-size: 2.7rem;
        }

        h3{
          font-size: 2.5rem;
        }

        p{
          font-size: 1.6rem;
        }
    }
`;

const HomeHeaderHeaderHero = styled.div``

export default function HOME() {
  const {t} = useTranslation();
  const navigate = useNavigate();

  const {lang} = useParams();
  const auth = useContext(AuthContext);
  const [loading, setLoading] = useState(true)

  const onBook = ()=>{
    window.open(`${clients['fahed']}/${lang}/questad-interest`)
  }

  const onStart = ()=>{
    console.log('Hiiii', auth);
    
    hasQuestAdSubscription(navigate, auth)
  }


  const get_my_quests = () => {
    if (auth?.authenticated) {
        q.getMyQuests()
        .then(res => {
            if (res?.data?.data?.length) {
                navigate(`/${i18n?.language}/dashboard`);
            }
            else {
                setLoading(false);
            }
        }).catch(setLoading(false))
    }
    else setLoading(false)
  }

  useEffect(() => {
    get_my_quests()
  },[])

  return (
    <>
    {loading ? <Main_Loader /> 
     : <Home>
        <Home_Header onBook={onBook} onStart={onStart}/>
        <Home_Run_Ads onStart={onStart} />
        <Home_Business onStart={onStart}/>
        <Home_Success onStart={onStart} />
        <Home_Pricing onStart={onStart}/> 
  
        <Home_Ads onStart={onStart} />

        <Home_Devices  onStart={onStart}/>
        <Home_Team/>
        <Home_Euros onStart={onStart}/>
        <Home_Dashboard/>
        <Home_Reporting onStart={onStart} />
        <Home_Qa/>
        <Home_Form onBook={onBook}/>
        <Footer_
          sales={t('Sales Department')}
          development={t('Development Department')}
          marketing={t('Marketing Department')}
          follow={t('Follow us')}
          headquarters={t('Headquarters')}
          address={t('Mikrasiaton 75, Volos, Greece')}
          terms={t('Terms and Conditions')}
          privacy={t('Privacy Policy')}
          onTerms={() => window.location.href = 'https://launchpad.doitforme.eu/en/terms-and-conditions'}
          onPrivacy={() => window.location.href = 'https://launchpad.doitforme.eu/en/data-protection-policy'}
        />
      </Home>
      
    }
    </>
  )
}
