import { IconSvg, device } from 'monica-alexandria'
import React from 'react'
import styled from 'styled-components'
import store from '../../../redux/store'
import { useTranslation } from 'react-i18next'
import { DailyprofitSvg } from '../../../svg/DailyprofitSvg'
import ReactPlayer from 'react-player'
import { findLabelByValue } from '../../../utils/findCtaLabel'
import NoImg from '../../../assets/ad/NoImgAd.jpg'


const InstagramProductFeed = styled.div`
    max-width: 40rem;
    width: 100%;
    width: 100vw;
    box-shadow: ${p => p.theme.out};
    border-radius: var(--smallRadius);
    background: var(--white);

   
    @media ${device.sm}{
        width: 35rem;
    }

`

const AdMockupTitle = styled.div`
    h5{
        color: var(--black);
    }
`
const AdMockButton = styled.div`

    svg{
        width: 1.8rem;
        fill: var(--black);
    }
`

const AdMockupSection = styled.div`
    background: var(--white);
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
`

const AdMockupAccount = styled.div`
    display: flex;
    align-content: center;
    justify-content: space-between;
    padding: var(--normalPads);
    svg{
        width: 2rem;
        height: 2rem;

        fill: var(--black);
        stroke: none;
    }
`

const AdMockupAccountInfo = styled.div`
    display: flex;
    gap: 1rem;

    img{
        border: 1px solid var(--grey);
        border-radius: 50%;
        width: 4rem;
        height: 4rem;
    }

    h5,p{
        color: var(--black);
    }
`

const AdMockupImage = styled.div`
    height : 40rem;
    position: relative;
    img{
        height : 100% ;
        width: 100%;
        object-fit: cover;
    }
`
const AdMockupShop = styled.div`
    background-color: var(--greyDark);
    padding: .5rem;
    border-radius: 50%;
    position: absolute;
    left:1rem;
    bottom: 1rem;


    svg{
        fill: #fff;
        width: 1.5rem;
    }
`
const AdMockupActions = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    border-top: 1px solid var(--grey);

    svg{
        stroke: var(--black);
        stroke-width: 2;
        fill: none;
        width: 2.8rem;
        height: 2.8rem;
    }

    .instaicon {
        width: 2.4rem;
        height: 2.4rem;
    }
`

const AdMockupActionsLeft = styled.div`
    display: flex;
    align-items: center;
    gap:1rem;
`

const AdMockupBottomText = styled.div`
    padding: .5rem 1rem 2rem 1rem;
    h6{
        color: var(--black);
    }
`

const minioUrl = process.env.REACT_APP_MINIO_PROTOCOL +
                process.env.REACT_APP_MINIO_HOST + 
                process.env.REACT_APP_MINIO_PORT+ '/' +
                process.env.REACT_APP_MINIO_BUCKET + '/';

export const Instagram_Product_Feed = (props) => {

    const {t} = useTranslation();
    const facebook_page = store.getState().metaSource?.page_selection_info;
    const userId = store.getState()?.user?.user?.sub;

    const getFirstImageUrl = (imageUrl) => {
        return imageUrl?.split(',')[0].trim();
    };

  return (
    <InstagramProductFeed>
        <AdMockupAccount>
            <AdMockupAccountInfo>
                    <img src={props.pageLogo} />
                    <div>
                        <h5>{facebook_page?.name}</h5>
                        <p>Sponsored</p>
                    </div>
            </AdMockupAccountInfo>
            <DailyprofitSvg Icon='InstaOptions' />
        </AdMockupAccount>
        <AdMockupImage>
            {props.products?.length > 0 && (
                <img 
                    src={getFirstImageUrl(props.products[0]?.image_url)}
                    alt="Product"
                />
            )}
            <AdMockupShop>
                <DailyprofitSvg Icon='ShoppingBag' />
            </AdMockupShop>
        </AdMockupImage>
        <AdMockupSection>
            <AdMockupTitle>
                <h5>{findLabelByValue(props.adInfo?.cta, t)}</h5>
            </AdMockupTitle>
            <AdMockButton>
                <IconSvg Icon='Next' />
            </AdMockButton>
        </AdMockupSection>
        <AdMockupActions>
            <AdMockupActionsLeft>
                <DailyprofitSvg Icon='InstaHeart'  />
                <DailyprofitSvg Icon='InstaComment' class_Icon='instaicon' />
                <DailyprofitSvg Icon='InstaShare' class_Icon='instaicon' />
            </AdMockupActionsLeft>
            <DailyprofitSvg Icon='InstaSave' class_Icon='instaicon' />
        </AdMockupActions>
        <AdMockupBottomText>
            <h6>{facebook_page?.name} {props.products[0]?.name} </h6> 
        </AdMockupBottomText>
    </InstagramProductFeed>
  )
}
