export const alertMessage = (alerts, setAlerts, message, status ) => {
    setAlerts([
        ...alerts,
        {
            message: {
                text: message,
                status: status,
            },
        },
    ]);
};