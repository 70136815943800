import React, { useContext, useState } from 'react'
import styled from 'styled-components'
import { Landing_Colors_Slider } from './Landing_Colors_Slider'
import {  Landing_Form } from './Landing_Form'
import { Landing_Image } from './Landing_Image'
import Landing_Header from './Landing_Header'
import { AlertContext, Alert_, Black_, Grey_, Grey_Link, device } from 'monica-alexandria'
import { LandingContext } from '../../context/LandingContext'
import { t } from 'i18next'
import { useTranslation } from 'react-i18next'

// This component is almost the same as Landing with different styled components in order to immitate responsiveness

const Landing = styled.div`
  position: relative;
  padding: var(--largePads);
  background-color: ${(p)=>p.color};
`

const LandingGrid = styled.div`
    display: grid;
    grid-gap: 2rem;
    grid-template-columns:  ${(p) => p.mobilePreview ? '1fr' : '1fr 1fr'};
`

const LandingLeft = styled.div`
    padding: ${(p) => p.mobilePreview ? '0 0 2rem 0' : '4rem 6rem'};
    order: ${(p) => p.mobilePreview ? 1 : -1};
`

const LandingColorsBtn = styled.div`
    display: flex;
    margin-bottom: 1rem;
`

const LandingRight = styled.div`
    padding-top: ${(p) => p.mobilePreview ? '2rem' : '0'};
`

export const Landing_Device_Preview = (props) => {
    const [editColors, toggleEditColors] = useState(false);
    const {landing} = useContext(LandingContext) || {};
    const {alerts} = useContext(AlertContext);
    const {t} = useTranslation();

  return (
    <Landing mobilePreview={props.mobilePreview} color={landing?.colors?.background} >  
        <Alert_ 
            messages = {alerts}
            duration = {3000}
        />
        {!props.viewMode &&
            <LandingColorsBtn>
                <Grey_ text={t('Edit colors')} iconLeft='Brandkit' onClick = {()=> toggleEditColors(true)}/>  
            </LandingColorsBtn>
        }    
        {editColors && 
            <Landing_Colors_Slider onClose = {()=> toggleEditColors(false)}/>
        }
        <LandingGrid mobilePreview={props.mobilePreview}>
            <LandingLeft mobilePreview={props.mobilePreview}>
                <Landing_Header viewMode={props.viewMode} mobilePreview={props.mobilePreview} />
                <Landing_Form mobilePreview={props.mobilePreview} oldLanding viewMode={props.viewMode} />
            </LandingLeft>
            <LandingRight mobilePreview={props.mobilePreview}>
                <Landing_Image viewMode={props.viewMode}/>
            </LandingRight>
        </LandingGrid>
    </Landing>
  )
}
