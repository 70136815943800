import { Grey_Link, IconSvg, Main_Plain, device } from 'monica-alexandria'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import FbLogo from "../../../../assets/dashboard/FbLogo.png"
const EditLanding = styled.div`
    background: ${p => p.theme.low};
    border-radius: var(--smallRadius);
    border: 2px solid ${p => p.theme.low};
`

const EditLandingTitle = styled.h5`
    padding: 1rem 2rem;
`

const EditLandingContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc(100% - 4rem);
`

const EditLandingWrapper = styled.div`
    background: var(--white);
    height: 100%;
`

const EditLandingText = styled.div`
    h5{
        font-size: 1.4rem;
    }

    p{
        font-size: 1.2rem;
    }
`

const EditLandingFormWrapper = styled.div`
    margin: 0rem 2rem;
    /* background: ${p => p.theme.color}; */
    background: var(--white);
    transform: translateY(-2rem);
    border-radius: var(--smallRadius);
`

const EditLandingForm = styled.div`
    background: var(--grey);
    height: 100%;

    h6{
        text-align: center;
        font-size: 1rem;
    }

    img{
        height: 115px;
        width: 100%;
        object-fit: cover;
    }
`

const EditLandingInput = styled.div`
    padding: .5rem 1rem;
    background: var(--greyLight);
    border-radius: var(--smallRadius);
    margin-top: 1rem;

    p{
        font-size: 1.2rem;
        color: var(--black);
    }
`

const EditLandingDropdown = styled.div`
    padding: .5rem 1rem;
    background: var(--greyLight);
    border-radius: var(--smallRadius);
    margin-top: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    p{
        font-size: 1.2rem;
        color: var(--black);
    }
    svg{
        width: 1rem;
    }
`

const EditLandingButton = styled(EditLandingInput)`
    background: var(--greyDark);

    p{
        text-align: center;
        color: var(--black);
        font-weight: 900;
    }
`

const EditLandingActions = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 2rem;
    padding: 1rem 2rem;
`


const EditLandingCoverImg = styled.div`
    img{
      height: 115px;
      width: 100%;
      object-fit: cover;
     }
`

const EditLandingPage = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transform: translateY(-2rem);

    img{
        border-radius: 50%;
        width: 6rem;
        height: 6rem;
        border: 1px solid var(--white);
    }
    p{
        padding-top: 1rem;
        color: var(--black);
        font-size: 1.2rem;
        //text-align: center;
    }

`

const EditLandingHeader = styled.div`
    padding: 0rem 2rem;
    padding-bottom: 0.5rem;

    h5{
        padding-bottom: 0.5rem;
        font-size: 1.3rem;
        text-align: center;
        color: var(--black);
    }
    p,h5{
        text-align: center;
        color: var(--black);
        font-size: 1rem;
    }

`
const EditLandingFormFields = styled.div`
    h6{
        text-align: left;
        padding-left: 1rem;
    }
    padding: 0 1rem 1rem 1rem;
` 
export default function Edit_Landing(props) {

    const {t} = useTranslation();

    const fieldDisplayNames = {
        Name: (t("First name")),
        Surname: (t("Last name")),
        FullName : (t('Full name')),
        City: (t('City')),
        Tel: (t("Phone number")),
        Email: (t("Email address"))
    };


  return (
    <EditLanding>
         <EditLandingActions>
            {props.hasLandingEdit ?
                <Main_Plain iconLeft="Edit" text={t("Edit")} noPadding size="small" onClick={props.onLandingEdit}/>
            :
                null
            }
        </EditLandingActions>
        <EditLandingTitle>{t("Form")}</EditLandingTitle>
        <EditLandingContainer>
            <EditLandingWrapper>
                <EditLandingForm>
                    <EditLandingCoverImg>
                        <img src={props.landingImg} />
                    </EditLandingCoverImg>
                    <EditLandingFormWrapper> 
                        <EditLandingPage>
                            <img src={FbLogo} />
                            <p>{props.page}</p>
                        </EditLandingPage>
                        <EditLandingHeader>
                            <h5>{props.landingHero || (t('Landing title'))}</h5>    
                            <p> {props.landingSub ||
                            (t("Craft a captivating subtitle that captures the essence of your [brand/product/service]. Clearly articulate how your unique offering and selling points deliver valuable benefits to your target audience. Your compelling story starts with a powerful subtitle."))}
                            </p>   
                        </EditLandingHeader>
                        <EditLandingFormFields>
                            <h6>{t("Contact information")}</h6>
                            {props.landingFields?.map((field, i) => (
                                <EditLandingInput key={i}>
                                    <p>{fieldDisplayNames[field]}</p>
                                </EditLandingInput>
                            ))}
                            {/* {props.landingCustom?.visibility ?
                                <EditLandingInput>
                                    <p>{props.landingCustom?.label}</p>
                                </EditLandingInput>
                            :
                                null
                            } */}
                
                            {props?.landingCustom?.map((field, i)=>(
                                field.type === 'multiple'   ?
                                    <EditLandingDropdown>
                                        <p>{field.label}</p>
                                        <IconSvg Icon='Dropdown' />
                                    </EditLandingDropdown>
                                    :
                                    <EditLandingInput>
                                        <p>{field.label}</p>
                                    </EditLandingInput>
                                
                                ))}
                            <EditLandingButton>
                                <p>{t("Submit")}</p>
                            </EditLandingButton>
                        </EditLandingFormFields>
                    </EditLandingFormWrapper>  
                </EditLandingForm>
            </EditLandingWrapper>
        </EditLandingContainer>
    </EditLanding>
  )
}
