import { device, PageTitle } from "monica-alexandria"
import styled from "styled-components"

export const LandingActions = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: end;

  @media ${device.md} {
     justify-content: end;
  }

`
export const LandingActionsDropdown = styled.div`
  background: ${(p) => p.theme.low};
  position: absolute;
  top: 4.5rem;
  right: 0;
  border-radius: var(--smallRadius);
  padding: var(--smallPads);
  z-index: 5;

  li:last-child{
    padding-top: 0.5rem;
    border-top: 1px solid ${(p) => p.theme.mid};
  }
`

export const LandingWrapper = styled.div`
  margin-top: 2rem;
  border-radius: var(--smallRadius);
  overflow: hidden;
`


export const LandingBtnWrapper = styled.div`
    margin-top: 2rem;

    svg{
        fill: var(--main);
    }
`

export const StepsTitle = styled.div`
  padding-bottom: 2rem;
`

export const StepsTitleInfo = styled.div`
  margin-top: 1rem;
    padding: 1rem;
    border-radius: 1rem;
    background: ${p => p.theme.mid};
    border: 1px dotted var(--greyDark);
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: 1rem;
    align-items: flex-start;

    aside{
      svg{
        width: 3rem;
        fill: var(--warning);
      }
    }

  h6{
    color: var(--warning) !important;
  }
`

export const FixedTitle = styled(PageTitle)`
  position: sticky;
  top: 6.6rem;
  z-index: 15;
  background: ${p => p.theme.mid};
  padding-right: 2rem;
  border-radius: 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const PaymentTip = styled.div`
    border-left: 4px solid var(--focus);
    border-radius: 0.2rem;
    background: ${(p)=> p.theme.mid};
    padding: 1rem;


    h3{
        margin-bottom: 0;
    }
   
`