import { Card, H2, H3, P, device } from 'monica-alexandria';
import React from 'react'
import { useTranslation } from 'react-i18next';
import styled from 'styled-components'

const HomePricing = styled.section`
    padding-top: 0rem !important;
`

const HomePricingGrid = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    margin: 0 4rem;

    @media ${device.md} {
        margin: 0 2rem;
        grid-template-columns: 2fr 1fr 2fr 1fr 2fr 1fr 2fr;
    }
`

const HomePricingTier = styled.div`

`

const HomePricingTierLimits = styled.div`
    position: relative;

    .Right{
        position: absolute;
        right: 0;
        top: 0;
        transform: translateX(50%);
    }

    .Left{
        position: absolute;
        top: 0;
        left: 0;
        transform: translateX(-50%);
    }

    @media ${device.md} {

        .Right{
            transform: translateX(50%) rotate(-90deg) !important;
        }
        
        .Left{
            transform: translateX(-50%) rotate(-90deg) !important;
        }

        h2{
            font-size: 1.5rem !important;
        }
    }
`

const HomePricingTierBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: ${p => p.height};
    margin-top: ${p => p.top};
    background: ${p => p.color};
    position: relative;
    border-top-left-radius: ${p => p.first ? '2rem' : '0'};
    border-bottom-left-radius: ${p => p.first ? '2rem' : '0'};
    border-top-right-radius: ${p => p.last ? '2rem' : '0'};
    border-bottom-right-radius: ${p => p.last ? '2rem' : '0'};


    h1,h6{
        color: ${p => p.white ? 'var(--black)' : 'var(--white)'};
    }

    @media ${device.md} {
        
        h6{
            display: none;
        }
    }
`

const HomePricingTierTick = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(50%, -50%);
    height: 1rem;
    width: 1rem;
    border-radius: 50%;
    background: var(--focus);
    z-index: 2;
`

const HomePricingTierTickLeft = styled(HomePricingTierTick)`
    left: 0;
    transform: translate(-50%, -50%);
`

const HomePricingBuffer = styled.div`
    margin-top: ${p => p.top};
`

const HomePricingBufferTop = styled.div`
    background: ${p => p.gradient};
    clip-path: polygon(0 0, 0% 100%, 100% 100%);
    height: 5rem;
`

const HomePricingBufferBottom = styled.div`
    background: ${p => p.gradient};
    height: ${p => p.height};
`


export const Home_Pricing = (props) => {
    const {t} = useTranslation();
  return (
    <HomePricing>
        {props.hideTitle ? null 
         :   <H2>{t("Our comission fee is calculated based on your Adspend")}</H2> }
        <HomePricingGrid>
            <HomePricingTier>
                <HomePricingTierLimits>
                    <h2 className='Left' >€ 50</h2>
                    <h2 className='Right'>€ 350</h2>
                </HomePricingTierLimits>
                <HomePricingTierBox first height='25rem' top='5rem' color='#5bb6b0' >
                    <h1>9%</h1>
                    <h6>{t("Comission fee")}</h6>
                    <HomePricingTierTick></HomePricingTierTick>
                </HomePricingTierBox>
            </HomePricingTier>
            <HomePricingBuffer top='5rem'>
                <HomePricingBufferTop gradient='linear-gradient(to right, #5bb6b0, #31a6b3)'></HomePricingBufferTop>
                <HomePricingBufferBottom height='20rem' gradient='linear-gradient(to right, #5bb6b0, #31a6b3)'></HomePricingBufferBottom>
            </HomePricingBuffer>
            <HomePricingTier>
                <HomePricingTierLimits>
                    <h2 className='Left' >€ 400</h2>
                    <h2 className='Right' >€ 750</h2>
                </HomePricingTierLimits>
                <HomePricingTierBox height='20rem' top='10rem' color='#31a6b3' >
                    <h1>8%</h1>
                    <h6>{t("Comission fee")}</h6>
                    <HomePricingTierTickLeft></HomePricingTierTickLeft>
                    <HomePricingTierTick></HomePricingTierTick>
                </HomePricingTierBox>
            </HomePricingTier>
            <HomePricingBuffer top='10rem'>
                <HomePricingBufferTop gradient='linear-gradient(to right, #31a6b3, #3a91c4)'></HomePricingBufferTop>
                <HomePricingBufferBottom height='15rem' gradient='linear-gradient(to right, #31a6b3, #3a91c4)'></HomePricingBufferBottom>
            </HomePricingBuffer>
            <HomePricingTier>
                <HomePricingTierLimits>
                    <h2 className='Left' >€ 800</h2>
                    <h2 className='Right' >€ 1000</h2>
                </HomePricingTierLimits>
                <HomePricingTierBox height='15rem' top='15rem' color='#3a91c4' >
                    <h1>7%</h1>
                    <h6>{t("Comission fee")}</h6>
                    <HomePricingTierTickLeft></HomePricingTierTickLeft>
                    <HomePricingTierTick></HomePricingTierTick>
                </HomePricingTierBox>
            </HomePricingTier>
            <HomePricingBuffer top='15rem'>
                <HomePricingBufferTop gradient='linear-gradient(to right, #3a91c4, #6678b9)'></HomePricingBufferTop>
                <HomePricingBufferBottom height='10rem' gradient='linear-gradient(to right, #3a91c4, #6678b9)'></HomePricingBufferBottom>
            </HomePricingBuffer>
            <HomePricingTier>
                <HomePricingTierLimits>
                    <h2 className='Left' >€ 1550</h2>
                    <h2 className='Right' >€ 10K</h2>
                </HomePricingTierLimits>
                <HomePricingTierBox last height='10rem' top='20rem' color='#6678b9' >
                    <h1>4%</h1>
                    <h6>{t("Comission fee")}</h6>
                    <HomePricingTierTickLeft></HomePricingTierTickLeft>
                </HomePricingTierBox>
            </HomePricingTier>
        </HomePricingGrid>
    </HomePricing>
  )
}
