import { Cta_, device, H2 } from 'monica-alexandria'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const HomeEuros = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: var(--dark);
    position: relative;
    margin-bottom: 20rem;
    padding-bottom: 25rem !important;

    @media ${device.md} {
        padding-bottom: 20rem!important;
    }
    

    .Cta{
        z-index: 100;
    }
   
`

const HomeEurosImg = styled.div`
    position: absolute;
    top: 15rem;
    left: 0;
    background: url(https://genius1071.friktoriaservers.net/doitforme/morning/app/tarzan/euros/MobileStories.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    height: 70rem;
    width: 100%;


    @media ${device.md} {
        top: 5rem;
    }
`

const HomeEurosTitle = styled(H2)`
    text-align: center;
    color: var(--white) !important;
    max-width: 80rem;
    position: relative;


`
export const Home_Euros = (props) => {
    const {t} = useTranslation();
  return (
    <HomeEuros>
        <HomeEurosImg/>
        <HomeEurosTitle>{t("Start running ads with a budget as low as € 50,00  per month.")}</HomeEurosTitle>
            <Cta_ size='large' className="" text={t("Advertise your business now")} onClick={props.onStart} />
        </HomeEuros>
  )
}
