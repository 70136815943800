import { Edit_, device } from 'monica-alexandria'
import React, { useContext, useState } from 'react'
import styled from 'styled-components'
import { LandingContext } from '../../context/LandingContext'

import NoImage from '../../assets/landing/NoImage.jpg'
import { ImageHolder } from './Landing_'

const minioUrl = process.env.REACT_APP_MINIO_PROTOCOL +
                process.env.REACT_APP_MINIO_HOST + 
                process.env.REACT_APP_MINIO_PORT+ '/' +
                process.env.REACT_APP_MINIO_BUCKET + '/';

const LandingImage = styled.div`
   position: relative;
   height: 100%;
`

const ImageEdit = styled.div`
    padding: 1rem;
    position: absolute;
    top: 0;
    right: 0;
`
export const Landing_Image = (props) => {
    const [editSlider, toggleEditSlider] = useState(false);
    const {landing, setLanding} = useContext(LandingContext);

  return (
    <LandingImage>
        <ImageHolder>   
            <img src={landing?.img ? landing?.img : (landing?.image ? minioUrl+landing?.userId+'/'+landing?.image : NoImage)} />
        </ImageHolder>
        {!props.viewMode &&  
            <ImageEdit>
                <Edit_ icon='Edit' onClick={()=>toggleEditSlider(true)}/>
            </ImageEdit>
        }
    </LandingImage>
  )
}
