import { CardsSixty, Grey_Link, On_Click_Card, PageAlwaysVisible, PageSixty, PageTitle, Sixty_Card_Loader } from 'monica-alexandria'
import React from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom'


export default function FACEBOOK_BUSINESS() {
    const navigate = useNavigate();
    const {lang} = useParams();
    const {t} = useTranslation();
  return (
    <PageSixty>
        <PageAlwaysVisible>
            <PageTitle>
                <h1>{t('Facebook business')}</h1>
            </PageTitle>
                <CardsSixty>
                    <On_Click_Card
                        title='DOITFORME'
                        imgSmall='https://genius1071.friktoriaservers.net/doitforme/logo/logo_light.svg'
                        onClick = {()=> navigate(`/${lang}/choose-facebook-ad-account`)}
                    />
                </CardsSixty>
        </PageAlwaysVisible>
    </PageSixty>
  )
}

