import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const Lead = styled.div`
    background: ${p => p.theme.low};
    padding: var(--normalPads);
    border-radius: 1rem;

    h6{
        color: var(--greyDark);
        text-align: right;
        margin-top: 2rem;
    }
`

export default function Lead_(props) {

  const {t} = useTranslation()

  return (
    props.formLeads?.map((lead) => (
    <Lead>
      <ul>
      {lead?.Name ? 
        <li>
            <h5>{t("Name")}</h5>
            <p>[{lead?.Name || "-"}]</p>
        </li> : null}
        {lead?.Surname ? 
        <li>
            <h5>{t("Surname")}</h5>
            <p>[{lead?.Surname || "-"}]</p>
        </li> : null}
        {lead?.Tel ? 
        <li>
            <h5>{t("Phone number")}</h5>
            <p>[{lead?.Tel || "-"}]</p>
        </li> : null}
        {lead?.Email ? 
        <li>
            <h5>{t("Email")}</h5>
            <p>[{lead?.Email || "-"}]</p>
        </li> : null}
        {/* {lead?.CustomField ? 
        <li>
            <h5>{t("Custom field")}</h5>
            <p>[{lead.CustomField || "-"}]</p>
        </li> :null} */}
      </ul>
      <h6>[{new Date(lead.createdAt).toLocaleDateString()}]</h6>
    </Lead>
    ))
  )
}
