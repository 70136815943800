import { Builder_, CardsTools, Grey_Link, Input_, On_Click_Card, PageFull, PageTitle, PageTools, Slider_ } from 'monica-alexandria'
import React, { useEffect, useState } from 'react'
import { findMissing, getTranslations, updateTranslations } from '../../../services/api/Translations';
import Toolbar_ from '../../partials/navigation/Toolbar_';
import { useNavigate } from 'react-router-dom';

import _ from 'lodash';
import { Keymaker_ } from '../../partials/keymaker/Keymaker_';
import { useTranslation } from 'react-i18next';
import i18n from '../../../localization/i18next'


const protocol = process.env.REACT_APP_DAILYPROFIT_AKIHIKO_TRANSLATIONS_PROTOCOl;
const host = process.env.REACT_APP_DAILYPROFIT_AKIHIKO_TRANSLATIONS_HOST;
const path = process.env.REACT_APP_DAILYPROFIT_AKIHIKO_TRANSLATIONS_PORT;

export default function TRANSLATIONS() {

  const {t} = useTranslation();
  const navigate = useNavigate();
  const lang = i18n.language ? i18n.language : 'en';

  useEffect(() =>{
    if(process.env.REACT_APP_SERVER === 'PRODUCTION') window.location.assign(`${protocol}${host}/${lang}${path}`)
  },[])

  return (
    <PageFull>
      <PageTitle>
        <Grey_Link iconLeft="Back" onClick={()=> navigate(-1)} />
        <h1>{t("Available languages")}</h1>
      </PageTitle>
      <Keymaker_ />
    </PageFull>
  )
}
