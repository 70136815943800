import { Facebook_, Grey_, Main_Plain, PageAlwaysVisible, PageDesktopOnly, PageForty, PageIllu, PageSubtitle, PageTitle } from 'monica-alexandria'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import store from '../../redux/store';
import m from '../../services/api/Meta'
import q from 'query-string'

const ConnectFacebookActions = styled.div`
    display: flex;
    align-items: flex-start;
    gap: 1rem;
    justify-content: space-between;
    margin-top: 4rem;
`

const ConnectFacebookAccount = styled.div`
    display: flex;
    //align-items: flex-end;
    justify-content: right;
    margin-top: 1rem;
`

export default function CONNECT_FACEBOOK() {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const {lang} = useParams();


    const [userInfo, setUserInfo] = useState({
        userID: '',
        username: '',
        accessToken: '',
        state: 'unchecked',
        shouldLogin: false,
    })

    const login = () => {

        let config;
        let flow = q?.parse(window.location.search);
        flow = flow?.field || 'services';

        // if (flow !== 'eshop') {
            config = {
                scope: 'ads_management,business_management,ads_read,pages_show_list, pages_read_engagement,read_insights, pages_manage_ads, catalog_management, pages_manage_metadata, leads_retrieval',
                redirect_uri: 'https://questad.doitforme.club/el/choose-facebook-page/',
                extras: {
                    setup: {
                        external_business_id: '327319108157286',
                        timezone: 'Europe/Athens',
                        currency: 'EUR',
                        business_vertical: 'ECOMMERCE',
                    },
                    business_config: {
                        business: {
                            name: 'Questad Doitforme',
                        }
                    }
                }
            }
    //    }
        // else {
        //     config = {
        //         // scope: 'ads_management,business_management,ads_read,pages_show_list, pages_read_engagement,read_insights, pages_manage_ads',
        //         config_id: '1604762707012603',
        //         response_type:'code',
        //         override_default_response_type: true,
        //         redirect_uri: 'https://questad.doitforme.club/el/choose-facebook-page',
        //     }
        // }

        window.FB.login(
            function (response) {
                console.log('RESPONSE', response)

                if (response?.authResponse?.code) {
                    m.getToken(response?.authResponse?.code)
                    .then(res => {
                        console.log('RES', res)
                    })
                }
                else if (response.status === 'connected') {
                    setUserInfo({
                        ...userInfo,
                        userID: response.authResponse.userID,
                        accessToken: response.authResponse.accessToken,
                        state: 'connected',
                        shouldLogin: true,
                    });
                } else
                    setUserInfo({
                        ...userInfo,
                        state: 'disconnected',
                        shouldLogin: false,
                    });
            },
            config
        );
    };

    const checkLoginState = () => {
        window.FB.getLoginStatus(function (response) {
            if (response.status === 'connected') {
                setUserInfo({
                    ...userInfo,
                    userID: response.authResponse.userID,
                    accessToken: response.authResponse.accessToken,
                    state: 'connected',
                    shouldLogin: false,
                });
            } else setUserInfo({ ...userInfo, userID: '', accessToken: '', state: 'disconnected', shouldLogin: false });
        });
    };

    console.log('userInfo', userInfo);


    const saveInfoAndNavigate = () => {
        m.getLongLiveToken(userInfo.accessToken)
        .then(res=> {
            console.log("res long live token", res?.data)
            store.dispatch({
                type: 'SAVE_FACEBOOK_USER',
                payload: {
                    userID: userInfo.userID,
                    accessToken: res?.data?.long_live_token,
                    username: userInfo.username,
                },
            });
            
            navigate(`/${lang}/choose-facebook-page`)

        })
        .catch(err => console.log('err', err))

        
    };


    const connectToDifferentAccount = () => {
        window.FB.getLoginStatus(function (response) {
            if (response.status === 'connected') {
                window.FB.logout(function (response) {
                    if (response.status === 'unknown') {
                        setUserInfo({
                            ...userInfo,
                            userID: '',
                            accessToken: '',
                            state: 'disconnected',
                            username: '',
                            shouldLogin: false,
                        });
                        login();
                    } 
                });
            } else login();
        });
    };
    
    const getUsername = () => {
        window.FB.api('/me', function (response) {
            setUserInfo({ ...userInfo, username: response.name });
        });
    };

    useEffect(() => {
        if (userInfo?.shouldLogin) saveInfoAndNavigate();
    }, [userInfo.username]);

    useEffect(() => {
        if (userInfo.state == 'connected') {
            getUsername();
        }
    }, [userInfo.state]);


    useEffect(() => {
        if (window?.FB) checkLoginState();
    },[window?.FB])

    console.log('USER INFO', userInfo);

  return (
    <PageForty>
        <PageAlwaysVisible>
            <PageTitle>
                <h1>{t('Connect account')}</h1>
            </PageTitle>
            <PageSubtitle>
                <p>
                    {t("Connect your app with Facebook to access the necessary data for automated ads. By connecting, you'll be able to retrieve information from your Facebook accounts seamlessly")}
                </p>
            </PageSubtitle>
            <ConnectFacebookActions>
                <Grey_
                    text={t("Back")}
                    iconLeft="Back"
                    onClick={() => navigate(`/${lang}/questionnaire/alpha`)}
                />
                <Facebook_
                    icon
                    size="medium"
                    text={
                        userInfo?.state == 'connected' &&
                        userInfo?.username
                            ? `${t('Connect as')} ${
                                    userInfo.username
                                }`
                            : (t('Continue with Facebook'))
                    }
                    onClick={() =>
                        userInfo.state == 'connected'
                            ? saveInfoAndNavigate()
                            : login()
                    }
                />
        
            </ConnectFacebookActions>
            <ConnectFacebookAccount>
                {userInfo?.state == 'connected' &&
                    userInfo?.username ? (
                        <Main_Plain
                            text={t('Connect to a different account')}
                            onClick={() => connectToDifferentAccount()}
                            noPadding
                            className="DifferentAccount"
                        />
                    ) : null}
            </ConnectFacebookAccount>
    
        </PageAlwaysVisible>
        <PageDesktopOnly>
            <PageIllu>
                <img src="https://genius1071.friktoriaservers.net/doitforme/morning/app/lois/FbLogin.svg" />
            </PageIllu>
        </PageDesktopOnly>
    </PageForty>
  )
}
