import { Main_Loader, PageFull } from 'monica-alexandria'
import {React, useContext, useEffect, useState} from 'react'
import { Landing_ } from '../../components/landing/Landing_'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import queryString from 'query-string';
import l from '../../services/api/Landings'
import i18n from '../../localization/i18next'
import { LandingContext } from '../../context/LandingContext';
import { load_landing } from './utils';
import { Helmet } from 'react-helmet'


const LandingView = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999999999;
  height: 100vh;
  width: 100vw;
  overflow: auto;
`

export default function LANDING_VIEW() {

  const parsedQuery = queryString.parse(window.location.search);
  const id = parsedQuery.id;
  const {landing, setLanding} = useContext(LandingContext);
  const [form, setForm] = useState({});
  const navigate = useNavigate();
  const lang = i18n.language ? i18n.language : 'el';

  const get_landing_by_id = () => {
    l.getLandingById(id)
      .then(res => {
        if (res?.data?.code == 'L2003') setLanding(load_landing(res?.data?.landing, res.data.code));
        else navigate(`/${lang}/page-not-found`)

      })
  }

  useEffect(() => {
    get_landing_by_id();
  }, [])
  console.log(landing)
  if (!landing?.code) return <Main_Loader />

  return (
    <LandingView>
      <Helmet>
        <title>{landing?.header?.title}</title>
      </Helmet>
        <Landing_ viewMode={true}/>
    </LandingView>
  )
}
