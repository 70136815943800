import { device, H2, H5, P } from 'monica-alexandria'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled, { keyframes } from 'styled-components'

const ShowDevelopers = keyframes`
    0%{
        transform: translate(0);
    }

    100%{
        transform: translate(-50%);
    }
`

const ShowMarketing = keyframes`
    0%{
        transform: translate(-50%);
    }

    100%{
        transform: translate(0%);
    }
`

const HomeTeam = styled.section``

const HomeTeamGrid = styled.div`
    display: grid;
    grid-template-columns: 1fr 3fr;
    grid-gap: 4rem;

    @media ${device.lg} {
        grid-template-columns: 1fr;
    }
`
const HomeTeamLeft = styled.div`
    width: 100%;
`


const HomeTeamText = styled(P)`
    button{
        display: inline-block;
        background: transparent;
        font-size: 2rem;
        cursor: pointer;
    }

`
const HomeTeamMarketingBtn = styled.button`
    color: var(--main);
    border: none;
`

const HomeTeamDevelopersBtn = styled.button`
    color: var(--focus);
    border: none;

`

const HomeTeamRight = styled.div`
    overflow: hidden;
    width: 100%;
`
const HomeTeamSlider = styled.div`
    display: flex;
    transform: ${p=> p.currentTeam === 'marketing' ? 'translate(0%)' : 'translate(-50%)'} ;
    animation: ${p=> p.currentTeam === 'marketing' ? ShowMarketing : ShowDevelopers} .5s linear;
    width: 200%;
`

const HomeTeamContainer = styled.div`
    padding: 4rem;
    border-radius: 2rem;
    width: 100%;
`

const HomeTeamMarketing = styled(HomeTeamContainer)`
    /* background: linear-gradient(to bottom right, var(--main), var(--mainDark)); */
`

const HomeTeamDevelopers = styled(HomeTeamContainer)`
    /* background: linear-gradient(to bottom right, var(--focus), var(--mainDark)); */
`

const HomeTeamWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr ;
    gap: 4rem;
    align-items: flex-start;
    justify-content: center;
    width: 100%;

    @media ${device.md} {
        grid-template-columns: 1fr ;
    }
`

const HomeTeamBox = styled.div`
    text-align: center;

   
`
const HomeTeamPic = styled.picture`
  position: relative;
`

const HomeTeamPicImg = styled.img`
    position: relative;
    width: 20rem;
`


const HomeTeamPicBg = styled.div`
    position: absolute;
    bottom: 0px;
    left: 0;
    height: 20rem;
    width: 100%;
    border-top-left-radius: 5rem;
    border-top-right-radius: 2rem;
`


const HomeTeamMarketingBg = styled(HomeTeamPicBg)`
    background: linear-gradient(to bottom, var(--main), var(--mainDark));
`

const HomeTeamDevBg = styled(HomeTeamPicBg)`
    background: linear-gradient(to bottom, var(--focus), var(--mainDark));
`
const HomeTeamContent = styled.div`

`

export const Home_Team = () => {
    const {t} = useTranslation();
    const [teamSlider, setTeamSlider] = useState('marketing')
  return (
    <HomeTeam>
        <HomeTeamGrid>
            <HomeTeamLeft>
                <H2>{t("Meet the team behind your screen")}</H2>
                <HomeTeamText>
                    {t("Doitforme’s")} <HomeTeamMarketingBtn onClick={()=>setTeamSlider('marketing')}>{t("marketing team")}</HomeTeamMarketingBtn> {t("works hand in hand with Questad’s ")}<HomeTeamDevelopersBtn onClick={()=>setTeamSlider('devs')}>{t("developers")}</HomeTeamDevelopersBtn> {t("so we can create marketing tools that get results and solve actual problems in the digital marketing indusrty.")}
                </HomeTeamText>
            </HomeTeamLeft>
            <HomeTeamRight>
                <HomeTeamSlider currentTeam={teamSlider}>
                    <HomeTeamContainer>
                        <HomeTeamWrapper>
                            <HomeTeamBox>
                                <figure>
                                    <HomeTeamPic>
                                        <HomeTeamMarketingBg/>
                                        <HomeTeamPicImg src="https://genius1071.friktoriaservers.net/doitforme/morning/app/tarzan/team/Goulianos.png" alt=""/>
                                    </HomeTeamPic>
                                    <HomeTeamContent>                                    
                                        <H5>{t("Luigi Goulianos")}</H5>
                                        <P>{t("Cofounder & CEO")}</P>
                                    </HomeTeamContent>
                                </figure>
                              
                            </HomeTeamBox>
                            <HomeTeamBox>
                                <figure>
                                    <HomeTeamPic>
                                        <HomeTeamMarketingBg/>
                                        <HomeTeamPicImg src="https://genius1071.friktoriaservers.net/doitforme/morning/app/tarzan/team/Dimitriou.png" alt=""/>
                                    </HomeTeamPic>
                                    <HomeTeamContent>         
                                        <H5>{t("Evi Dimitriou")}</H5>
                                        <P>{t("Head of Digital Marketing")}</P>
                                    </HomeTeamContent>
                                </figure>
                            </HomeTeamBox>
                            <HomeTeamBox>
                                <figure>
                                    <HomeTeamPic>
                                        <HomeTeamMarketingBg/>
                                        <HomeTeamPicImg src="https://genius1071.friktoriaservers.net/doitforme/morning/app/tarzan/team/Mavrofoti.png" alt=""/>
                                    </HomeTeamPic>
                                    <HomeTeamContent>         
                                        <H5>{t("Charis Mavrofoti")}</H5>
                                        <P>{t("Head of SEO")}</P>
                                    </HomeTeamContent>
                                </figure>
                            </HomeTeamBox>
                            <HomeTeamBox>
                                <figure>
                                    <HomeTeamPic>
                                        <HomeTeamMarketingBg/>
                                        <HomeTeamPicImg src="https://genius1071.friktoriaservers.net/doitforme/morning/app/tarzan/team/Korobili.png" alt=""/>
                                    </HomeTeamPic>
                                    <HomeTeamContent>         
                                        <H5>{t("Dimitra Korobili")}</H5>
                                        <P>{t("Digital Marketer")}</P>
                                    </HomeTeamContent>
                                </figure>

                            </HomeTeamBox>

                            <HomeTeamBox>
                                <figure>
                                    <HomeTeamPic>
                                        <HomeTeamMarketingBg/>
                                        <HomeTeamPicImg src="https://genius1071.friktoriaservers.net/doitforme/morning/app/tarzan/team/Anagianni.png" alt=""/>
                                    </HomeTeamPic>
                                    <HomeTeamContent>         
                                        <H5>{t("Lina Anagianni")}</H5>
                                        <P>{t("Sales Executive")}</P>
                                    </HomeTeamContent>
                                </figure>
                            </HomeTeamBox>


                            <HomeTeamBox>
                                <figure>
                                    <HomeTeamPic>
                                        <HomeTeamMarketingBg/>
                                        <HomeTeamPicImg src="https://genius1071.friktoriaservers.net/doitforme/morning/app/tarzan/team/Bagiara.png" alt=""/>
                                    </HomeTeamPic>
                                    <HomeTeamContent>         
                                        <H5>{t("Theodora Bagiara")}</H5>
                                        <P>{t("Graphic Designer")}</P>
                                    </HomeTeamContent>
                                </figure>
                            </HomeTeamBox>

                            <HomeTeamBox>
                                <figure>
                                    <HomeTeamPic>
                                        <HomeTeamMarketingBg/>
                                        <HomeTeamPicImg src="https://genius1071.friktoriaservers.net/doitforme/morning/app/tarzan/team/Gianniou.png" alt=""/>
                                    </HomeTeamPic>
                                    <HomeTeamContent>         
                                        <H5>{t("Elpiniki Gianniou")}</H5>
                                        <P>{t("Graphic Designer")}</P>
                                    </HomeTeamContent>    
                                    </figure>
                            </HomeTeamBox>
                        </HomeTeamWrapper>
                    </HomeTeamContainer>
                    <HomeTeamContainer>
                        <HomeTeamWrapper>
                            <HomeTeamBox>
                                <figure>
                                    <HomeTeamPic>
                                        <HomeTeamDevBg/>
                                        <HomeTeamPicImg src="https://genius1071.friktoriaservers.net/doitforme/morning/app/tarzan/team/Reda.png" alt=""/>
                                    </HomeTeamPic>
                                     <HomeTeamContent>         
                                        <H5>{t("Youssef Reda")}</H5>
                                        <P>{t("Cofounder & CTO")}</P>
                                    </HomeTeamContent>    
                                </figure>
                            </HomeTeamBox>
                            <HomeTeamBox>
                                <figure>
                                    <HomeTeamPic>
                                        <HomeTeamDevBg/>    
                                        <HomeTeamPicImg src="https://genius1071.friktoriaservers.net/doitforme/morning/app/tarzan/team/Chalvatzis.png" alt=""/>
                                    </HomeTeamPic>
                                    <HomeTeamContent>         
                                        <H5>{t("George Chalvatzis")}</H5>
                                        <P>{t("Head of Software Engineering")}</P>
                                    </HomeTeamContent>    
                                </figure>
                            </HomeTeamBox>
                            <HomeTeamBox>
                                <figure>
                                    <HomeTeamPic>
                                        <HomeTeamDevBg/>        
                                        <HomeTeamPicImg src="https://genius1071.friktoriaservers.net/doitforme/morning/app/tarzan/team/Iliadi.png" alt=""/>
                                    </HomeTeamPic>
                                    <HomeTeamContent>         
                                        <H5>{t("Ioanna Iliadi")}</H5>
                                        <P>{t("Frontend Developer")}</P>
                                    </HomeTeamContent>    
                                </figure>
                            </HomeTeamBox>
                         
                            <HomeTeamBox>
                                <figure>
                                    <HomeTeamPic>
                                        <HomeTeamDevBg/>            
                                        <HomeTeamPicImg src="https://genius1071.friktoriaservers.net/doitforme/morning/app/tarzan/team/Mantoudi.png" alt=""/>
                                    </HomeTeamPic>
                                    <HomeTeamContent>         
                                        <H5>{t("Ntora Mantoudi")}</H5>
                                        <P>{t("Backend Developer")}</P>
                                    </HomeTeamContent>    
                                </figure>
                            </HomeTeamBox>

                            <HomeTeamBox>
                                <figure>
                                    <HomeTeamPic>
                                        <HomeTeamDevBg/>            
                                        <HomeTeamPicImg src="https://genius1071.friktoriaservers.net/doitforme/morning/app/tarzan/team/Felekidi.png" alt=""/>
                                    </HomeTeamPic>
                                    <HomeTeamContent>         
                                        <H5>{t("Eva Felekidi")}</H5>
                                        <P>{t("Frontend Developer")}</P>
                                    </HomeTeamContent>
                                </figure>
                            </HomeTeamBox>


                            <HomeTeamBox>
                                <figure>
                                    <HomeTeamPic>
                                        <HomeTeamDevBg/>                
                                        <HomeTeamPicImg src="https://genius1071.friktoriaservers.net/doitforme/morning/app/tarzan/team/Baimpa.png" alt=""/>
                                    </HomeTeamPic>
                                </figure>
                                <HomeTeamContent>         
                                    <H5>{t("Myrianthi Baimpa")}</H5>
                                    <P>{t("Web Designer")}</P>
                                </HomeTeamContent>
                            </HomeTeamBox>

                            <HomeTeamBox>
                                <figure>
                                    <HomeTeamPic>
                                        <HomeTeamDevBg/>        
                                        <HomeTeamPicImg src="https://genius1071.friktoriaservers.net/doitforme/morning/app/tarzan/team/Kyritsis.png" alt=""/>
                                    </HomeTeamPic>
                                    <HomeTeamContent>         
                                        <H5>{t("Vasilis Kyritsis")}</H5>
                                        <P>{t("Backend Developer")}</P>
                                    </HomeTeamContent>    
                                </figure>
                            </HomeTeamBox>
                        </HomeTeamWrapper>
                    </HomeTeamContainer>
                </HomeTeamSlider>
            </HomeTeamRight>
        </HomeTeamGrid>
    </HomeTeam>
  )
}
