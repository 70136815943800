export const findLabelByValue = (value, t) => {

    const dropdownOptions = [
        { value: 'SUBSCRIBE', label: (t('Subscribe')) },
        { value: 'APPLY_NOW', label: (t('Apply Now')) },
        { value: 'DOWNLOAD', label: (t('Download')) },
        { value: 'GET_OFFER', label: (t('Get offer')) },
        { value: 'GET_QUOTE', label: (t('Get quote')) },
        { value: 'LEARN_MORE', label: (t('Learn More')) },
        { value: 'SHOP_NOW', label: (t('Shop Now')) },
        { value: 'SIGN_UP', label: (t('Sign Up')) },
        { value: 'SUBSCRIBE', label: (t('Subscribe')) }

    ];

    const selectedOption = dropdownOptions.find((option) => option.value === value);
    return selectedOption ? selectedOption.label : '';
};
