import { Actions_, Card, Main_Plain } from 'monica-alexandria'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const FacebookPageBox = styled(Card)`
  transition: box-shadow .5s;
  cursor: pointer;
  padding: 1rem;

  img{
    width: 5rem;
    border-radius: 0.5rem;
  }

  h5{
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
  }


  &:hover{
    transition: box-shadow .5s;
    box-shadow: ${p => p.theme.outFocus};
  }
`

const FacebookPageBoxTop = styled.div`
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  grid-gap: 1rem;
`

export default function Facebook_Page_Box(props) {

  const {t} = useTranslation()

  return (
    <FacebookPageBox key={props.key} onClick={props.onClick}>
      <FacebookPageBoxTop>
      <img src={props.imgSmall} />
      <h5>{props.title}</h5>
      <Main_Plain iconRight="Next"/>
      </FacebookPageBoxTop>
    </FacebookPageBox>
  )
}
