import React from 'react'
import styled from 'styled-components';


const EndpointsCard = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${(p) => p.theme.mid};
  justify-content: center;
  border-radius: var(--smallRadius);

`;


const ValueButton = styled.button`
    background-color: transparent;
    border: none;
    padding: var(--normalPads);
    display: flex;
    position: relative;
    gap: 1rem;
    cursor: pointer;
    border-radius: var(--smallRadius);

  &:not(:active):hover,
  &:focus {
    background-color: ${(p) => p.theme.high};
  }

  &:focus,
  &:active {
    background-color:  ${(p) => p.theme.high};
    outline: none;
  }

  &::before {
    content: "";
    position: absolute;
    top: 5px;
    left: -10px;
    width: 5px;
    height: 80%;
    background-color: var(--focus);
    border-radius: 5px;
    opacity: 0;
  }

  &:focus::before,
  &:active::before {
    opacity: 1;
  }

`;

const EndpointsCardTitle = styled.div`
    padding: var(--normalPads);
    border-bottom: 1px solid var(--greyDark);
`
export default function Endpoints_Card(props) {
  
  return (
    <EndpointsCard>
        <EndpointsCardTitle>
            <h2>{props.title}</h2>
        </EndpointsCardTitle>
            {props?.buttons?.map((button, index) => (
                <ValueButton key={index} onClick={() => props.setClicked(button)}>
                    <h4>{button}</h4>
                </ValueButton>
            ))}
            {props.children ? props.children : null }
        </EndpointsCard>
  )
}
