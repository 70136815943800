import React, { useContext, useState } from 'react'
import styled from 'styled-components'
import { Landing_Colors_Slider } from './Landing_Colors_Slider'
import {  Landing_Form } from './Landing_Form'
import Landing_Header from './Landing_Header'
import { AlertContext, Alert_, Black_, Edit_, Grey_, Grey_Link, PageFull, Slider, Slider_, Upload_Cover, device } from 'monica-alexandria'
import { LandingContext } from '../../context/LandingContext'
import { useTranslation } from 'react-i18next'
import { alertMessage } from '../../utils/messagesAlerts'
import NoImage from '../../assets/landing/NoImage.jpg'
import query from 'query-string'
import { Landing_Image_Slider } from './Landing_Image_Slider'
import { Landing_Image } from './Landing_Image'
import { FixedTitle } from '../../styles/Layout'
import { useNavigate } from 'react-router-dom'
import i18n from '../../localization/i18next'
import i18next from 'i18next'
import { Dropdown_Button } from '../../library/buttons/Dropdown_Button'

const minioUrl = process.env.REACT_APP_MINIO_PROTOCOL +
                process.env.REACT_APP_MINIO_HOST + 
                process.env.REACT_APP_MINIO_PORT+ '/' +
                process.env.REACT_APP_MINIO_BUCKET + '/';

const Landing = styled.div`
  position: relative;
  padding: var(--largePads);
  background-color: ${(p)=>p.color};

`

const LandingPage = styled.div``

const LandingGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2rem;
  align-items: flex-start;

  @media ${device.md}{
    grid-template-columns: 1fr;
  }
`

const LandingLeft = styled.div`
  padding: 4rem 6rem;
  width: 100%;
  overflow: hidden;
   @media ${device.md}{
    padding: 0;
    order: 1;
  }
  `

const LandingColorsBtn = styled.div`
  display: flex;
  margin-bottom: 1rem;
`

const LandingRight = styled.div`
  position: sticky;
  top: 7rem;
  height: calc(100vh - 8rem);

    @media ${device.md}{
      padding: 0;
      position: static;
      height: 30rem;
    }
`

export const ImageHolder = styled.div`
    height : 100%;

    img{
        height : 100% ;
        width: 100%;
        object-fit: cover;
        border-radius: var(--smallRadius);
    }
`

const ImageEdit = styled.div`
    padding: 1rem;
    position: absolute;
    top: 0;
    right: 0;
`

const PageTitleLeft = styled.div`
  display: flex;
  align-items: center;
`

export const Landing_ = (props) => {
  const [editColors, toggleEditColors] = useState(false);
  const {landing, setLanding} = useContext(LandingContext) || {};
  const {alerts,setAlerts} = useContext(AlertContext);
  const {t} = useTranslation();
  const navigate = useNavigate();
  const lang = i18next.language ? i18n.language : 'el';


  const [imgErrors, setImgErrors] = useState([]);


    const [editSlider, toggleEditSlider] = useState(false);
 
     const upload_pic = async (files) => {

        if (files?.length && files[0]?.preview) {
        
                setLanding({
                  ...landing,
                  img:`${files[0]?.preview}`,
                  file: files[0]
                });    
            }  
            setImgErrors([])
      };
    
      const delete_pic = async (files) => {
        if (files?.length ) {
                setLanding((prevLanding) => ({
                  ...prevLanding,
                  img:''
                }));    
            } 
            setImgErrors([]) 
      };

      const handleBoomRejected = (type) => {
        let reason = '';
        switch (type) {
            case 'fileMimetype': {
                reason = (t('File must be png or jpeg'));
                break;
            }
            case 'fileSize': {
                reason = (t('Image must be less than 3MB'));
                break;
            }
        }
        setImgErrors( [(t('Could not upload image'))+": "+reason])
       // alertMessage(alerts, setAlerts, (t('Could not upload image. Reason'))+": "+reason,'Error');
        alertMessage(alerts, setAlerts, (t('Could not upload image'))+": "+reason,'Error');
    };

    const [colors, setColors] = useState({
        background: '#ffffff',
        text: '#000000',
    });

   const queryParams = query.parse(window.location.search);

  return (

    <LandingPage>
      {props.landingPreview || props.AlertContextviewMode || props.viewMode ? null
      :
      <FixedTitle>
        <PageTitleLeft>
          <Grey_Link iconLeft="Back" onClick={()=>navigate(`/${lang}/dashboard`)} />
          <h1>{t('Edit landing')}</h1>
        </PageTitleLeft>
      
        <Dropdown_Button
          textMain={t('Save')}
          text1={t('Save draft')}
          text2={t('Preview')}
          textLast ={t('Publish')}
          icon1 = 'Structure'
          icon2 = 'EyeOpen'
          iconLast = 'Save'
          onClick1={props.onSaveDraft}
          onClick2={props.onPreview}
          onClickLast = {props.onPublish}
        />
               
      </FixedTitle>
      }

    <Landing color={landing?.colors?.background} > 
        {editSlider ?
            <Slider_
              text={t("Done")}
              onClose={()=>toggleEditSlider(false)}
              title={t("Edit image")}
              onClick={()=>toggleEditSlider(false)}
            >
              {/* <Landing_Image_Slider /> */}
            <Upload_Cover
                title={t("Select page cover")}
                upload={t("Upload")}
                errors = {imgErrors?.length ? imgErrors : []}
                getFilesFromChild={(files) => upload_pic(files, 'cover')}
                img={landing?.img ? landing.img : (landing?.image ? minioUrl  + landing?.userId+ '/' + landing?.image : NoImage )}
                onDelete={() => delete_pic('cover', t)}
                delete="Delete"
                uploaded={true}
                boomRejected={(type) => handleBoomRejected(type, 'cover', t,  alerts, setAlerts)}
                helpers={[(t('Acceptable files png, jpg, jpeg, less than 6mb'))]}
                maxFiles="1"
                maxSize="3000000"
                aspect='6/4'
            />
        </Slider_>
        : 
            null
        }
      <Alert_ 
        messages = {alerts}
        duration = {10000}
      />
      {!props.viewMode &&
        <LandingColorsBtn>
          <Grey_ text={t('Edit colors')} iconLeft='Brandkit' onClick = {()=> toggleEditColors(true)}/>  
        </LandingColorsBtn>
      }    
      {editColors && 
        <Landing_Colors_Slider 
          onClose = {()=> toggleEditColors(false)} setColors={setColors}
          getFilesFromChild={(files) => upload_pic(files, 'cover')}
          img={landing?.img ? landing.img : (landing?.image ? minioUrl  + landing?.userId+ '/' + landing?.image : NoImage )}
          onDelete={() => delete_pic('cover', t)}
          boomRejected={(type) => handleBoomRejected(type, 'cover', t,  alerts, setAlerts)}
        />
      }
      <LandingGrid>
          <LandingLeft>
            <Landing_Header viewMode={props.viewMode} colors={colors}/>
            <Landing_Form oldLanding questId={queryParams?.id} disableForm={props.disableForm} viewMode={props.viewMode} />
          </LandingLeft>
          <LandingRight>
          <ImageHolder>   
            <img src={landing?.img ? landing?.img : (landing?.image ? minioUrl+landing?.userId+'/'+landing?.image : NoImage)} />
          </ImageHolder>
          {!props.viewMode &&  
              <ImageEdit>
                  <Edit_ icon='Edit' onClick={()=>toggleEditSlider(true)}/>
              </ImageEdit>
          }
          </LandingRight>
      </LandingGrid>
    </Landing>
    </LandingPage>
  )
}
