import { Single_ } from "monica-alexandria";
import { useNavigate } from 'react-router-dom';
import i18n from '../../localization/i18next';
import { useTranslation } from "react-i18next";

export default function PAGE_NOT_FOUND() {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const lang = i18n.language ? i18n.language : 'el';

    return (
        <>
            <Single_
                backgroundImage="https://genius1071.friktoriaservers.net/doitforme/morning/app/boiler/PageNotFound.svg"
                iconGrey="Rocket"
                iconMain="Rocket"
                onClickGrey={() => navigate(`/${lang}/`)}
                onClickMain="-"
                subtitle={t("Page not found")}
                text={t("We cannot find the page you are looking for")}
                textGrey={t("Back to QuestAd")}
                title={t("Oops")}
            />
        </>
        
      )
}