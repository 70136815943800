import { Button, IconSvg } from 'monica-alexandria'
import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'

const Carousel = styled.div`
    padding: 1rem 0;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    gap: 1rem;
    position: relative;
`

const CarouselContainer = styled.div`
    max-width: 100%;
    width: 100%;
    overflow: hidden;
`

const CarouselList = styled.ul`
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    transition: all .5s;
    transform: ${({ movement }) => `translateX(-${movement}%)`};
    /* min-width: ${p => p.minWidth}; */
`

const CarouselItem = styled.li`

    h5{
        white-space: nowrap;
    }
`

const CarouselButton = styled(Button)`
    
    svg{
        fill: var(--greyDark);
    }
`

const CarouselButtonRight = styled(CarouselButton)`
  background: transparent;
    svg{
      fill: ${p => p.theme.color};
    }
`

const CarouselButtonLeft = styled(CarouselButton)`
    /* position: absolute;
    left : 0;
    top :50%; */
    background: transparent;
    svg{
      fill: ${p => p.theme.color};
    }
`

export const Carousel_ = (props) => {

  const containerRef = useRef(null);  
  const listRef = useRef(null);
  const [containerWidth, setContainerWidth] = useState(0); 
  const [listWidth, setListWidth] = useState(0);
  const [divident, setDivident] = useState(0);
  const [movementStep, setMovementStep] = useState(0);
  const percentageMovement = 100/divident;
  const movement = percentageMovement * movementStep;

  const move_right = () => {
    if (movementStep < divident) {
        setMovementStep( movementStep + 1);
    }
  }

  const move_left = () => {
    if (movementStep  != 0) {
        setMovementStep( movementStep - 1);
    }
  }

  const updateContainerWidth = () => {
    if (containerRef.current) {
      const { width } = containerRef.current.getBoundingClientRect();
      setContainerWidth(width);
    }
  };

  useEffect(() => {
   
    updateContainerWidth(); // Measure width initially

    window.addEventListener('resize', updateContainerWidth);
    return () => window.removeEventListener('resize', updateContainerWidth);
  }, []);

  useEffect (() => {

    if (listRef.current) {
        setListWidth(listRef.current.scrollWidth);
    }
  }, [props.refreshWidth])

  useEffect (() => {
    setDivident(Math.ceil(listWidth/containerWidth));
  }, [listWidth, containerWidth])


  return (
    <Carousel>
        {movementStep > 0 ?
            <CarouselButtonLeft noPadding onClick={() => move_left()}>
                <IconSvg Icon="Back" />
            </CarouselButtonLeft>
        :
            null
        }
        <CarouselContainer ref={containerRef}>
            <CarouselList ref={listRef} movement={movement}>
               {props.children}
            </CarouselList>
        </CarouselContainer>
        {(movementStep < divident && containerWidth < listWidth) ?
            <CarouselButtonRight noPadding onClick={() => move_right()}>
                <IconSvg Icon="Next" />
            </CarouselButtonRight>
        :
            null
        }
    </Carousel>
  )
}
