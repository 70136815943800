import React, { useState } from 'react'
import styled from 'styled-components'
import { Landing_ } from './Landing_'
import { IconSvg, device } from 'monica-alexandria'
import { Landing_Device_Preview } from './Landing_Device_Preview'

const DesktopContainer = styled.div`
  scale: .8;
  translate: 0 -10%;

  @media ${device.xl} {
    scale: 1;
    translate: 0 0%;
  }

`

const DesktopCase = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    outline: 1px solid grey;
    border-top: 1rem  solid black;
    border-bottom: 1rem  solid black;
    border-left: .5rem solid black;
    border-right: .5rem solid black;
    border-radius: 1rem;
    background: transparent;
    pointer-events: none;
`


const DesktopWrapper = styled.div`
    overflow-y: auto;
    max-height: 100%;
`

const DesktopWebNav = styled.div`
  width:100%;
  border-bottom: 1px solid var(--grey);
  display: flex;
  gap: .5rem;
  align-items: center;
  margin-top: 1.5rem;
  margin-left: .5rem;
  padding-left: 1rem;

`
const DesktopSearch = styled.div`
  width:100%;
  background-color: var(--grey);
  border-radius: .5rem;
  margin-bottom: .5rem;
  padding: 1rem 1rem;

  span{
    font-size: 1rem;
  }

`
const Desktop = styled.div`
  position: relative;
  width: 100%;
  aspect-ratio: 16/9;
`

const DesktopCloseBtn = styled.div`
  svg{
    fill: red;
    width: 1rem;
  }
`

const DesktopMinimiseBtn = styled.div`
  svg{
    fill: orange;
    width: 1rem;
  }
`

const DesktopExpandBtn = styled.div`
  svg{
    fill: green;
    width: 1rem;
  }
`

export const Desktop_ = (props) => {
  //const [viewMode, setViewMode] = useState(true);
  return (
    <Desktop>
      <DesktopContainer>
          <DesktopWrapper>
            <DesktopWebNav>
              <DesktopCloseBtn>
                <IconSvg Icon='Bullet'/>
              </DesktopCloseBtn>
              <DesktopMinimiseBtn>
                <IconSvg Icon='Bullet'/>
              </DesktopMinimiseBtn>
              <DesktopExpandBtn>
                <IconSvg Icon='Bullet'/>
              </DesktopExpandBtn>
              <DesktopSearch>
              </DesktopSearch>
            </DesktopWebNav>
              <Landing_Device_Preview mobilePreview={props.mobilePreview} viewMode={props.viewMode}/>
              {/* <Landing_  viewMode={viewMode} /> */}
          </DesktopWrapper>
          <DesktopCase>
          </DesktopCase>
      </DesktopContainer>
    </Desktop>
  )
}
