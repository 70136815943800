import { device, Focus_Plain, H2, H3, P } from 'monica-alexandria'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const HomeBusiness = styled.section`

`
const HomeBusinessGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 4rem;

    @media ${device.lg} {
      grid-template-columns: 1fr 1fr;
    }
    @media ${device.md} {
      grid-template-columns: 1fr;
    }
`;

const HomeBusinessBox = styled.div`
  box-shadow: ${p => p.theme.out};
  border-radius: 2rem;
  cursor: pointer;
  transition: box-shadow 0.5s;
  background: ${p => p.theme.low};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 1rem;
  &:hover {
    box-shadow: ${p => p.theme.outFocus};
  }


`;


const HomeBusinessHeader = styled.div`
  background: linear-gradient(to bottom right, var(--main), var(--focus));
  border-top-left-radius: 2rem;
  border-top-right-radius: 2rem;
`;

const HomeBusinessImg = styled.img`
  width: 100%;
  height: 20rem;
  margin: 1rem;
  transition: transform 0.5s;
  object-fit: contain;
  &:hover {
    transform: scale(1.1);
  }
`;


const HomeBusinessTitle = styled.div`
  height: 6rem;
  padding: 0 2rem;
  margin-top: 2rem;
`;

const HomeBusinessText = styled.div`
  padding: 0 2rem 2rem 2rem;
`;



export const Home_Business = (props) => {
    const {t} = useTranslation();
  return (
    <HomeBusiness>
        <H2>{t('Check how Questad can help your business?')}</H2>
        <HomeBusinessGrid>
            <HomeBusinessBox>
                <div>
                  <HomeBusinessHeader>
                      <HomeBusinessImg src="https://genius1071.friktoriaservers.net/doitforme/morning/app/tarzan/business/Freelancer.png" alt=""/>
                  </HomeBusinessHeader>
                  <HomeBusinessTitle>
                      <h3>{t('Service providers & Freelancers')}</h3>
                  </HomeBusinessTitle>
                  <HomeBusinessText>
                      <P>{t("Questad helps you to generate prospect leads ready to buy your services. Gain popularity and connect with even more people through Facebook and Instagram.")}</P>
                  </HomeBusinessText>
                </div>
                  <Focus_Plain text={t('Advertise your services')} onClick={props.onStart}/>
            </HomeBusinessBox>

            <HomeBusinessBox>
              <div>
                <HomeBusinessHeader>
                    <HomeBusinessImg src="https://genius1071.friktoriaservers.net/doitforme/morning/app/tarzan/business/OnlineShops.png" alt="" />
                </HomeBusinessHeader>
                <HomeBusinessTitle>
                    <h3>{t('Online shops')}</h3>
                </HomeBusinessTitle>
                <HomeBusinessText>
                    <P>{t("Rise your sales and make your products known to even more audience. Scale your business to its maximum.")}</P>
                </HomeBusinessText>
              </div>
                <Focus_Plain text={t('Advertise your e-shop')} onClick={props.onStart}/>
            </HomeBusinessBox>


            <HomeBusinessBox>
              <div>
                <HomeBusinessHeader>
                    <HomeBusinessImg src="https://genius1071.friktoriaservers.net/doitforme/morning/app/tarzan/business/PhysicalStores.png" alt="" />
                </HomeBusinessHeader>
           
                  <HomeBusinessTitle>
                      <h3>{t('Physical stores')}</h3>
                  </HomeBusinessTitle>
                  <HomeBusinessText>
                      <P>{t("Drive more customers to your store's door and grow your loyal audience even more.")}</P>
                  </HomeBusinessText>
              </div>
                <Focus_Plain text={t('Advertise your store')} onClick={props.onStart}/>
            
                </HomeBusinessBox>



            <HomeBusinessBox>
              <div>
                <HomeBusinessHeader>
                    <HomeBusinessImg src="https://genius1071.friktoriaservers.net/doitforme/morning/app/tarzan/business/Bar.png" alt="" />
                </HomeBusinessHeader>
                <HomeBusinessTitle>
                    <h3>{t('Bars & Restaurants')}</h3>
                </HomeBusinessTitle>
                <HomeBusinessText>
                    <P>{t("Get a strong presence in the internet like every modern business. Showcase your flavorful creations and stand out.")}</P>
                </HomeBusinessText>
              </div>
                <Focus_Plain text={t('Advertise your bar/resaturant')} onClick={props.onStart}/>
            </HomeBusinessBox>


        </HomeBusinessGrid>
    </HomeBusiness>
  )
}
