import { AlertContext, Upload_Avatar, Upload_Cover } from 'monica-alexandria'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import NoImage from '../../assets/landing/NoImage.jpg'
import { LandingContext } from '../../context/LandingContext'


const LandingImageSlider = styled.div`
`

const minioUrl = process.env.REACT_APP_MINIO_PROTOCOL +
                process.env.REACT_APP_MINIO_HOST + 
                process.env.REACT_APP_MINIO_PORT+ '/' +
                process.env.REACT_APP_MINIO_BUCKET + '/';


export const Landing_Image_Slider = (props) => {
    const {t} = useTranslation();

    const {alerts,setAlerts} = useContext(AlertContext);
    const {landing, setLanding} = useContext(LandingContext) || {};

  return (
    <Upload_Avatar
        title={t("Select page cover")}
        upload={t("Upload")}
    //imgDefault = {}
        errors = {props.imgErrors?.length ? props.imgErrors : []}
        getFilesFromChild={(files) => props.upload_pic(files, 'cover')}
        img={landing?.img ? landing.img : (landing?.image ? minioUrl  + landing?.userId+ '/' + landing?.image : NoImage )}
        onDelete={() => props.delete_pic('cover', t)}
        delete="Delete"
        uploaded={true}
        boomRejected={(type) => props.handleBoomRejected(type, 'cover', t,  alerts, setAlerts)}
        helpers={[(t('Acceptable files png, jpg, jpeg, less than 6mb'))]}
        maxFiles="1"
        maxSize="3000000"
        aspect='6/4'
        //tips={[(t("Preferable ratio")) + ' 3:2']}
    />
  )
}
