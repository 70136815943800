import React, { useState } from 'react'
import styled from 'styled-components'
import { Landing_Device_Preview } from './Landing_Device_Preview'


const Phone = styled.div`
  position: relative;
  max-width: 30rem;
  width: 100%;
  aspect-ratio: 8/16;
  overflow: hidden;
`

const PhoneCase = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    max-width: 30rem;
    width: 100%;
    aspect-ratio: 8/16;
    outline: 1px solid grey;
    border-top: 1rem  solid black;
    border-bottom: 1rem  solid black;
    border-left: .5rem solid black;
    border-right: .5rem solid black;
    border-radius: 2.5rem;
    background: transparent;
    pointer-events: none;
`

const PhoneCamera = styled.div`
    height: 3rem;
    border-radius: 1rem;
    width: 40%;
    background: black;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -1rem);
`

const PhoneWrapper = styled.div`
    overflow-y: auto;
    max-height: 100%;
    border-radius: 2.5rem;
`


export const Phone_ = (props) => {
  return (   
    <Phone>
      <PhoneWrapper>
        <Landing_Device_Preview mobilePreview={props.mobilePreview} viewMode={props.viewMode}/>
      </PhoneWrapper>
      <PhoneCase></PhoneCase>
      <PhoneCamera></PhoneCamera>
    </Phone>  )
}



