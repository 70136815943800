import axios from 'axios';

const protocol = process.env.REACT_APP_DAILYPROFIT_BACKEND_PROTOCOL;
const url = process.env.REACT_APP_DAILYPROFIT_BACKEND_HOST;
const port = process.env.REACT_APP_DAILYPROFIT_BACKEND_PORT;

const checkForLanding = () => {

    return new Promise((resolve, reject) => {

        axios.get(`${protocol}${url}${port}/landings/checkForLanding`)
        .then(res => {
            resolve(res);
        })
        .catch(() => {
            console.error('CHECK FOR LANDING')
            resolve(false);
        })
    })
}

const editLanding = (dataa, file, fileLogo) => {

    let form = new window.FormData();

    let data = dataa;

    if (file) form.append('file', file);
    if (fileLogo) form.append('fileLogo', fileLogo);
    
    form.append('data', JSON.stringify(data));

    console.log('editLanding formm', form);
    return new Promise((resolve, reject) => {

        axios.post(`${protocol}${url}${port}/landings/editLanding`, form, {})
        .then(res => {
            resolve(res);
        })
        .catch(() => {
            console.error('EDIT LANDING')
            resolve(false);
        })
    })
}

const getMyLanding = (questId) => {

    return new Promise((resolve, reject) => {

        axios.get(`${protocol}${url}${port}/landings/getMyLanding?questId=${questId}`)
        .then(res => {
            resolve(res);
        })
        .catch(() => {
            console.error('GET MY LANDING')
            resolve(false);
        })
    })
}

const getMyLandings = () => {

    return new Promise((resolve, reject) => {

        axios.get(`${protocol}${url}${port}/landings/getMyLandings`)
        .then(res => {
            resolve(res);
        })
        .catch(() => {
            console.error('GET MY LANDINGS')
            resolve(false);
        })
    })
}

const getLandingById = (id) => {

    return new Promise((resolve, reject) => {

        axios.get(`${protocol}${url}${port}/landings/getLandingById?landingId=${id}`)
        .then(res => {
            resolve(res);
        })
        .catch(() => {
            console.error('GET LANDING')
            resolve(false);
        })
    })
}

const submitForm = (data) => {

    return new Promise((resolve, reject) => {

        axios.post(`${protocol}${url}${port}/landings/submitForm`, {data: data}, {})
        .then(res => {
            resolve(res);
        })
        .catch(() => {
            console.error('SUBMIT FORM')
            resolve(false);
        })
    })
}

const cloneLanding = (id, facebookPageId) => {

    return new Promise((resolve, reject) => {

        axios.post(`${protocol}${url}${port}/landings/cloneLanding?landingId=${id}&facebookPageId=${facebookPageId}`, {}, {})
        .then(res => {
            resolve(res);
        })
        .catch(() => {
            console.error('CLONE LANDING')
            resolve(false);
        })
    })
}

const getPaginatedLeads = (questId, pageNo, numOfDocuments) => {

    return new Promise((resolve, reject) => {

        axios.post(`${protocol}${url}${port}/landings/getPaginatedLeads`, {questId, pageNo, numOfDocuments}, {})
        .then(res => {
            resolve(res);
        })
        .catch(() => {
            console.error('GET PREVIEW')
            resolve(false);
        })
    })
}

const landingEndpoints = {
    checkForLanding,
    editLanding,
    getMyLanding,
    getMyLandings,
    getLandingById,
    submitForm,
    cloneLanding,
    getPaginatedLeads
}

export default landingEndpoints;