import { Actions_, Confirmation_, Grey_, Grey_Link, Main_, PageAlwaysVisible, PageForty, PageIllu, PageTitle } from 'monica-alexandria'
import React, { useState } from 'react'
import { NavItem } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components'
import q from '../../services/api/Quests';
import store from '../../redux/store'
import query from 'query-string'
import { prettifyDates } from '../../helpers/prettifiers'

const clients = require('../../config/index').config

const SubscriptionWrapper = styled.div`

    h2{
        color: var(--greyDark) !important;
        font-size: 4rem;
    }
    
    h3{
       // margin-bottom: 4rem;
        color: var(--greyDark) !important;
    }
`
const SubscriptionTitle = styled.div`
    padding-bottom: 2rem;
`
const SubscriptionText = styled.div`
    padding-top: 3rem;

    a{
        color: var(--focus);
    }
`
export default function CANCEL_SUBSCRIPTION() {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const {lang} = useParams();
    const {questId} = useParams();

    const [subChoice, setSubChoice] = useState(false);
    const queryParams = query.parse(window.location.search);

    const onYesStopAds = () =>{
        q.stop_Ads(queryParams?.customer, `${clients['dailyprofit']}/${lang}/dashboard`)
        .then(res => {
            setSubChoice(false);
            console.log(res)
            window.location.replace(res?.data?.portal_session_url)
        })
        .catch(err => console.log(err))
    }
  return (
    <PageForty>
        {subChoice === 'stop' ?
            <Confirmation_
                message={t("Are you sure? After canceling your ads, you won't be able to reach your target audience effectively.")}
                no={t("No")}
                onNo={()=>setSubChoice(false)}
                onYes={()=>onYesStopAds(false)}
                yes={t("Yes")}
            />
        :null}
        <PageAlwaysVisible>
            <PageTitle>
                <Grey_Link iconLeft="Back" onClick={() => navigate(`/${lang}/dashboard`)} noPadding />
                <h1>{t("Cancel subscription")}</h1>
            </PageTitle>
            <SubscriptionWrapper> 
                <SubscriptionTitle>
                    <h3>{t("Your subscription is paid until")} {prettifyDates(queryParams?.date, true)} . </h3>
                    <h3>{t("Do you wish to stop ads immediately or continue running them until your subscription period ends?")}</h3>

                    {/* <p>Are you sure? After canceling your subscription</p> */}
                </SubscriptionTitle>
                    <Actions_>
                        <Grey_
                            text = {t('Stop now')}
                            noPadding
                            onClick = {() => setSubChoice('stop')}
                            iconLeft = 'Xicon'
                        />
                        <Main_ 
                            text ={t("Run until end")} 
                            noPadding
                            onClick = {() =>
                                window.location.assign(`${clients['griphook']}/${lang}/plan/questad`
                            )}
                            iconRight = 'Check'
                        />
                    </Actions_>
                    <SubscriptionText>
                        <p>{t("If you encounter any issues with QuestAd, reach out to our support team at")} <a href="mailto:support@doitforme.eu">support@doitforme.eu</a>.</p>
                        <p>{t("We're here to assist you!")}</p>
                    </SubscriptionText>

            
            </SubscriptionWrapper>
        </PageAlwaysVisible>
        <PageAlwaysVisible>
            <PageIllu>
                <img src='https://genius1071.friktoriaservers.net/doitforme/morning/app/hedwig/deleteAccount/byeBye.png'/>
            </PageIllu>
        </PageAlwaysVisible>
    </PageForty>
  )
}
