import { Actions_, Form, Grey_, Grey_Link, IconSvg, Main_, Main_Plain, PageAlwaysVisible, PageDesktopOnly, PageForty, PageIllu, PageSingle, Single_, Slider_ } from 'monica-alexandria'
import React, { useState } from 'react'
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components';


const LandingHelperFormWrapper = styled.div`
  padding-top: 4rem;
  h2{
    padding-bottom: 2rem
  }
`

const LandingHelperTextWrapper = styled.div`
  padding-bottom: 2rem;

  h5{
    margin-bottom: 2rem;
  }
`

const LandingHelperWhy = styled.div`

  ul{
    margin-top: 1rem;

    li{
      display: grid;
      grid-template-columns: auto 1fr;
      align-items: center;
      grid-column-gap: 1rem;
      margin-bottom: 1rem;

      svg{
        width: var(--normalIcon);
        fill: var(--main);
      }

      p{
        grid-column-start: 2;
        grid-column-end: 3;
        grid-row-start: 2;
        grid-row-end: 3;
      }
    }
  }
`
export default function LANDING_HELPER() {
    const navigate = useNavigate();
    const {lang} = useParams();
    const {t} = useTranslation();
  return (
    <PageForty>
      <Helmet>
        <title>Questad | Landing transition</title>
      </Helmet>
      <PageAlwaysVisible>
        <LandingHelperFormWrapper>
          <Form>
            <LandingHelperTextWrapper>
                <h2>{t("Create Webpage")}</h2>
                <h5>{t("You are about to create the page that people will land after they click your advertisement.")}</h5>
                
                <h5>{t("This page has one purpose. To gather essential information from potential customers through a user-friendly form.")}</h5>
            </LandingHelperTextWrapper>
              <LandingHelperWhy>
                <p>{t("Why create a webpage")}</p>
                <ul>
                  <li>
                    <IconSvg Icon='Check' />
                    <h6>{t("Pixel Ready")}</h6>
                    <p>{t("Set up to track all actions that occur, ensuring pixel-perfect accuracy.")}</p>
                  </li>
                  {/* <li>
                    <IconSvg Icon='Check' />
                    <h6>{t("Google Analytics Ready")}</h6>
                    <p>{t("Configured to work seamlessly with Google Analytics, providing comprehensive insights into user behavior.")}</p>
                  </li>
                  <li>
                    <IconSvg Icon='Check' />
                    <h6>{t("G-tag ready")}</h6>
                    <p>{t("Optimized for G-tag integration, allowing for precise tracking of user interactions and conversions.")}</p>
                  </li> */}
                  <li>
                    <IconSvg Icon='Check' />
                    <h6>{t("Leads consolidation")}</h6>
                    <p>{t("Designed to consolidate leads, streamlining the process of capturing and managing potential customers.")}</p>
                  </li>
                </ul>
              </LandingHelperWhy>
            <Actions_>
              <Grey_ text={t("Back")} iconLeft='Back'  onClick={()=> navigate(`/${lang}/create-ad`)}/>
              <Main_ text={t("Next")} iconRight = 'Next' onClick={()=> navigate(`/${lang}/webpage-setup`) }/>
            </Actions_>
          </Form>
        </LandingHelperFormWrapper>
      </PageAlwaysVisible>
      <PageIllu>
          <img src='https://genius1071.friktoriaservers.net/doitforme/morning/app/tarzan/root/HeaderHero.svg' />
      </PageIllu>
    </PageForty>
  )
}
