import { Edit_, Slider, Slider_, device } from 'monica-alexandria'
import React, { useContext, useEffect, useState } from 'react'
import { useSSR, useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Landing_Header_Slider } from './Landing_Header_Slider'
import { LandingContext } from '../../context/LandingContext'

const Header = styled.div`
    border: ${(p)=> p.viewMode ? 'none' : '2px dashed var(--greyDark)'};
    border-radius: ${(p)=> p.viewMode ? '0' : 'var(--normalRadius)'};
    padding: var(--largePads);
    position: relative;
`

const HeaderTitle = styled.div`
    padding-top: 2rem;

    h1{ 
        font-size: ${(p) => (p.mobilePreview ? `calc(${p.titleFontSize} * 0.3)` : p.titleFontSize)};
        //font-size:${(p)=>p.titleFontSize};
        padding-bottom: 1rem;
        color: ${(p)=>p.color} ;
        
        @media ${device.xl} {
            font-size: ${(p) => (p.mobilePreview ? `calc(${p.titleFontSize} * 0.3)` : `calc(${p.titleFontSize} * 0.5)`)};
        }

        @media ${device.lg} {
            font-size: ${(p) => (p.mobilePreview ? `calc(${p.titleFontSize} * 0.3)` : `calc(${p.titleFontSize} * 0.3)`)};
        }

        @media ${device.md} {
            font-size: ${(p) => (p.mobilePreview ? `calc(${p.titleFontSize} * 0.3)` : `calc(${p.titleFontSize} * 0.6)`)};
        }

        @media ${device.sm} {
            font-size: ${(p) => (p.mobilePreview ? `calc(${p.titleFontSize} * 0.3)` : `calc(${p.titleFontSize} * 0.3)`)};
        }
    }
    
    h3{
        font-size: ${(p) => (p.mobilePreview ? `calc(${p.subtitleFontSize} * 0.7)` : p.subtitleFontSize)};
        color: ${(p)=>p.color} ;

        @media ${device.xl} {
            font-size: ${(p) => (p.mobilePreview ? `calc(${p.subtitleFontSize} * 0.5)` : `calc(${p.subtitleFontSize} * 1)`)};
        }

        @media ${device.lg} {
            font-size: ${(p) => (p.mobilePreview ? `calc(${p.subtitleFontSize} * 0.5)` : `calc(${p.subtitleFontSize} * 0.7)`)};
        }

        @media ${device.md} {
            font-size: ${(p) => (p.mobilePreview ? `calc(${p.subtitleFontSize} * 0.7)` : `calc(${p.subtitleFontSize} * 1)`)};
        }

        @media ${device.sm} {
            font-size: ${(p) => (p.mobilePreview ? `calc(${p.subtitleFontSize} * 0.7)` : `calc(${p.subtitleFontSize} * 0.7)`)};
        }
    }
`
const HeaderEdit = styled.div` 
    position: absolute;
    padding: 1rem;
    top: 0;
    right: 0;
`
export default function Landing_Header(props) {
    const [edit, toggleEdit] = useState(false)
    const [fontSize, setFontSize] = useState({title:'7rem', subtitle: "2.2rem"});
    const {landing, setLanding} = useContext(LandingContext) || {};

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const {t} = useTranslation();
    //if window size listener
    useEffect(() => {
        const handleResize = () => {
          setWindowWidth(window.innerWidth);
        };
    
        window.addEventListener('resize', handleResize);
    
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []);
    
    //calculateFontSize function that takes the length and an array of breakpoints with corresponding font sizes. 
    useEffect(() => {
        const titleLength = landing?.header?.title?.length || 0;

        if (titleLength > 30)  setFontSize({ ...fontSize, title: windowWidth > 992 ? '5rem' : '2rem' });
        else if (titleLength > 20) setFontSize({ ...fontSize, title: windowWidth > 992 ? '6rem' : '2.5rem' });
        else setFontSize({ ...fontSize, title: windowWidth > 992 ? '7rem' : '3rem' });

    }, [landing.header.title, windowWidth]);
    

    useEffect(() => {
        const subtitleLength = landing?.header?.subtitle?.length || 0;

        if (subtitleLength > 200)  setFontSize({ ...fontSize, subtitle: windowWidth > 992 ? '1.8rem' : '1.4rem' });
        else if (subtitleLength > 150)   setFontSize({ ...fontSize, subtitle: windowWidth > 992 ? '2rem' : '1.8rem' });
        else setFontSize({ ...fontSize, subtitle: windowWidth > 992 ? '2.2rem' : '2rem' });
    
    }, [landing.header.subtitle, windowWidth]);

    console.log('Landing header', landing);
  return (
    <Header viewMode={props.viewMode}>
        {edit ?
            <Slider_
            text={t("Done")}
            grey={t("Cancel")}
            onGrey={() => toggleEdit(false)}
            onClose={() => toggleEdit(false)}
            title={t("Edit Header")}
            onClick={() => toggleEdit(false)}
        >
            <Landing_Header_Slider title={landing?.header?.title} subtitle={landing?.header?.subtitle} toggleEdit={toggleEdit} />
        </Slider_>

           : null     }
        <HeaderTitle mobilePreview={props.mobilePreview} color={landing?.colors?.text} titleFontSize={fontSize?.title} subtitleFontSize={fontSize?.subtitle}> 
            <h1>{landing?.header?.title || (t('Landing title'))}</h1>    
            <h3> {landing?.header?.subtitle ||
               (t("Craft a captivating subtitle that captures the essence of your [brand/product/service]. Clearly articulate how your unique offering and selling points deliver valuable benefits to your target audience. Your compelling story starts with a powerful subtitle."))}
            </h3>       
         </HeaderTitle>
        {!props.viewMode &&  
            <HeaderEdit>
                <Edit_ icon='Edit' onClick = {()=>toggleEdit(true)}/>
            </HeaderEdit>
        }
    </Header>
  )
}
