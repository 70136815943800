import { Actions_, Main_, PageAlwaysVisible, PageDesktopOnly, PageIllu, PageSixty, PageTitle, Single_ } from 'monica-alexandria'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components';

export default function THANK_YOU() {
    const {t} = useTranslation();
  return (
        <Single_
          backgroundImage="https://genius1071.friktoriaservers.net/doitforme/morning/app/dailyprofit/telephoneDoers.svg"
          text={t("We've successfully received your submission and will get back to you shortly.")}
          title={t("Thank you for your submission")}
        />
  )
}
