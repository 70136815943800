import React, { useContext } from 'react'
import { LandingContext } from '../../context/LandingContext';
import NoImage from '../../assets/landing/NoImage.jpg'
import NoImageGrey from '../../assets/landing/NoImageGrey.jpg'
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import store from '../../redux/store';
import { Landing_Form } from './Landing_Form';
import { Filters_, Grey_Link, Main_, PageDesktopOnly, PageFull, PageTitle, device } from 'monica-alexandria';
import { FixedTitle } from '../../styles/Layout';


const minioUrl = process.env.REACT_APP_MINIO_PROTOCOL +
                process.env.REACT_APP_MINIO_HOST + 
                process.env.REACT_APP_MINIO_PORT+ '/' +
                process.env.REACT_APP_MINIO_BUCKET + '/';

const LandingFormPreview = styled(PageFull)`
   padding-bottom: 2rem;
`
const InstanFormWrapper = styled.div`
    background: var(--grey);
    border-radius: var(--smallRadius);
    overflow: hidden;

    @media ${device.md} {
        margin-top: 2rem;
    }
`
const InstantFormCoverImg = styled.div`
    img{
      height: 215px;
      width: 100%;
      object-fit: cover;
     }
`

const InstantForm = styled.div`
    transform: translateY(-4rem);
    border-radius: var(--normalRadius);
    background: var(--white);
    margin : 0rem 4rem;
`

const InstantFormHeader = styled.div`
    padding: 0rem 2rem;
    h3{
        padding-bottom: 1rem;
    }
    h3,p{
        text-align: center;
        color: var(--black);
        white-space: pre-wrap;
        word-break: break-word;
    }

    .defaultText{
        opacity: 0.7;
        font-style: italic;
    }
`

const InstantFormPage = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    transform: translateY(-4rem);
    img{
        border-radius: 50%;
        width: 10rem;
        height: 10rem;
      
    }
    p{
        padding-top: 1rem;
        color: var(--black);
        text-align: center;
    }

`

const LandingFormMobNav = styled.div`
   display: none;

    @media ${device.md} {
        display: block;
    }
`

const PageTitleLeft = styled.div`
  display: flex;
  align-items: center;
`
export const Landing_Form_Preview = (props) => {

  const {landing, setLanding} = useContext(LandingContext) || {};
    const {t} = useTranslation();
    let facebookPagePicture = store.getState()?.metaSource?.page_selection_info?.picture || false;
    let facebookPageName = store.getState()?.metaSource?.page_selection_info?.name || false;


    console.log('landing', landing);
    console.log('adImg', props.adImg);
  return (
    
    <LandingFormPreview> 
        <LandingFormMobNav>
            <PageTitle>
            <Grey_Link iconLeft='Back' onClick={()=>(props.toggleLandingPreview(false))} noPadding/>
            <h1>{t("Preview form")}</h1>
        </PageTitle>
            <Filters_>
                <Main_ className='desktopNav' text={t('Save')} iconLeft="Save" onClick={()=>props.onSave()}/>
            </Filters_>
        </LandingFormMobNav>
{/*      
        <LandingFormMobNav>
            <FixedTitle>   
                <PageTitleLeft>
                   
                    <h1>{t('Preview')}</h1>
                </PageTitleLeft>
               
            </FixedTitle>
        
        </LandingFormMobNav>  */}

        <InstanFormWrapper>
            <InstantFormCoverImg>
                {/* <img src={landing?.img ? landing?.img : (landing?.image ? minioUrl+landing?.userId+'/'+landing?.image : NoImage)} /> */}
                <img src={props.adImg ? props.adImg : NoImageGrey} />
            </InstantFormCoverImg>
            <InstantForm> 
                <InstantFormPage>
                    <img src={facebookPagePicture} />
                    <p>{facebookPageName}</p>
                </InstantFormPage>
                <InstantFormHeader>
                    {landing?.header?.title ?
                        <h3>{landing?.header?.title}</h3>  
                        : <h3 className='defaultText'> { (t('Limited time offer!'))}</h3>  }
                    {landing?.header?.subtitle ?
                    <p> {landing?.header?.subtitle } </p>:
                    <p className='defaultText'>  {t("Fill in the form and get informed now")} </p>
                    }

                </InstantFormHeader>
                <Landing_Form landingNew={props.landingNew} disableForm={props.disableForm} viewMode={props.viewMode}/>
            </InstantForm>  
        </InstanFormWrapper>
    </LandingFormPreview>
  )
}
