import { createContext } from "react";

export const LandingContext = createContext({
  backgroundColor: "#ffffff", // default background color
  textColor: "#000000", // default text color
  
  logo: '',

  header:{
    title:'',
    subtitle:'',
  },
    customFormField: [{visibility: false, label:'', type: 'input'}],
  customFields: [{ title: '', choices: ['', ''], type: 'input' }],
  defaultFormFields:['Name' , 'Tel'],
  // customFormField: {visibility: false, label:''},

  img:'https://genius1071.friktoriaservers.net/monica/fbReview/landingReview.jpg',
  
  setLanding: () => {},
});
