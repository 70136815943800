import { AlertContext, Builder_, Input_, Slider_, Textarea_ } from 'monica-alexandria'
import React, { useContext, useState } from 'react'
import styled from 'styled-components'
import { LandingContext } from '../../context/LandingContext'
import { alertMessage } from '../../utils/messagesAlerts'
import { useTranslation } from 'react-i18next'

const EditHeader = styled.div``

export const TextareaTest = styled.textarea`
  width: 100%;
  border: none;
  background: ${(props) => props.theme.background};
  color: ${(props) => props.theme.color};
  box-shadow: ${(props) => props.theme.in};
  border-radius: var(--smallRadius);
  transition-property: box-shadow;
  transition-duration: 0.5s;
  padding: var(--normalPads);
  font-size: var(--normalText);
  resize: none;

  &::placeholder {
    color: inherit;
    opacity: 0.5;
  }

  &:focus {
    outline: 0;
    box-shadow: ${(props) => props.theme.inFocus};
  }
`;
export const Landing_Header_Slider = (props) => {
    const {t} = useTranslation();

    const {alerts, setAlerts} = useContext(AlertContext);
    const {landing, setLanding} = useContext(LandingContext);

  let titleLimit = 60;
  let subtitleLimit = 250;


  const validateText = (text, limit, field) => {
    let helpers = [];
    let successes = [];

    // Check if the text contains a comma
    if (text.includes(',')) {
        helpers.push(t('Text must not contain a comma (,)'));
    } else {
        successes.push(t('Valid input'));
    }

    // Check if text length is near the limit
    if (text.length === limit) {
        helpers.push(t('You have reached the character limit.'));
    } else if (text.length > limit) {
        helpers.push(t('Exceeded the character limit.'));
    }

    // Update state based on field (title or subtitle)
    if (field === 'title') {
        props.setMessages((prev) => ({
            ...prev,
            helpersTitle: helpers,
            successesTitle: successes,
        }));
    } else if (field === 'subtitle') {
          props.setMessages((prev) => ({
            ...prev,
            helpersSubtitle: helpers,
            successesSubtitle: successes,
        }));
    }
};


  const handleSaveHeaderToContext = (limit, field, text) => {
    validateText(text, limit, field);

    // Save to context if character limit is not exceeded
    if (text.length <= limit) {
        setLanding({
            ...landing,
            header: {
                ...landing?.header,
                [field]: text,
            },
        });
    } else {
        alertMessage(alerts, setAlerts, t('The maximum character limit for this input has been reached.'), 'Error');
    }
  };
    



  return (
    <EditHeader>
        
            <Builder_ title={t("Edit title")}>
                <Textarea_
                  required
                  valueLength = {landing?.header?.title?.length}
                  limit={titleLimit}
                  rows="3"
                  defaultValue={props?.title}
                  placeholder={t('Landing page title e.g. "Limited time offer!”')}
                  helpers={props?.messages?.helpersTitle}
                  successes={props?.messages?.successesTitle}
                  onChange = {(e)=>handleSaveHeaderToContext(titleLimit,'title', e.target.value)}
                />

            </Builder_>
            <Builder_ title={t("Edit subtitle")}>
                <Textarea_
                  required
                  defaultValue={props?.subtitle}
                  valueLength = {landing?.header?.subtitle?.length}
                  placeholder={(t('Landing page description e.g. "Fill in the form and get informed now"'))}
                  onChange = {(e)=>handleSaveHeaderToContext(subtitleLimit,'subtitle', e.target.value)}
                  helpers={props?.messages?.helpersSubtitle}
                  successes={props?.messages?.successesSubtitle}
                  limit={subtitleLimit}
                  rows="5"
                />
            </Builder_>
    </EditHeader>
  )
}
