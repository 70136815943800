import React, { useEffect, useLayoutEffect, useRef, useState, useContext } from 'react'
import m from '../../services/api/Meta'
import { Actions_, AlertContext, Alert_, Grey_, Grey_Link, IconSvg, Main_, Main_Loader, Main_Plain, On_Stat, PageAlwaysVisible, PageForty, PageFull, PageSubtitle, PageTitle, Section_Title, Slider_, ValidatorError } from 'monica-alexandria'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components'
import { alertMessage } from '../../utils/messagesAlerts'
import store from '../../redux/store'
import { load_my_ad } from '../createAd/utils'
import a from '../../services/api/Ads'
import q from '../../services/api/Quests'
import { Instagram_Mockup_Feed } from './components/Instagram_Mockup_Feed'
import { Instagram_Mockup_Reels } from './components/Instagram_Mockup_Reels'
import { Instagram_Mockup_Stories } from './components/Instagram_Mockup_Stories'
import { Desktop_Feed_Standard } from './components/Desktop_Feed_Standard'
import { Feed_Mobile } from './components/Feed_Mobile'
import { Facebook_Reels_Mobile } from './components/Facebook_Reels_Mobile'
import { Facebook_Story_Mobile } from './components/Facebook_Story_Mobile'
import { Messenger_Mobile_Story } from './components/Messenger_Mobile_Story'
import { Messenger_Mobile_Inbox } from './components/Messenger_Mobile_Inbox'
import Preview_Catalog_Mockup from '../../components/ad/Preview_Catalog_Mockup'
import { Desktop_Feed_Products } from './components/Desktop_Feed_Products'
import { Mobile_Feed_Products } from './components/Mobile_Feed_Products'
import { Facebook_Product_Reels } from './components/Facebook_Product_Reels'
import { Facebook_Product_Story } from './components/Facebook_Product_Story'
import { Instagram_Product_Feed } from './components/Instagram_Product_Feed'
import { Instagram_Product_Stories } from './components/Instagram_Product_Stories'
import { Instagram_Product_Reels } from './components/Instagram_Product_Reels'
import { DailyprofitSvg } from '../../svg/DailyprofitSvg'
import FbLogo from "../../assets/dashboard/FbLogo.png"
import { PocketAgencyContext } from '../../context/PocketAgencyContext'


const clients = require('../../config/index').config


const PreviewOptionsWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 2rem;

   
`

const PreviewButtons = styled.div`
   .OnStat{
     box-shadow: ${p => p.selected ? p.theme.in : p.theme.out};

    //  background-color: ${p => p.selected ? '#000' :'${(p) => p.theme.out}' } ;
    }
`

const PreviewAdLeft = styled(PageAlwaysVisible)`
  padding-top: 4rem;
  display: flex;
  justify-content: center;
  align-items: flex-start;

`
const PreviewAdWrapper = styled.div`
  background: ${p => p.theme.low};
  padding: 2rem;
  border-radius: var(--normalRadius);
`
const PreviewAdTitle = styled.div`
  h4{
    padding-bottom: 1rem;
  }
`
const AdError = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  grid-gap: .5rem;
  border-radius: .5rem;
  padding: var(--normalPads);
  border: 2px solid var(--error);
  a {
    text-decoration: underline;
    font-weight: 900;
    color: ${(p)=> p.theme.color}
  }

  h6{
    color: var(--error);
  }

  svg {
    fill: var(--error);
    width: 2rem;
  }
`

export default function PREVIEW_AD() {
    const [previews, setPreviews] = useState([])
    const [loading, setLoading] = useState(false)
    const [partialLoading, setPartialLoading] = useState(false);
    const [selectedPreview, setSelectedPreview] = useState({});
    const {alerts, setAlerts} = useContext(AlertContext);
    const {pocketAgencyContext, setPocketAgencyContext} = useContext(PocketAgencyContext)

    const {questId} = useParams();

    const [adInfo, setAdInfo] = useState({caption:"", title: "",cta:"", media:""})
    const {t} = useTranslation();
    const navigate = useNavigate();
    const {lang} = useParams();

    const fbPage = store.getState().metaSource.page_selection_info.id; 
    const accessToken = store.getState().metaSource?.facebook_user?.accessToken; 
    const user = store.getState()?.user?.user;
    const isKnight = user?.roles?.includes('Knight');
    const [slider,toggleSlider] = useState(false);

    const [error, setError] = useState('');
 
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);




    const [products, setProducts] = useState()


    const get_products_from_product_set = () => {
        // TODO: check for user mode
        if (adInfo?.questId && adInfo?.mode == 'productCatalog') {
          console.log('this is the adinfo', adInfo)
          q.getProductsFromProductSet(adInfo?.questId, adInfo?.productSetId)
            .then(res => {
              if (!["Q2010", "Q2011"]?.includes(res?.data?.code)) {
                alertMessage(alerts, setAlerts, res?.data?.msg, 'Error')
              }
              else {
                setProducts(res?.data?.products);
              }
            })
          }
      }
  
    



    const check_for_ad = () => {
      a.checkForAd()
      .then(res => {
          if (res?.data?.ad_exists) get_my_ad(res?.data?.questId)
          else setAdInfo({...adInfo, questId: res?.data?.questId});
      })
      .catch(err => console.log(err))
  }
    const get_my_ad = (questId) => {
      a.getMyAd(questId)
      .then(res => {
           console.log('adsfgd', res.data?.ad)
          if (res?.data?.code == "A2002") {
              console.log('++++++++++++++++++++')
              setAdInfo(load_my_ad(res?.data?.ad));
          }
      })
  }
    console.log('user?.user?.roles', isKnight);

    const editAdCreative = () => {

      setLoading(true);
      m.editAdCreative(questId, adInfo?.mode)
        .then(res => {
          if (res?.data?.code !== 'C2007' && res?.data?.code !== '2002') {
            setError(res?.data);
          }
          else {
            alertMessage(alerts, setAlerts, (t('Campaigns edited succesfully!')),'Success');
            navigate(`/${lang}/dashboard`)
          }
          setLoading(false);
        }) 
    }

    const createCampaign = () => {

      setLoading(true);

      // Checks campaigns one-by-one for errors
      m.createCampaign()
        .then(res => {
          console.log('RESSSSSSSSSS', res?.data);
          if (res?.data?.code !== '2002') {
            setError(res?.data);
          }
          else if (res?.data?.data?.length && !res?.data?.data[0]?.campaign_id) {
         //   alertMessage(alerts, setAlerts, res?.data?.data[0]?.msg,'Error');
            setError(res?.data?.data[0])
          }
          else if (res?.data?.data?.length > 1 && !res?.data?.data[1]?.campaign_id) {
          //  alertMessage(alerts, setAlerts, res?.data?.data[1]?.msg,'Error');
            setError(res?.data?.data[1])

          }
          else if (res?.data?.data?.length > 2 && !res?.data?.data[2]?.campaign_id) {
            //alertMessage(alerts, setAlerts, res?.data?.data[2]?.msg,'Error');
            setError(res?.data?.data[2])

          }
          else {
            alertMessage(alerts, setAlerts, res?.data?.msg || (t('Campaigns created succesfully!')), 'Success');
            if (isKnight || pocketAgencyContext === 'E2000') navigate(`/${lang}/dashboard`)
            else window.location.assign(`${clients['griphook']}/${lang}/questad-checkout/?category=dailyprofit&interval=month&quest=${res?.data?.questId}`);
          }
          setLoading(false);
        })
    }

    const handlePreview = (preview) => {
      setSelectedPreview(preview);
      if (windowWidth < 992) toggleSlider(true)
    };

    //to keep 
    let preview_format_array = [{title:(t('Desktop feed standard')), width: 500, height:709},
      {title:(t('Feed mobile')), width: 320, height:540}, 
      {title:(t('Facebook reels mobile')), width:274, height:567}, 
      {title:(t('Facebook Story Mobile')), width:318, height:565}, 
      {title:(t('Messenger mobile inbox')), width: 375, height:760},
      {title:(t('Facebook Reels Mobile')), width:320, height:567}, 
      {title:(t('Messenger mobile story')), width:318, height:566},
    ];

    //for delete
      let previewsIframe = previews?.map((preview, index) => {
        return {
            ...preview,
            title: preview_format_array[index]?.title,
            width: preview_format_array[index]?.width,
            height: preview_format_array[index]?.height,
        };
    });


  const fbPreviews = [
    { value: 'feedStandard', title: (t('Desktop feed standard')) },
    { value: 'feedMob', title: (t('Feed mobile')) },
    { value: 'fbReelsMob', title: (t('Facebook reels mobile')) },
    { value: 'fbStoryMob', title: (t('Facebook story mobile')) },
    { value: 'messMobInbox', title: (t('Messenger mobile inbox')) },
    { value: 'messMobStory', title: (t('Messenger mobile story')) },
  ];

  const instaPreviews = [
    { value: 'instaFeed', title: (t('Instagram feed')) },
    { value: 'instaReels', title: (t('Instargram reels')) },
    { value: 'instaStories', title: (t('Instagram stories')) },
  ];


  const fbProductPreviews = [
    { value: 'productFeed', title: (t('Desktop feed')) },
    { value: 'productFeedMob', title: (t('Mobile feed')) },
    { value: 'productReels', title: (t('Reels')) },
    { value: 'productStory', title: (t('Stories')) },
  ]


  const instaProductPreviews = [
    { value: 'instaProductFeed', title: (t('Instagram feed')) },
    { value: 'instaProductReels', title: (t('Instargram reels')) },
    { value: 'instaProductStory', title: (t('Instagram stories')) },
  ]


  const renderPreviewComponent = () => {
    switch (selectedPreview?.value) {
      case 'feedStandard':
        return <Desktop_Feed_Standard pageLogo={FbLogo} adInfo={adInfo} />;
      case 'feedMob':
        return <Feed_Mobile pageLogo={FbLogo} adInfo={adInfo}  />;
      case 'fbReelsMob':
        return <Facebook_Reels_Mobile pageLogo={FbLogo} adInfo={adInfo} />;
      case 'fbStoryMob':
        return <Facebook_Story_Mobile pageLogo={FbLogo} adInfo={adInfo}  />;
      case 'messMobInbox':
        return <Messenger_Mobile_Inbox pageLogo={FbLogo} adInfo={adInfo} />;
      case 'messMobStory':
        return <Messenger_Mobile_Story pageLogo={FbLogo} adInfo={adInfo} />;
      case 'instaFeed':
        return <Instagram_Mockup_Feed pageLogo={FbLogo} adInfo={adInfo}  />;
      case 'instaReels':
        return <Instagram_Mockup_Reels pageLogo={FbLogo} adInfo={adInfo} />;
      case 'instaStories':
        return <Instagram_Mockup_Stories pageLogo={FbLogo} adInfo={adInfo} />;
      default:
        return <Desktop_Feed_Standard pageLogo={FbLogo} adInfo={adInfo} />;
    }
  };



  const renderPreviewProductComponent = () => {
    switch (selectedPreview?.value) {
      case 'productFeed':
        return <Desktop_Feed_Products questIdInUrl={questId ? true : false} products={products} adInfo={adInfo} pageLogo={FbLogo} />;
      case 'productFeedMob':
        return <Mobile_Feed_Products questIdInUrl={questId ? true : false} products={products} adInfo={adInfo}  pageLogo={FbLogo} />;
      case 'productReels':
        return <Facebook_Product_Reels questIdInUrl={questId ? true : false}  products={products} adInfo={adInfo} pageLogo={FbLogo} />;
      case 'productStory':
        return <Facebook_Product_Story questIdInUrl={questId ? true : false} products={products} adInfo={adInfo} pageLogo={FbLogo} />;
      case 'instaProductFeed':
        return <Instagram_Product_Feed questIdInUrl={questId ? true : false} products={products} adInfo={adInfo} pageLogo={FbLogo}  />;
      case 'instaProductReels':
        return <Instagram_Product_Reels questIdInUrl={questId ? true : false} products={products} adInfo={adInfo} pageLogo={FbLogo} />;
      case 'instaProductStory':
        return <Instagram_Product_Stories questIdInUrl={questId ? true : false} products={products} adInfo={adInfo} pageLogo={FbLogo} />;
    
      default:
        return <Desktop_Feed_Products products={products} adInfo={adInfo}  pageLogo={FbLogo}/>;
    }
  };
 
 
    // Set to the first object in previewsIframe
    useEffect(() => {
     if (adInfo?.flow === 'eshop' && adInfo?.mode === 'productCatalog') 
      setSelectedPreview(fbProductPreviews?.length > 0 ? fbProductPreviews[0] : {}); 
    else setSelectedPreview(fbPreviews?.length > 0 ? fbPreviews[0] : {}); 
  }, [adInfo?.flow]);

  useEffect(()=>{
    if (!questId) check_for_ad();
    // preview_ad();
    get_my_ad(questId);
  },[])


  
  useEffect(() => {
    get_products_from_product_set();
  }, [adInfo?.questId, adInfo?.productSetId])

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
        
  // console.log('alerts', alerts);
   console.log('adInfo---------_____', adInfo);
  // console.log('selectedPreview---------', selectedPreview);
  console.log('error---------', error);
  //                 isPocketAgencyUser = {pocketAgencyContext === 'E2000' ?  true : false}
  console.error('CONTEXT', pocketAgencyContext)

  if (loading) return <Main_Loader />
  if (partialLoading) return <Main_Loader />
  return (
    <PageForty>
      <Alert_
          messages={alerts} 
          duration = {6000}
      />
      <PageAlwaysVisible>
        <PageTitle>
          {/* <Grey_Link iconLeft="Back" onClick={() => navigate(-1)} noPadding /> */}
          <h1>{t('Ad preview')}</h1>
        </PageTitle>   
        <PageSubtitle>
          <p>{t("An overview of your ad on multiple social media placements")}</p>
        </PageSubtitle>

        {error ? 
        <AdError>
            <IconSvg Icon ='Tip' />
            <h6>{error?.msg} {error?.link ? <a href={error?.link} target="_blank">{t("Click here to accept the terms")}</a>: <></>}</h6>
          </AdError> 
         :   null } 
        <Section_Title text={t("Facebook placements")} />
            <PreviewOptionsWrapper>
            { adInfo?.flow === 'eshop' && adInfo?.mode === 'productCatalog' ? 

              fbProductPreviews?.map((preview, index) => (
                <PreviewButtons selected={selectedPreview?.value === preview?.value}>
                  <On_Stat title={preview?.title}  onClick={() => handlePreview(preview)}/>
                </PreviewButtons>
              ))

            :  
              fbPreviews?.map((preview, index) => (
                <PreviewButtons selected={selectedPreview?.value === preview?.value}>
                    <On_Stat title={preview?.title}  onClick={() => handlePreview(preview)}/>
                </PreviewButtons>           
                ))
              }
            </PreviewOptionsWrapper>

            <Section_Title text={t("Instagram placements")} />
              <PreviewOptionsWrapper>
                  { adInfo?.flow === 'eshop' && adInfo?.mode === 'productCatalog' ?  
                  
                    instaProductPreviews?.map((preview, index) => (
                    <PreviewButtons selected={selectedPreview?.value === preview?.value}>
                      <On_Stat title={preview?.title}  onClick={() => handlePreview(preview)}/>
                    </PreviewButtons>
                  ))
                : 
                  instaPreviews?.map((preview, index) => (
                    <PreviewButtons selected={selectedPreview?.value === preview?.value}>
                      <On_Stat title={preview?.title} selected={selectedPreview?.value === preview?.value} onClick={() => handlePreview(preview)}/>
                    </PreviewButtons>
                  ))
                }
              </PreviewOptionsWrapper>


        <Actions_>
          <Grey_ text={t("Back")} iconLeft="Back" onClick={() => navigate(-1)} noPadding />
          {/* <Main_Plain iconLeft="Edit" text={t('Edit Ad')} onClick={() => navigate(`/${lang}/edit-ad/${adInfo?.questId}`)} noPadding /> */}
          <Main_ text={questId || pocketAgencyContext === 'E2000' ? (t('Done')) : (t('Proceed to Payment'))}  iconRight='Next' onClick={() => questId?.length ? editAdCreative(): createCampaign()} />
        </Actions_>




      </PageAlwaysVisible>

          {windowWidth < 992 ? (
            slider &&
               <Slider_
                title = {selectedPreview?.title}
                done = {t('Done')}
                onClick = {() => toggleSlider(false)}
                onClose = {() => toggleSlider(false)}
               >
                 {adInfo?.mode === 'productCatalog' ? 
                                <Desktop_Feed_Products adInfo={adInfo}/>

                : renderPreviewComponent()
                 } 
              </Slider_>


          ) : (
            <PreviewAdLeft>
            {adInfo?.flow === 'eshop' && adInfo?.mode === 'productCatalog' ? 

               <PreviewAdWrapper>
                  <PreviewAdTitle>
                   <h4>{selectedPreview.title}</h4>
                  </PreviewAdTitle>
                {/* <Desktop_Feed_Products adInfo={adInfo} /> */}
                  {/* <Mobile_Feed_Products adInfo={adInfo} /> */}
                  {renderPreviewProductComponent()}
               </PreviewAdWrapper>

            :  <PreviewAdWrapper>
                  <PreviewAdTitle>
                    <h4>{selectedPreview.title}</h4>
                  </PreviewAdTitle>
                  {renderPreviewComponent()}
              </PreviewAdWrapper>
            }
            </PreviewAdLeft>
        )}
        </PageForty>
  )
}
