import axios from 'axios';
import store from '../../redux/store';

const protocol = process.env.REACT_APP_DAILYPROFIT_BACKEND_PROTOCOL;
const url = process.env.REACT_APP_DAILYPROFIT_BACKEND_HOST;
const port = process.env.REACT_APP_DAILYPROFIT_BACKEND_PORT;

const hasQuestAdSubscription  = () => {

    return new Promise((resolve, reject) => {

        axios.get(`${protocol}${url}${port}/quests/hasQuestAdSubscription`)
        .then(res => {
            resolve(res);
        })
        .catch(() => {
            console.error('CHECK FOR QUESTAD SUBSCRIPTION')
            resolve(false);
        })
    })
}


const getDraft = (questId) => {

    return new Promise((resolve, reject) => {

        let params = ``
        if(questId) params = `?questId=${questId}`

        axios.get(`${protocol}${url}${port}/quests/getDraft${params}`)
        .then(res => {
            resolve(res);
        })
        .catch(() => {
            console.error('GET DRAFT')
            resolve(false);
        })
    })
}

const getMyQuest = (questId) => {

    return new Promise((resolve, reject) => {

        axios.get(`${protocol}${url}${port}/quests/getMyQuest?questId=${questId}`)
        .then(res => {
            resolve(res);
        })
        .catch(() => {
            console.error('GET MY QUEST')
            resolve(false);
        })
    })
}

const getQuest = (questId) => {

    return new Promise((resolve, reject) => {

        axios.get(`${protocol}${url}${port}/quests/getQuest?id=${questId}`)
        .then(res => {
            resolve(res);
        })
        .catch(() => {
            console.error('GET QUEST')
            resolve(false);
        })
    })
}

const getQuests = (userId) => {

    return new Promise((resolve, reject) => {

        axios.get(`${protocol}${url}${port}/quests/getAllQuests?userId=${userId}`)
        .then(res => {
            resolve(res);
        })
        .catch(() => {
            console.error('GET ALL QUESTS')
            resolve(false);
        })
    })
}

const getUsers = () => {

    return new Promise((resolve, reject) => {

        axios.get(`${protocol}${url}${port}/quests/getAllUsers`)
        .then(res => {
            resolve(res);
        })
        .catch(() => {
            console.error('GET ALL USERS')
            resolve(false);
        })
    })
}

const getMyQuests = () => {

    return new Promise((resolve, reject) => {

        axios.get(`${protocol}${url}${port}/quests/getMyQuests`)
        .then(res => {
            resolve(res);
        })
        .catch(() => {
            console.error('GET MY QUESTS')
            resolve(false);
        })
    })
}

const getMyQuestsWithKPIS = (onlyLeads) => {

    const access_token = store.getState().metaSource?.facebook_user?.accessToken;


    return new Promise((resolve, reject) => {

        axios.get(`${protocol}${url}${port}/quests/getMyQuestsWithKPIS?onlyLeads=${onlyLeads}&token=${access_token}`)
        .then(res => {
            resolve(res);
        })
        .catch(() => {
            console.error('GET MY QUESTS')
            resolve(false);
        })
    })
}


const deleteDraftQuest = (questId) => {

    return new Promise((resolve, reject) => {

        axios.post(`${protocol}${url}${port}/quests/deleteDraftQuest?questId=${questId}`)
        .then(res => {
            resolve(res);
        })
        .catch(() => {
            console.error('DELETE DRAFT QUEST')
            resolve(false);
        })
    })
}

const getQuestionnaireB = (questId) => {

    return new Promise((resolve, reject) => {

        axios.post(`${protocol}${url}${port}/quests/getQuestionnaireB?questId=${questId}`)
        .then(res => {
            resolve(res);
        })
        .catch(() => {
            console.error('GET QUESTIONNAIRE B')
            resolve(false);
        })
    })
}

const editQuest = (questionnaire, questId, eShopUrl, business_id, ad_account_id) => {

    let facebookPageId = store.getState()?.metaSource?.page_selection_info?.id || false;
    let facebookPageName = store.getState()?.metaSource?.page_selection_info?.name || false;
    let facebookPagePicture = store.getState()?.metaSource?.page_selection_info?.picture || false;
    let instagramAccountId = store.getState().metaSource?.instagramAccount || false;
    let instagramAccountUsername = store.getState().metaSource?.instagramAccountUsername || false;

    const token = store.getState().metaSource?.facebook_user?.accessToken;
   
    let Meta = {
        facebookPageId,
        facebookPageName,
        facebookPagePicture,
        instagramAccountId,
        instagramAccountUsername
    }

    return new Promise((resolve, reject) => {

        axios.post(`${protocol}${url}${port}/quests/editQuest`, {questionnaire:questionnaire, questId:questId, Meta, eShopUrl, business_id, ad_account_id, token: token})
        .then(res => {
            resolve(res);
        })
        .catch(() => {
            console.error('EDIT QUEST')
            resolve(false);
        })
    })
}


const editQuestMode = (data, questId) => {
    // let mode = data;

    // let Meta = {
    //  mode
    // }

    return new Promise((resolve, reject) => {

        axios.post(`${protocol}${url}${port}/quests/editQuest`,data )
        .then(res => {
            resolve(res);
        })
        .catch(() => {
            console.error('EDIT QUEST MODE')
            resolve(false);
        })
    })
}
const adminEditQuest = (data) => {

    return new Promise((resolve, reject) => {

        axios.post(`${protocol}${url}${port}/quests/adminEditQuest`, data)
        .then(res => {
            resolve(res);
        })
        .catch(() => {
            console.error('ADMIN EDIT QUEST')
            resolve(false);
        })
    })
}

const topUpQuestBudgetandRestart = (questId, customer, newBudget, newBudgetWithTax) => {
    return new Promise((resolve, reject) => {

        axios.post(`${protocol}${url}${port}/quests/topUpQuestBudgetandRestart`, {
            _id: questId,
            newBudget: newBudget,
            newBudgetWithTax: newBudgetWithTax,
            customer: customer,
        })
        .then(res => {
            resolve(res);
        })
        .catch(() => {
            console.error('TOP UP QUEST BUDGET')
            resolve(false);
        })
    })
}

const topUpQuestBudgetV2 = (questId, newBudget, subscription) => {
    console.log("questId", questId, "newBudget", newBudget, "subscription",subscription)
        return new Promise((resolve, reject) => {
    
            axios.post(`${protocol}${url}${port}/quests/topUpQuestBudgetV2`, {
                _id: questId,
                amount: newBudget,
                subId: subscription
            })
            .then(res => {
                resolve(res);
            })
            .catch(() => {
                console.error('TOP UP QUEST BUDGET')
                resolve(false);
            })
        })
    }
    
const calculateTaxAmount = (customerId, amount) => {
    console.log("customerId", customerId);

        return new Promise((resolve, reject) => {
    
            axios.post(`${protocol}${url}${port}/quests/calculateTopUpTax`, {
                customerId: customerId,
                amount: amount
            })
            .then(res => {
                resolve(res);
            })
            .catch(() => {
                console.error('TOP UP QUEST BUDGET TAX CALC')
                resolve(false);
            })
        })
    }
    
const healthCheckQuests = () => {

    return new Promise((resolve, reject) => {

        const access_token = store.getState().metaSource?.facebook_user?.accessToken;

        axios.get(`${protocol}${url}${port}/quests/healthCheckQuests?token=${access_token}`)
        .then(res => {
            resolve(res);
        })
        .catch(() => {
            console.error('HEALTH CHECK QUESTS')
            resolve(false);
        })
    })
}

const stop_Ads = (customer, redirect) => {

    return new Promise((resolve, reject) => {

        axios.post(`${protocol}${url}${port}/quests/stopQuestSubscription`, {customer:customer, redirect: redirect})
        .then(res => {
            resolve(res);
        })
        .catch(() => {
            console.error('HEALTH CHECK QUESTS')
            resolve(false);
        })
    })
}

const getProductsFromProductSet = (questId, productSetId) => {

    const access_token = store.getState().metaSource?.facebook_user?.accessToken;

    return new Promise((resolve, reject) => {

        let data = {
            questId: questId ? questId : false,
            productSetId: productSetId ? productSetId: false,
            token: access_token
        }

        axios.post(`${protocol}${url}${port}/meta/getProductsFromProductSet`, data)
        .then(res => {
            resolve(res);
        })
        .catch(() => {
            console.error('GET PRODUCTS FROM PRODUCT SET');
            resolve(false);
        })
    })
}

const getNotifications = (questId) => {

    return new Promise((resolve, reject) => {

        axios.get(`${protocol}${url}${port}/quests/getNotifications?questId=${questId}`)
        .then(res => {
            resolve(res);
        })
        .catch(() => {
            console.error('GET NOTIFICATIONS')
            resolve(false);
        })
    })
}

const getAdminNotifications = (questId) => {

    return new Promise((resolve, reject) => {

        axios.get(`${protocol}${url}${port}/quests/getAdminNotifications?questId=${questId}`)
        .then(res => {
            resolve(res);
        })
        .catch(() => {
            console.error('GET ADMIN NOTIFICATIONS')
            resolve(false);
        })
    })
}


const getAllNotifications = () => {

    return new Promise((resolve, reject) => {

        axios.get(`${protocol}${url}${port}/quests/getAllNotifications`)
        .then(res => {
            resolve(res);
        })
        .catch(() => {
            console.error('GET NOTIFICATIONS')
            resolve(false);
        })
    })
}

const markAsRead = (notificationIds) => {

    return new Promise((resolve, reject) => {

        axios.post(`${protocol}${url}${port}/quests/markAsRead`, {notificationIds})
        .then(res => {
            resolve(res);
        })
        .catch(() => {
            console.error('MARK AS READ')
            resolve(false);
        })
    })
}

const createNotification = (notification) => {
    console.log('NOTIFFF', notification);
    return new Promise((resolve, reject) => {

        axios.post(`${protocol}${url}${port}/quests/createNotification`, notification)
        .then(res => {
            resolve(res);
        })
        .catch(() => {
            console.error('CREATE NOTIFICATION')
            resolve(false);
        })
    })
}


const deleteNotification = (notificationId, questId) => {

    return new Promise((resolve, reject) => {

        axios.post(`${protocol}${url}${port}/quests/deleteNotification?questId=${questId}&notificationId=${notificationId}`)
        .then(res => {
            resolve(res);
        })
        .catch(() => {
            console.error('DELETE NOTIFICATION')
            resolve(false);
        })
    })
}


const ownsPocketAgency = () => {

    return new Promise((resolve, reject) => {

        axios.get(`${protocol}${url}${port}/quests/ownsPocketAgency`)
        .then(res => {
            resolve(res);
        })
        .catch(() => {
            console.error('OWNS POCKET AGENCY')
            resolve(false);
        })
    })
}

const isOldBudgetUser = () => {

    return new Promise((resolve, reject) => {

        axios.get(`${protocol}${url}${port}/quests/isOldBudgetUser`)
        .then(res => {
            resolve(res);
        })
        .catch(() => {
            console.error('IS OLD BUDGET USER')
            resolve(false);
        })
    })
}

const questEndpoints = {
    getDraft,
    getMyQuest,
    getMyQuests,
    getQuest,
    getQuests,
    getUsers,
    getQuestionnaireB,
    editQuest,
    editQuestMode,
    adminEditQuest,
    hasQuestAdSubscription,
    deleteDraftQuest,
    healthCheckQuests,
    stop_Ads,
    getMyQuestsWithKPIS,
    getProductsFromProductSet,
    topUpQuestBudgetandRestart,
    getNotifications,
    getAdminNotifications,
    getAllNotifications,
    markAsRead,
    createNotification,
    deleteNotification,
    topUpQuestBudgetV2,
    ownsPocketAgency,
    isOldBudgetUser,
    calculateTaxAmount
}

export default questEndpoints;
