import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { History_Notification_Box } from './History_Notification_Box'
import q from '../../../services/api/Quests'

const DashboardHistoryNotifications = styled.div`
  h3{
    padding-bottom: 1rem;
  }
`

const DashboardNotificationsList = styled.ul`

  li{
    margin-bottom: 2rem;
  }

`
export const Dashboard_History_Notifications = (props) => {
  const {t} = useTranslation();

  const test_markasread_endpoint = (ids) => {
    q.markAsRead(ids).then(res => console.log('asdfdgf', res))
  }

  const test = props.quests.find(q => q._id === '6617e2c87c252b1940908219');

  // useEffect(() => {
  //   test_markasread_endpoint([props?.notifications[1]?._id, props?.notifications[0]?._id])
  // },[])
  // get_all_notifications();

  const mark_as_read = (id) => {
    let ids = [];
    ids.push(id);

    q.markAsRead(ids).then(res => {
      props.get_all_notifications() 
      console.log('asdfdgf', res)
     })

  }


  console.log('edw', test);
  return (
    <DashboardHistoryNotifications>
      <h3>{t('Notifications')}</h3>
      <DashboardNotificationsList>
        {props.notifications?.map(notification => (
          <History_Notification_Box onClick={()=>mark_as_read(notification?._id)} quest={props.quests.find(q => q._id === notification?.questId)}  notification={notification} />
       ))}
    </DashboardNotificationsList>
    </DashboardHistoryNotifications>
  )
}
