import React from 'react'
import styled from 'styled-components'
import { Cta_, device, H2, H5, Main_Plain, P } from 'monica-alexandria';
import { useTranslation } from 'react-i18next';

const HomePricing = styled.section`
`

const HomePricingGrid = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 4rem;

    p{
       max-width: 50rem;
    }

    @media ${device.lg} {
      grid-template-columns: 1fr;
    }
    
`;

const HomePricingGridTitle = styled.div`
  .MainPlain{
      margin-top: 2rem;
    }
`;

const HomePricingGridImg = styled.div`
    img{
        width: 90%;
        display: block;
        margin: 0 auto;
    }
`;




const HomePricingGridText = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    padding-top: 4rem;
    grid-gap: 4rem;
     h5{
        /* padding-bottom: 8rem; */
    }
    p{
        opacity: 0.8;
    }
`;
export default function Home_Pricing(props) {
    const  {t} = useTranslation();
  return (
    <HomePricing>
        <HomePricingGrid>
       
            <HomePricingGridTitle>
                <H2>{t('How are ads paid for on Questad?')} </H2>
                <P>{t("Questad uses a simple, transparent commission model with a flat 13% rate, regardless of your advertising budget. Payments are automatically processed every 30 days, covering the total prepaid amount, which includes your advertising budget, the 13% commission, and VAT. You'll receive a detailed invoice for each billing cycle, ensuring full visibility of costs.")}</P>
                <Main_Plain noPadding iconRight='Next' text={t("Start now")} size='large' onClick={props.onStart}/>

            </HomePricingGridTitle>
            <HomePricingGridImg>
                <img src='https://genius1071.friktoriaservers.net/doitforme/morning/app/tarzan/pricing/PricingCommission.png'/>
            </HomePricingGridImg>
           
        </HomePricingGrid>
    </HomePricing>
  )
}
