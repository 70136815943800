import React from 'react'
import styled from 'styled-components'
import Dashboard_Quest from '../Dashboard_Quest'
import Archived_Old from './Archived_Old'

const DashboardArchived = styled.div``

export default function Dashboard_Archived(props) {
  return (
    <DashboardArchived>
        <Archived_Old 
          type={props.type}
          archivedOpenLeads={props.archivedOpenLeads}
          archivedQuests={props.archivedQuests}
          archivedSetSelectedQuest={props.archivedSetSelectedQuest}
          archivedOpenBudget={props.archivedOpenBudget}
        />
    </DashboardArchived>
  )
}
