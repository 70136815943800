import React from 'react'
import styled from 'styled-components'
import { IconSvg, device } from 'monica-alexandria'
import ReactPlayer from 'react-player'
import store from '../../../redux/store'
import { DailyprofitSvg } from '../../../svg/DailyprofitSvg'
import { findLabelByValue } from '../../../utils/findCtaLabel'
import { useTranslation } from 'react-i18next'
import NoImg from '../../../assets/ad/NoImgAd.jpg'
import { useParams } from 'react-router-dom'

const FeedMobile = styled.div`
    max-width: 32rem;
    width: 100%;
  //  max-height: 56.7rem;
    box-shadow: ${p => p.theme.out};
    border-radius: var(--smallRadius);
    background: #fff;

    p{
        font-size: 1.4rem;
    }
    h1,h2,h3,h4,h5,h6,p{
        color: black;
    }

`

const AdMockupTitle = styled.div`
  h4{
    font-size: 1.5rem  ;
    padding: 0.3rem 0;
    color: #000;
  }

  p{
    font-size: 1.2rem;
  }
`
const AdMockButton = styled.div`
    background-color: #E4E6EB;
    padding:0.7rem 1rem ;
    border-radius: 0.7rem;
    text-align: center;

    h5{
        color: black;
    }
`

const AdMockupCaption = styled.div`
    padding: var(--normalPads);
    white-space: pre-wrap;
    word-break: break-word;
`

const AdMockupSection = styled.div`
    background:#F7F8FA;
    padding: 1.5rem 1rem;
    z-index: 1;
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
`


const AdMockupTop = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: var(--normalPads);

`

const AdMockupPage = styled.div`
   
    display: flex;
    gap: 1rem;

    img{
        border: 1px solid var(--grey);
        border-radius: 50%;
        width: 3.5rem;
        height: 3.5rem;
    }
`

const AdMockupTopText = styled.div`
    h4{
        font-size: 1.5rem;
    }
    p{
        font-size: 1.2rem;
    }
`
const AdMockupTopActions = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;

    svg{
        width: 2rem;
        fill: black;
    }
    .fbOptions{
        transform: rotate(90deg );
    }
`

const AdMockupImage = styled.div`

    img{
        max-height : 32rem;
        width: 100%;
        object-fit: cover;
    }
`

const AdMockupActions = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding: 1rem 0rem;
    border-top: 1px solid var(--grey);
`

const AdMockupActionItem = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;

    .fbLike{
        width: 1.8rem;
        fill: #606770;
        
    }

    .fbComment {
        width: 1.8rem;
        stroke: #606770;
        stroke-width: 1.2;
        fill: none;
    }   

    .fbShare{
        width: 3.2rem;
        stroke: #606770;
        stroke-width: 1.2;
        fill: none;
    }
    h5{
        color: #606770;
    }
`
const minioUrl = process.env.REACT_APP_MINIO_PROTOCOL +
                process.env.REACT_APP_MINIO_HOST + 
                process.env.REACT_APP_MINIO_PORT+ '/' +
                process.env.REACT_APP_MINIO_BUCKET + '/';

const dailyprofitUrl = process.env.REACT_APP_DAILYPROFIT_FRONTEND_PROTOCOL +
        process.env.REACT_APP_DAILYPROFIT_FRONTEND_HOST + 
        process.env.REACT_APP_DAILYPROFIT_FRONTEND_PORT;

const ogTitle = process.env.REACT_APP_OG_TITLE;

export const Feed_Mobile = (props) => {

    const facebook_page = store.getState().metaSource?.page_selection_info;
    const userId = store.getState()?.user?.user?.sub;
    const {t} = useTranslation();
    const {questId} = useParams();


  return (
    <FeedMobile>
        <AdMockupTop>
            <AdMockupPage>
                <img src={props.pageLogo} />
            <AdMockupTopText>
                <h4>{questId ? props?.adInfo?.facebookPageName :facebook_page?.name}</h4>
                <p>Sponsored</p>
                </AdMockupTopText>
            </AdMockupPage>
            <AdMockupTopActions>
                <IconSvg Icon='Xicon' />
                <DailyprofitSvg Icon='InstaOptions' class_Icon='fbOptions'/>
            </AdMockupTopActions>
        </AdMockupTop>
        <AdMockupCaption>
            <h5>{props.adInfo?.caption && props.adInfo.caption?.length > 500 ? props.adInfo.caption?.substring(0, 500) + '...' : props.adInfo.caption}</h5>
        </AdMockupCaption>
        <AdMockupImage>
            {props?.adInfo?.isVideo ? (
                <ReactPlayer
                    url={props.adInfo.file?.preview ? props.adInfo.file?.preview : (props?.adInfo.visualElement ? minioUrl+userId+'/'+props?.adInfo?.visualElement : '')}
                    light={props?.adInfo?.thumbnail !== "default.jpg"  ? <img src={props?.adInfo?.thumbnail} alt='Thumbnail' /> : false}
                    width="100%"
                    height="100%"  //might need change
                    controls={true} 
                    playing={false}
                    //config={facebookConfig}
                />
            ):(
                <img src={props.adInfo.file?.preview ? props.adInfo.file?.preview : (props?.adInfo.visualElement ? minioUrl+userId+'/'+props?.adInfo?.visualElement : NoImg)} />
            ) }
        </AdMockupImage>
        <AdMockupSection>
            <AdMockupTitle>
                <p>{dailyprofitUrl}</p>
                {/* <h4>{props.adInfo?.title}</h4> */}
                <h4>{props.adInfo?.title && props.adInfo.title.length > 10 ? props.adInfo.title.substring(0, 15) + '...' : props.adInfo.title}</h4>
                <p>{ogTitle}</p>
            </AdMockupTitle>
            <AdMockButton>
                <h5>{findLabelByValue(props.adInfo?.cta,t)}</h5>
            </AdMockButton>
        </AdMockupSection>
     
        <AdMockupActions>
            <AdMockupActionItem>
                <DailyprofitSvg Icon='FbLike' class_Icon='fbLike'/>
                <h6>Like</h6>
            </AdMockupActionItem>
            <AdMockupActionItem>
                <DailyprofitSvg Icon='FbComment' class_Icon='fbComment'/>
                <h6>Comment</h6>
            </AdMockupActionItem>
            <AdMockupActionItem>
                <DailyprofitSvg Icon='FbShare' class_Icon='fbShare' />
                <h6>Share</h6>
            </AdMockupActionItem>
        </AdMockupActions>
    </FeedMobile>
  )
}
