import { t } from 'i18next'
import React, { useState } from 'react'
import styled from 'styled-components'

const DashboardNotificationBox = styled.li`
  padding: var(--normalPads);
  border-radius: var(--smallRadius);
  background: ${p => p.theme.low};
  margin-top: 1rem;
  cursor: pointer;
 // opacity: ${p => p.new ? '1' : '.6'};

  h6,h5,p{
    display: inline-block;
     opacity: ${p => p.new ? '1' : '.6'};
  }
  
`

const DashboardNotificationBoxNew = styled.h6`
  padding: var(--smallPads);
  border-radius: var(--normalRadius);
  background: ${p => p.theme.mid};
  margin-right: 1rem;
`

const DashboardNotificationBoxStamp = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: .5rem;
`

const DashboardNotificationInner = styled.div`
  display: flex;
  align-items: flex-start;
`

export default function Dashboard_Notification_Box(props) {
  const [read,setRead] = useState(false);
  return (
    <DashboardNotificationBox onClick={()=>{setRead(true); window.open(`https://www.facebook.com/doitforme.eu/posts/pfbid05hHBBJ1QJXiwmThZ1PQPt44LTReAEM85tj6BCzPfrDYBMa89KtnWXr3KpHHeiuPel?notif_id=1713682513030980&notif_t=feedback_reaction_generic&ref=notif`);}} new={!read}>
      <DashboardNotificationInner>
        {!read ?
          <DashboardNotificationBoxNew>{t("NEW")}</DashboardNotificationBoxNew>
        :
          null
        }
        <div>
            {props.comment ? 
               <div> <h5>{props.name}</h5>  <p>commented on your</p> <h5>ad</h5> </div>
            : 
              <div> <h5>Ad reaction</h5> <p>by</p> <h5>{props.name}</h5> </div>
            }
            <h6>Doitforme.eu</h6>
          </div>
        </DashboardNotificationInner>
        <DashboardNotificationBoxStamp >
          <h6>{props?.time}</h6>
        </DashboardNotificationBoxStamp>
    </DashboardNotificationBox>
  )
}
