import { AlertContext, CardsSixty, Main_Text_Loader, On_Click_Card, PageAlwaysVisible, Sixty_Card_Loader, Slider_ } from 'monica-alexandria';
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import a from '../../services/api/Ads';
import store from '../../redux/store';
import { alertMessage } from '../../utils/messagesAlerts';
import NoImg from '../../assets/ad/NoImgAd.jpg'


const minioUrl = process.env.REACT_APP_MINIO_PROTOCOL +
                process.env.REACT_APP_MINIO_HOST + 
                process.env.REACT_APP_MINIO_PORT+ '/' +
                process.env.REACT_APP_MINIO_BUCKET + '/';


export const Ad_Templates = ({adInfo,adsLoading, ads,setAds,setShowTemplates, questId}) => {

    const {t} = useTranslation();
   // const [ads, setAds] = useState([]);
    const {alerts, setAlerts} = useContext(AlertContext);
    const [loading, setLoading] = useState(false);
    // const [adsLoading, setAdsLoading] = useState(true);
    const facebookPageId = store.getState()?.metaSource?.page_selection_info?.id;
    const [filteredItems, setFilteredItems] = useState([]);

    // const get_my_ads = () => {

    //     a.getMyAds()
    //     .then(res => {
    //         console.log('RESPONSE GET MY ADS' , res);
    //         if (res?.data?.code == 'A2006') {
    //             console.log('RESADS',res?.data?.ads );
    //             handleFilter(adInfo.flow,adInfo?.mode, res?.data?.ads )
    //             //setAds(res?.data?.ads);

    //         }
    //         else {
    //             alertMessage(alerts, setAlerts, (t('Something went wrong')), 'Error');
    //         }
    //         setAdsLoading(false);
    //     })
    // }


    // const handleFilter = (flow, mode, ads) => {
    //     const filteredData = ads.filter((item) => {

    //     if (flow === 'eshop' && mode) {
    //         return item.flow === flow && item.mode === mode;
    //     } else if (flow) {
    //         return item.flow === flow;
    //     } else {
    //         return item; // No filter applied, return everything
    //     }
    //     })

    //     console.log('filteredData', filteredData);
    //      setAds(filteredData);
    // };

    const clone_ad = (id) => {
        setLoading(true);
        a.cloneAd(id, facebookPageId)
          .then(res => {
            console.log('CLONE AD' , res);
            if (res?.data?.code == 'A2005' && questId) {
                setShowTemplates({id: id, slider: false})
                alertMessage(alerts, setAlerts, (t('Ad loaded successfully')), 'Success');
            }
            else if (res?.data?.code == 'A2005') {
                setShowTemplates({id: id, slider: false})
                alertMessage(alerts, setAlerts, (t('Ad loaded successfully')), 'Success');
            }
            else {
                alertMessage(alerts, setAlerts, (t('Something went wrong')), 'Error');
            }
            setLoading(false);
        })
    }


    function renderAdImage (ad) {
        if(ad.productSetImg) return ad.productSetImg
        else if( ad?.thumbnail && ad?.thumbnail !== "default.jpg") return ad?.thumbnail 
        else if (ad?.visualElement) return  `${minioUrl}/${ad?.userId}/${ad?.visualElement}` 
        else return NoImg
    }
    // useEffect(()=>{
    //     get_my_ads();
    // }, [])

    console.log('ads', ads);
    console.log('props.adInfo', adInfo);

    if (loading) return <Main_Text_Loader text={t('Cloning ad and uploading files...')}/>
    return (
        <Slider_
            text={t("Done")}
            grey={t("Cancel")}
            onClose={() => setShowTemplates({id:'', slider: false})}
            title={t("Previous ad creatives")}
            onGrey={() => setShowTemplates({id:'', slider: false})}
            onClick={() => setShowTemplates({id:'', slider: false})}
        >
            <PageAlwaysVisible>
                {adsLoading ? 
                    <Sixty_Card_Loader />
                :
                    <CardsSixty>
                        {ads?.map((page) => (
                            <On_Click_Card
                                title={page?.title}
                                imgSmall= {renderAdImage(page)}
                                onClick = {()=> clone_ad(page?._id)}
                            />
                        ))}
                    </CardsSixty>
                }
            </PageAlwaysVisible>
        </Slider_>
  )
}
