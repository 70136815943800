import React from 'react'
import styled from 'styled-components'
import noQuests from '../../../../assets/dashboard/NoQuests.svg';
import { Card, Main_, Main_Plain } from 'monica-alexandria';
import { useTranslation } from 'react-i18next';

const DashboardNoQuests = styled(Card)`
  padding: 2rem;
  background: ${p => p.theme.low};
  width: 100%;
  /* max-width: 70rem; */
  max-height: calc(100vh - 15rem);
  overflow: auto;

   /* Hide scrollbar */
   &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  & {
    scrollbar-width: none; /* For Firefox */
  }

  /* Show scrollbar on hover */
  &:hover {
    overflow: auto;
    scrollbar-width: thin; /* For Firefox */
    scrollbar-color: rgba(0, 0, 0, 0.5) rgba(0, 0, 0, 0.1); /* For Firefox */
  }

  &:hover::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  &:hover::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 4px;
  }

  &:hover::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.1);
  }
`

const DashboardNoQuestsImg = styled.div`
  img{
    display: block;
    margin: 0 auto;
    max-width: 50rem;
  }
`

const DashboardNoQuestsText = styled.div`
  h3{
    margin-bottom: 2rem;
  }
`

const DashboardNoQuestsActions = styled.div`
  margin-top: 4rem;
  display: flex;
  gap: 1rem;
  justify-content: space-between;
`

export default function Dashboard_No_Quests(props) {
  
  const {t} = useTranslation();

  return (
    <DashboardNoQuests>
      <DashboardNoQuestsImg>
        <img src={noQuests} />
      </DashboardNoQuestsImg>
      <DashboardNoQuestsText>
        <h3>{t("Seems like there are no quests available for creating ads on Meta at the moment.")}</h3>
        <p>{t("Why not take the initiative and start a new quest? Let's kickstart your advertising journey by crafting your own ad!")}</p>
        <p>{t("Unleash your creativity and let the quest for the perfect ad begin!")}</p>
      </DashboardNoQuestsText>
      <DashboardNoQuestsActions>
        <Main_ text= {t("New Quest")} iconLeft='Add' onClick={props.onNoQuestsAdd}/>
      </DashboardNoQuestsActions>
    </DashboardNoQuests>
  )
}
