import { Card } from 'monica-alexandria';
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const DashboardStatus = styled(Card)`
    padding: 1rem 2rem;
    background: ${p => p.theme.low};
    margin-bottom: 2rem;
    border: 2px solid ${p =>
        p.status === 'ACTIVE' || p.status === 'PREAPPROVED' ? 'transparent'
        :p.status === 'IN_PROCESS' || p.status === 'PENDING_REVIEW' ? 'var(--warning)'
        : 'var(--error)'
    };

    h5{
        color: ${p =>
            p.status === 'ACTIVE' || p.status === 'PREAPPROVED' ? 'var(--success)'
            :p.status === 'IN_PROCESS' || p.status === 'PENDING_REVIEW' ? 'var(--warning)'
            : 'var(--error)'
        };
    }
`

export default function Dashboard_Status(props) {

    const {t} = useTranslation();

    const statuses = {
        "WITH_ISSUES":(t("Your Ads are paused. If you did not cause this action, please contact support.")),
        "DISAPPROVED":(t("Your Ads are paused. If you did not cause this action, please contact support.")),
        "ARCHIVED":(t("Your Ads are paused. If you did not cause this action, please contact support.")),
        "NO_STATUS":(t("Your Ads are paused. If you did not cause this action, please contact support.")),
        "DELETED":(t("Your Ads are paused. If you did not cause this action, please contact support.")),
        "NO_STATUS":(t("Your Ads are paused. If you did not cause this action, please contact support.")),
        "NO_STATUS":(t("Your Ads are paused. If you did not cause this action, please contact support.")),
        "NO_STATUS":(t("Your Ads are paused. If you did not cause this action, please contact support.")),
        "NO_STATUS":(t("Your Ads are paused. If you did not cause this action, please contact support.")),
        "PAUSED":(t("Your Ads are paused. If you did not cause this action, please contact support.")),
        "CAMPAIGN_PAUSED":(t("Your Ads are paused. If you did not cause this action, please contact support.")),
        "ADSET_PAUSED":(t("Your Ads are paused. If you did not cause this action, please contact support.")),
        "PENDING_BILLING_INFO":(t("Your Ads are paused. If you did not cause this action, please contact support.")),
        "PENDING_REVIEW":(t("Your Ads are currently under review by Meta.")),
        "IN_PROCESS":(t("Your Ads are currently under review by Meta.")),
        "PREAPPROVED":(t("Your Ads are running normally.")),
        "ACTIVE":(t("Your Ads are running normally."))
    }

  return (
    <DashboardStatus status={props.status}>
        <h5>{statuses[props.status]}</h5>
        {/* <h5>{t("Θα θέλαμε να σας ενημερώσουμε για ένα προσωρινό τεχνικό ζήτημα με την META που επηρεάζει τη λειτουργία των διαφημίσεών.")}</h5>
        <h5>{t("Λόγω αυτού του προβλήματος, οι καμπάνιες σας βρίσκονται προσωρινά σε παύση.")}</h5>
        <h5>{t("Θα θέλαμε να σας διαβεβαιώσουμε ότι το budget σας δεν επηρεάζεται.")}</h5>
        <h5>{t("Η ομάδα μας εργάζεται πυρετωδώς για την άμεση επίλυση του θέματος και βρίσκεται σε συνεχή επικοινωνία με την Meta για την οριστική διευθέτηση.")}</h5>
        <h5>{t("Σας ευχαριστούμε για την κατανόηση, θα σας ενημερώσουμε μόλις το πρόβλημα διευθετηθεί.")}</h5>
        <h5>{t("Με εκτίμηση,")}</h5>
        <br></br>
        <h5>{t("Η ομάδα της doitforme.eu")}</h5> */}
    </DashboardStatus>
  )
}
