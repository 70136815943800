import React, { useContext, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import styled from 'styled-components'
import Eshop_Configure_Url from '../../components/eshopConfigure/Eshop_Configure_Url'
import { Alert_, Main_Loader, PageAlwaysVisible, PageForty, PageTitle, Steps_ } from 'monica-alexandria'
import { useTranslation } from 'react-i18next'
import Eshop_Configure_Pixel from '../../components/eshopConfigure/Eshop_Configure_Pixel'
import { StepContext } from '../../context/StepContext'
import m from '../../services/api/Meta'
import q from '../../services/api/Quests'
import { alertMessage } from '../../utils/messagesAlerts'
import store from '../../redux/store'
import { useParams } from 'react-router-dom'
const EshopConfigure = styled.div``

export default function ESHOP_CONFIGURE() {

    const {t} = useTranslation()
    const [step, setStep] = useState(0);
    const [quest, setQuest] = useState(false);
    const [originalUrl, setOriginalUrl] = useState('');
    const {questId} = useParams();
    const [alerts, setAlerts] = useState([]);
    const [pixels,setPixels] = useState([]);

    const get_my_quest = () => {
        if (questId) {
            q.getMyQuest(questId).then(res => {
                setOriginalUrl(res?.data?.quest?.eShopUrl)
                setQuest(res?.data?.quest);
            })
        }
        else {
            q.getDraft().then(res => {
                setQuest(res?.data?.data);
            }) 
        }
    }

    // Pixel implemetation for the future:

    // const access_token = store.getState().metaSource?.facebook_user?.accessToken;
    

    const get_pixels = () => {
        m.getPixels()
        .then(res => {
            console.log('RESPONSE GET PIXELS' , res);
            if (res?.data?.code == "C2001") {
                setPixels(res?.data?.data);
            }
            else {
                alertMessage(alerts, setAlerts, (t('Something went wrong')), 'Error');
            }
            //setAdsLoading(false);
        })
    }


    useEffect(() => {
        get_my_quest();
        get_pixels();
    },[])

    console.log('quest', quest);

  if (!quest) return <Main_Loader />
  return (
    <EshopConfigure>
        <Alert_ messages={alerts} duration={2000} />
        <Helmet>
        <title>Questad | Eshop configuration</title>
        </Helmet>
        <PageForty>
            <PageAlwaysVisible>
                <PageTitle>
                    <h1>{t("Eshop configuration")}</h1>
                </PageTitle>
                <StepContext.Provider value={{ step, setStep }}>
                    <Steps_
                        currentStep={step}
                        steps={[
                            <Eshop_Configure_Url originalUrl={originalUrl} quest={quest} setQuest={setQuest}/>,
                            <Eshop_Configure_Pixel pixels={pixels} questId={quest?._id} Meta={quest?.Meta}/>
                        ]}
                    />
                </StepContext.Provider>
            </PageAlwaysVisible>
        </PageForty>
    </EshopConfigure>
  )
}
