import React from 'react'
import styled from 'styled-components'
import { prettifyDates } from '../../../../helpers/prettifiers'
import { device } from 'monica-alexandria'
import { useTranslation } from 'react-i18next'

const DashboardReccomendations = styled.div`
    
`

const DashboardReccomendationsBox = styled.div`
    padding: 2rem;
    background: ${p => p.theme.low};
    border-radius: 1rem;

    p{
        margin-left: 5rem;
    }

    @media ${device.sm} {
        
        p{
            margin-left: 0;
        }
    }
`

const DashboardReccomendationsBy = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-bottom: 1rem;

    img{
        height: 4rem;
        width: 4rem;
        object-fit: cover;
        border-radius: 50%;
        overflow: hidden;
    }
`

const DashboardReccomendationsText = styled.div`

    h6{
        font-size: 1.2rem;
        color: var(--greyDark);
    }
`

const DashboardReccomendationsWrapper = styled.div`
`

const DashboardReccomendationsEmpty = styled.div`
    text-align: center;

    img{
        width: 100%;
        display: block;
        max-width: 40rem;
        margin: 2rem auto;
        opacity: .5;
    }

    h1,h5{
        color: var(--greyDark);
    }
`

export default function Dashboard_Reccomendations(props) {

    const {t} = useTranslation();

  return (
    <DashboardReccomendations>
        {props.reccomendationsNotifications?.length ?
            <DashboardReccomendationsWrapper>
                {props.reccomendationsNotifications?.map((reccomendation, i) => 
                    <DashboardReccomendationsBox key={i}>
                        <DashboardReccomendationsBy>
                            <img src='https://genius1071.friktoriaservers.net/doitforme/logo/logo_light.svg' />
                            <DashboardReccomendationsText>
                                <h5>Doitforme Marketing Team</h5>
                                <h6>{prettifyDates(reccomendation?.createdAt)}</h6>
                            </DashboardReccomendationsText>
                        </DashboardReccomendationsBy>
                        <p>{reccomendation?.details?.action}</p>
                    </DashboardReccomendationsBox>
                )}  
            </DashboardReccomendationsWrapper>
        :
           <DashboardReccomendationsEmpty>
                <img src='https://genius1071.friktoriaservers.net/doitforme/morning/app/global/Empty.svg' />
                <h1>{t("You have no reccomendations")}</h1>
                <h5>{t("We have not any reccomendation for your quest at the moment.")}</h5>
           </DashboardReccomendationsEmpty>
        }
    </DashboardReccomendations>
  )
}
