import { IconSvg, device } from 'monica-alexandria'
import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import store from '../../../redux/store'
import { useTranslation } from 'react-i18next'
import { DailyprofitSvg } from '../../../svg/DailyprofitSvg'
import ReactPlayer from 'react-player'
import { Background_Detector } from './Background_Detector'
import { findLabelByValue } from '../../../utils/findCtaLabel'
import NoImg from '../../../assets/ad/NoImgAd.jpg'


const FacebookProductStory = styled.div`
    aspect-ratio: 9/16;
    //background-image: linear-gradient(180deg, var(--greyDark) 0%, transparent 50%, var(--greyDark) 100%);
    background: var(--greyLight);
    box-shadow: ${p => p.theme.out};
    border-radius: var(--smallRadius);
    position: relative;
    max-width: 31.8rem; //normal
    height: 56.5rem;
    width: 100vw; //temp


    /* @media ${device.sm}{
       max-width: 35rem;
    } */

`

const AdMockButton = styled.div`
    background-color: var(--white);
    width: fit-content;
    border-radius: 1rem;
    padding: var(--normalPads);
    display: flex;
    align-self: center;
    gap: 0.5rem;

    h3{
        font-family: serif;
        color: black;
    }
    svg{
        width: 2.5rem;
        stroke: #1A78F2;
        stroke-width: 2;
        fill: none;
    }
`

const AdMockupAccount = styled.div`
    display: flex;
    align-content: center;
    justify-content: space-between;
    padding: 1rem;

    h1{
        color: var(--white);
    }
 
`

const AdMockupAccountInfo = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;
    padding-bottom: 1.5rem;

    h6{
        color: var(--white);
    }
    img{
        border: 1px solid var(--grey);
        border-radius: 50%;
        width: 3rem;
        height: 3rem;
    }

    p{
        font-size: 1.1rem;
        color: var(--white);
    }
`

const AdMockupAccountActions = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;
    padding-bottom: 1.5rem;

    svg{
        width: 1.6rem;
        height: 1.6rem;
        fill: var(--white);
    }
`

const AdMockupImage = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;

    img{
        height : 100% ;
        width: 100%;
        object-fit: contain;
    }
`

const AdMockupTop = styled.div`
    background-image: linear-gradient(180deg, var(--greyDark) 0%, transparent 90%);
    padding-top: 1rem;
    width: 100%;
    position: absolute;
    top: 0;
    z-index: 1;
    
    `

const AdMockupBottomWrapper = styled.div`
    position: absolute; 
    bottom: 2rem;
    left: 50%;
    width: 100%;
    transform: translate(-50%, 0%);
`

const AdMockupBottom = styled.div`
    padding: 0 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    

    h2,h4{
        text-align: center;
        color: var(--greyDark);
        padding-bottom: 4rem;
      //  opacity: 0.7;
    }

    h4{
        white-space: pre-wrap;
        word-break: break-word;
    }
`

const AdMockupLine = styled.div`
    width: 98%;
    height: 3px;
    margin: 0 .5rem;
    position: relative;
    overflow: hidden;
    background-color: #D0D0D0;
    border-radius: 0.3rem;

    &::before{
        content: "";
        position: absolute;
        left: -50%;
        height: 3px;
        width: 80%;
        border-radius: 0.3rem;
        background-color: var(--white);

    }
`
const minioUrl = process.env.REACT_APP_MINIO_PROTOCOL +
                process.env.REACT_APP_MINIO_HOST + 
                process.env.REACT_APP_MINIO_PORT+ '/' +
                process.env.REACT_APP_MINIO_BUCKET + '/';

export const Facebook_Product_Story = (props) => {

    const {t} = useTranslation();
    const facebook_page = store.getState().metaSource?.page_selection_info;
    const userId = store.getState()?.user?.user?.sub;
    

    const getFirstImageUrl = (imageUrl) => {
        return imageUrl.split(',')[0].trim();
    };
    
  return (
    <FacebookProductStory>
        <AdMockupTop>
            <AdMockupLine></AdMockupLine>
            <AdMockupAccount>
                <AdMockupAccountInfo>
                    <img src={props.pageLogo} />
                    <div>
                        <h6>{facebook_page?.name}</h6>
                        <p>Sponsored • Show {props.products?.length -1} more</p>
                    </div>
                </AdMockupAccountInfo>
                <AdMockupAccountActions>
                    <DailyprofitSvg Icon='InstaOptions' />
                    <IconSvg Icon='Xicon'/>
                </AdMockupAccountActions>
            </AdMockupAccount>
        </AdMockupTop>
        <AdMockupImage>
            <img src={getFirstImageUrl(props.products[0]?.image_url)}/>
        </AdMockupImage>
        <AdMockupBottomWrapper>
            <AdMockupBottom>
                <h4>{props.adInfo?.caption && props.adInfo.caption.length > 200 ? props.adInfo.caption?.substring(0, 200) + '...' : props.adInfo.caption}</h4>
                <AdMockButton>
                    <DailyprofitSvg Icon='FbLink' />
                    <h3>{findLabelByValue(props.adInfo?.cta,t)}</h3>
                </AdMockButton>
            </AdMockupBottom>
        </AdMockupBottomWrapper>
    </FacebookProductStory>
  )
}
