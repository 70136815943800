import { Builder_, CardsFull, Dropdown_, Elastic_Search, Google_Search, Grey_, Grey_Link, Main_Plain, PageFull, PageTitle, device, Main_Loader } from 'monica-alexandria';
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import m from '../../services/api/Meta';
import q from '../../services/api/Quests'; 
import styled from 'styled-components';
import Lead_ from '../../components/leads/Lead_';
import { useTranslation } from 'react-i18next';

import g from 'greek-utils'


const DashboardRightLeadsGrid = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;
    margin-bottom: 1rem;
`

const FormLeadsSearch = styled.div`
   display: flex;
   justify-content: space-between;
   grid-template-columns: 1fr auto;
`

export default function FORM_LEADS() {
    const {lang} = useParams();
    const {questId} = useParams();
    const {t} = useTranslation();
    const navigate= useNavigate();
    const params = useParams();
    const [loading, setLoading] = useState(true)

    const [formLeads, setFormLeads] = useState([]);
    const [greeklishFormLeads, setGreeklishFormLeads] = useState([]);
    const [searchResults, setSearchResults] = useState([]);

    const get_kpis_for_quest = () =>{
        q.getMyQuestsWithKPIS(true)
        .then(res => {
            if (res?.data?.data?.length) {
                let quest = res.data.data.find(quest => quest._id == questId);

                let greeklishFormLeads = JSON.parse(JSON.stringify(quest?.matchingLead));

                greeklishFormLeads?.forEach(lead => {
                    if (lead?.Name) lead.greeklishName = g.toGreeklish(lead.Name);
                    if (lead?.Surname) lead.greeklishSurname = g.toGreeklish(lead.Surname);
                    if (lead?.Tel) lead.greeklishTel = g.toGreeklish(lead.Tel);
                    if (lead?.Email) lead.greeklishEmail = g.toGreeklish(lead.Email);
                })
                setFormLeads(quest?.matchingLead);
                setGreeklishFormLeads(greeklishFormLeads);
                setLoading(false);
            }
        })
        .catch(err => {
            setLoading(false);
            console.log("ERROR:",err)
        })
    }

    useEffect(() =>{
        if(questId) {
            get_kpis_for_quest();
        }
    },[])

    console.log(searchResults)

    if (loading) return <Main_Loader />

  return (
    <PageFull>
        <PageTitle>
            <Grey_Link iconLeft="Back" onClick={()=> navigate(`/${lang}/dashboard`)} noPadding />
            <h1>{t("Form Leads")}</h1> 
            {/* <Main_Plain onClick={()=> setSortDate(-1)} text='LOL' /> */}
        </PageTitle>
    
        <Builder_ title={t("Search for leads")}>
        {/* <FormLeadsSearch> */}
            <Google_Search
                    file={greeklishFormLeads}
                    fileKeys={['greeklishName', 'greeklishSurname', 'greeklishEmail', 'greeklishTel']}
                    defaultValue={''}
                    sanitize={true}
                    placeholder={t('Lead')}
                    applyReverseFileProperties={[
                        {
                            originalKey: 'greeklish',
                            reversedKey: 'greeklish_reversed',
                        },
                    ]}
                    setResults={(data) => setSearchResults(data)}
                    minMatchCharacters={1}
                    maxMatchCharacters={50}
                    disabled={false}
                    isCaseSensitive={false}
                    maxAcceptedScore={0.6}
                /> 
             
            {/* <Dropdown_>
                <select value={sortDate ? sortDate : 'default'} onChange={(e) => {setSortDate(e.target.value) }}>
                    <option value={'default'} disabled>{t('Default')}</option>
                    <option value={1}  > {t('Date ascending')} </option>
                    <option value={-1}  > {t('Date descending')} </option>
                </select>
            </Dropdown_> */}
            {/* </FormLeadsSearch>                 */} 
          </Builder_>
       
        <CardsFull>
            <Lead_ formLeads={searchResults.data?.length? searchResults.data : formLeads}/>
        </CardsFull>
    </PageFull>
  )
}
