import { IconSvg, device } from 'monica-alexandria'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { prettifyDates } from '../../../../helpers/prettifiers'

const DashboardHistory = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 2rem;
`

const DashboardHistoryLeft = styled.div``

const DashboardHistoryRight = styled.div`
    padding: 2rem;
    background: ${p => p.theme.low};
    border-radius: 1rem;
`

const DashboardHistoryInfo = styled.div`
    display: flex;
    margin: .5rem 0 1rem 0;
    gap: .5rem;
`

const DashboardHistoryInfoBox = styled.div`

    h6{
        color: ${p => p.status === 'success' ? 'var(--success)' : 'var(--greyDark)'};
        font-size: 1.2rem;
    }
`

const DashboardHistoryGrid = styled.div`
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    align-items: center;
    grid-gap: 1rem;

    @media ${device.lg} {
        grid-template-columns: 1fr;
    }

    @media ${device.md} {
        grid-template-columns: 1fr auto 1fr;
    }

    @media ${device.sm} {
        grid-template-columns: 1fr;
    }
`

const DashboardHistoryTo = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    
    svg{
        width: 2rem;
        fill: var(--greyDark);
    }

    @media ${device.lg} {

        svg{
            transform: rotate(90deg);
        }
    }

    @media ${device.md} {
        

        svg{
            transform: rotate(0deg);
        }
    }

    @media ${device.sm} {

        svg{
            transform: rotate(90deg);
        }
    }
`

const DashboardHistoryChange = styled.div`
    position: relative;
    padding: 1rem;
    border-radius: 1rem;
    overflow: hidden;

    .Color{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: ${p => p.old ? 'var(--greyDark)' : 'var(--success)'};
        opacity: .2;
    }
`

export default function Dashboard_History(props) {

    const {t} = useTranslation();

  return (
    <DashboardHistory>
        {props.historyNotifications?.map((notification, i) => 
            <DashboardHistoryRight key={i}>
                <h5>{notification?.details?.action}</h5>
                <DashboardHistoryInfo>
                    <DashboardHistoryInfoBox>
                        <h6>{prettifyDates(notification?.createdAt)}</h6>
                    </DashboardHistoryInfoBox>
                </DashboardHistoryInfo>
                {notification?.details?.from && notification?.details?.to ?
                    <DashboardHistoryGrid>
                        <DashboardHistoryChange old>
                            <div className='Color' ></div>
                            <p>{notification?.details?.from}</p>
                        </DashboardHistoryChange>
                        <DashboardHistoryTo>
                            <IconSvg Icon="Next" />
                        </DashboardHistoryTo>
                        <DashboardHistoryChange>
                            <div className='Color'></div>
                            <p>{notification?.details?.to}</p>
                        </DashboardHistoryChange>
                    </DashboardHistoryGrid>
                :
                    null
                }
            </DashboardHistoryRight>
        )}
    </DashboardHistory>
  )
}
