import React from 'react'
import styled from 'styled-components'

const GraphBox = styled.div`
    background: ${p => p.theme.low};
    box-shadow: ${p => p.selected ? p.theme.inFocus : 'none'};
    border-radius: var(--smallRadius);
    padding: 2rem;
    cursor: ${p => p.unclickable ? "default" : "pointer" };
    transition: all .5s;

    &:hover{
      box-shadow: ${p => p.selected ? p.theme.inFocus :p.unclickable ? "none"  : p.theme.out};
      transition: all .5s;
    }
`

const GraphBoxBorder = styled.div`
  border: 2px solid ${p => p.selected ? 'var(--main)' : p.theme.color};
  border-radius: .5rem;
  padding: 0 1rem 1rem 1rem;
`

const GraphBoxTitle = styled.div`
  background-color: ${p => p.theme.low};
  transform: translateY(-1rem);
  display: inline-block;
  padding: 0 1rem;

  h6{
    color: ${p => p.selected ? 'var(--main)' : p.theme.color} !important;
  }
`

const GraphBoxValue = styled.div`
  text-align: center;

  h1{
    color: ${p => p.selected ? 'var(--main)' : p.theme.color} !important;
  }
`

export default function Graph_Box(props) {

  function formatYAxis(number) {
    if (!number) 
      return;
    // Check if the number is one thousand or more but less than a million
    if (number >= 1000 && number < 1000000) {
        return (number / 1000)?.toFixed(1)?.replace(/\.0$/, '') + 'K';
    }
    // Check if the number is one million or more
    else if (number >= 1000000) {
        return (number / 1000000)?.toFixed(1)?.replace(/\.0$/, '') + 'M';
    }
    // Handle numbers less than 1000 with up to two decimal places
    else if (!Number?.isInteger(number)) {
        return number?.toFixed(2);
    }
    // Handle integer values under 1000
    return number;
}

  return (
    <GraphBox unclickable={props.unclickable} onClick={props.onClick} selected={props.selected} key={props.key} id={props.id}>
      <GraphBoxBorder selected={props.selected}>
        <GraphBoxTitle selected={props.selected}>
          <h6>{props.kpiTitle}</h6>
        </GraphBoxTitle>
        <GraphBoxValue selected={props.selected}>
          <h1>{formatYAxis(props?.kpiValue)}</h1>
        </GraphBoxValue>
      </GraphBoxBorder>
    </GraphBox>
  )
}
