import { Main_Text_Loader } from "monica-alexandria";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import i18n from '../../localization/i18next';
import store from '../../redux/store';
import quests from '../../services/api/Quests';
import q from 'query-string'
const clients = require('../../config/index').config

export default function SUCCESS_PAYMENT(props) {
    const navigate = useNavigate();
    const {t} = useTranslation();
    const [safeToRedirect, setSafeToRedirect] = useState(false);
    const lang = i18n.language ? i18n.language : 'en';
    const userId = store.getState()?.user?.user?.sub;
    const queryParams = q.parse(window.location.search);
    let questId = queryParams?.quest || props?.relativeQues || false;
    /* Validate Tool Ownership */
    const checkSubscriptions = () => {  

        quests.getMyQuest(questId)
        .then((res) => {   
                console.log('myQuest ', res?.data);
                if (res?.data?.quest?.active) {
                        setSafeToRedirect(true);
                        window.location.assign(`${clients['dailyprofit']}/${lang}/dashboard`);
                } else {
                    // Retry after a delay if subscriptions are empty
                    setTimeout(checkSubscriptions, 3000); // Retry after 5 seconds
                }
            })
            .catch((err) => {
                console.log('error', err);
                // Retry after a delay in case of error
                setSafeToRedirect(false);
                setTimeout(checkSubscriptions, 3000); // Retry after 5 seconds
            });
    };

    useEffect(() => {
        if (!safeToRedirect) {
            checkSubscriptions();
        }
    }, [safeToRedirect]);

    console.log('safeToRedirect', safeToRedirect);

    return (
        <Main_Text_Loader text={t("Thank you for your trust. We are verifying your payment.")}/>
    );
}
