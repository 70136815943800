import { Main_Plain, Red_ } from 'monica-alexandria'
import React from 'react'
import styled from 'styled-components'

const CardActions = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    background: ${p => p.theme.mid};
    border-radius: var(--smallRadius);
    padding: var(--smallPads);
`

const CardActionsLeft = styled.div`
    display: flex;
    align-items: center;
    justify-items: left;
    padding-left: 1rem;
`

const CardActionsRight = styled.div`
    display: flex;
    justify-content: right;
`

const CardActionsRightInner = styled.div`

`
export const Card_Actions = (props) => {
  return (
    <CardActions>
        <CardActionsLeft>
            <h3>{props.title}</h3>
        </CardActionsLeft>
        <CardActionsRight>
            <CardActionsRightInner>
                <Main_Plain iconLeft='EyeOpen' onClick={props.onView} />
                <Main_Plain iconLeft='Edit' onClick={props.onEdit} />
                <Red_ iconLeft='Delete' onClick={props.onDelete} />
            </CardActionsRightInner>
        </CardActionsRight>
    </CardActions>
  )
}
