import React, { useEffect, useState } from 'react'
import styled, { keyframes } from 'styled-components'
import { useTranslation } from 'react-i18next';
import Dashboard_Notification_Box from './Dashboard_Notification_Box';
import { Main_ } from 'monica-alexandria';

const DashboardNotifications = styled.div`

`

const DashboardNotificationsList = styled.ul`

`
const slideIn = keyframes`
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

const NotificationItem = styled.li`
  animation: ${slideIn} 0.5s ease;
  margin-bottom: 10px;
`;
export default function Dashboard_Notifications(props) {

    const {t} = useTranslation();
    const [notifications, setNotifications] = useState([]);

    useEffect(() => {
      const dummyNotifications = [
        { id: 1, name: "Lina", time: '2h' },
        { id: 2, name: "Eva", time: '2h' },
        { id: 3, name: "Xaris", time: '6h' },
        { id: 4, name: "Ntora", time: '7h' },
        { id: 5, name: "Luigi", time: '7h' },
        { id: 6, name: "Evi", time: '8h', comment:true},
        { id: 7, name: "Youssef", time: '8h', comment:true},
        // Add more notifications here
      ];
  
      const interval = setInterval(() => {
        if (notifications.length < 10 && dummyNotifications.length > 0) {
          const randomIndex = Math.floor(Math.random() * dummyNotifications.length);
          const newNotification = dummyNotifications[randomIndex];
          setNotifications(prevNotifications => [newNotification, ...prevNotifications]);
  
          // Remove the used notification from dummyNotifications
          dummyNotifications.splice(randomIndex, 1);
        } else {
          clearInterval(interval); // Stop adding notifications when reaching 10 or when no more available
        }
      }, 4000);
  
      return () => clearInterval(interval);
    }, []); // Empty dependency array, runs only once on mount
  
    const clearAllNotifications = () => {
      setNotifications([]);
    };
  return (

    <DashboardNotifications>
    <h3>{t("Notifications")}</h3>
    <DashboardNotificationsList>
      {/* <Dashboard_Notification_Box name={'John'} time={'15m'} /> */}
      {notifications.map(notification => (
        <NotificationItem key={notification.id} style={{ opacity: notification.read ? 0.5 : 1 }}>
          <Dashboard_Notification_Box name={notification.name} time={notification.time} comment={notification.comment} />
        </NotificationItem>
      ))}
    </DashboardNotificationsList>
    {/* {notifications.length > 0 && (
      <Main_ text='Clear All' onClick={clearAllNotifications}/>
    )} */}
    </DashboardNotifications>
  )
}

