import React from 'react'
import styled from 'styled-components'
import i18n from '../../../../localization/i18next'
import { useTranslation } from 'react-i18next'

const Summary = styled.div`
    padding: 0 2rem 2rem 2rem ;
`

const SummaryItem = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`

const SummarySep = styled.div`
    margin: 1rem 0;
    height: 2px;
    width: 100%;
    background: ${p => p.theme.mid};
`



const SummaryPrice = styled.div`
    display: flex;
    gap: 1rem;
    align-items: flex-start;
    justify-content: space-between;
`


export default function Subscription_Topup_Summary(props) {

    const {t} = useTranslation();
    const lang = i18n.language ? i18n.language : "el";


    const prettifyDates = (date, isUnix, lang) => {
        let dateLang;
        if(lang == "el") {dateLang = 'el-GR'}
        else if (lang == "en") {dateLang = 'en-US'}
        else {dateLang = 'el-GR'}
      
        const createdAt = isUnix  ? new Date(date * 1000) : new Date(date); 
        console.log("date", date)
        const options = {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
          // hour: 'numeric',
          // minute: 'numeric',
          // second: 'numeric',
          hour12: false
        };
        if(createdAt.toLocaleDateString(dateLang, options)==='Invalid Date') return null;
        else return createdAt.toLocaleDateString(dateLang, options);
      }
          

    function prettifyCurrency(currency) {
        if (currency == "eur") return "€";
        else return currency;
    }

    console.log('amount', props.amount);

    
  return (
    <Summary>
        <SummaryItem>
            <h4>{t('New budget summary')}</h4> 
        </SummaryItem>
        <SummarySep></SummarySep>
        <SummaryPrice>
            <p>{t("Adspend")}</p>
            <h5>{prettifyCurrency(props?.currency)} {props.amount?.amountWithoutTax} </h5>
        </SummaryPrice>
        <SummaryPrice>
            <p>{t("Tax")}</p>
            <h5>{prettifyCurrency(props?.currency)} {props.amount?.taxAmount} ({props.amount?.taxPercentage*100}%)</h5>
        </SummaryPrice>
        <SummarySep></SummarySep>
        <SummaryItem>
            <div>
                <h4>{t("Payable at")}:</h4>
                <h6>{ prettifyDates(props.subscriptionNextPayment * 1000)}</h6>
            </div>
            {/* <h2>{prettifyCurrency(props?.currency)} {props?.tax ? prettifyPrice(props?.total) : prettifyPrice(props?.total+238)}</h2> */}
            <h2>{prettifyCurrency(props?.currency)} {props?.tax ? '' : props.amount?.totalAmount}</h2>
        </SummaryItem>      
    </Summary>
  )
}
