import { AlertContext, Delete_Plain, FormGroup, FormInput, FormLabel, FormList, IconSvg, Main_, Red_ } from 'monica-alexandria'
import React, { useContext, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { alertMessage } from '../../utils/messagesAlerts'

const GoogleMapSearch = styled.div`
    
`

const GoogleMapSearchWrapper = styled(FormGroup)`
    position: relative;

    .GoogleMapSearchAdd{
        position: absolute;
        right: 1.5rem;
        top: 1.6rem;
    }
`

const GoogleMapSearchInput = styled(FormInput)`
    
`

const GoogleMapSearchLabel = styled(FormLabel)`
    
`

const GoogleMapSearchList = styled(FormList)`
    
`

export const Google_Map_Search = (props) => {

    const {t} = useTranslation();
    const autoCompleteRef = useRef();
    const inputRef = useRef(null);
    const {alerts, setAlerts} = useContext(AlertContext);

    const options = {
     //componentRestrictions: { country: "gr" },
     fields: ["name", 'address_components'], //types of data you want to retrieve
     types: ['locality','continent', 'administrative_area_level_1','administrative_area_level_2','country'], //types of places are shown on search
    };


    useEffect(() => {        
        autoCompleteRef.current = new window.google.maps.places.Autocomplete(
        inputRef.current,
        options
    );
        //"place_changed" event, which occurs when the user selects a place from the Autocomplete suggestions.
        autoCompleteRef.current.addListener("place_changed", async function () {
            //is used to get detailed information about the selected place.
            const place = await autoCompleteRef.current.getPlace(); 
            autoCompleteRef.current.value = true;
        });
    }, []);

    const handleAddToList = () => {
        const place = autoCompleteRef.current.getPlace(); 
        let countryObject = place?.address_components?.find(obj => obj.types.includes("country"));
        let shortName = countryObject ? countryObject.short_name : 'GR';
        const inputValue =place? inputRef.current.value.trim() + ', ' + shortName : '';
        
        if (autoCompleteRef?.current?.value && inputValue !== '') {
          // Add the input value to the list
          props.onAddToList(inputValue);
      
          // Clear the input field
          inputRef.current.value = '';
          autoCompleteRef.current.value = false;
        }
        else{
            alertMessage(alerts, setAlerts, (t('Invalid input, not from Google results')),'Error');
        }
      };

      const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
          e.preventDefault(); // Prevent the default behavior of the Enter key in a text input
          const place = autoCompleteRef.current.getPlace(); 
          let countryObject = place?.address_components?.find(obj => obj.types.includes("country"));
          let shortName = countryObject ? countryObject.short_name : 'GR';
          const inputValue =place? inputRef.current.value.trim() + ', ' + shortName : '';
         if (autoCompleteRef?.current?.value && inputValue !== '')  {
            // Add the input value to the list
            props.onAddToList(inputValue);
      
            // Clear the input field
            inputRef.current.value = '';
            autoCompleteRef.current.value = false;
            
          }
        }
      };

  return (
    <GoogleMapSearch>
        <GoogleMapSearchWrapper>
            <GoogleMapSearchInput
                id="pac-input"
                placeholder={props.placeholder}
                ref={inputRef}
                onKeyDown={handleKeyDown}
            />
            <GoogleMapSearchLabel>{props.placeholder}</GoogleMapSearchLabel>
            <Main_  
                iconLeft='Add'
                className = 'GoogleMapSearchAdd'
                onClick={handleAddToList}
            />
        </GoogleMapSearchWrapper>
        {props.list?.length > 0 ? (
            <GoogleMapSearchList>
                {props.list?.map((item, i) => (
                    <li key={i}>
                    <IconSvg Icon="Location" />
                    <h6>{item}</h6>
                        <Red_
                            onClick={() => props.onDelete(i)}
                            size="small"
                            iconLeft="Delete"
                        />
                    </li>
                ))}
            </GoogleMapSearchList>
        ) : null}
    </GoogleMapSearch>
  )
}
