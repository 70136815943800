import { Builder_, Dropdown_, IconSvg, Main_Plain, Main_Text_Loader, Slider_, Steps_Actions, ValidatorError } from 'monica-alexandria'
import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { StepContext } from '../../context/StepContext'
import { useNavigate, useParams } from 'react-router-dom'
import m from '../../services/api/Meta'
import { MetaError } from '../../styles/Layout'
const EshopConfigurePixel = styled.div`

    .Slider{
        h3{
            margin-bottom: 0 !important;
        }

        h4{
            margin-top: 4rem;
            margin-bottom: 2rem;
            padding-bottom: 1rem;
            border-bottom: 1px solid var(--greyDark);
            font-family: "fontLight", sans-serif;
            font-size: 2rem;
        }

        h6{
            color: var(--greyDark);
        }

        .NotWordpress{
            margin-top: 1rem;
        }
    }

    .FacebookLink{
        svg{
            width: .8rem;
        }
    }
`

const EshopConfigurePixelHelper = styled.div`
    border: 3px dotted ${p => p.theme.background};
    background: ${p => p.theme.mid};
    padding: var(--normalPads);
    border-radius: 1rem;

    h5{
        color: var(--greyDark);
        margin-bottom: 1rem;
    }

    svg{
        width: 2rem;
        margin-bottom: 5px;
        fill: var(--warning);
    }
`

export default function Eshop_Configure_Pixel(props) {

    const {t} = useTranslation()

    const [instructions, toggleInstructions] = useState(false);
    const { step, setStep } = useContext(StepContext);
    const [error, setError] = useState('');

    const [selectedPixel, setSelectedPixel] = useState(
        props?.Meta?.pixelId && props?.Meta?.pixelName 
          ? { id: props.Meta.pixelId, name: props.Meta.pixelName } 
          : {}
      );
          const navigate = useNavigate();
    const {lang} = useParams();
    const [loading, setLoading] = useState(false);


   // savePixel
    const save_pixel = () =>{
        setLoading(true);
        m.savePixel(selectedPixel?.id, selectedPixel?.name, props?.questId)
        .then(res => {
            // check_for_ad();
            if (res.data?.code === "C2001") navigate(`/${lang}/ad-helper`)
            else setError(res.data?.msg)
            console.log('RESSSSSSSSSSSSS',res);
            setLoading(false);
        })
        .catch((err) => setLoading(false))
    }


    console.log('SEselectedPixel', selectedPixel, props?.questId);
    console.log('PIXELS', props.pixels);
if (loading) return <Main_Text_Loader text={t('Saving pixel..')}/>

  return (
    <EshopConfigurePixel>
        {instructions ?
            <Slider_ title={t("Integrate Pixel")} text={t("Done")} onClose={()=>toggleInstructions(!instructions)} onClick={()=>toggleInstructions(!instructions)}>
            <Builder_ title={t("Step one")}>
                    <h3></h3>
                    <h5>{t("Navigate to Facebook Events Manager by clicking the following link")}</h5>
                    <Main_Plain className="FacebookLink" size="small" iconLeft="Bullet" text={t("Facebook Events Manager")}/>
                </Builder_> 
                <Builder_ title={t("Step two")}>
                    <h5>{t('Click the "Add" button, which is highlighted in green, on the left side of the screen.')}</h5>
                </Builder_> 
                <Builder_ title={t("Step three")}>
                    <h5>{t('Enter a name for your Pixel and provide the url(link) of your website')}</h5>
                </Builder_> 
                <Builder_ title={t("Step four")}>
                    <h5>{t('After creating the Pixel, get the Pixel ID generated')}</h5>
                </Builder_> 
                <Builder_ title={t("Step five")}>
                    <h6>{t("WORDPRESS ESHOP THAT YOU HAVE ADMIN ACCESS")}</h6>
                    <h5>{t('You need to download a compatible plugin that will help you integrate Facebook Pixel to your eshop like "PixelYourSite" ')}</h5>
                    <Main_Plain className="FacebookLink" size="small" iconLeft="Bullet" text={t("PixelYourSite")} onClick={() => 'https://wordpress.org/plugins/pixelyoursite/'}/>
                    <h5>{t("Activate it and fill in the Facebook Pixel ID in the plugin's configuration settings")}</h5>
                    <h6 className='NotWordpress'>{t("NON WORDPRESS ESHOPS OR WITHOUT ADMIN ACCESS")}</h6>
                    <h5>{t('Email the Pixel ID to your developer team and tell them to integrate Facebook Pixel to your eshop.')}</h5>
                </Builder_> 
            </Slider_>
        :
            null
        }
        <h3>{t("Eshop information")}</h3>
        <Builder_ title={t("Select your facebook pixel")}>
            <Dropdown_>
                <select value={selectedPixel?.id ? selectedPixel?.id : 'none'} 
                        onChange={(e) => setSelectedPixel({ id: e.target.value, name: e.target.options[e.target.selectedIndex].text })}
                  >
                    <option value={'none'} disabled>{t('Facebook pixel')}</option>
                    {props.pixels?.map((pixel) => (
                        <option
                            key={pixel.id}
                            value={pixel.id}
                        >
                            {pixel.name}
                        </option>
                    ))}
                </select>
            </Dropdown_>
        </Builder_>
        {error? 
            <ValidatorError>
            <IconSvg Icon ='Xicon' />
            <h6>{error} </h6>
            </ValidatorError> 
        : 
            null
        }
        <EshopConfigurePixelHelper>
            <h5>{t("If you are having trouble finding your page's pixel in the dropdown list, follow the instructions provided on how to create and successfully instegrate Facebook Pixel in your eshop.")}</h5>
            <Main_Plain onClick={()=>toggleInstructions(!instructions)} noPadding size="small" iconLeft="Tip" text={t("How to setup Facebook Pixel")} />
        </EshopConfigurePixelHelper>
        <Steps_Actions
            icon_Grey="Back"
            icon_Main="Next"
            onClick_Grey={() => setStep(step - 1)}
            onClick_Main={() => save_pixel()}
            text_Grey={t("Back")}
            text_Main={t("Next")}
        />
    </EshopConfigurePixel>
  )
}
