import { Actions_, AlertContext, Card, CardsForty, Confirmation_, Facebook_, Grey_, Grey_Link, IconSvg, Input_, Main_, Main_Plain, PageTitle, Red_, device, Main_Text_Loader } from 'monica-alexandria'
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import q from '../../../services/api/Quests'; 

const clients = require('../../../config/index').config



const minioUrl = process.env.REACT_APP_MINIO_PROTOCOL +
                process.env.REACT_APP_MINIO_HOST + 
                process.env.REACT_APP_MINIO_PORT+ '/' +
                process.env.REACT_APP_MINIO_BUCKET + '/';

const DashboardTopUp = styled.div`
    position: sticky;
`

const DashboardTopUpTitle = styled.div`
    margin-bottom: 2rem;
`

const DashboardTopUpPlan = styled(Card)`
    padding: 1rem 1.5rem;
    background: ${p => p.theme.low};
    margin-bottom: 2rem;

    h6{
        color: var(--greyDark);
    }

   

    ul{
        li{
            display: flex;
            align-items: center;
            gap: .5rem;

            svg{
                width: 1.6rem;
            }
        }
    }
`

const DashboardTopUpPlanPrice = styled.div`
    display: flex;
    align-items: baseline;
    margin-bottom: 2rem;

    h1{
        color: var(--main);
    }
`


const DashboardRightStatus = styled.h6`
    color: ${p => p.status === 'active' ? 'var(--success)' : 'var(--warning)'} !important;
`

export const Dashboard_Topup = (props) => {
    const { alerts, setAlerts } = useContext(AlertContext);;
    const {lang} = useParams();
    const [loading,setLoading] = useState(false);
    const {t} = useTranslation();
    const [newBudget, setNewBudget] = useState(props?.selectedQuest?.questionnaires[5]?.answer);
    // const permissionToEdit = store.getState()?.user?.user?.roles.includes('Squire') ? false : true;
    const [messages, setMessages] = useState({
        errors: [], 
        helpers: [],
        successes: [],
    });

    const alertMessage = (message, status) => {
        setAlerts([
            ...alerts,
            {
                message: {
                    text: message,
                    status: status,
                },
            },
        ]);
    };
    
    const handleInputChange = (e) => {
        // Check if the entered value is a valid number (float or integer)

        let helpers = [];
        let successes = [];

        const inputValue = e.target.value; 

        if (inputValue != '') {
            if(inputValue < 50 )  helpers.push(t('Minimum budget is 50€/month')) 
            else if (inputValue >= 50) successes.push(t('Minimum budget is 50€/month'))

            if (!Number.isInteger(Number(inputValue))) helpers.push(t('Do not include decimals')) 
            else successes.push(t('Do not include decimals'))

            if(inputValue > 10000 )  helpers.push(t('Maximum budget is 10000€/month')) 
            else if (inputValue <= 10000) successes.push(t('Maximum budget is 10000€/month'))
        }
            setNewBudget(inputValue.trim());
            setMessages({...messages, helpers: helpers, successes: successes})
      };
    
    const proceed_witth_new_budget = () =>{
        let budgeterrors = [];  

        if (messages.helpers?.length !== 0) budgeterrors = messages.helpers;

        if (newBudget === '')  alertMessage(t('Montly budget must not be empty.'), 'Error')
            else if (newBudget < 50 || !Number.isInteger(Number(newBudget)) ) {

                setMessages({ ...messages, errors: budgeterrors });
                setNewBudget('');
            } else {
                // call some backend function
                setLoading(true);
                q.topUpQuestBudget(props?.selectedQuest?._id, newBudget)
                .then( res => {
                    console.log('res top up budget', res)
                    
                    if (res?.data?.code === "Q2012") window.location.assign(`${clients['griphook']}/${lang}/questad-checkout?category=dailyprofit&interval=month&quest=${props?.selectedQuest?._id}`)
                    else  {
                        setLoading(false);
                        alertMessage(t('Something went wrong. Try again or contact support.'), 'Error')
                    }
                })
                .catch( error => {
                    console.log(error)
                    setLoading(false);
                })
                console.log("newBudget", newBudget)
            }
    }

    if (loading) return <Main_Text_Loader text={t('Proceeding to checkout...')}/>
    return (
        <DashboardTopUp>
            <DashboardTopUpTitle>
                <h5>{props?.selectedQuest?.adInfo?.facebookPageName}</h5>
                <h3>{props?.selectedQuest?.questionnaires[1]?.answer} | {props?.selectedQuest?.questionnaires[0]?.answer}</h3>
                {props?.selectedQuest?.active == true ? 
                    <DashboardRightStatus status='active'> {t("Active quest")}</DashboardRightStatus>
                :
                    <DashboardRightStatus> {t("Paused quest")}</DashboardRightStatus>
                }
            </DashboardTopUpTitle>
            <DashboardTopUpPlan>
                <h6>{t("Do you want to top up your budget?")}</h6>
                <DashboardTopUpPlanPrice>
                    <h3>{t("Your current monthly budget is")}: {" "}</h3>
                    <h1>€{props?.selectedQuest?.questionnaires[5]?.answer},00</h1>
                    <p>{t("/month")}</p>
                    </DashboardTopUpPlanPrice>
                    <Input_ 
                        placeholder={t('Set a new budget')} 
                        value={newBudget}
                        onChange = {handleInputChange}
                        errors={messages?.errors}
                        helpers={messages?.helpers}
                        successes={messages?.successes}
                    />
                    <Actions_>
                        <div></div>
                        <Main_
                            text ={t("Next")} 
                            noPadding
                            onClick = {() =>
                                proceed_witth_new_budget()
                            }
                            iconRight = 'Check'
                        />
                    </Actions_>
            </DashboardTopUpPlan>
        </DashboardTopUp>

    )
}