import React from 'react'
import styled from 'styled-components'

const DashboardOverall = styled.div`
    margin-top: 2rem;
`

export default function Dashboard_Overall(props) {
  return (
    <DashboardOverall>
        continues days
        total months
        total Adspent
        total leads
    </DashboardOverall>
  )
}
