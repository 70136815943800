import { Cta_, device, H2, P } from 'monica-alexandria'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const HomeRunAds = styled.section`
    display: grid;
    grid-template-columns: 2fr 3fr;
    grid-gap: 4rem;

    p{
       max-width: 50rem;
    }

    @media ${device.lg} {
      grid-template-columns: 1fr;
    }

`

const HomeRunAdsLeft = styled.div`
    .Cta{
      margin-top: 4rem;
    }


`

const HomeRunAdsRight = styled.div`
    img{
        width: 80%;
        display: block;
        margin: 0 auto;
    }
`

export const Home_Run_Ads = (props) => {
  const {t} = useTranslation();
  return (
    <HomeRunAds>    
        <HomeRunAdsLeft>
            <H2>{t('You answer questions,')}<br/>{t("we run ads!")}</H2>
            <P>{t("Just answer a few easy questions about your business, and our algorithm takes over! We create campaigns, we adjust campaigns in retargeting your audience based on landing page visits and social engagement, all automatically without the need for marketing expertise by your side.")}</P>
            <Cta_ text={t("Run ads")} size='large' onClick={props.onStart}/>
        </HomeRunAdsLeft>
        <HomeRunAdsRight>
            <img src="https://genius1071.friktoriaservers.net/doitforme/morning/app/tarzan/runAds/RunAds.png" alt="Doitforme run automated ads"/>
        </HomeRunAdsRight>
    </HomeRunAds>
  )
}
