import axios from 'axios';

const protocol = process.env.REACT_APP_GRIPHOOK_BACKEND_PROTOCOL;
const url = process.env.REACT_APP_GRIPHOOK_BACKEND_HOST;
const port = process.env.REACT_APP_GRIPHOOK_BACKEND_PORT;

const get_payment_event = (questId) => {

    return new Promise((resolve, reject) => {

        axios.get(`${protocol}${url}${port}/checkout/events`)
        .then(res => {
            resolve(res);
        })
        .catch(() => {
            console.error('GET EVENT')
            resolve(false);
        })
    })
}


const cancelSubscription = (subscriptionId) => {
    return new Promise((resolve, reject) => {

        axios.post(`${protocol}${url}${port}/subscriptions/cancel_subscription`, {
                subscriptionId: subscriptionId, 
        })
        .then(res => {
            resolve(res)
        })
        .catch(() => {
            resolve(false);
        })
    })
}


const renewSubscription = (subscriptionId) => {
    return new Promise((resolve, reject) => {

        axios.post(`${protocol}${url}${port}/subscriptions/undo_cancel_subscription`, {
            subscriptionId: subscriptionId, 
        })
        .then(res => {
            resolve(res)
        })
        .catch(() => {
            resolve(false);
        })
    })
}


const getTransactions = (customer) => {
    return new Promise((resolve, reject) => {
        console.log(customer)
        let params = ``;
       
        if( customer ) params += `customer=${customer}`

        axios.get(`${protocol}${url}${port}/transactions/getStripeTransactions?${params}`)
        .then(res => {
            resolve(res?.data?.data)
        })
        .catch(() => {
            resolve(false);
        })
    })
}


const questad_customer_portal = (customer, redirect) => {
    return new Promise((resolve, reject) => {
        axios.post(`${protocol}${url}${port}/checkout/questadCustomerPortal`, {
            customer, redirect
        })
        .then(res => {
            resolve(res)
        })
        .catch(err=>{
            resolve(false)
        })
    })
}
const paymentsEndpoints = {
    get_payment_event,
    cancelSubscription,
    renewSubscription,
    getTransactions,
    questad_customer_portal
}




export default paymentsEndpoints;