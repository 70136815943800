import axios from 'axios';
import store from '../redux/store';
import { expireSession } from '../services/authentication/SessionService';
import i18n from '../localization/i18next';

const clients = require('../config/index').config
const client = process.env.REACT_APP_CLIENT;
const authClient = process.env.REACT_APP_AUTH_CLIENT;

export function jwtReqInterceptor() {

    axios.interceptors.request.use(request => {

        const lang = i18n.language ? i18n.language : 'en';

        const token = store.getState()?.user?.token;

        request.headers.Authorization = `${token}`;
        request.headers.Lang = lang;

        return request;
    });
}

export function jwtResInterceptor() {
    
    const lang = i18n.language ? i18n.language : 'en';

    axios.interceptors.response.use(response => {
        return response;
    }, err => {
        if (err.response.data.code == 403) {
           window.location.replace(`${clients[client]}/${lang}/access-denied`)
        }
        else if (err?.response?.data?.code == 401) {
            window.location.assign(`${clients[authClient]}/${lang}/auth?redirectUri=${window.location.href}`)
        }
        else if (err?.response?.data?.code == 'C5190') {
            window.location.assign(`${window.location.origin}/${lang}/?redirectUri=${window.location.href}`)
        }
    })
}
