import React from 'react'
import styled from 'styled-components'
import Current_Rotors from './Current_Rotors'

const DashboardCurrent = styled.div`

`

export default function Dashboard_Current(props) {
  return (
    <DashboardCurrent>
        <Current_Rotors 
            interval={props.interval}
            onInterval={props.onInterval}
            type={props.type}
            days={props.days}
            spentBudget = {props.spentBudget}
            totalBudget = {props.totalBudget}
            leads={props.leads}
            leadsOverview={props.leadsOverview}
            pageviews={props.pageviews}
            engagement={props.engagement}
            onLeads={props.onLeads}
            onEngagement={props.onEngagement}
            onPageviews={props.onPageviews}
        />
    </DashboardCurrent>
  )
}
