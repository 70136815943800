import { Main_, Main_Plain, device } from 'monica-alexandria';
import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components';


export const DropdownButton = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: end;

  @media ${device.md} {
     justify-content: end;
  }

`
export const DropdownButtonActions = styled.div`
  background: ${(p) => p.theme.low};
  position: absolute;
  top: 4.5rem;
  right: 0;
  border-radius: var(--smallRadius);
  padding: var(--smallPads);
  z-index: 5;

  .last{
    padding-top: 0.5rem;
    border-top: 1px solid ${(p) => p.theme.mid};
  }
`

export const Dropdown_Button = (props) => {

    const [showOptions, toggleShowOptions] = useState(false);
  const dropdownPopupRef = useRef(null);

  // Function to close the OptionsPopup when clicking outside
  const closeOptionsPopup = (event) => {
    if (dropdownPopupRef.current && !dropdownPopupRef.current.contains(event.target)) {
      toggleShowOptions(false);
    }
  };

  useEffect(() => {
    // Add event listener when component mounts
    document.addEventListener('mousedown', closeOptionsPopup);
    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener('mousedown', closeOptionsPopup);
    };
  }, []);

  return (
    <DropdownButton  ref={dropdownPopupRef}>
    <Main_ text={props.textMain} iconRight='Dropdown' onClick={()=> toggleShowOptions(!showOptions)} />
    {showOptions && 
      <DropdownButtonActions>
          <ul>
            {props.text1 ? <li><Main_Plain text={props.text1} size='medium' iconLeft={props.icon1} onClick={props.onClick1} /></li> : null}
            {props.text2 ? <li><Main_Plain text={props.text2} size='medium' iconLeft={props.icon2} onClick={props.onClick2}/></li> : null}
            {props.textLast ? <li className='last'><Main_Plain text={props.textLast} size='medium' iconLeft={props.iconLast} onClick={props.onClickLast} /></li> : null}
          </ul>
      </DropdownButtonActions>
    }
    </DropdownButton>
  )
}
