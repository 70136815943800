import { Actions_, Form, Grey_, IconSvg, Main_, PageAlwaysVisible, PageDesktopOnly, PageForty, PageIllu, Single_ } from 'monica-alexandria'
import React from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components';


const AdHelperFormWrapper = styled.div`
  padding-top: 4rem;
  h2{
    padding-bottom: 2rem
  }
`

const AdHelperTextWrapper = styled.div`
  padding-bottom: 2rem;

  h5{
    margin-bottom: 2rem;
  }
`

const AdHelperWhy = styled.div`

  ul{
    margin-top: 1rem;

    li{
      display: grid;
      grid-template-columns: auto 1fr;
      align-items: center;
      grid-column-gap: 1rem;
      margin-bottom: 1rem;

      svg{
        width: var(--normalIcon);
        fill: var(--main);
      }

      p{
        grid-column-start: 2;
        grid-column-end: 3;
        grid-row-start: 2;
        grid-row-end: 3;
      }
    }
  }
`
export default function AD_HELPER() {
    const navigate = useNavigate();
    const {lang} = useParams();
    const {t} = useTranslation();
  return (
    <PageForty>
      <PageAlwaysVisible>
          <AdHelperFormWrapper>
            <Form>
            <AdHelperTextWrapper>
                <h2>{t("Create Ad Displays")}</h2>
                <h5>{t("In this step, you will craft an ad creative. Creating an ad is like sending out a powerful signal to draw potential customers directly to your business.")}</h5>
                
                <h5>{t("It’s the first step in a joursney where your product or service gets showcased to the world, highlighting its unique value and how it can solve problems or fulfill needs.")}</h5>
            </AdHelperTextWrapper>
            <AdHelperWhy>
              <p>{t("Why create an ad?")}</p>
              <ul>
                <li>
                  <IconSvg Icon='Check' />
                  <h6>{t("Targeted Audience")}</h6>
                  <p>{t("Ads allow you to reach a specific audience based on demographics, interests, and behaviors.")}</p>
                </li>
                <li>
                  <IconSvg Icon='Check' />
                  <h6>{t("Brand Visibility")}</h6>
                  <p>{t("Ads help increase brand visibility and awareness among potential customers.")}</p>
                </li>
                <li>
                  <IconSvg Icon='Check' />
                  <h6>{t("Lead Generation")}</h6>
                  <p>{t("Ads can generate leads by encouraging users to take action, such as signing up for a newsletter or requesting more information.")}</p>
                </li>
                <li>
                  <IconSvg Icon='Check' />
                  <h6>{t("Conversion Tracking")}</h6>
                  <p>{t("Ads provide insights into user actions, allowing you to track conversions and optimize your campaigns.")}</p>
                </li>
              </ul>
            </AdHelperWhy>
            <Actions_>
              <Grey_ text={t("Back")} iconLeft='Back'  onClick={()=> navigate(-1)}/>
              <Main_ text={t("Next")} iconRight = 'Next' onClick={()=> navigate(`/${lang}/create-ad`)}/>
            </Actions_>
            </Form>
          </AdHelperFormWrapper>

      </PageAlwaysVisible>
      <PageDesktopOnly>
        <PageIllu>
          <img src='https://genius1071.friktoriaservers.net/doitforme/morning/app/tarzan/root/HeaderHero.svg' />
        </PageIllu>
      </PageDesktopOnly>
    </PageForty>
  )
}
