import React from 'react'
import styled from 'styled-components'
import Subscription_Card from './Subscription_Card'
import { Builder_, Card, Input_, Slider_ } from 'monica-alexandria'
import { useTranslation } from 'react-i18next'

const DashboardSubscription = styled.div``

const DashboardSubscriptionTopUp = styled.div``


const DashboardSubscriptionStatus = styled(Card)`
    padding: 1rem 2rem;
    background: ${p => p.theme.low};
    margin-bottom: 2rem;
    border: 2px solid var(--warning);

    h5{
        color: var(--warning);
    }
`

export default function Dashboard_Subscription(props) {

  const {t} = useTranslation();

  return (
    <DashboardSubscription>
      {props.isOldBudgetUser ?
      <DashboardSubscriptionStatus>
        <h5>{t("You have a legacy subscription for this quest. If you cancel this subscription, the new pricing policy of a flat 13% commission (excluding VAT) will apply to any future quests. Please note that once canceled, the legacy rate cannot be reinstated.")}</h5>
      </DashboardSubscriptionStatus>
      :null}
        <Subscription_Card
          isPocketAgencyUser={props.isPocketAgencyUser}
          amountPaid={props.amountPaid}
          adspend={props.adspend}
          amountTopUp={props.amountTopUp}
          subscriptionStatus={props.subscriptionStatus}
          subscriptionNextPayment={props.subscriptionNextPayment}
          onSubscriptionTopup={props.onSubscriptionTopup}
          onSubscriptionDetails={props.onSubscriptionDetails}
          onSubscriptionCancel={props.onSubscriptionCancel}
          subscriptionCancelOnPeriodEnd={props.subscriptionCancelOnPeriodEnd}
          onSubscriptionRenew={props?.onSubscriptionRenew}
          customerId={props.customerId}
          isOldBudgetUser={props.isOldBudgetUser} 
         
          />
    </DashboardSubscription>
  )
}
