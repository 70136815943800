import React from 'react'
import styled from 'styled-components'
import Dashboard_Quest from './Dashboard_Quest'

const DashboardDraftQuests = styled.div``

export default function Dashboard_Draft_Quests(props) {
  return (
    <DashboardDraftQuests>
        {props.draftQuests?.map((quest, i) => 
            <Dashboard_Quest 
                key={i}
                avatar={quest?.Meta?.facebookPagePicture}
                page={quest?.Meta?.facebookPageName}
                type={quest?.questionnaires[0]?.answer}
                industry={quest?.questionnaires[1]?.answer}
                totalBudget={quest?.questionnaires[5]?.answer}
                spentBudget = {quest?.questionnaires[6]?.answer}
            />
        )}
    </DashboardDraftQuests>
  )
}
