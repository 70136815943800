import React from 'react'
import styled from 'styled-components'
import Dashboard_Current from './current/Dashboard_Current'
import Dashboard_Graph from './graph/Dashboard_Graph'
import Dashboard_Overall from './overall/Dashboard_Overall'
import { Grey_Link, device } from 'monica-alexandria'
import Dashboard_Status from './status/Dashboard_Status'
import { useTranslation } from 'react-i18next'

const DashboardStats= styled.div``

const DashboardStatsShortcuts = styled.div`
  display: flex;
  align-items: center;
  gap: 2rem;
  margin-bottom: 2rem;

  @media ${device.sm} {
    /* flex-direction: column;
    align-items: flex-start; */
    display: none;
  }
`

export default function Dashboard_Stats(props) {

  const {t} = useTranslation()

  return (
    <DashboardStats>
        <DashboardStatsShortcuts>
          {props.type === 'services' ?
            <Grey_Link noPadding iconLeft="EyeOpen" text={t("View leads")} onClick={props.onViewLeads} />
          :
            null
          }
          {props.isPocketAgencyUser ?  null
          : <Grey_Link noPadding iconLeft="User" text={t("Edit Quest")} onClick={props.onEditQuest} /> }
          {/* <Grey_Link noPadding iconLeft="Payment" text={t("Edit Subscription")} onClick={props.onEditSubscription} /> */}
        </DashboardStatsShortcuts>
          <Dashboard_Status 
            status={props.status}
          />
          <Dashboard_Current
            interval={props.interval}
            onInterval={props.onInterval}
            type={props.type}
            days={props.days} 
            totalBudget = {props.totalBudget}
            spentBudget = {props.spentBudget}
            pageviews={props.pageviews}
            engagement={props.engagement}
            leads={props.leads}
            leadsOverview={props.leadsOverview}
            onLeads={props.onLeads}
            onEngagement={props.onEngagement}
            onPageviews={props.onPageviews}
          />
          {/* <Dashboard_Overall /> */}
          <Dashboard_Graph 
            graphTotal = {props.graphTotal}
            graphTitle = {props.graphTitle}
            graphData = {props.graphData}
            kpis = {props.kpis}
            onKpi = {props.onKpi}
            kpiSelected = {props.kpiSelected}
          />
    </DashboardStats>
  )
}
