import { AlertContext, Builder_, Color_Picker, Grey_, Grey_Link, IconSvg, Input_, Main_Plain, On_Click_Card, On_Stat, Red_, Slider_, Toggle_, Toggle_Stat } from 'monica-alexandria'
import React, { useContext, useEffect, useRef, useState, useTransition } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { LandingContext } from '../../context/LandingContext'

const LandingForm = styled.div`

`
const LandingFormFields = styled.div`
    .FormField{
        margin-bottom: 1.5rem;
    }
`

const FormMultipleChoiceInput = styled.div`
    padding-left: 2rem;
      
`

export const Landing_Form_Edit_New = (props) => {
    const {t} = useTranslation();
    const {landing, setLanding} = useContext(LandingContext);
    const {alerts, setAlerts} = useContext(AlertContext);

    const [customFieldType, setCustomFieldType] = useState('')
    const [customFields, setCustomFields] = useState([{title:'', choices: ['', ''], type:''}])

    
    const toggleField = (fieldId) => {
        
        let tempFields = [...landing.defaultFormFields];
        
        if (tempFields.includes(fieldId)){
            tempFields = tempFields.filter(id => id !== fieldId);

            if ( !tempFields.some((field) => field === 'Tel' || field === 'Email')  ){
                alertMessage(t('At least one contact field is needed.'), 'Loading')
                tempFields = [...tempFields, fieldId === 'Tel' ? 'Email' : 'Tel'];
            } 
            
        } else {
            tempFields = [...tempFields, fieldId]

            if (fieldId === 'FullName') {
                // Remove 'FirstName' and 'LastName' if 'FullName' is added
                tempFields = tempFields.filter(id => id !== 'Name' && id !== 'Surname');
            } else if (fieldId === 'Name' || fieldId === 'Surname') {
                // Remove 'FullName' if 'FirstName' or 'LastName' is added
                tempFields = tempFields.filter(id => id !== 'FullName');
            }
        }

        setLanding({
          ...landing,
          defaultFormFields: tempFields,
        });
    };


    // const [customFields, setCustomFields] = useState({type:})
    const alertMessage = (message, status) => {
        setAlerts([
            ...alerts,
            {
                message: {
                    text: message,
                    status: status,
                },
            },
        ]);
    };

    const suggestions = [
        (t('What products are you interested in?')),
        (t('Which sessions are you interested in attending?')),
        (t('What industry do you work in?')),
        (t('What type of industry do you own?'))
    ];
   


    // const handleAddChoice = (index) => {
    //     console.log('index' , index);
    //     setCustomFields((prevState) => {
    //         const newChoices = [...prevState.choices];
    //         newChoices.splice(index + 1, 0, ''); // Insert a new empty string after the current index
    //         return {
    //             ...prevState,
    //             choices: newChoices
    //         };
    //     });
    // };
   // checks that the user has selected at least one contact field 
  
    console.log('customFields', customFields);
    return (
        <LandingForm>
            <Builder_ title={t("Select the fields you'd like to include in your contact form ")}>
                <LandingFormFields>
                    <Toggle_Stat
                        active={landing?.defaultFormFields?.includes('Name')}
                        id='Name'
                        text={t("First Name")}
                        onClick={() => toggleField('Name')}
                        className='FormField'
                    />
                    <Toggle_Stat
                        active={landing?.defaultFormFields?.includes('Surname')}
                        id='Surname'
                        text={t("Last Name")}
                        onClick={() => toggleField('Surname')}
                        className='FormField'
                    />
                    <Toggle_Stat
                        active={landing?.defaultFormFields?.includes('FullName')}
                        id='FullName'
                        text={t("Full Name")}
                        onClick={() => toggleField('FullName')}
                        className='FormField'
                    />
                    <Toggle_Stat
                        active={landing?.defaultFormFields?.includes('City')}
                        id='City'
                        text={t("City")}
                        onClick={() => toggleField('City')}
                        className='FormField'
                    />
                </LandingFormFields>
            </Builder_>
            <Builder_ title={t('Select at least one contact field')}>
                <LandingFormFields>
                    <Toggle_Stat
                        active={landing?.defaultFormFields?.includes('Tel')}
                        id='Tel'
                        text={t("Phone number")}
                        onClick={() => toggleField('Tel')}
                        className='FormField'
                    />
                    <Toggle_Stat
                        active={landing?.defaultFormFields?.includes('Email')}
                        id='Email'
                        text={t("Email")}
                        onClick={() => toggleField('Email')}
                        className='FormField'
                    />
                </LandingFormFields>
            </Builder_>
   
        </LandingForm>
    );
};