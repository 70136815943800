import store from "../../redux/store";
import questEndpoints from "../../services/api/Quests";

const check_status_of_quests_and_organize_them_in_sections = (quests, setQuests, setLoading, health) => {
    console.log('QUESTSSSSSSSSSSSSS--------------------------------------');
    console.log('stin ygeia mas', health);

    let questsWithStatus = JSON.parse(JSON.stringify(quests));

    questsWithStatus?.forEach(quest => {
        quest['campaignStatus'] = health[quest?._id]
    })

    setQuests(questsWithStatus);
}

const displayQuests = (status, quests) => {

    if (status == 'PAUSED') {
        return quests?.quests?.filter((quest) => quests?.pausedQuests?.includes(quest._id)) || [];
    }
    else if (status == 'OLD') {
        return quests?.quests?.filter((quest) => quests?.oldQuests?.includes(quest._id)) || [];
    }
    else if (status == 'ACTIVE') {
        return quests?.quests?.filter((quest) => quests?.activeQuests?.includes(quest._id)) || [];
    }
    else if (status == 'DRAFT') {
        return quests?.quests?.filter((quest) => quest?.draft) || [];
    }
    else return [];
}


const check_for_drafts = (quests, navigate, lang, setConfirmation) => {

    let foundDraft = false;
    quests?.quests?.forEach(element => {
        if (element?.draft == true) foundDraft = element?._id;
    });
    if (foundDraft) setConfirmation(foundDraft);
    else {
        // notifies frontend that the flow we are about to enter is "START_NEW_QUEST"
        store.dispatch({type:'REUSE_LANDING', payload: 'NOT_DECIDED'});
        store.dispatch({type:'REUSE_AD', payload: 'NOT_DECIDED'});
        store.dispatch({type:'ENTER_NEW_FLOW', payload: "START_NEW_QUEST"});
        navigate(`/${lang}/questionnaire/alpha`, {state: {flow: 'START_NEW_QUEST'}});
    }
}

const check_for_existing_lead_field = (leads, fieldName) => {

    if (!leads?.length) return false;

    let detected = false;

    leads.forEach(lead => {
        if (fieldName == 'CustomField' && (typeof(lead?.CustomField) == 'string' || lead?.CustomField?.key || lead?.CustomField?.length)) {
            detected = true;
        }
        else if (lead[fieldName] && fieldName !== 'CustomField') detected = true;
    })

    return detected;
}

const handle_top_up_and_restart = async (quest, customer, newBudget) => {
    console.log(quest, customer, newBudget)
    try {
        let next_action;
        const res = await questEndpoints.topUpQuestBudgetandRestart(quest, customer, newBudget);
        console.log('res top up budget', res);

        if (res?.data?.code === "Q2013") {
            console.log(res?.data);
            let failed_inv_for_quest = res?.data?.failedCharges?.data?.filter(item => item?.status === "open" && item?.subscription?.status !== "canceled" && item?.subscription?.metadata?.questId === quest);
            console.log("failed_inv_for_quest", failed_inv_for_quest);

            if (failed_inv_for_quest?.length > 0) {
                next_action = { payment_missing: true, redirectUrl: failed_inv_for_quest[0]?.hosted_invoice_url };
            } else {
                next_action = { payment_missing: false};
            }
        } else {
            if (parseInt(res?.data?.data?.code) >= 4000 && parseInt(res?.data?.data?.code) < 5000) {
                next_action = false;
            } else {
                next_action = true;
            }
        }

        return next_action;
    } catch (error) {
        console.log(error);
        return false;
    }
};

export {
    check_status_of_quests_and_organize_them_in_sections,
    check_for_existing_lead_field,
    check_for_drafts,
    displayQuests,
    handle_top_up_and_restart
}