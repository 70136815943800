import { AlertContext, Builder_, Color_Picker, Input_, Slider_, Toggle_, Toggle_Stat } from 'monica-alexandria'
import React, { useContext, useState, useTransition } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { LandingContext } from '../../context/LandingContext'

const LandingForm = styled.div`

`
const LandingFormFields = styled.div`
    .FormField{
        margin-bottom: 1.5rem;
    }
`
export const Landing_Form_Slider = (props) => {
    const {t} = useTranslation();
    const {landing, setLanding} = useContext(LandingContext);
    const {alerts, setAlerts} = useContext(AlertContext);
    const [customField, setCustomField] = useState(landing?.customFormField?.label);

    const alertMessage = (message, status) => {
        setAlerts([
            ...alerts,
            {
                message: {
                    text: message,
                    status: status,
                },
            },
        ]);
    };
   
   // checks that the user has selected at least one contact field 
    const toggleField = (fieldId) => {
        
        let tempFields = [...landing.defaultFormFields];
        
        if (tempFields.includes(fieldId)){
            tempFields = tempFields.filter(id => id !== fieldId);

            if ( !tempFields.some((field) => field === 'Tel' || field === 'Email')  ){
                alertMessage(t('At least one contact field is needed.'), 'Loading')
                tempFields = [...tempFields, fieldId === 'Tel' ? 'Email' : 'Tel'];
            } 
            
        } else {
            tempFields = [...tempFields, fieldId]
        }

        setLanding({
          ...landing,
          defaultFormFields: tempFields,
        });
    };
      

    console.log('customFieldcustomFieldcustomFieldcustomFieldcustomField', landing);

  return (
    <LandingForm>

            <Builder_ title={t("Select the fields you'd like to include in your contact form ")}>
                <LandingFormFields>
                    <Toggle_Stat
                        active={landing?.defaultFormFields?.includes('Surname')}
                        id='Surname'
                        text={t("Surname")}
                        onClick={() => toggleField('Surname')}
                        className='FormField'
                    />
                </LandingFormFields>
            </Builder_>
            <Builder_ title={t('Select at least one contact field')}>
                <LandingFormFields>    
                    <Toggle_Stat
                        active={landing?.defaultFormFields?.includes('Tel')}
                        id='Tel'
                        text={t("Phone number")}
                        onClick={() => toggleField('Tel')}
                        className='FormField'
                    />
                    <Toggle_Stat
                        active={landing?.defaultFormFields?.includes('Email')}
                        id='Email'
                        text={t("Email")}
                        onClick={() => toggleField('Email')}
                        className='FormField'
                    />
                </LandingFormFields>    
            </Builder_>
           
            <Builder_ title={t("Custom field")}>
                <LandingFormFields>    
                        <Input_ 
                            required
                            // value={props.customField} 
                            defaultValue={landing?.customFormField[0]?.label} 
                            placeholder={t("Field title")}  
                            onChange={(e) => {
                                const newLabel = e.target.value;
                                setLanding((prevLanding) => {
                                    const newCustomFormField = [...prevLanding.customFormField];
                                    newCustomFormField[0] = {
                                        ...newCustomFormField[0],
                                        label: newLabel,
                                    };
                                    return {
                                        ...prevLanding,
                                        customFormField: newCustomFormField,
                                    };
                                });
                            }}
                            tips={[
                                (t('e.g. What kind of business do you have?')),
                            ]} 
                        />
                </LandingFormFields>
            </Builder_>
    </LandingForm>
  )
}
