import { Main_, Main_Plain, PageFull } from 'monica-alexandria'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import styled from 'styled-components'
import q from '../../services/api/Quests'
import { useNavigate, useParams } from 'react-router-dom';

const RedirectEshopUrl = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-top: 10rem;
    gap: 4rem;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    background: ${(p) => p.theme.low};
    z-index: 10000;

  main {
    position: fixed;
    top: 1rem;
    left: 4rem;
    height: 4rem;
  }
`;


const RedirectEshopUrlContent = styled.div`
    display: flex;
  align-items: center;
  justify-content: center;
  /* h1{
    padding-bottom: 2rem;
  } */

`
const RedirectEshopUrlImg = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
    img{
        width: 80%;
        max-width: 25rem;
    }
`
export default function REDIRECT_ESHOP_URL() {
    const {t} = useTranslation();
    const [eShopUrl, setEshopUrl] = useState('');
    const {questId} = useParams();
    const {lang} = useParams();
    const navigate = useNavigate();

    const get_my_quest = () => {
        if (questId) {
            q.getQuest(questId).then(res => {
                if (res?.data?.data) setEshopUrl(res?.data?.data?.eShopUrl);
                else navigate(`${lang}/page-not-found`)
            })
        }
    }

    const extractBaseUrl = (url) => {
      try {
          const baseURL = new URL(url).origin;
          return baseURL.replace(/^https?:\/\//, '');
      } catch (error) {
          return "Invalid URL";
      }
  };
  

    useEffect(() => {
        get_my_quest();
    },[])


  return (
    <RedirectEshopUrl>
        <RedirectEshopUrlImg>
        <img src='https://genius1071.friktoriaservers.net/doitforme/logo/logo_light.svg' /> 
        </RedirectEshopUrlImg>
          <h1>{t("Click to visit the e-shop")}</h1>
          <h3>{extractBaseUrl(eShopUrl)}</h3>
        <RedirectEshopUrlContent>
            <Main_ iconRight='Next' noPadding text={t('Visit')} onClick={()=> window.location.replace(eShopUrl)}/>
        </RedirectEshopUrlContent>
    </RedirectEshopUrl>
  )
}
