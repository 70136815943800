import { Single_ } from 'monica-alexandria'
import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

export default function FB_PAGES_NOT_FOUND() {

  const navigate = useNavigate();
  const {lang} = useParams();
  const {t} = useTranslation();

  const createFacebookPage = () => {
    const facebookPageCreationURL = 'https://www.facebook.com/pages/create/';
    window.open(facebookPageCreationURL, '_blank');
  };
 
  return (
   
    <Single_
        backgroundImage="https://genius1071.friktoriaservers.net/doitforme/morning/app/boiler/PageNotFound.svg"
        iconGrey="Rocket"
        iconMain="Rocket"
        onClickGrey= {()=> createFacebookPage()}
        onClickMain= {() =>  navigate(`/${lang}/facebook-login/`)}
        subtitle={t("Facebook pages not found")}
        text={t("You need to have a Facebook page to run ads.")}
        textGrey={t("Create page")}
        textMain={t("Connect to a different account")}
        title={t("Oops")}
    />
  )
}
